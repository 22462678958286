export const headCells = [
  { id: 'Srno', label: 'SrNo.', align: 'left', maxWidth: 70 },
  { id: 'requirement_id', label: 'Requirement Id', align: 'left' },
  { id: 'field_sector', label: 'Sector', align: 'left' },
  { id: 'field_company', label: 'Company', align: 'left' },
  { id: 'field_process', label: 'Description', align: 'left' },
  { id: 'field_verification_type', label: 'Status', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'right', maxWidth: 100 }
];

export const rows = [
  {
    Srno: 1,
    reqId: 'REQ121',
    category: 'Equipment',
    description: 'need all the products',
    products: [
      {
        productId: 'PROD121',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      },
      {
        productId: 'PROD122',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      },
      {
        productId: 'PROD123',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      }
    ]
  },
  {
    Srno: 2,
    reqId: 'REQ122',
    category: 'Equipment',
    description: 'need all the products',
    products: [
      {
        productId: 'PROD121',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      },
      {
        productId: 'PROD122',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      },
      {
        productId: 'PROD123',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      }
    ]
  },
  {
    Srno: 3,
    reqId: 'REQ123',
    category: 'Equipment',
    description: 'need all the products',
    products: [
      {
        productId: 'PROD121',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      },
      {
        productId: 'PROD122',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      },
      {
        productId: 'PROD123',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      }
    ]
  },
  {
    Srno: 4,
    reqId: 'REQ124',
    category: 'Equipment',
    description: 'need all the products',
    products: [
      {
        productId: 'PROD121',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      },
      {
        productId: 'PROD122',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      },
      {
        productId: 'PROD123',
        productName: 'bars',
        siteLocation: 'silvassa',
        monthlyQty: 100,
        targetPrice: 1000,
        deliveryTerms: 'delivery on time'
      }
    ]
  }
];
