import { useEffect, useState } from 'react';
// material
import { Box, Grid, Container, Typography } from '@mui/material';

// components
import Page from '../components/Page';
import {
  AppTasks,
  Verified,
  Pending,
  Completed,
  TotalReq,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  RfxChart,
  UserProfile,
  UpcomingAuction,
  ActiveAuction,
  PendingAuction,
  TotalAuction,
  Activities,
  RfxPieChart
} from '../components/_dashboard/app';
import { token, userData } from '../utils/helper';
import {
  getActiveRfx,
  getInvitations,
  getParticipated,
  getUpcoming,
  getVendorDetails
} from '../apis/VendorApi';

import RfxChartSkeleton from '../components/_dashboard/app/skeleton/RfxChartSkeleton';
import PieChartSkeleton from '../components/_dashboard/app/skeleton/PieChartSkeleton';
// import UpcomingAuction from 'src/components/_dashboard/app/UpcomingAuction';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { fullName } = userData;
  const [data, setData] = useState();
  const [pendingRows, setPendingRows] = useState([]);
  const [upcomingRows, setUpcomingRows] = useState([]);
  const [activeRows, setActiveRows] = useState([]);
  const [participatedRows, setParticipatedRows] = useState([]);
  const [loading, isLoading] = useState(true);
  // const navigate = useNavigate();
  const getList = async () => {
    await getInvitations(token)
      .then((r) => {
        setPendingRows(r.data.result);
      })
      .catch((e) => console.log(e.message));

    await getUpcoming(token)
      .then((r) => {
        setUpcomingRows(r.data.result);
      })
      .catch((e) => console.log(e.message));

    await getActiveRfx(token)
      .then((r) => {
        setActiveRows(r.data.result);
      })
      .catch((e) => console.log(e.message));

    await getParticipated(token)
      .then((r) => {
        setParticipatedRows(r.data.result);
      })
      .catch((e) => console.log(e.message));
    isLoading(false);
  };

  useEffect(() => {
    getList();
  }, []);

  const getDetails = async () => {
    const d = await getVendorDetails(token);

    if (d.status === true) {
      setData({
        name: d.result.vendor_name,
        email: d.result.vendor_email,
        mobile: d.result.vendor_mobile_number,
        address: d.result.vendor_address
      });
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            Welcome, <span style={{ color: '#b98036' }}>{fullName}</span>
          </Typography>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={3}>
            <UpcomingAuction />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TotalAuction />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PendingAuction />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ActiveAuction />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <UserProfile data={data} />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Activities />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            {loading ? (
              <RfxChartSkeleton />
            ) : (
              <RfxChart
                pendingRows={pendingRows}
                upcomingRows={upcomingRows}
                activeRows={activeRows}
                participatedRows={participatedRows}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {/* <AppCurrentSubject /> */}
            {loading ? (
              <PieChartSkeleton />
            ) : (
              <RfxPieChart
                pendingRows={pendingRows}
                upcomingRows={upcomingRows}
                activeRows={activeRows}
                participatedRows={participatedRows}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {/*     A */}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/*  <AppTrafficBySite /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/*  <AppTrafficBySite /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {/*  <AppTasks /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
