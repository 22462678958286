import { AddCircleTwoTone, Backup, Delete, EditTwoTone } from '@material-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { enqueueSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import FullDialog from '../../../../components/_dashboard/app/FullDialog';
import { createRfx } from '../../../../apis/BuyerApi';
import { sleep } from '../../../../utils/helper';

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 150,
      maxWidth: 170
    },
    drip: {
      minHeight: '130px',
      maxHeight: '130px',
      color: 'grey',
      backgroundColor: '#FFF7CD',

      fontSize: 14
    }
  })
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const valSchema = Yup.object().shape({
  rfxName: Yup.string().nullable().required('RFX name is required'),
  field_minimum_bid_change: Yup.string().required('Bid Change is required'),
  attachment: Yup.array()
    .min(1, 'Select atleast one file')
    .required('Attachment is required')
    .nullable(),
  field_incremental_closing_time_m: Yup.number()
    .typeError('you must specify a number')
    .required('Incremental time is required'),
  field_change_value: Yup.number()
    .typeError('you must specify a number')
    .required('Change value is required')
});

const NewRfx = ({ getList, onSubmit, editData }) => {
  const initalState = {
    rfxName: editData ? editData.name : '',
    attachment: [],
    desc: editData ? editData.field_product_description : '',
    field_incremental_closing_time_m: editData ? editData.field_incremental_closing_time_m : '',
    field_minimum_bid_change: editData ? editData.field_minimum_bid_change : 'Amount',
    field_change_value: editData ? editData.field_change_value : ''
  };
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState(
    editData ? editData.field_product_catalogue : ''
  );
  const classes = useStyles();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  // const handleChange = (key, value) => {
  //   setFormData({
  //     ...formData,
  //     [key]: value
  //   });
  // };

  const handleRfxSubmit = async () => {
    // const fileBase64 = allFiles ? await getBase64(allFiles[0]) : null;
    setLoading(true);
    const params = {
      productFile: values.attachment,
      name: values.rfxName,
      description: values.desc,
      field_incremental_closing_time_m: values.field_incremental_closing_time_m,
      field_minimum_bid_change: values.field_minimum_bid_change,
      field_change_value: values.field_change_value
    };
    if (editData) params.rfx_id = editData.nid;
    if (currentAttachment == null) params.productFile = 2;
    if (currentAttachment) params.productFile = 1;
    if (values.attachment.length > 0) params.productFile = values.attachment;

    await createRfx(params)
      .then(async (res) => {
        setLoading(false);
        if (res.status) {
          if (editData) {
            enqueueSnackbar('RFX Updated Successfully!', {
              variant: 'success'
            });
          } else {
            enqueueSnackbar('New RFX Created Successfully!', {
              variant: 'success'
            });
          }

          await getList();
          handleClose();
          resetForm();

          if (onSubmit) onSubmit(res.result);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: initalState,
    validationSchema: valSchema,
    onSubmit: async () => {
      setFieldError('attachment', '');

      handleRfxSubmit();
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    resetForm,
    setFieldError,
    setValues,
    setSubmitting
  } = formik;

  useEffect(() => {
    if (editData) {
      if (currentAttachment === null && values.attachment.length === 0) {
        setFieldError('attachment', '');
      }
    }
  }, [values, currentAttachment]);
  console.log(errors, 'errors');

  const handleClose = () => {
    setOpen(false);
  };

  const fileReject = () => {
    // setSnack({
    //   open: true,
    //   color: 'error',
    //   message: `Only pdf and image file is allowed`
    // });
  };

  const handleDeleteAttachment = () => {
    setCurrentAttachment(null);
  };

  return (
    <>
      {editData ? (
        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
            <EditTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
          </ListItemIcon>
          Edit RFX
        </MenuItem>
      ) : (
        <Button variant="contained" onClick={() => setOpen(true)} size="small">
          <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
          {editData ? 'Update' : 'New'} RFX
        </Button>
      )}
      {/* <Button variant="contained" onClick={() => setOpen(true)} size="small">
        <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
        New RFX
      </Button> */}
      <FullDialog
        open={open}
        setOpen={setOpen}
        headName="New RFX"
        subHead="Field marked as * are required"
        maxWidth="md"
        minWidth="md"
        onClose={handleClose}
      >
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack gap={2}>
                <TextField
                  label="RFX Name"
                  placeholder=""
                  {...getFieldProps('rfxName')}
                  error={touched.rfxName && errors.rfxName}
                  helperText={touched.rfxName && errors.rfxName}
                  size="small"
                  required
                  fullWidth
                />

                <TextField
                  label="Incremental closing Time (minutes)"
                  placeholder=""
                  {...getFieldProps('field_incremental_closing_time_m')}
                  error={
                    touched.field_incremental_closing_time_m &&
                    errors.field_incremental_closing_time_m
                  }
                  helperText={
                    touched.field_incremental_closing_time_m &&
                    errors.field_incremental_closing_time_m
                  }
                  type="number"
                  size="small"
                  fullWidth
                  required
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl size="small" required>
                <FormLabel>Minimum Bid Change</FormLabel>
                <RadioGroup
                  defaultValue="na"
                  {...getFieldProps('field_minimum_bid_change')}
                  error={touched.field_minimum_bid_change && errors.field_minimum_bid_change}
                  helperText={touched.field_minimum_bid_change && errors.field_minimum_bid_change}
                  row
                >
                  {/* <FormControlLabel
                    value="na"
                    control={<Radio size="small" />}
                    label={<Typography sx={{ fontSize: '0.9rem' }}>N/A</Typography>}
                  /> */}
                  <FormControlLabel
                    value="Amount"
                    control={<Radio size="small" />}
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Amount</Typography>}
                  />
                  <FormControlLabel
                    value="Percentage"
                    control={<Radio size="small" />}
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Percentage</Typography>}
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                label="Change Value"
                required
                size="small"
                {...getFieldProps('field_change_value')}
                error={touched.field_change_value && errors.field_change_value}
                helperText={touched.field_change_value && errors.field_change_value}
                inputProps={{ maxLength: 255 }}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DropzoneAreaBase
                fileObjects={values.attachment}
                showAlerts={false}
                showPreviews
                previewChipProps={{ classes: { root: classes.previewChip } }}
                showPreviewsInDropzone={false}
                useChipsForPreview
                acceptedFiles={['application/pdf', 'image/jpeg', 'image/png', 'image/bmp']}
                // getFileAddedMessage={fileAdded}
                // getFileRemovedMessage={fileRemove}
                getDropRejectMessage={fileReject}
                Icon={Backup}
                dropzoneText="Drag and drop file *"
                onChange={(files) => console.log('Files:', files)}
                onAdd={(newFileObjs) => {
                  // handleChange('attachment', newFileObjs);
                  setFieldValue('attachment', newFileObjs);
                }}
                onDelete={(newFileObjs) => {
                  // handleChange('attachment', newFileObjs);
                  setFieldValue('attachment', newFileObjs);
                }}
                filesLimit={1}
                dropzoneClass={classes.drip}
              />
              {touched.attachment && errors.attachment && (
                <FormHelperText error>{errors.attachment}</FormHelperText>
              )}

              {currentAttachment && (
                <Box>
                  Current File: &nbsp;
                  <Link target="_blank" href={currentAttachment}>
                    Attachment
                  </Link>
                  <IconButton onClick={() => handleDeleteAttachment()}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                label="Incremental closing Time (minutes)"
                placeholder=""
                value={formData.field_incremental_closing_time_m}
                onChange={(e) => handleChange('field_incremental_closing_time_m', e.target.value)}
                type="number"
                size="small"
                fullWidth
              />
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <TextField
                label="Description"
                placeholder=""
                {...getFieldProps('desc')}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack flexDirection="row" justifyContent="flex-end">
                <LoadingButton loading={loading} variant="contained" onClick={() => handleSubmit()}>
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </FullDialog>
    </>
  );
};

export default NewRfx;
