import Cookies from 'js-cookie';
import { Navigate, useRoutes } from 'react-router-dom';
import { authRoutes } from './routes/authRoutes';
// layouts
import { buyerRoutes } from './routes/buyerRoutes';
import { vendorRoutes } from './routes/vendorRoutes';
import { getRole } from './utils/helper';

// ----------------------------------------------------------------------
const userRole = getRole();
console.log(userRole);

export const routeInfo = [
  userRole === 'vendor' && vendorRoutes,
  userRole === 'buyer' && buyerRoutes,

  authRoutes,
  { path: '*', element: <Navigate to="/404" replace /> }
];

export function Router() {
  return useRoutes(routeInfo);
}
