import * as React from 'react';
import { Button, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHeading from '../../../../components/table/TableHeading';
import { Heading, ItemContainer } from '../../../../components/table/styles';
import { getComparator, stableSort } from '../../../../components/table/utils';
import { headCells } from './data';
import BasicMenu from './BasicMenu';

export default function PreviewTable({ rows, handleDelete }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filtered = stableSort(rows, getComparator(order, orderBy));

  const NotFound = filtered.length === 0;

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer sx={{ minWidth: 650 }}>
        <Table>
          <TableHeading
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows && rows.length}
            headCells={headCells}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {filtered
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const {
                  productName,
                  location,
                  targetPrice,
                  paymentMode,
                  noOfMonths,
                  totalQty,
                  payTerms,
                  confPo,
                  deliveryTerms,
                  OtherDeliveryTerms
                } = row;
                const pay = Object.entries(paymentMode);
                const res = pay.map((x) => x[1] === true && x[0]).filter((x) => x !== false);

                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{productName}</TableCell>
                    <TableCell>{location}</TableCell>
                    <TableCell>{targetPrice}</TableCell>
                    <TableCell>{paymentMode}</TableCell>
                    <TableCell>{noOfMonths}</TableCell>
                    <TableCell>{totalQty || 'N/A'}</TableCell>
                    <TableCell>{payTerms}</TableCell>
                    <TableCell>{confPo || 'N/A'}</TableCell>
                    <TableCell>
                      {deliveryTerms === 'other' ? `${OtherDeliveryTerms} days` : deliveryTerms}
                    </TableCell>
                    {/* <TableCell align="right">
                      <BasicMenu
                        menu={[
                          {
                            prop: (
                              <Link
                                sx={{ textDecoration: 'none', color: 'gray' }}
                                onClick={() => handleDelete(index)}
                              >
                                Delete
                              </Link>
                            )
                          }
                        ]}
                      />
                    </TableCell> */}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={11} />
              </TableRow>
            )}
          </TableBody>
          {NotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                  Not found
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
