/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  useMediaQuery,
  useTheme,
  Box,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AccountBoxTwoTone,
  AddBoxTwoTone,
  Inventory2TwoTone,
  RequestQuoteTwoTone,
  ScheduleTwoTone
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import {  } from '@material-ui/icons';
// components
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';

import { UserListHead } from '../../../../components/_dashboard/user';
import NotFound from '../../../../components/_dashboard/app/NotFound';
import MobileQuotationCard from './MobileQuotationCard';
import { ordinalSuffixOf } from '../../../../utils/helper';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function QuotationList({ rows }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const TABLE_HEAD = [
    { id: 'srNo', label: 'SrNo.', align: 'left' },
    // { id: 'rfq_id', label: 'RFQ Id', align: 'left' },
    { id: 'user_name', label: 'Quotation User Name', align: 'left' },
    { id: 'product_name', label: 'Quotaion Product Name', align: 'center' },
    { id: 'amount', label: 'Quotaion Amount', align: 'center' },
    { id: 'rank', label: 'Quotation Rank', align: 'center' },
    { id: 'time', label: 'Quotation Time', align: 'right' }
    // { id: 'field_start_time_minutes_', label: 'Open time', align: 'center' },
    // { id: 'field_close_time_minutes_', label: 'Close time', align: 'center' },
    // { id: 'actions', label: 'Actions', align: 'right' }
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Page sx={{ mt: 2 }}>
      <TableContainer>
        <Scrollbar style={{ minWidth: isMdDown ? 'auto' : 850 }}>
          {isMdDown ? (
            <>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const { title, rfx_id, unique_id, invitation_id, schedule, type } = row;

                  return <MobileQuotationCard row={row} index={index} />;
                })}
              {filteredUsers.length === 0 && <NotFound />}
            </>
          ) : (
            <Table size="small">
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const title = row.pid;
                    const isItemSelected = selected.indexOf(title) !== -1;
                    const srNo = index + 1;
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{ height: 58 }}
                      >
                        <TableCell align="center">{srNo}</TableCell>
                        <TableCell align="left">{row.user_full_name}</TableCell>

                        <TableCell align="center">{row.pid}</TableCell>
                        <TableCell align="center">
                          {' '}
                          <Label
                            variant="ghost"
                            color="default"
                            style={{ fontSize: 12, padding: 12 }}
                          >
                            ₹ {row.q_amount}
                          </Label>{' '}
                        </TableCell>

                        <TableCell align="center">
                          <Label
                            variant="ghost"
                            color={row.qid_rank === '1' ? 'success' : 'warning'}
                            style={{ fontSize: 12, padding: 12 }}
                          >
                            {ordinalSuffixOf(row.qid_rank)}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          {' '}
                          {moment(parseInt(row.q_time, 10) * 1000).format('DD/MM/YYYY hh:mm A')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 58 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <NotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </Scrollbar>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}
