import { useState } from 'react';
import { Button, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BiCategory } from 'react-icons/bi';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Icon } from '@iconify/react';
import { BtnContainer, CompanyBody, CompanyHeader, Item, ItemText, useStyles } from './styles';
import FullDialog from './components/FullDialog';
import CompanyDetails from './CompanyDetails';

const CompanyCard = ({ data }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  // console.log(data);

  return (
    <>
      {data && (
        <Card>
          <CompanyHeader>
            <Icon icon="foundation:torso-business" color="#b5813e" width={50} height={50} />
            <div>
              <Typography
                sx={{ fontSize: 18, fontWeight: 700 }}
                color="text.secondary"
                align="right"
              >
                {data.field_owner_name}
              </Typography>
              <Typography color="text.secondary" sx={{ fontSize: 12 }} align="right">
                Owner
              </Typography>
            </div>
          </CompanyHeader>
          <CompanyBody>
            <Item>
              <BiCategory className={classes.icon} />
              <ItemText>{data.field_sector || ''}</ItemText>
            </Item>
            <Item>
              <BusinessIcon className={classes.icon} />
              <ItemText>{data.field_company_name}</ItemText>
            </Item>
            {/*  <Item>
              <GroupIcon className={classes.icon} />
              <ItemText>KK Group</ItemText>
            </Item> */}

            <Item>
              <LocationOnIcon className={classes.icon} />
              <ItemText>{data.field_location}</ItemText>
            </Item>
            <Item>
              <PhoneIcon className={classes.icon} />
              <ItemText>{data.field_office_landline_number || ''}</ItemText>
            </Item>
          </CompanyBody>

          <BtnContainer>
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate('/profile/projects', { state: data.project_details })}
              disabled={data.project_details == null}
            >
              View projects
            </Button>
            <Button size="small" variant="contained" onClick={() => setOpen(true)}>
              View more
            </Button>
          </BtnContainer>
          <FullDialog open={open} setOpen={setOpen} headName="Company details">
            <CompanyDetails data={data} />
          </FullDialog>
        </Card>
      )}
    </>
  );
};

export default CompanyCard;
