import React from 'react';
import { Typography } from '@mui/material';

const BidHistory = () => {
  const hello = <h1>in this bid</h1>;
  return (
    <div>
      <Typography color="primary" variant="h6" sx={{ opacity: 0.7 }}>
        Bid History
      </Typography>
    </div>
  );
};

export default BidHistory;
