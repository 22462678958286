import { Button, Divider, Grid, Paper, Stack, TextField, Typography, Tooltip } from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import { useFormik } from 'formik';
import React from 'react';
import Label from '../../../components/Label';

const ProfileInfo = ({ data }) => {
  const a = 1;
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item sm={12} xs={12}>
          <h2 style={{ padding: '.5rem', color: 'grey' }}>Profile</h2>
          <Divider />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Label
            variant="ghost"
            color="warning"
            style={{ fontSize: 20, padding: 15, margin: '10px 0' }}
          >
            {data.buyer_id}
          </Label>
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <PersonRoundedIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Typography sx={{ fontsize: 14, fontWeight: 600 }}>{data.owner_name}</Typography>
                <Typography variant="caption" color="gray">
                  Owner name
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        {/*  <Grid item sm={6} xs={12} md={4}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <BusinessCenterIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Typography sx={{ fontsize: 14, fontWeight: 600 }}>{data.designation}</Typography>
                <Typography variant="caption" color="gray">
                  Designation
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid> */}
        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2, overflow: 'hidden' }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <EmailIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Tooltip title={data.owner_email || 'N/A'} placement="bottom">
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      width: '90%',
                      overflow: ' hidden ',
                      whiteSpace: 'nowrap ',
                      fontsize: 14,
                      fontWeight: 600
                    }}
                  >
                    {data.owner_email}
                  </div>
                </Tooltip>
                {/*  <Typography sx={{ fontsize: 14, fontWeight: 600 }}>{data.owner_email}</Typography> */}
                <Typography variant="caption" color="gray">
                  Email id
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <PhoneAndroidIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Typography sx={{ fontsize: 14, fontWeight: 600 }}>
                  +91 {data.owner_mobile_number}
                </Typography>
                <Typography variant="caption" color="gray">
                  Mobile number
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <LocationOnIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Tooltip title={data.field_residence_address || 'N/A'} placement="bottom">
                  <Typography component="div" sx={{ fontsize: 14, fontWeight: 600 }}>
                    <LinesEllipsis
                      text={data.field_residence_address || 'N/A'}
                      maxLine="1"
                      ellipsis="..."
                      trimRight
                      basedOn="words"
                    />
                  </Typography>
                </Tooltip>
                <Typography variant="caption" color="gray">
                  Address
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <GroupIcon color="primary" sx={{ height: 40, width: 40 }} />

              <Stack>
                <Typography sx={{ fontsize: 14, fontWeight: 600 }}>
                  {data.group_name || '-'}
                </Typography>
                <Typography variant="caption" color="gray">
                  Group
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileInfo;
