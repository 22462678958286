import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CategoryForm from './CategoryForm';
import { getBuyerCompanies } from '../../../apis/BuyerApi';
import { token } from '../../../utils/helper';
import { ProductForm } from './addProduct/productForm';
import { formContext } from './FormContainer';

export default function FormSwitcher({
  mainFormik,
  activeStep,
  setActiveStep,
  setOpen,
  data,
  catLoading,
  setSnack,
  setFlag,
  id
}) {
  const theme = useTheme();
  const form = React.useContext(formContext);
  const productState = form.productFormik;

  const steps = [
    {
      label: 'Category',
      description: <CategoryForm mainFormik={mainFormik} />
    },
    {
      label: 'Basic',
      description: <ProductForm prod={data} setSnack={setSnack} setFlag={setFlag} />
    } /* ,
    {
      label: 'Timeline',
      description: <ProductTimeline prod={data} setSnack={setSnack} setFlag={setFlag} />
    } */
  ];
  const maxSteps = steps.length;

  /*  React.useEffect(() => {
    if (reqId !== null) {
      setActiveStep(1);
    }
  }, []); */

  const setCompany = async () => {
    await getBuyerCompanies(token).then((r) => {
      console.log(r);
      if (mainFormik.values.company) {
        const a = r.result.filter((x) => mainFormik.values.company === x.id);
        localStorage.setItem('companyName', a[0].title);
      }

      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    });
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      await mainFormik.handleSubmit();
      setCompany();
      productState.setFieldValue('paymentMode', mainFormik.values.paymentMode);
      productState.setFieldValue('payTerms', mainFormik.values.payTerms);
      productState.setFieldValue('onCredit', mainFormik.values.onCredit);
      productState.setFieldValue('confPo', mainFormik.values.confPo);
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // console.log(mainFormik.isSubmitting);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          sx={{ width: '100%' }}
          activeStep={activeStep}
          nextButton={
            activeStep === 1 ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => setOpen(true)}
                  disabled={data.length === 0 ? true : undefined}
                >
                  Preview
                </Button>
              </>
            ) : (
              <>
                <LoadingButton
                  onClick={handleNext}
                  size="small"
                  variant="outlined"
                  loading={mainFormik.isSubmitting ? true : undefined}
                  disabled={catLoading}
                >
                  Next
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </LoadingButton>
              </>
            )
          }
          backButton={
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep === 0 || (id.reqId !== '' && activeStep === 1)}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      </Box>

      <Box sx={{ width: '100%' }}>{steps[activeStep].description}</Box>
    </Box>
  );
}

// disabled={data.length === 0 && true}
/* || activeStep === maxSteps - 1 */
