import { Box, Button, Card, Grid, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import SelectInput from '../../../components/SelectInput';
import { newProductSchema } from '../../utils/validationSchema';
import { HeadingText, Helper, LabelText } from '../../styles';
import DropFiles from '../DropFiles';
import {
  getUnitOfMeasure,
  getProductList,
  getCategory,
  categoryWiseMeasure
} from '../../../../../apis/BuyerApi';
import { token } from '../../../../../utils/helper';
import Drop from '../Drop';

const c = [
  {
    tid: 60,
    name: 'real Estate'
  }
];

// const units = ['cm', 'mm', 'm', 'inch', 'foot', 'kg', 'g'];

const NewReqContainer = ({ formik }) => {
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchCategory = async () => {
    const a = await getCategory(token);
    const b = a.result.map((x) => ({ tid: x.pid, name: x.name }));
    b.push({
      tid: 'other',
      name: 'other'
    });
    // console.log(b);
    setCategories(b);
    // console.log(a);
  };

  useEffect(() => {
    fetchCategory();
  }, []);
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange
  } = formik;

  const getUnits = async () => {
    /* await getUnitOfMeasure(token).then((r) => setUnits(r.result)); */
    if (values.category !== 'other') {
      await categoryWiseMeasure(token, values.category).then((r) => setUnits(r.result));
    } else {
      await getUnitOfMeasure(token).then((r) => setUnits(r.result));
    }
  };
  useEffect(() => {
    if (values.category !== '') getUnits();
  }, [token, values.category]);

  /*  const convert = () => {};
  useEffect(() => {
    convert();
  }, [data]); */

  return (
    <Box sx={{ overflow: 'auto', padding: 3, width: 600, height: 'auto' }}>
      <Grid container spacing={5}>
        <Grid item sm={6}>
          <Stack direction="column" spacing={3}>
            <div>
              <SelectInput
                cat={values.category}
                getFieldProps={getFieldProps('category')}
                name="Category"
                dta={categories}
                error={Boolean(touched.category && errors.category)}
                helper={touched.category && errors.category}
              />
            </div>
            {/*  <TextField
              label="Delivery terms"
              id="outlined-multiline-static"
              multiline
              rows={3}
              error={Boolean(touched.deliveryTerms && errors.deliveryTerms)}
              helperText={touched.deliveryTerms && errors.deliveryTerms}
              inputProps={{ maxLength: 255 }}
              fullWidth
              {...getFieldProps('deliveryTerms')}
            /> */}
            <div>
              <SelectInput
                cat={values.unitOfMeasure}
                getFieldProps={getFieldProps('unitOfMeasure')}
                name="Unit of measure"
                dta={units}
                error={Boolean(touched.unitOfMeasure && errors.unitOfMeasure)}
                helper={touched.unitOfMeasure && errors.unitOfMeasure}
              />
            </div>
            {/* <TextField
              fullWidth
              type="text"
              label="Monthly quantity"
              {...getFieldProps('monthlyQty')}
              error={Boolean(touched.monthlyQty && errors.monthlyQty)}
              helperText={touched.monthlyQty && errors.monthlyQty}
            /> */}
            <div style={{ border: '1px solid lightgrey', padding: 10, borderRadius: 10 }}>
              <LabelText>Upload product specifications</LabelText>
              {/* <DropFiles setFieldValue={setFieldValue} val={values.productFile} /> */}
              <Drop setFieldValue={setFieldValue} val={values.productFile} />
              <Helper style={{ marginTop: 4 }}>{touched.productFile && errors.productFile}</Helper>
            </div>
          </Stack>
        </Grid>
        <Grid item sm={6}>
          <Stack direction="column" spacing={3}>
            {values.category === 'other' && (
              <TextField
                fullWidth
                type="text"
                label="if other please specify"
                {...getFieldProps('otherCategory')}
                error={Boolean(touched.otherCategory && errors.otherCategory)}
                helperText={touched.otherCategory && errors.otherCategory}
              />
            )}
            <TextField
              fullWidth
              type="text"
              label="product name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              label="Description"
              id="outlined-multiline-static"
              multiline
              rows={3}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              inputProps={{ maxLength: 255 }}
              fullWidth
              {...getFieldProps('description')}
            />
            {/*  
            <TextField
              label="Site location"
              id="outlined-multiline-static"
              multiline
              rows={2}
              error={Boolean(touched.location && errors.location)}
              helperText={touched.location && errors.location}
              inputProps={{ maxLength: 255 }}
              fullWidth
              {...getFieldProps('location')}
            />
            <TextField
              fullWidth
              type="text"
              label="Target price"
              {...getFieldProps('targetPrice')}
              error={Boolean(touched.targetPrice && errors.targetPrice)}
              helperText={touched.targetPrice && errors.targetPrice}
            /> */}
          </Stack>
        </Grid>
        {/*  <Grid item sm={12} align="center">
          <Button variant="contained" size="large" onClick={handleSubmit}>
            Add new product
          </Button>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default NewReqContainer;
