import { IconButton, Menu, MenuItem, ListItemIcon, useTheme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AccountBoxTwoTone,
  AddBoxTwoTone,
  Inventory2TwoTone,
  RequestQuoteTwoTone,
  ScheduleTwoTone
} from '@mui/icons-material';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Schedule from './schedule';
import InviteVendors from './invite-vendors';
import NewRfq from './newRfq/NewRfq';

const RfqMenu = ({ row, getList }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(row, 'menurow');

  const handleViewQuotation = () => {
    console.log(row.schedule);
    if (row.schedule.length > 0) {
      const sch = row.schedule[row.schedule.length - 1];
      const currentDate = moment(new Date()).format('DD-MM-YYYY HH:mm');
      const rfqCloseDate = moment(parseInt(sch.close_date, 10) * 1000).format('DD-MM-YYYY HH:mm');
      const res = moment(currentDate).isBefore(rfqCloseDate);
      // console.log(res, currentDate, rfqCloseDate, 'res');
      if (!res) {
        handleClose();
        navigate('/rfq/view-quotation', {
          state: { rfDet: row }
        });
      } else {
        enqueueSnackbar('RFQ in progress. You can view quatation once RFQ is completed', {
          variant: 'info'
        });
      }
    } else {
      handleClose();
      navigate('/rfq/view-quotation', {
        state: { rfDet: row }
      });
    }
  };

  const handleOpenVendorInvitation = () => {
    navigate('/rfq/vendor-invitation', {
      state: { rfDet: row, type: 'rfq' }
    });
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        // MenuListProps={{
        //   'aria-labelledby': 'long-button',
        // }}

        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        <NewRfq editData={row} getList={getList} />
        <MenuItem
          onClick={() => {
            handleClose();
            navigate('/rfq/products', {
              state: { rfDet: row, type: 'rfq' }
            });
          }}
        >
          <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
            <Inventory2TwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
          </ListItemIcon>
          Products
        </MenuItem>

        <Schedule row={row} getList={getList} />

        {/* <InviteVendors rowData={row} type="rfq" /> */}
        <MenuItem onClick={() => handleOpenVendorInvitation()}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
            <AccountBoxTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
          </ListItemIcon>
          Invite Vendors
        </MenuItem>
        <MenuItem onClick={() => handleViewQuotation()}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
            <RequestQuoteTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
          </ListItemIcon>
          View Quotation
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate('/rfq/add-to-rfx', {
              state: { rfDet: row }
            });
          }}
        >
          <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
            <AddBoxTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
          </ListItemIcon>
          Add To RFX
        </MenuItem>
      </Menu>
    </div>
  );
};

export default RfqMenu;
