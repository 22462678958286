import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack
} from '@mui/material';
import Label from '../../../components/Label';
import DateSearch from './DateSearch';
import BasicMenu from './BasicMenu';

// ----------------------------------------------------------------------

const menu = [
  {
    text: 'CSV',
    goTo: '#',
    icon: 'file-csv-solid'
  }
  /* {
    text: 'PDF',
    goTo: '#',
    icon: 'file-pdf-solid'
  } */
];

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1),
  overflow: 'auto'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  fontSize: 12,

  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ReportToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function ReportToolbar({
  numSelected,
  filterName,
  onFilterName,
  setDateQuery,
  csvReport
}) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Stack direction="row" spacing={2}>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            style={{ height: 55 }}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          {/* <DateSearch setDateQuery={setDateQuery} /> */}
        </Stack>
      )}

      {/*   <Label variant="ghost" color="error" style={{ fontSize: 16, padding: 15 }}>
        <AccessTimeIcon sx={{ width: 20, height: 20, marginRight: 1 }} color="error" /> Time left:
        08:39:00
      </Label> */}
      <BasicMenu menu={menu} csvReport={csvReport} />
    </RootStyle>
  );
}
