import { makeStyles, styled } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: '.5rem',
    width: 18,
    height: 18
  },
  btn: {
    color: theme.palette.warning.lighter
  }
}));

export const CompanyHeader = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.warning.lighter,
  borderRadius: '10px',
  padding: '1rem',
  height: 90
}));

export const CompanyBody = styled('div')(({ theme }) => ({
  padding: 10,
  height: 'auto'
}));

export const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: '3px'
}));

export const ItemText = styled('div')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));
export const BtnContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'right',
  gap: 10,
  alignItems: 'flex-end',
  paddingRight: 10,
  paddingBottom: 10,
  marginBottom: 5,
  marginRight: 5,
  height: '100%'
}));
