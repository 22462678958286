/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';

import { useAutocomplete, Grid, IconButton, Paper, Chip, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block'
  },
  input: {
    // width: 200,
    fontSize: 12
  },
  listbox: {
    width: 'auto',
    fontSize: 12,
    margin: 0,

    // borderBottomLeftRadius: 6,
    // borderBottomRightRadius: 6,
    // elevation: 6,
    zIndex: 1000,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    // border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#13098e',
      color: 'white',
      cursor: 'pointer'
    },
    '& li:active': {
      backgroundColor: '#13098e',
      color: 'white'
    }
  }
}));

export default function EditableAutoCell({
  width,
  dataList,
  first,
  handleOnChange,
  name,
  data,
  val,
  freeSolo,
  handleKeyPress,
  fieldIndex,
  index,
  isDisabled,
  calculateQty,
  tableStyle,
  multiple
}) {
  const classes = useStyles();
  const [switchCell, setSwitchCell] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [medicineProperty, setMedicineProperty] = useState(multiple ? [] : '');
  const [inputVal, setInputVal] = useState('');
  console.log(val, 'vallllllllllll');
  useEffect(() => {
    setMedicineProperty(val);
  }, [data, val]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    inputValue
  } = useAutocomplete({
    id: `med${index}-${fieldIndex}`,
    options: dataList,
    getOptionLabel: (option) => option.title,
    freeSolo,
    openOnFocus: true,
    onChange: (e, val) => val && handleFeildChange(val),
    onInputChange: (e, val) => setInputVal(val),
    inputValue: inputVal,
    value: medicineProperty,
    disabled: isDisabled,
    multiple,
    onKeyDown: (event, originalHandler) => {
      if (event.key === 'Backspace') {
        event.stopPropagation();
      }
    }
    // isOptionEqualToValue
  });

  const handleFeildChange = (newVal) => {
    setMedicineProperty(newVal);

    const thisInput = document.querySelector(`input[id=med${index}-${fieldIndex}]`);
    console.log(newVal, thisInput, 'tempDathere1');
    thisInput.blur(() => {
      console.log('no blur', 'tempDat');
      getInputProps().onBlur();
    });
    if (multiple) {
      handleOnChange(name, newVal);
    } else {
      handleOnChange(name, newVal.title);
      if (name !== 'payTerms' || name !== 'credit') {
        //  console.log("")
      } else {
        handleKeyPress(`med${index}-${fieldIndex + 1}`);
      }
    }

    // console.log(newVal.title, 'fromchange');
    // console.log(data, 'fromchange');

    // updateMedCell(newVal);
  };

  // console.log(getInputProps(), 'valll');

  return (
    <td
      onFocus={() => setSwitchCell(true)}
      style={{
        padding: 0,
        borderBottom: switchCell ? '1.5px solid #13098e' : '1px solid lightgrey',
        width: width || 'auto'
      }}
      // onMouseEnter={() => setIsShown(true)}
      // onMouseLeave={() => setIsShown(false)}
    >
      <div {...getRootProps()}>
        {/* <label className={classes.label} {...getInputLabelProps()}>
          useAutocomplete
        </label> */}
        {multiple && (
          <Stack direction="row" gap={1} sx={{ maxWidth: 200, flexWrap: 'wrap', mt: 0.5 }}>
            {medicineProperty.map((x, index) => (
              <Chip
                label={x.title}
                size="small"
                onDelete={() => handleFeildChange(medicineProperty.filter((y, i) => i !== index))}
              />
            ))}
          </Stack>
        )}

        <input
          onClick={() => setSwitchCell(true)}
          onFocus={() => setSwitchCell(true)}
          onKeyDown={() => setSwitchCell(true)}
          onMouseDown={(e) => e.preventDefault()}
          className={classes.input}
          disabled={isDisabled}
          {...getInputProps()}
          // disabled={data.key == ''}
          onBlur={(e, val) => {
            setSwitchCell(false);
            getInputProps().onBlur();
            if (freeSolo && e)
              if (medicineProperty.title !== e.target.value) {
                console.log(e.target.value, 'fromblur');
                handleOnChange(name, e.target.value || '');
              }
          }}
          style={{
            //  width: width || '100%',
            width: '100%',
            height: 40,
            border: 'none',

            fontSize: 12,

            outline: 'none'
          }}
        />
        {/* <div /> */}
      </div>

      {groupedOptions.length > 0 ? (
        <Paper elevation={6} className={tableStyle.listbox} component="ul" {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })} style={{ padding: '2px 10px' }}>
              {option.title}
            </li>
          ))}
        </Paper>
      ) : null}
    </td>
  );
}
