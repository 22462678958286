/* eslint-disable camelcase */
import axios from 'axios';
import { token } from '../utils/helper';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export async function getBuyerDetails(token) {
  const dta = JSON.stringify({});

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-buyer-details`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/get-buyer-details');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-buyer-details');
      return error;
    });
}

export async function getUnitOfMeasure() {
  const dta = JSON.stringify({
    role: 'buyer'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-unit-of-measure`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/get-unit-of-measure');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-unit-of-measure');
      return error;
    });
}

export async function getProductList(token) {
  const dta = JSON.stringify({
    role: 'buyer'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-products`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/get-products');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-products');
      return error;
    });
}

export async function submitTempProduct(token, values) {
  const dta = JSON.stringify(values);

  const config = {
    method: 'post',
    url: `${serverUrl}api/submit-temp-product`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/submit-temp-product');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/submit-temp-product');
      return error;
    });
}

export async function getCategory() {
  const dta = JSON.stringify({
    role: 'buyer'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-category`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/get-category');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-category');
      return error;
    });
}

export async function postProducts(token, items, reqId, Nid) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...items,
    requ_id: reqId,
    nid: Nid,
    productType: ''
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/submit-buyer-requirement-product`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function createReq(token, values) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...values
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/submit-buyer-requirement`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/submit-buyer-requirement');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/submit-buyer-requirement');
      return error;
    });
}

export async function getBuyerRequirementList(token) {
  const dta = JSON.stringify({
    role: 'buyer'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-buyer-requirement-list`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/get-buyer-requirement-list');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-buyer-requirement-list');
      return error;
    });
}

export async function getBuyerRequirementProducts(token, Nid) {
  const dta = JSON.stringify({
    role: 'buyer',
    nid: Nid
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-buyer-requirement-product-details`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/get-buyer-requirement-product-details');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-buyer-requirement-product-details');
      return error;
    });
}

export async function getBuyerCompanies(token) {
  const dta = JSON.stringify({
    role: 'buyer'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-company-details`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/get-company-details');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-company-details');
      return error;
    });
}

export async function getProductDetails(token, tid) {
  const dta = JSON.stringify({
    role: 'buyer',
    target_id: tid
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-product-details-tid`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  const d = await axios(config)
    .then((response) => {
      console.log('api/get-product-details-tid');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-product-details-tid');
      return error;
    });

  return d;
}

export async function deleteRequirement(token, nid) {
  const dta = JSON.stringify({
    role: 'buyer',
    nid
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/delete-requirement`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  const d = await axios(config)
    .then((response) => {
      console.log('api/delete-requirement');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/delete-requirement');
      return error;
    });

  return d;
}

export async function productWiseMeasure(token, product_id) {
  const dta = JSON.stringify({
    role: 'buyer',
    product_id
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-product-wise-unit-of-measure`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  const d = await axios(config)
    .then((response) => {
      console.log('api/delete-requirement');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/delete-requirement');
      return error;
    });

  return d;
}

export async function categoryWiseMeasure(token, c_id) {
  const dta = JSON.stringify({
    role: 'buyer',
    c_id
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-category-wise-unit-of-measure`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  const d = await axios(config)
    .then((response) => {
      console.log('api/delete-requirement');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/delete-requirement');
      return error;
    });

  return d;
}

export async function createRfq(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/create-rfq`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/create-rfq');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/create-rfq');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/create-rfq');
      return error;
    });

  return d;
}

export async function getBuyerRfqList(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-buyer-rfq-list`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/get-buyer-rfq-list');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/get-buyer-rfq-list');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-buyer-rfq-list');
      return error;
    });

  return d;
}

export async function getBuyerRfqProductDetails(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-buyer-rfq-product-details`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/get-buyer-rfq-product-details');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/get-buyer-rfq-product-details');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-buyer-rfq-product-details');
      return error;
    });

  return d;
}

export async function submitBuyerNodeProduct(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/submit-buyer-node-product`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/submit-buyer-node-product');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/submit-buyer-node-product');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/submit-buyer-node-product');
      return error;
    });

  return d;
}

export async function getBuyerRfxList(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-buyer-rfx-list`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/get-buyer-rfx-list');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/get-buyer-rfx-list');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-buyer-rfx-list');
      return error;
    });

  return d;
}

export async function createRfx(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/create-buyer-rfx`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/create-buyer-rfx');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/create-buyer-rfx');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/create-buyer-rfx');
      return error;
    });

  return d;
}

export async function getRfqBidList(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-rfq-bid`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/get-rfq-bid');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/get-rfq-bid');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-rfq-bid');
      return error;
    });

  return d;
}

export async function getRfxBidList(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-rfx-all-bid`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  // console.log(token, 'api/get-rfx-all-bid');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/get-rfx-all-bid');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-rfx-all-bid');
      return error;
    });

  return d;
}

export async function addEditSchedule(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/add-edit-schedule`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/add-edit-schedule');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/add-edit-schedule');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/add-edit-schedule');
      return error;
    });

  return d;
}

export async function getVendorForInvitation(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-vendor-for-invitation`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/get-vendor-for-invitation');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/get-vendor-for-invitation');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-vendor-for-invitation');
      return error;
    });

  return d;
}

export async function submitVendorInvitation(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/submit-invitation`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/submit-invitation');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/submit-invitation');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/submit-invitation');
      return error;
    });

  return d;
}

export async function addRfqProductToRfx(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/add-rfq-product-to-rfx`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/add-rfq-product-to-rfx');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/add-rfq-product-to-rfx');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/add-rfq-product-to-rfx');
      return error;
    });

  return d;
}

export async function createNewVendor(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/create-new-vendor-onboard`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };
  console.log(token, 'api/create-new-vendor-onboard');
  const d = await axios(config)
    .then((response) => {
      console.log(token, 'api/create-new-vendor-onboard');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/create-new-vendor-onboard');
      return error;
    });

  return d;
}

export async function vendorList(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });
  const config = {
    method: 'post',
    url: `${serverUrl}api/vendor-list`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function createVendorCompany(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });
  const config = {
    method: 'post',
    url: `${serverUrl}api/create-new-vendor-com`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function uploadVendor(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });
  const config = {
    method: 'post',
    url: `${serverUrl}api/upload-vendor`,
    headers: {
      // Authorization: token,
      'Content-Type': 'multipart/form-data'
    },
    data
  };

  return axios(config);
}

export async function searchProducts(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });
  const config = {
    method: 'post',
    url: `${serverUrl}api/search-products`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function addProductByCsv(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });
  const config = {
    method: 'post',
    url: `${serverUrl}api/add-product-by-csv`,
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data'
    },
    data
  };

  return axios(config);
}

export async function invitationSendByBuyerList(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });
  const config = {
    method: 'post',
    url: `${serverUrl}api/all-invitation-send-by-buyer`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function updateInvitationStatus(data) {
  const dta = JSON.stringify({
    role: 'buyer',
    ...data
  });
  const config = {
    method: 'post',
    url: `${serverUrl}api/update-invitation-status`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}
