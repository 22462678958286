import { AddCircleTwoTone, Close } from '@material-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import FullDialog from '../../../components/_dashboard/app/FullDialog';
import { createNewVendor, createVendorCompany, getCategory } from '../../../apis/BuyerApi';
import { businessType, organizationType, serv } from '../../../utils/helper';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const AddVendorCompany = ({ vendorData, getList }) => {
  const initalCatState = {
    cat: '',
    subCat: ''
  };
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedCat, setSelectedCat] = useState(initalCatState);

  const initalState = {
    companyName: '',
    mobile: '',
    organization: '',
    business: '',
    address: ''
  };

  const formik = useFormik({
    initialValues: initalState,
    validationSchema: Yup.object().shape({
      companyName: Yup.string().nullable().required('Company name is required'),
      mobile: Yup.number()
        .typeError('you must specify a number')
        .required('Mobile Number is required'),
      organization: Yup.string().nullable().required('Organization is required'),
      business: Yup.string().nullable().required('Business type is required')
    }),
    onSubmit: async () => {
      setSubmitting(true);

      await createVendorCompany({
        vid: vendorData.nid,
        company_name: values.companyName,
        field_company_address: values.secondOwner,
        field_mobile_number_buyer: values.mobile,
        field_type_of_business: values.organization,
        field_type_of_organization: values.business
      }).then((res) => {
        if (res.data.status) {
          getList();
          enqueueSnackbar('Vendor Company Added Successfully!', {
            variant: 'success'
          });
          handleClose();
        }
      });
      setSubmitting(false);
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    setFieldError,
    setSubmitting,
    resetForm
  } = formik;

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleCatChange = (key, value) => {
    setSelectedCat({
      ...selectedCat,
      [key]: value
    });
  };

  const handleAddCategory = () => {
    if (selectedCat.cat === '') {
      setFieldError('categories', 'Please select category ');
    } else {
      setFieldValue('categories', [...values.categories, selectedCat]);
      setSelectedCat(initalCatState);
    }
  };

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
          <AddCircleTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
        </ListItemIcon>
        Add Company
      </MenuItem>

      <FullDialog
        open={open}
        setOpen={setOpen}
        headName="Add Vendor Company"
        subHead="Field marked as * are required"
        maxWidth="md"
        minWidth="md"
        onClose={handleClose}
      >
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="company Name"
                placeholder=""
                size="small"
                required
                {...getFieldProps('companyName')}
                error={Boolean(touched.companyName && errors.companyName)}
                helperText={touched.companyName && errors.companyName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                size="small"
                error={touched.organization && errors.organization}
                helperText={touched.organization && errors.organization}
                required
              >
                <InputLabel>Select Organization</InputLabel>
                <Select
                  value={values.organization}
                  label="Select organization"
                  fullWidth
                  {...getFieldProps('organization')}
                  defaultValue={values.organization}
                >
                  {organizationType.map((x) => (
                    <MenuItem value={x.name}>{x.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {touched.organization && <FormHelperText error>{errors.organization}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                size="small"
                error={touched.business && errors.business}
                helperText={touched.business && errors.business}
                required
              >
                <InputLabel>Select business</InputLabel>
                <Select
                  value={values.business}
                  label="Select business"
                  fullWidth
                  {...getFieldProps('business')}
                  defaultValue={values.business}
                >
                  {businessType.map((x) => (
                    <MenuItem value={x.name}>{x.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {touched.business && <FormHelperText error>{errors.business}</FormHelperText>}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Mobile Number"
                placeholder=""
                required
                size="small"
                type="number"
                {...getFieldProps('mobile')}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Company Address"
                placeholder=""
                size="small"
                // type="email"
                multiline
                rows={4}
                // required
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {touched.categories && errors.categories && (
                <FormHelperText error>{errors.categories}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack flexDirection="row" justifyContent="flex-end">
                <LoadingButton
                  variant="contained"
                  onClick={() => handleSubmit()}
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </FullDialog>
    </>
  );
};

export default AddVendorCompany;
