/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  useMediaQuery,
  useTheme,
  Box,
  Stack
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Info } from '@material-ui/icons';
// components
import Page from '../../../../../components/Page';
import Label from '../../../../../components/Label';
import Scrollbar from '../../../../../components/Scrollbar';
import SearchNotFound from '../../../../../components/SearchNotFound';
import { UserListHead } from '../../../../../components/_dashboard/user';
import BasicMenu from '../BasicMenu';
import { fDateTimeSuffix } from '../../../../../utils/formatTime';
import MobileCard from './MobileCard';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AucTable({
  rows,
  setOpen,
  setSumm,
  menu,
  pending,
  upcoming,
  active,
  participated
}) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const TABLE_HEAD = [
    { id: 'srNo', label: 'SrNo.', align: 'left' },
    { id: 'unique_id', label: 'Unique id', align: 'left' },
    { id: 'title', label: 'Name', align: 'left' },
    { id: 'type', label: 'Type', align: 'center' },
    { id: 'summary', label: 'Summary', align: 'center' },
    { id: 'field_start_time_minutes_', label: 'Open time', align: 'center' },
    { id: 'field_close_time_minutes_', label: 'Close time', align: 'center' },
    { id: 'actions', label: 'Actions', align: 'right' }
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleSumm = (s) => {
    setSumm(s);
    // console.log(s);
    setOpen(true);
  };

  return (
    <Page>
      <TableContainer>
        <Scrollbar style={{ minWidth: isMdDown ? 'auto' : 850 }}>
          {isMdDown ? (
            <>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const { title, rfx_id, unique_id, invitation_id, schedule, type } = row;

                  return (
                    <MobileCard
                      row={row}
                      index={index}
                      key={index}
                      selected={selected}
                      menu={menu}
                      rid={rfx_id}
                      invitationId={invitation_id}
                      lotName={title}
                      pending={pending}
                      upcoming={upcoming}
                      active={active}
                      participated={participated}
                      handleSumm={handleSumm}
                    />
                  );
                })}
              {filteredUsers.length === 0 && (
                <Stack justifyContent="center" alignItems="center" sx={{ minHeight: '69vh' }}>
                  <Info style={{ color: 'grey', fontSize: 50 }} />
                  <h3 style={{ color: 'grey' }}>No Record Found!</h3>
                </Stack>
              )}
            </>
          ) : (
            <Table size="small">
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const { title, rfx_id, unique_id, invitation_id, schedule, type } = row;

                    const openTime = new Date(schedule[0].open_date * 1000);
                    const closeTime = new Date(schedule[0].close_date * 1000);
                    const isItemSelected = selected.indexOf(title) !== -1;
                    const srNo = index + 1;
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{ height: 58 }}
                      >
                        <TableCell align="center">{srNo}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color="warning"
                            style={{ fontSize: 12, padding: 12 }}
                          >
                            {unique_id}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="center">
                          <Label
                            variant="ghost"
                            color="default"
                            style={{ fontSize: 12, padding: 12 }}
                          >
                            {type.toUpperCase()}
                          </Label>
                        </TableCell>
                        <TableCell align="center">
                          <Button size="small" onClick={() => handleSumm(row)}>
                            {/* <VisibilityOutlinedIcon /> &nbsp; */} View
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Label
                            variant="ghost"
                            color="success"
                            style={{ fontSize: 12, padding: 12 }}
                          >
                            {fDateTimeSuffix(openTime)}
                          </Label>
                        </TableCell>

                        <TableCell align="center">
                          <Label
                            variant="ghost"
                            color="error"
                            style={{ fontSize: 12, padding: 12 }}
                          >
                            {fDateTimeSuffix(closeTime)}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <BasicMenu
                            menu={menu}
                            rid={rfx_id}
                            invitationId={invitation_id}
                            lotName={title}
                            pending={pending}
                            upcoming={upcoming}
                            active={active}
                            participated={participated}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 58 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      Not found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </Scrollbar>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}
