/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  useMediaQuery,
  useTheme,
  Box,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Link
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AccountBoxTwoTone,
  AddBoxTwoTone,
  Inventory2TwoTone,
  RequestQuoteTwoTone,
  ScheduleTwoTone
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import {  } from '@material-ui/icons';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';

import { UserListHead } from '../../../components/_dashboard/user';
import NotFound from '../../../components/_dashboard/app/NotFound';
import NewRfq from './newRfq/NewRfq';
import Schedule from './schedule';
import InviteVendors from './invite-vendors';
import RfqMenu from './RfqMenu';
import MobileRfqCard from './MobileRfqCard';
import { rfqStatus } from '../../../utils/helper';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RfqList({ rows, getList, tableHead }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const getStatus = (schedule) => {
    let status = 'Not scheduled yet';

    status = 'Upcoming';
    const startDate = moment(parseInt(schedule[schedule.length - 1].open_date, 10) * 1000);
    const endDate = moment(parseInt(schedule[schedule.length - 1].close_date, 10) * 1000);
    const currentDate = moment(new Date());

    const res = currentDate.isBefore(endDate);

    if (res) {
      status = 'Upcoming';
      const isActive = currentDate.isAfter(startDate);

      if (isActive && res) {
        status = 'Active';
      }
    } else {
      status = 'Completed';
    }

    return status;
  };

  return (
    <Page sx={{ mt: 2 }}>
      <TableContainer>
        <Scrollbar style={{ minWidth: isMdDown ? 'auto' : 850 }}>
          {isMdDown ? (
            <>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const { title, rfx_id, unique_id, invitation_id, schedule, type } = row;

                  return <MobileRfqCard row={row} index={index} />;
                })}
              {filteredUsers.length === 0 && (
                <NotFound actionBtn={() => <NewRfq getList={getList} />} />
              )}
            </>
          ) : (
            <Table size="small">
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHead}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = selected.indexOf(row.title) !== -1;
                    const srNo = index + 1;

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{ height: 58 }}
                      >
                        <TableCell align="center">{srNo}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color="warning"
                            style={{ fontSize: 12, padding: 12 }}
                          >
                            {row.field_rfq_id}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="center">
                          <Box>{row.field_description}</Box>

                          {row.field_rfq_attachment && (
                            <Link target="_blank" href={row.field_rfq_attachment}>
                              Attachment
                            </Link>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.schedule.length > 0 ? (
                            <>
                              <Label
                                variant="ghost"
                                color="success"
                                style={{ fontSize: 11, padding: 12 }}
                              >
                                {moment(
                                  parseInt(row.schedule[row.schedule.length - 1].open_date, 10) *
                                    1000
                                ).format('DD-MM-YYYY hh:mm A')}
                              </Label>{' '}
                              -{' '}
                              <Label
                                variant="ghost"
                                color="error"
                                style={{ fontSize: 11, padding: 12 }}
                              >
                                {moment(
                                  parseInt(row.schedule[row.schedule.length - 1].close_date, 10) *
                                    1000
                                ).format('DD-MM-YYYY hh:mm A')}
                              </Label>
                            </>
                          ) : (
                            '-'
                          )}
                        </TableCell>

                        <TableCell align="center">
                          <Label
                            variant="ghost"
                            color="default"
                            style={{ fontSize: 12, padding: 12 }}
                          >
                            {/* {rfqStatus.find((x) => x.id === parseInt(row.status, 10)).label} */}
                            {row.schedule.length > 0 ? getStatus(row.schedule) : 'New'}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <RfqMenu row={row} getList={getList} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 58 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <NotFound actionBtn={() => <NewRfq getList={getList} />} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </Scrollbar>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}
