/* eslint-disable camelcase */
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Navigate, useNavigate, Link } from 'react-router-dom';

export default function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { menu, itemName, rid, invitationId, pending, active, upcoming, participated } = props;

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOp = () => {
    if (active) {
      localStorage.setItem('rid', rid);
      // localStorage.setItem('itemName', itemName);
      // localStorage.setItem('rfxName', lotName);
    }
    if (pending) {
      localStorage.setItem('rid', rid);
      localStorage.setItem('invId', invitationId);
    }
    if (upcoming) {
      localStorage.setItem('rid', rid);
    }
    if (participated) {
      localStorage.setItem('rid', rid);
    }

    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menu.map((x, index) => (
          <MenuItem key={index} onClick={handleOp}>
            <Link style={{ textDecoration: 'none', color: 'grey' }} to={`${x.goTo}`}>
              {x.text}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
