/* eslint-disable camelcase */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import { styled } from '@mui/styles';

import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHeading from '../../../components/table/TableHeading';
import { Heading, ItemContainer } from '../../../components/table/styles';
import { getComparator, stableSort } from '../../../components/table/utils';
import BasicMenu from '../components/BasicMenu';
import { headCells } from './data';
import FullDialog from './FullDialog';
import ProductDetailsPopup from './ProductDetailsPopup';
import ProductReqTimelinePopup from './ProductReqTimelinePopup';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '68vh',
  color: theme.palette.primary.main
}));

export default function DetailsTable({ rows }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [timelineOpen, setTimelineOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = React.useState();
  const open = Boolean(anchorEl);

  const handleClick = (event, items) => {
    // console.log(items);
    setData(items);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewDetails = () => {
    // setData(items);
    setDetailsOpen(true);
    setAnchorEl(null);
  };

  const handleViewTimeline = (items) => {
    setData(items);
    setTimelineOpen(true);
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filtered = stableSort(rows, getComparator(order, orderBy));

  const NotFound = filtered.length === 0;

  return (
    <Box sx={{ width: '100%' }}>
      {rows.length > 0 ? (
        <>
          <TableContainer sx={{ minWidth: 650 }}>
            <Table size="small">
              <TableHeading
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows && rows.length}
                headCells={headCells}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {filtered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const {
                      nid,
                      field_site_location,
                      field_field_assured_minimum_quan,
                      field_target_price_buyer,
                      field_mode_of_payment,
                      field_product,
                      field_verification_type
                    } = row;
                    const Srno = index + 1;

                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell>{Srno}</TableCell>
                        <TableCell>{field_product}</TableCell>
                        <TableCell>{field_site_location}</TableCell>
                        <TableCell>{field_target_price_buyer}</TableCell>
                        <TableCell>{field_mode_of_payment}</TableCell>
                        <TableCell>{field_verification_type}</TableCell>
                        <TableCell align="right">
                          <>
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={(e) => handleClick(e, row)}
                            >
                              <MoreVertIcon />
                            </IconButton>

                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                            >
                              <MenuItem onClick={handleViewDetails}>
                                <div style={{ color: 'grey' }}>View product details</div>
                              </MenuItem>
                              {/* <MenuItem onClick={() => handleViewTimeline(row)}>
                                <div style={{ color: 'grey' }}>View requirement timeline</div>
                              </MenuItem> */}
                            </Menu>
                          </>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows
                    }}
                  >
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              {NotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      Not found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <FullDialog open={detailsOpen} setOpen={setDetailsOpen} headName="Product details">
            <ProductDetailsPopup data={data} />
          </FullDialog>
          <FullDialog open={timelineOpen} setOpen={setTimelineOpen} headName="Requirement timeline">
            <ProductReqTimelinePopup data={data} />
          </FullDialog>
        </>
      ) : (
        <Loader>No data found</Loader>
      )}
    </Box>
  );
}
