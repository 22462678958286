import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const token = Cookies.get('jwt-token');

const decodedJwt = token ? jwtDecode(token) : null;

const userData = token ? JSON.parse(Cookies.get('log_response')) : null;

function getRole() {
  const token = Cookies.get('jwt-token');
  const decodedJwt = token ? jwtDecode(token) : null;
  return decodedJwt && decodedJwt.role;
  // return 'buyer';
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export { getRole, token, decodedJwt, userData, sleep };

export const m = [
  {
    tid: 0,
    name: 'Select'
  },
  {
    tid: 1,
    name: 1
  },
  {
    tid: 2,
    name: 2
  },
  {
    tid: 3,
    name: 3
  },
  {
    tid: 4,
    name: 4
  },
  {
    tid: 5,
    name: 5
  },
  {
    tid: 6,
    name: 6
  },
  {
    tid: 7,
    name: 7
  },
  {
    tid: 8,
    name: 8
  },
  {
    tid: 9,
    name: 9
  },
  {
    tid: 10,
    name: 10
  },
  {
    tid: 11,
    name: 11
  },
  {
    tid: 12,
    name: 12
  }
];

export const pro = [
  {
    tid: 'ex-stock',
    name: 'ex-stock'
  },
  {
    tid: 'Within 15 days',
    name: 'within 15 days'
  },
  {
    tid: 'Within 01 month',
    name: 'within 01 month'
  },
  {
    tid: 'Within 02 months',
    name: 'within 02 months'
  },
  {
    tid: 'Within 03 months',
    name: 'within 03 months'
  }
  /* {
    tid: 'other',
    name: 'other'
  } */
];

export const terms = [
  {
    tid: 'payment on delivery',
    name: 'payment on delivery'
  },
  // {
  //   tid: 'advance payments',
  //   name: 'advance payments'
  // },

  {
    tid: 'credit',
    name: 'credit'
  }
];

export const aPo = [
  {
    tid: '10%',
    name: '10%'
  },
  {
    tid: '20%',
    name: '20%'
  },
  {
    tid: '30%',
    name: '30%'
  }
];

export const creditOp = [
  {
    tid: '7 days',
    name: '7 days'
  },
  {
    tid: '15 days',
    name: '15 days'
  },
  {
    tid: '30 days',
    name: '30 days'
  },
  {
    tid: '45 days',
    name: '45 days'
  },
  {
    tid: '60 days',
    name: '60 days'
  }
];

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const rfqStatus = [
  {
    id: 0,
    label: 'Pending For Acceptance'
  },
  {
    id: 1,
    label: 'Accepted'
  },
  {
    id: 2,
    label: 'Rejected'
  },
  {
    id: 4,
    label: 'Stop'
  },
  {
    id: 5,
    label: 'Completed'
  },
  {
    id: 6,
    label: 'Paused'
  }
];

export function ordinalSuffixOf(i) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = function (e) {
      if (this.status === 200) {
        const blob = this.response;
        const file = new File([blob], url.split('/').pop(), { type: blob.type });
        resolve(file);
      } else {
        reject(new Error(`Failed to fetch file: ${this.statusText}`));
      }
    };

    xhr.onerror = function (e) {
      reject(new Error('Network error'));
    };

    xhr.send();
  });
}

export const serv = [
  { key: 'service', value: 'Service' },
  { key: 'serviceWithMaterial', value: 'Service With Material' },
  { key: 'materialOnly', value: 'Material Only' }
];

export const organizationType = [
  {
    name: 'Public enterprise'
  },
  {
    name: 'Private company'
  },
  {
    name: 'Organization sponsored(assisted by Government)'
  },
  {
    name: 'Sole proprietorship'
  },
  {
    name: 'Partnership'
  },
  {
    name: 'Regd. Private Ltd. Co.'
  },
  {
    name: 'Regd. Public Ltd Co.'
  },
  {
    name: 'Cooperative body.'
  },
  {
    name: 'Unregistered individual business'
  },
  {
    name: 'LLP registration'
  }
];

export const businessType = [
  {
    name: 'Manufacturer'
  },
  {
    name: 'Distributer'
  },
  {
    name: 'Trader'
  },
  {
    name: 'Service provider'
  },
  {
    name: 'Retailer'
  },
  {
    name: 'Authorized Agent'
  },
  {
    name: 'Consulting Company'
  }
];
