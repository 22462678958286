/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { BiRupee } from 'react-icons/bi';

import NoDataFound from '../../../components/NoDataFound';

const ProductReqTimelinePopup = ({ data }) => {
  const rows = data.requirement_timeline;

  return (
    <>
      <TableContainer sx={{ maxHeight: 300, minHeight: 300, minWidth: 300 }}>
        <Table stickyHeader aria-label="sticky table">
          {rows.length > 0 ? (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="left">SrNo.</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row, index) => {
                  const { date, qty } = row;
                  const Srno = index + 1;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.srNo}>
                      <TableCell>{Srno}</TableCell>
                      <TableCell>{date}</TableCell>
                      <TableCell>{qty}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          ) : (
            <NoDataFound height="300px" />
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductReqTimelinePopup;
