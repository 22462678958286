export const columns = [
  { label: 'SrNo.', key: 'srNo' },
  { label: 'Rfq name', key: 'title' },
  {
    label: 'Rfx unique id',
    key: 'unique_id'
  },
  {
    label: 'Open time',
    key: 'open_date'
  },
  {
    label: 'Close time',
    key: 'close_date'
  },
  {
    label: 'change value',
    key: 'field_change_value'
  },
  {
    label: 'lower limit',
    key: 'field_lower_limit'
  },
  {
    label: 'upper limit',
    key: 'field_upper_limit'
  },
  {
    label: 'Description',
    key: 'description'
  },
  {
    label: 'Contact info',
    key: 'contact_info'
  },
  {
    label: 'remarks',
    key: 'remarks'
  }
];
