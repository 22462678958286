import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import FullDialog from '../../../../components/_dashboard/app/FullDialog';
import { ordinalSuffixOf } from '../../../../utils/helper';
import Label from '../../../../components/Label';

const BidHistoryRow = ({ historyRow, ind, showName, bidList }) => {
  const [open, setOpen] = useState(false);
  const [userBids, setUserBids] = useState([]);

  useEffect(() => {
    const list = bidList.filter(
      (x) => x.pid_nid === historyRow.pid_nid && historyRow.bid_uid === x.bid_uid
    );

    setUserBids([...list]);
  }, [bidList]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <TableRow key={historyRow.bid_id}>
        <TableCell sx={{ color: 'grey' }}>{ind + 1}</TableCell>
        {showName && (
          <TableCell component="th" scope="row">
            <Box sx={{ fontWeight: '600' }}> {historyRow.user_full_name}</Box>
            <Box sx={{ fontSize: 12, color: 'gray' }}> {historyRow.company_id}</Box>
          </TableCell>
        )}

        <TableCell>₹ {historyRow.bid_amt}</TableCell>
        <TableCell>₹ {historyRow.bid_total_cost}</TableCell>
        <TableCell>
          {' '}
          <Label
            variant="ghost"
            color={historyRow.bid_rank === '1' ? 'success' : 'warning'}
            style={{ fontSize: 12, padding: 12 }}
          >
            {ordinalSuffixOf(historyRow.bid_rank)}
          </Label>
        </TableCell>
        <TableCell>{historyRow.product_minimum_qty}</TableCell>
        <TableCell align="right">
          {' '}
          {moment(parseInt(historyRow.bid_time, 10) * 1000).format('DD/MM/YYYY hh:mm A')}
        </TableCell>
        {showName && (
          <TableCell align="right">
            <Button size="small" onClick={() => setOpen(true)}>
              View
            </Button>
          </TableCell>
        )}
      </TableRow>
      <FullDialog
        open={open}
        setOpen={setOpen}
        headName="History"
        // subHead="Field marked as * are required"
        maxWidth="sm"
        minWidth="sm"
        onClose={handleClose}
      >
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell>Sr No.</TableCell>
              <TableCell>Bid Amount</TableCell>
              <TableCell align="right">Bid Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userBids.map((bid, i) => (
              <TableRow key={i}>
                <TableCell sx={{ color: 'grey' }}>{i + 1}</TableCell>
                <TableCell>₹ {bid.bid_amt}</TableCell>
                <TableCell align="right">
                  {moment(parseInt(bid.bid_time, 10) * 1000).format('DD/MM/YYYY hh:mm A')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </FullDialog>
    </>
  );
};

export default BidHistoryRow;
