/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Breadcrumbs,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { BiRupee } from 'react-icons/bi';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
// components
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { UserListHead } from '../../../../components/_dashboard/user';
import BasicMenu from '../BasicMenu';
import TableMenu from '../TableMenu';
import { fDateTimeSuffix } from '../../../../utils/formatTime';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BidTable({ rows, setOpen, setItem, active }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);

  const TABLE_HEAD = [
    { id: 'srNo', label: 'SrNo.', align: 'left' },
    // { id: 'unitCost', label: 'Unit cost', align: 'left' },
    { id: 'totalCost', label: 'Total cost', align: 'left' },
    { id: 'bidAmount', label: 'Bid amount', align: 'left' },
    { id: 'rank', label: 'Rank', align: 'left' },
    { id: 'bidTime', label: 'Bid time', align: 'left' }
  ];
  // console.log(rows, 'from product table');
  const handleViewProduct = (row) => {
    setItem(row);
    setOpen(true);
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleOp = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuOpen = Boolean(anchorEl);

  return (
    <Page title="User | Minimal-UI">
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const { bid_unit_cost, bid_total_cost, bid_amt, bid_rank, bid_time } = row;
                  const isItemSelected = selected.indexOf(bid_rank) !== -1;
                  const srNo = index + 1;

                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell>{srNo}</TableCell>

                      {/* <TableCell>
                        {' '}
                        <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                        {bid_unit_cost}
                      </TableCell> */}
                      <TableCell>
                        {' '}
                        <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                        {bid_total_cost}
                      </TableCell>
                      <TableCell>
                        {' '}
                        <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                        {bid_amt}
                      </TableCell>
                      <TableCell>{bid_rank}</TableCell>
                      <TableCell>{fDateTimeSuffix(new Date(bid_time * 1000))}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    Not found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}
