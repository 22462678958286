import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { Grid, TextField, Button, Link } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { checkOtp, getOtp } from '../../apis/LoginApi';
import SmallSnackbar from '../../components/SnackBar';

const OtpField = () => {
  window.onbeforeunload = null;
  const [timer, setTimer] = useState(100);
  const [clicked, setClicked] = useState(true);
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const [ot, setOt] = useState('');
  // const { handleChange } = props;
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (clicked === true) {
      const t = timer > 0 ? setInterval(() => setTimer(timer - 1), 1000) : initials();
      return () => clearInterval(t);
    }
  }, [clicked, timer]);

  useEffect(() => {
    if (!state) {
      navigate('/login');
    }
    setOt(state.Otp);
  }, []);

  const initials = () => {
    setTimer(100);
    setClicked(false);
  };

  const handleClick = async () => {
    const r = await getOtp(state.mobNum);
    console.log(r.otp.toString());
    setOt(r.otp.toString());
    setClicked(true);
  };

  const LoginSchema = Yup.object().shape({
    otpInput: Yup.number()
      .typeError('you must specify a number')
      .required('otp is required')
      .test('len', 'Otp should be of 6 digits', (val) => val && val.toString().length === 6)
  });

  const formik = useFormik({
    initialValues: {
      otpInput: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      const r = await checkOtp(state.mobNum, values.otpInput);
      console.log(r.status);
      // eslint-disable-next-line camelcase
      const { jwt_token, status } = r;
      if (status) {
        Cookies.set('log_response', JSON.stringify(r.user));
        Cookies.set('jwt-token', jwt_token);
        window.location.href = '/dashboard';
      } else if (r.status.toString() === 'false') {
        setSnack({
          open: true,
          color: 'error',
          message: 'OTP is incorrect'
        });
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {clicked ? <h4>OTP has been sent on your registered mobile number</h4> : ''}
            {ot}
            <br />
          </Grid>
          <Grid item xs={7}>
            <TextField
              fullWidth
              type="text"
              label="Enter OTP"
              required
              {...getFieldProps('otpInput')}
              error={Boolean(touched.otpInput && errors.otpInput)}
              helperText={touched.otpInput && errors.otpInput}
              inputProps={{ maxLength: 6 }}
            />
          </Grid>

          <Grid item xs={5}>
            <Button
              color="secondary"
              variant="outlined"
              disabled={clicked}
              onClick={() => handleClick()}
              sx={{ height: 56 }}
              fullWidth
            >
              Resend OTP
              {clicked ? <span> &nbsp; in {timer}s</span> : ''}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={values.otpInput.length !== 6}
              loading={isSubmitting ? true : undefined}
            >
              Verify
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <br />
            <Link
              underline="none"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate('/login/number')}
            >
              Change mobile number
            </Link>
          </Grid>
        </Grid>
      </Form>
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </FormikProvider>
  );
};

export default OtpField;
