import { Box, Card, Paper, Stack } from '@mui/material';
import React from 'react';
import moment from 'moment';
import Label from '../../../../components/Label';
import { ordinalSuffixOf } from '../../../../utils/helper';

const MobileQuotationCard = ({ row, index }) => {
  console.log('a');
  return (
    <Paper
      sx={{
        borderBottom: '1px solid lightgrey',
        m: 1,
        p: 1,
        display: 'flex',
        gap: 1,
        flexDirection: 'column'
      }}
      square
    >
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box>{index + 1}</Box>
          <Box>{row.user_full_name}</Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Label variant="ghost" color="default" style={{ fontSize: 12, padding: 12 }}>
            ₹ {row.q_amount}
          </Label>
          <Label
            variant="ghost"
            color={row.qid_rank === '1' ? 'success' : 'warning'}
            style={{ fontSize: 12, padding: 12 }}
          >
            {ordinalSuffixOf(row.qid_rank)}
          </Label>
        </Stack>
      </Stack>
      <Box sx={{ fontSize: 12, color: 'grey', ml: 3 }}>
        {' '}
        {moment(parseInt(row.q_time, 10) * 1000).format('DD/MM/YYYY hh:mm A')}
      </Box>
      <Box>
        <Label
          variant="ghost"
          color="default"
          style={{ fontSize: 12, padding: 12, marginLeft: 20 }}
        >
          {row.pid}
        </Label>
      </Box>
    </Paper>
  );
};

export default MobileQuotationCard;
