import { Navigate } from 'react-router-dom';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import { Login } from '../pages/login';
import Register from '../pages/Register';
import NotFound from '../pages/Page404';
import LoginField from '../pages/login/LoginField';
import OtpField from '../pages/login/OtpField';
import LandingPage from '../pages/landingSite/home';
import Guide from '../pages/landingSite/howItWork';
import Contact from '../pages/landingSite/contactus';

export const authRoutes = {
  path: '/',
  element: <LogoOnlyLayout />,
  children: [
    {
      path: 'login',
      element: <Login />,
      children: [
        { element: <Navigate to="/login/number" replace />, index: true },
        { path: 'number', element: <LoginField /> },
        { path: 'otp', element: <OtpField /> }
      ]
    },
    { path: 'home', element: <LandingPage />, index: true },
    { path: 'howItWorks', element: <Guide /> },
    { path: 'contact', element: <Contact /> },
    { path: 'register', element: <Register /> },
    { path: '404', element: <NotFound /> },
    { path: '/', element: <Navigate to="/dashboard" /> },
    { path: '*', element: <Navigate to="/404" /> }
  ]
};
