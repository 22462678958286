import * as React from 'react';
// import { useContext } from 'react';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import Slide from '@mui/material/Slide';
// import { SnackBarContext } from '../SnackBarContext';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const SmallSnackbar = ({ snack, setSnack }) => {
  // const [open, setOpen] = React.useState(false);
  // const { openn, setOpenn, show } = useContext(SnackBarContext);
  const { open, color, message, pos } = snack;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack({
      ...snack,
      open: false
    });
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const vertical = 'bottom';
  const horizontal = pos || 'right';
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      action={action}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
      TransitionComponent={SlideTransition}
    >
      <Alert
        onClose={handleClose}
        severity={color || 'success'}
        elevation={24}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SmallSnackbar;
