import { Box, Button, FormHelperText, IconButton, Stack } from '@mui/material';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { DeleteOutlineOutlined, Edit, Pin } from '@mui/icons-material';
import { DropzoneAreaBase, DropzoneDialog, DropzoneDialogBase } from 'material-ui-dropzone';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { BiPaperclip } from 'react-icons/bi';
import { Backup, Close } from '@material-ui/icons';
import EditableAutoCell from './EditableAutoCell';
import EditableTextCell from './EditableTextCell';
import EditableMedCell from './EditableMedCell';
import { aPo, creditOp, terms } from '../../../../../../../utils/helper';
import FullDialog from '../../../../../../../components/_dashboard/app/FullDialog';

const ls = require('local-storage');

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 150,
      maxWidth: 250
    },
    drip: {
      minHeight: '130px',
      maxHeight: '130px',
      color: 'grey',
      backgroundColor: '#FFF7CD',

      fontSize: 14
    }
  })
);

const widthArr = [
  {
    name: 'med',
    ratio: 2.7,
    percentage: 30,
    mdPercentage: 30
  },
  {
    name: 'type',
    ratio: 0.63,
    percentage: 5,
    mdPercentage: 7
  },
  {
    name: 'unit',
    ratio: 0.63,
    percentage: 5,
    mdPercentage: 7
  },
  {
    name: 'dose',
    ratio: 0.72,
    percentage: 7,
    mdPercentage: 8
  },
  {
    name: 'when',
    ratio: 0.81,
    percentage: 12,
    mdPercentage: 9
  },
  {
    name: 'frequency',
    ratio: 0.99,
    percentage: 10,
    mdPercentage: 11
  },
  {
    name: 'duration',
    ratio: 0.81,
    percentage: 7,
    mdPercentage: 10
  },
  {
    name: 'qty',
    ratio: 0.63,
    percentage: 5,
    mdPercentage: 6
  },
  {
    name: 'instruction',
    ratio: 1.08,
    percentage: 19,
    mdPercentage: 12
  }
];

const RowWrapper = styled('div')`
  .MuiTableRow-root {
    height: 40;
  }
`;

const EditableRow = ({
  row,
  index,
  isMdDown,

  productItems,
  handleRowsChange,
  tableStyle,
  handleAddMedicines,
  addNewMedicine,
  handleRemoveMedicines,
  units,
  productAttachments,
  handleChangeAttachment
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileObjects, setFileObjects] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles();

  const [inputRefsArray] = useState(() => Array.from({ length: 7 }, () => createRef()));

  // console.log(doctorProfileDetails)

  const handleKeyPress = (nextField) => {
    console.log(nextField, 'next');
    if (typeof nextField !== 'object') {
      const nextInput = document.querySelector(`input[id=${nextField}]`);
      nextInput.disabled = false;
      nextInput.focus();
      nextInput.select();
      setCurrentIndex((prevIndex) => {
        // calculate the next input index, next input after the final input will be again the first input. you can change the logic here as per your needs
        const nextIndex = prevIndex < 7 - 1 ? prevIndex + 1 : 0;
        return nextIndex;
      });
    }
  };

  useEffect(() => {
    if (row.productName === '') {
      //
      if (productItems.length - 1 === index) {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(false);
    }

    // if (row.cartItem) {
    //   setIsDisabled(true);
    // }
  }, [row]);

  useEffect(() => {
    // focus the firs iput initially
    if (inputRefsArray[0].current) {
      inputRefsArray[0].current.focus();
    }

    // add the event listener for keyup keyboard event
    window.addEventListener('keyup', handleKeyPress, false);

    // remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, []);

  const handleOnChange = (key, value, multipleVals) => {
    console.log(key, value, multipleVals, row, 'tempDat');
    let tempData = row;
    if (multipleVals) {
      multipleVals.forEach((val) => {
        tempData[val.key] = val.value;
      });
    } else {
      // if (key === 'qty' && row.cartItem === true) {
      //   editProduct(row, value, 0);
      // }
      tempData = { ...row, [key]: value };
    }
    // setItems(tempData);
    if (key === 'productName') {
      handleAddMedicines(tempData, index);
    }

    handleRowsChange(index, key, value, multipleVals);
  };

  const editProduct = async (newVal, qty, replaceProduct) => {
    // await updateCartMedicine({
    //   order_no: orderDetails.order_no,
    //   new_medicine_id: newVal.medid,
    //   old_medicine_id: row.medid,
    //   qty: qty,
    //   replaceProduct: replaceProduct,
    //   row_id: row.medid
    // })
    //   .then((res) => {
    //     if (res.data.success === true) {
    //       getOrderDetails();
    //     }
    //   })
    //   .catch((e) => console.log(e));
  };

  const deleteCartItem = async (row) => {
    const params = {
      // order_no: orderDetails.order_no,
      row_id: row.medid
    };
    // await deleteCartAndProformaItems(params).then((res) => {
    //   if (res.data.success === true) {
    //     getOrderDetails();
    //   }
    // });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fileReject = () => {
    // setSnack({
    //   open: true,
    //   color: 'error',
    //   message: `Only pdf and image file is allowed`
    // });
  };

  return (
    <>
      {/* <td
        style={{
          padding: 0,
          border: "1px solid lightgrey",
          width: 35,
        }}
      >
        {index + 1}
      </td> */}
      {/* <EditableTextCell
        // width={60}
        handleOnChange={handleOnChange}
        name="productName"
        val={row.productName}
        type="text"
        data={row}
        handleKeyPress={handleKeyPress}
        fieldIndex={1}
        index={index}
        // isDisabled={isDisabled}
      /> */}

      <EditableMedCell
        // width={isMdDown ? 260 : 300}
        width={200}
        dataList={[]}
        medName
        data={row}
        index={index}
        // handleOnChange={handleOnChange}
        // placeholder='Type salt name...'
        handleKeyPress={handleKeyPress}
        id={`med${index}-0`}
        fieldIndex={0}
        isMdDown={isMdDown}
        setIsDisabled={setIsDisabled}
        productItems={productItems}
        tableStyle={tableStyle}
        handleAddMedicines={handleAddMedicines}
        addNewMedicine={addNewMedicine}
        // editProduct={editProduct}
      />
      <EditableAutoCell
        width={120}
        tableStyle={tableStyle}
        dataList={units.map((x) => ({ id: x.tid, title: x.name }))}
        data={row}
        index={index}
        handleOnChange={handleOnChange}
        name="uom"
        freeSolo={false}
        multiple={false}
        val={{ title: row.uom }}
        handleKeyPress={handleKeyPress}
        fieldIndex={1}
        isDisabled={isDisabled}
      />
      <EditableTextCell
        width={100}
        handleOnChange={handleOnChange}
        name="desiredQty"
        val={row.desiredQty}
        type="number"
        data={row}
        handleKeyPress={handleKeyPress}
        fieldIndex={2}
        index={index}
        isDisabled={isDisabled}
      />

      <EditableTextCell
        width={100}
        handleOnChange={handleOnChange}
        name="startPrice"
        val={row.startPrice}
        type="number"
        data={row}
        handleKeyPress={handleKeyPress}
        fieldIndex={3}
        index={index}
        isDisabled={productItems.length - 1 === index}
        startAdorn="₹"
      />

      <EditableAutoCell
        width={120}
        tableStyle={tableStyle}
        dataList={terms.map((x) => ({ id: x.tid, title: x.name }))}
        data={row}
        index={index}
        handleOnChange={handleOnChange}
        name="payTerms"
        freeSolo={false}
        multiple={false}
        val={{ title: row.payTerms }}
        handleKeyPress={handleKeyPress}
        fieldIndex={1}
        isDisabled={isDisabled}
      />

      <EditableAutoCell
        width={110}
        tableStyle={tableStyle}
        dataList={aPo.map((x) => ({ id: x.tid, title: x.name }))}
        data={row}
        index={index}
        handleOnChange={handleOnChange}
        name="confPo"
        freeSolo={false}
        multiple={false}
        val={{ title: row.confPo }}
        handleKeyPress={handleKeyPress}
        fieldIndex={1}
        isDisabled={isDisabled || row.payTerms !== 'advance payments'}
      />

      <EditableAutoCell
        width={76}
        tableStyle={tableStyle}
        dataList={creditOp.map((x) => ({ id: x.tid, title: x.name }))}
        data={row}
        index={index}
        handleOnChange={handleOnChange}
        name="credit"
        freeSolo={false}
        multiple={false}
        val={{ title: row.credit }}
        handleKeyPress={handleKeyPress}
        fieldIndex={1}
        isDisabled={isDisabled || row.payTerms !== 'credit'}
      />
      <td
        style={{
          padding: 0,
          border: '1px solid lightgrey',
          width: 100,
          cursor: 'pointer'
        }}
        // onClick={() => setOpen(true)}
      >
        <Stack flexDirection="row" alignItems="center">
          <div>
            <IconButton
              variant="contained"
              size="small"
              color="primary"
              onClick={() => setOpen(true)}
              disabled={isDisabled}
            >
              <BiPaperclip />
            </IconButton>

            <FullDialog
              open={open}
              setOpen={setOpen}
              headName="Add Attachment"
              // subHead="Field marked as * are required"
              maxWidth="sm"
              minWidth="sm"
              onClose={handleClose}
            >
              <Box p={2}>
                <DropzoneAreaBase
                  fileObjects={productAttachments[index]?.file}
                  showAlerts={false}
                  showPreviews
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  acceptedFiles={['application/pdf', 'image/jpeg', 'image/png', 'image/bmp']}
                  // getFileAddedMessage={fileAdded}
                  // getFileRemovedMessage={fileRemove}
                  getDropRejectMessage={fileReject}
                  Icon={Backup}
                  dropzoneText="Drag and drop file"
                  onChange={(files) => console.log('Files:', files)}
                  onAdd={(newFileObjs) => {
                    // handleChange('attachment', newFileObjs);
                    handleChangeAttachment(newFileObjs, index);
                    // setFileObjects(newFileObjs);
                    setOpen(false);
                  }}
                  onDelete={(newFileObjs) => {
                    // handleChange('attachment', newFileObjs);
                    handleChangeAttachment(newFileObjs, index);
                    // setFileObjects(newFileObjs);
                  }}
                  filesLimit={1}
                  dropzoneClass={classes.drip}
                />

                <FormHelperText>
                  Max 2mb file size. Only pdf png jpeg jpg is allowed.
                </FormHelperText>
              </Box>
            </FullDialog>
          </div>

          {productAttachments.length > 0 &&
            productAttachments[index] &&
            productAttachments[index].file.length > 0 && (
              <Box
                onClick={() => setOpen(true)}
                sx={{ fontSize: 11, color: 'grey', overflow: 'hidden' }}
              >
                {productAttachments[index].file[0].file.path}
              </Box>
            )}
        </Stack>
      </td>

      {/* <EditableTextCell
        // width={40}
        handleOnChange={handleOnChange}
        name="rtot"
        val={row.rtot}
        type="number"
        data={row}
        handleKeyPress={handleKeyPress}
        fieldIndex={7}
        index={index}
        // isDisabled={isDisabled}
        isDisabled
      /> */}

      {/* <td
        style={{
          padding: 0,
          border: "1px solid lightgrey",
          width: 35,
        }}
      >
        1
      </td> */}
      <td style={{ padding: 0, width: 20, border: '1px solid lightgrey' }}>
        {productItems.length - 1 !== index && (
          <IconButton
            onClick={() => {
              // if (row.cartItem === true) {
              //   deleteCartItem(row);
              // }
              handleRemoveMedicines(row, index);
            }}
            // disabled={row.cartItem === true}
          >
            <DeleteOutlineOutlined sx={{ fontSize: 15 }} />
          </IconButton>
        )}
      </td>
    </>
  );
};

export default EditableRow;
