import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  FormControlLabel,
  Card,
  CardContent,
  Typography,
  useMediaQuery
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SmallSnackbar from '../../../components/SnackBar';
import { submitContactUsForm } from '../../../apis/CommonApi';
import SelectInput from './SelectInput';

const ContactForm = () => {
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Full name is required'),
    mobNumber: Yup.number()
      .typeError('you must specify a number')
      .required('mobile number is required')
      .test(
        'len',
        'Mobile number should be of 10 digits',
        (val) => val && val.toString().length === 10
      ),
    emailId: Yup.string().email('Invalid email format').required('Email id is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      mobNumber: '',
      emailId: '',
      type: '',
      message: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      const data = {
        name: values.name,
        field_e: values.emailId,
        field_mobile_number: values.mobNumber,
        description: values.message,
        type: values.type
      };
      // console.log(data);
      await submitContactUsForm(data)
        .then((r) => {
          //  console.log(r.data.status);
          if (r.data.status) {
            setSnack({
              open: true,
              color: 'success',
              message: 'Request sent successfully'
            });
            resetForm();
          } else {
            setSnack({
              open: true,
              color: 'error',
              message: 'Something went wrong'
            });
          }

          // console.log(r.data);
        })
        .catch((e) => {
          setSnack({
            open: true,
            color: 'error',
            message: 'Something went wrong'
          });
        });

      /*  setSnack({
        open: true,
        color: 'error',
        message: 'This mobile number does not exist'
      }); */
    }
  });

  const list = [
    {
      tid: 'vendor',
      name: 'Vendor'
    },
    {
      tid: 'buyer',
      name: 'Buyer'
    }
  ];

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <Card sx={{ width: matches ? '100%' : '100%' }}>
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Typography sx={{ fontSize: 14, color: 'gray' }}>
                Contact us for registration and other queries
              </Typography>
              <TextField
                fullWidth
                type="text"
                label="Full name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                fullWidth
                autoComplete="phone-number"
                type="text"
                label="Mobile Number"
                {...getFieldProps('mobNumber')}
                error={Boolean(touched.mobNumber && errors.mobNumber)}
                helperText={touched.mobNumber && errors.mobNumber}
                inputProps={{ maxLength: 10 }}
              />
              <TextField
                fullWidth
                type="email"
                label="Email id"
                {...getFieldProps('emailId')}
                error={Boolean(touched.emailId && errors.emailId)}
                helperText={touched.emailId && errors.emailId}
              />
              <SelectInput
                cat={values.type}
                getFieldProps={getFieldProps('type')}
                name="Type"
                dta={list}
                error={Boolean(touched.type && errors.type)}
                helper={touched.type && errors.type}
              />
              <TextField
                label="Message"
                id="outlined-multiline-static"
                multiline
                rows={3}
                {...getFieldProps('message')}
                error={Boolean(touched.message && errors.message)}
                helperText={touched.message && errors.message}
                inputProps={{ maxLength: 255 }}
                fullWidth
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting ? true : undefined}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Form>
          <SmallSnackbar snack={snack} setSnack={setSnack} />
        </FormikProvider>
      </CardContent>
    </Card>
  );
};

export default ContactForm;
