import { IconButton, Menu, MenuItem, ListItemIcon, useTheme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { EditTwoTone } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const ProductMenu = ({ row, rfDet, type }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(row, 'menurow');

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/${type}/products/edit`, {
              state: {
                rfDet,
                type,
                editData: row
              }
            });
          }}
        >
          <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
            <EditTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
          </ListItemIcon>
          Edit
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProductMenu;
