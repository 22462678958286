import React, { useState, useEffect } from 'react';
import { getUpcoming } from '../../../../apis/VendorApi';
import { token } from '../../../../utils/helper';
import Loader from '../_components/Loader';
import { Rfx } from '../_components/rfxTable';
import Page from '../../../../components/Page';

const UpcomingAuction = () => {
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);

  const getList = async () => {
    await getUpcoming(token)
      .then((r) => {
        setRows(r.data.result.reverse());
        isLoading(false);
        //  console.log(r.data.result);
      })
      .catch((e) => console.log(e.message, 'upcoming auction table'));
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Page title="Upcoming Rfq">
      {loading ? (
        <Loader height="75vh" />
      ) : (
        <Rfx
          rows={rows}
          menu={[{ text: 'view upcoming rfq', goTo: `/myauction/upcoming` }]}
          upcoming
        />
      )}
    </Page>
  );
};

export default UpcomingAuction;
