export const columns = [
  { label: 'SrNo.', key: 'srNo' },
  {
    label: 'Product id',
    key: 'field_product_id'
  },
  { label: 'product name', key: 'name' },

  {
    label: 'Category',
    key: 'field_category'
  },
  {
    label: 'Lot name',
    key: 'field_lot'
  },
  {
    label: 'Unit of measure',
    key: 'field_unit_of_measure'
  },
  {
    label: 'Unit cost',
    key: 'field_unit_cost'
  },
  {
    label: 'Start price',
    key: 'field_start_price'
  },
  {
    label: 'Desired quantity',
    key: 'field_minimum_desired_quantity'
  },
  {
    label: 'Historical cost',
    key: 'field_historical_cost'
  },
  {
    label: 'Bid change',
    key: 'field_minimum_bid_change'
  },
  {
    label: 'Bid change value',
    key: 'field_minimum_change_value'
  },
  {
    label: 'Payment terms',
    key: 'field_payment_term_buyer_product'
  },
  {
    label: 'Supply location',
    key: 'field_place_of_supply'
  }
];
