export const headCells = [
  { id: 'Srno', label: 'SrNo.', align: 'left', maxWidth: 60 },
  { id: 'productName', label: 'Product name', align: 'left' },
  { id: 'location', label: 'Location', align: 'left' },
  { id: 'targetPrice', label: 'Target price', align: 'left', maxWidth: 70 },
  { id: 'paymentMode', label: 'Payment mode', align: 'left', maxWidth: 80 },
  { id: 'NoOfMonths', label: 'No of months', align: 'left' },
  { id: 'TotalQty', label: 'Total quantity', align: 'left' },
  { id: 'payTerms', label: 'Payment terms', align: 'left' },
  { id: 'confPo', label: 'Confirmed PO', align: 'left' },
  { id: 'deliveryTerms', label: 'Delivery terms', align: 'left' }
  /*  { id: 'actions', label: 'Actions', align: 'right' } */
];
