import { Box, Card, Collapse, Paper, Stack } from '@mui/material';
import React from 'react';
import Label from '../../../../../components/Label';

const MobileProductCard = ({ row, index, selectedIndex, setSelectedIndex }) => {
  // const []
  console.log('a');
  return (
    <Paper
      sx={{ borderBottom: '1px solid lightgrey', m: 1, p: 1 }}
      square
      onClick={() => setSelectedIndex(selectedIndex === index ? null : index)}
    >
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box>{index + 1}</Box>
          <Stack>
            <Box>{row.field_product_name_rfq}</Box>
            <Box style={{ fontSize: 12, color: 'grey' }}>
              {' '}
              {row.product_cat.map((x) => x.name).join(' >> ')}
            </Box>
            <Box style={{ fontSize: 12, color: 'grey' }}>{row.field_services}</Box>
          </Stack>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Label variant="ghost" color="default" style={{ fontSize: 12, padding: 12 }}>
            ₹{row.field_start_price}
          </Label>
        </Stack>
      </Stack>
      <Collapse in={selectedIndex === index} timeout="auto" unmountOnExit>
        <Stack sx={{ ml: 2, mt: 0.8 }} gap={1}>
          <Box>
            <Label variant="ghost" color="warning" style={{ fontSize: 12, padding: 12 }}>
              {row.title}
            </Label>
          </Box>

          <Stack flexDirection="row" sx={{ flexWrap: 'wrap' }} gap={1}>
            {row.field_unit_of_measure.map((x) => (
              <Label variant="ghost" color="default" style={{ fontSize: 12, padding: 12 }}>
                {x.default_unit_of_measure}
              </Label>
            ))}
          </Stack>

          <Box sx={{ fontSize: 12, ml: 1 }}>
            <b style={{ marginRight: 10 }}>Desired Quantity:</b>{' '}
            {row.field_minimum_desired_quantity}
          </Box>
          <Box sx={{ fontSize: 12, ml: 1 }}>
            <b style={{ marginRight: 10 }}>Payment Terms:</b> {row.field_payment_term_buyer_product}
          </Box>
          <Box sx={{ fontSize: 12, ml: 1 }}>
            <b style={{ marginRight: 10 }}>Address:</b> {row.field_city}, {row.field_state}{' '}
            {row.field_pin_code}
          </Box>
        </Stack>
      </Collapse>
    </Paper>
  );
};

export default MobileProductCard;
