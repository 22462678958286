import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: '#ba8036'
}));

const Sub = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.text.secondary
}));

const lab = [
  'Project name',
  'Statutary compliance',
  'Location',
  'Admin name',
  'Email id',
  'Mobile number',
  'Alt mobile number',
  'Project category',
  'Total buildings',
  'Est. cost',
  'Starting year',
  'Year of completion',
  'Unit cost',
  'Per sqft cost',
  'Target customer'
];
const DetailsCard = ({ item }) => {
  const a = 1;
  return (
    <Card>
      <CardContent>
        <Heading>{item.prop}</Heading>
        <Sub>{item.name}</Sub>
      </CardContent>
    </Card>
  );
};

export default DetailsCard;
