// routes
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Router } from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import IdleContainer from './Idle/IdleContainer';
import Logout from './utils/Logout';
import { checkAuth } from './apis/LoginApi';
import { getRole, token } from './utils/helper';

// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  // window.tidioChatApi.setColorPalette('#be8740');
  console.log(process.env.PUBLIC_URL, 'public url ');
  useEffect(() => {
    if (location.pathname === '/login' || location.pathname === '/login/number') {
      Logout();
    }
  }, [location]);

  useEffect(() => {
    if (!token) {
      if (
        location.pathname !== '/login/number' &&
        location.pathname !== '/login/otp' &&
        location.pathname !== '/home' &&
        location.pathname !== '/howItWorks' &&
        location.pathname !== '/contact' &&
        location.pathname !== '/404'
      ) {
        console.log('checked in home');
        navigate('/home');
      }
    }
  }, []);

  const loginCheck = async () => {
    if (
      location.pathname !== '/login/number' &&
      location.pathname !== '/login/otp' &&
      location.pathname !== '/home' &&
      location.pathname !== '/howItWorks' &&
      location.pathname !== '/contact' &&
      location.pathname !== '/404' &&
      location.pathname !== '/'
    ) {
      await checkAuth(token, getRole())
        .then((r) => {
          if (!r.data.status) {
            console.log('checked in login');
            // window.location.href = '/login/number';
          }
        })
        .catch((e) => {
          if (
            location.pathname !== '/login/number' &&
            location.pathname !== '/login/otp' &&
            location.pathname !== '/home' &&
            location.pathname !== '/howItWorks' &&
            location.pathname !== '/404' &&
            location.pathname !== '/contact' &&
            location.pathname !== '/'
          ) {
            console.log(e.message);
            window.location.href = '/login/number';
          }
        });
    }
  };

  useEffect(() => {
    loginCheck();
  }, []);

  return (
    <ThemeConfig>
      <IdleContainer>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </IdleContainer>
    </ThemeConfig>
  );
}
