import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { FaRegFilePdf, FaRegImage } from 'react-icons/fa';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Close, CloudUpload } from '@material-ui/icons';
import Label from '../../../../../components/Label';
import { rfqProductSchema } from '../../../../../utils/formikSchema';
import { aPo, creditOp, terms } from '../../../../../utils/helper';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder'
];

const initialVals = {
  productName: '',
  category: '',
  subCategory: '',
  bidChange: '',
  changeValue: '',
  services: {
    service: false,
    serviceWithMaterial: false,
    materialOnly: false
  },
  unitOfMeasure: [],
  desiredQty: '',
  startPrice: '',
  payTerms: '',
  confPo: '',
  onCredit: '',
  state: '',
  city: '',
  pincode: '',
  productFile: []
};

const AddEditProductForm = () => {
  const theme = useTheme();
  const { state } = useLocation();

  const productFormik = useFormik({
    initialValues: initialVals,
    validationSchema: rfqProductSchema,
    onSubmit: async () => {
      console.log('flag');
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange
  } = productFormik;
  const { service, serviceWithMaterial, materialOnly } = values.services;
  return (
    <Box mx={2}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        flexWrap="wrap"
      >
        {/* <h3>Add Product</h3> */}
        <Box>
          <Label
            sx={{ height: 'auto', padding: 1, backgroundColor: 'transparent' }}
            color="primary"
          >
            <Stack spacing={1} direction="row">
              {/* <Avatar {...stringAvatar(res.title)} /> */}
              <Stack>
                <Typography sx={{ fontSize: 11, color: 'gray' }}>Rfx Id</Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 800 }}>RQ/2024/021</Typography>
              </Stack>
            </Stack>
          </Label>
          <Label
            sx={{ height: 'auto', padding: 1, backgroundColor: 'transparent' }}
            color="primary"
          >
            <Stack spacing={1} direction="row">
              {/* <Avatar {...stringAvatar(res.title)} /> */}
              <Stack>
                <Typography sx={{ fontSize: 11, color: 'gray' }}>Rfx name</Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 800 }}>TMT RFQ 2-4-24</Typography>
              </Stack>
            </Stack>
          </Label>

          <Typography sx={{ color: theme.palette.error.main, fontSize: '.8rem' }}>
            Field marked with * is mandatory
          </Typography>
        </Box>

        <Box>
          <Button variant="contained">Submit</Button>
        </Box>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Stack gap={3}>
            <FormControl
              fullWidth
              size="small"
              error={touched.category && errors.category}
              helperText={touched.category && errors.category}
              required
            >
              <InputLabel>Select Category</InputLabel>
              <Select
                value={values.category}
                label="Select Category"
                fullWidth
                {...getFieldProps('category')}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              size="small"
              error={touched.subCategory && errors.subCategory}
              helperText={touched.subCategory && errors.subCategory}
              required
            >
              <InputLabel>Select Sub Category</InputLabel>
              <Select
                value={values.subCategory}
                label="Select Sub Category"
                fullWidth
                {...getFieldProps('subCategory')}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <FormControl size="small">
              <FormLabel>Minimum Bid Change</FormLabel>
              <RadioGroup defaultValue="na" row {...getFieldProps('bidChange')}>
                <FormControlLabel
                  value="na"
                  control={<Radio size="small" />}
                  label={<Typography sx={{ fontSize: '0.9rem' }}>N/A</Typography>}
                />
                <FormControlLabel
                  value="amount"
                  control={<Radio size="small" />}
                  label={<Typography sx={{ fontSize: '0.9rem' }}>Amount</Typography>}
                />
                <FormControlLabel
                  value="percentage"
                  control={<Radio size="small" />}
                  label={<Typography sx={{ fontSize: '0.9rem' }}>Percentage</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Change Value"
              // multiline
              // rows={2}
              size="small"
              error={Boolean(touched.changeValue && errors.changeValue)}
              helperText={touched.changeValue && errors.changeValue}
              inputProps={{ maxLength: 255 }}
              {...getFieldProps('changeValue')}
              fullWidth
            />

            <FormControl
              fullWidth
              size="small"
              error={touched.state && errors.state}
              helperText={touched.state && errors.state}
              required
            >
              <InputLabel>Select State</InputLabel>
              <Select
                value={values.state}
                label="Select State"
                fullWidth
                {...getFieldProps('state')}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              size="small"
              required
              error={touched.city && errors.city}
              helperText={touched.city && errors.city}
            >
              <InputLabel>Select City</InputLabel>
              <Select value={values.city} label="Select City" fullWidth {...getFieldProps('city')}>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Pincode"
              // multiline
              // rows={2}
              size="small"
              type="number"
              error={Boolean(touched.pincode && errors.pincode)}
              helperText={touched.pincode && errors.pincode}
              inputProps={{ maxLength: 6 }}
              {...getFieldProps('pincode')}
              fullWidth
            />

            <FormControl
              variant="standard"
              error={touched.productFile && errors.productFile}
              helperText={touched.productFile && errors.productFile}
              component="fieldset"
              sx={{ border: '1px solid lightgrey', p: 1, borderRadius: 1 }}
              size="small"
            >
              <FormGroup aria-label="position" column>
                <FormLabel component="legend">Product Catalogue</FormLabel>
                <Stack sx={{ maxWidth: '100%' }}>
                  <List>
                    {values.productFile.map((x, index) => (
                      <ListItem>
                        <ListItemIcon>
                          {x.name.split('.')[1] === 'pdf' ? (
                            <FaRegFilePdf style={{ color: theme.palette.primary.main }} />
                          ) : (
                            <FaRegImage style={{ color: theme.palette.primary.main }} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={<Typography sx={{ fontSize: 12 }}>{x.name}</Typography>}
                          sx={{
                            // maxWidth: '30%',
                            wordWrap: 'break-word',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: '12 !important',
                            color: 'gray'
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            setFieldValue('productFile', [
                              ...values.productFile.filter((x, i) => i !== index)
                            ]);
                          }}
                          sie="small"
                        >
                          <Close />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload />}
                    size="small"
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      multiple
                      onChange={(e) => {
                        const allFiles = [...values.productFile, ...e.target.files];

                        setFieldValue('productFile', [...allFiles]);
                      }}
                      accept="image/jpeg,image/png,image/jpg,application/pdf"
                    />
                  </Button>
                </Stack>
              </FormGroup>
              <FormHelperText> {touched.productFile && errors.productFile}</FormHelperText>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack gap={3}>
            <FormControl
              variant="standard"
              error={touched.services && errors.services}
              helperText={touched.services && errors.services}
              component="fieldset"
              sx={{ border: '1px solid lightgrey', p: 1, borderRadius: 1 }}
              size="small"
            >
              <FormGroup aria-label="position" column>
                <FormLabel component="legend">Services</FormLabel>
                <Stack direction="row" flexWrap="wrap">
                  <FormControlLabel
                    value={values.services.service}
                    control={
                      <Checkbox
                        {...getFieldProps('services.service')}
                        checked={service}
                        size="small"
                      />
                    }
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Service</Typography>}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={values.services.serviceWithMaterial}
                    control={
                      <Checkbox
                        {...getFieldProps('services.serviceWithMaterial')}
                        checked={serviceWithMaterial}
                        size="small"
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '0.9rem' }}>Service With Material</Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={values.services.materialOnly}
                    control={
                      <Checkbox
                        {...getFieldProps('services.materialOnly')}
                        checked={materialOnly}
                        size="small"
                      />
                    }
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Material Only</Typography>}
                    labelPlacement="end"
                  />
                </Stack>
              </FormGroup>
              <FormHelperText> {touched.services && errors.services}</FormHelperText>
            </FormControl>
            <TextField
              label="Product Name"
              required
              // multiline
              // rows={2}
              size="small"
              error={Boolean(touched.productName && errors.productName)}
              helperText={touched.productName && errors.productName}
              inputProps={{ maxLength: 255 }}
              {...getFieldProps('productName')}
              fullWidth
            />

            <FormControl size="small" required>
              <InputLabel>Unit Of Measure</InputLabel>
              <Select
                multiple
                value={values.unitOfMeasure}
                onChange={(e) => {
                  setFieldValue(
                    'unitOfMeasure',
                    typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                  );
                }}
                input={<OutlinedInput label="Unit Of Measure" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    // style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Minimum Desired Quantity"
              required
              // multiline
              // rows={2}
              type="number"
              size="small"
              error={Boolean(touched.desiredQty && errors.desiredQty)}
              helperText={touched.desiredQty && errors.desiredQty}
              // inputProps={{ maxLength: 255 }}
              {...getFieldProps('desiredQty')}
              fullWidth
            />
            <TextField
              label="Start Price"
              required
              // multiline
              // rows={2}
              type="number"
              size="small"
              error={Boolean(touched.startPrice && errors.startPrice)}
              helperText={touched.startPrice && errors.startPrice}
              // inputProps={{ maxLength: 255 }}
              {...getFieldProps('startPrice')}
              fullWidth
            />

            <FormControl fullWidth size="small">
              <InputLabel>Payment Terms</InputLabel>
              <Select
                value={values.payTerms}
                label="Payment Terms"
                fullWidth
                {...getFieldProps('payTerms')}
              >
                {terms.map((x) => (
                  <MenuItem value={x.tid}>{x.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {values.payTerms === 'credit' && (
              <FormControl fullWidth size="small">
                <InputLabel>No. Of Days</InputLabel>
                <Select
                  value={values.onCredit}
                  label="No. Of Days"
                  fullWidth
                  {...getFieldProps('onCredit')}
                >
                  {creditOp.map((x) => (
                    <MenuItem value={x.tid}>{x.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {values.payTerms === 'advance payments' && (
              <FormControl fullWidth size="small">
                <InputLabel>Advance on confirmation of po</InputLabel>
                <Select
                  value={values.confPo}
                  label="Advance on confirmation of po"
                  fullWidth
                  {...getFieldProps('confPo')}
                >
                  {aPo.map((x) => (
                    <MenuItem value={x.tid}>{x.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <TextField
              label="Description"
              required
              multiline
              rows={2}
              // type="number"
              size="small"
              error={Boolean(touched.desc && errors.desc)}
              helperText={touched.desc && errors.desc}
              inputProps={{ maxLength: 255 }}
              {...getFieldProps('desc')}
              fullWidth
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper square={false} variant="outlined">
            a
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditProductForm;
