import { Divider, Grid } from '@mui/material';
import React, { useState } from 'react';
import CompanyCard from './CompanyCard';
import { CompanyCardSkeleton } from './components/skeletons';
import NoDataFound from '../../../components/NoDataFound';

const Company = ({ data }) => {
  const [open, setOpen] = useState(false);
  // console.log(data, 'buyer company');
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <h2 style={{ padding: '.5rem', color: 'grey' }}>Company details</h2>
          <Divider />
          <br />
        </Grid>
        {data ? (
          <>
            {data.company_details.length === 0 ? (
              <NoDataFound height="100%" />
            ) : (
              <>
                {data.company_details.map((x, i) => (
                  <Grid item sm={6} md={4} xs={12} key={i}>
                    <CompanyCard data={x} />
                  </Grid>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            <Grid item sm={6} md={4} xs={12}>
              <CompanyCardSkeleton />
            </Grid>
            <Grid item sm={6} md={4} xs={12}>
              <CompanyCardSkeleton />
            </Grid>
            <Grid item sm={6} md={4} xs={12}>
              <CompanyCardSkeleton />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Company;
