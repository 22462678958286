import React from 'react';
import { Box, Stack } from '@mui/material';
import ItemColumn from './ItemColumn';

import DetailsColumn from './DetailsColumn';
import StartPriceInput from './StartPriceInput';
import BidInput from './BidInput';

const MobileProductBid = ({
  row,
  rfxData,
  bidingRules,
  companyName,
  data,
  fetchAllBids,
  sendAtFirst,
  index
}) => {
  console.log(row);
  const Srno = index + 1;
  return (
    <div>
      <Stack
        flexDirection="column"
        className={sendAtFirst === row.p_nid && 'element'}
        sx={{ p: 1, borderBottom: '1px solid grey' }}
      >
        <Stack flexDirection="row" justifyContent="space-around">
          <Box sx={{ color: 'gray', mr: 1, fontWeight: '600' }}>{Srno}.</Box>
          <ItemColumn row={row} />
          <DetailsColumn row={row} bidingRules={bidingRules} rfxData={rfxData} />
        </Stack>
        <Stack spacing={2} sx={{ mt: 1, mx: 2 }}>
          {rfxData.type === 'rfq' ? (
            <StartPriceInput pid={row.p_nid} type={rfxData.type} />
          ) : (
            <BidInput
              data={row}
              pNid={row.p_nid}
              bidingRules={bidingRules}
              companyName={companyName}
              rows={data}
              fetchAllBids={fetchAllBids}
            />
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default MobileProductBid;
