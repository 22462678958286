import { Stack, alpha, useTheme } from '@mui/material';
import React from 'react';

const NotFound = ({ message, actionBtn, containerStyle = {} }) => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap={1}
      sx={{ minHeight: 300, ...containerStyle }}
    >
      <h1 style={{ color: alpha(theme.palette.primary.main, 0.7), fontSize: 18 }}>
        {message || 'No Record Found!'}
      </h1>
      {actionBtn && actionBtn()}
    </Stack>
  );
};

export default NotFound;
