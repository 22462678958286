import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Box, Breadcrumbs, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { fCurrency } from '../../../../../utils/formatNumber';
import BasicMenu from '../BasicMenu';

const MobileProductCard = ({
  row,
  menu,
  setOpen,
  setItem,
  active,
  upcoming,
  index,
  handleClick
}) => {
  console.log('products');

  const srNo = index + 1;
  const category = row.category_items && Object.values(row.category_items);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Stack flexDirection="row" gap={2} p={1} justifyContent="space-between" sx={{ wisth: '100%' }}>
      <Stack>
        <div style={{ color: 'grey', fontSize: 12 }}>{srNo}.</div>
        {/* <div style={{ color: 'grey', fontSize: 11 }}>PR/2024/123</div> */}
      </Stack>
      <Stack>
        <div style={{ color: theme.palette.primary.main, fontSize: 13, fontWeight: '600' }}>
          {row.field_product_name}
        </div>
        <div style={{ color: 'grey', fontSize: 11 }}>
          {' '}
          {row.category_items && (
            <Breadcrumbs separator=">>" maxItems={1} sx={{ fontSize: 13 }}>
              {category.map((y, i) => (
                <span key={i}>{y}</span>
              ))}
            </Breadcrumbs>
          )}
        </div>
      </Stack>
      <Stack justifyContent="center">
        <div style={{ color: theme.palette.primary.main, fontSize: 13, fontWeight: '600' }}>
          ₹ {fCurrency(row.field_start_price)}
        </div>
      </Stack>
      <Stack justifyContent="center">
        {active ? (
          <BasicMenu menu={menu} setOpen={setOpen} itemName={row.name} active={active} />
        ) : (
          <IconButton onClick={() => handleClick(row)}>
            <ChevronRight style={{ fontSize: 20, color: 'grey' }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default MobileProductCard;
