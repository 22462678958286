import { Card, CardContent, CircularProgress } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { token } from '../../../utils/helper';
import { getBuyerDetails } from '../../../apis/BuyerApi';
import Company from './Company';
import OfficeTable from './OfficeTable';
import ProfileForm from './ProfileForm';
import ProfileInfo from './ProfileInfo';
import { OfficeTableSkeleton, ProfileInfoSkeleton } from './components/skeletons';
import Page from '../../../components/Page';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20vh'
}));

const ProfileContainer = () => {
  const [data, setData] = useState();
  const [open, setOpen] = React.useState(true);
  const getDetails = async () => {
    const d = await getBuyerDetails(token);
    setData(d.result);
    console.log(d.result);
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Page title="Profile">
      <Card>
        <CardContent>
          {/*  <ProfileForm data={data} /> */}
          {data ? <ProfileInfo data={data} /> : <ProfileInfoSkeleton />}
          <br />
          <br />
          {/*  {data ? <OfficeTable data={data} /> : <OfficeTableSkeleton />} */}
          {/*   <br />
          <br /> */}
          <Company data={data} />
        </CardContent>
      </Card>
    </Page>
  );
};

export default ProfileContainer;
