export const items = [
  {
    Srno: 1,
    itemName: 'Black ink cartridge',
    supplyLocation: 'silvassa',
    paymentTerms: 'credit',
    desiredQty: 500,
    startPrice: 2100,
    bidChange: 1.1,
    bid: 1000,
    time: '10:20am',
    rank: 2
  },
  {
    Srno: 2,
    itemName: 'color ink cartridge',
    supplyLocation: 'Vapi',
    paymentTerms: 'credit',
    desiredQty: 500,
    startPrice: 2100,
    bidChange: 1.1,
    bid: 1000,
    time: '10:20am',
    rank: 2
  },
  {
    Srno: 3,
    itemName: 'mix ink cartridge',
    supplyLocation: 'sarigam',
    paymentTerms: 'credit',
    desiredQty: 500,
    startPrice: 2100,
    bidChange: 1.1,
    bid: 1000,
    time: '10:20am',
    rank: 2
  },
  {
    Srno: 4,
    itemName: 'Black ink cartridge',
    supplyLocation: 'silvassa',
    paymentTerms: 'credit',
    desiredQty: 500,
    startPrice: 2100,
    bidChange: 1.1,
    bid: 1000,
    time: '10:20am',
    rank: 2
  },
  {
    Srno: 5,
    itemName: 'Black ink cartridge',
    supplyLocation: 'silvassa',
    paymentTerms: 'credit',
    desiredQty: 500,
    startPrice: 2100,
    bidChange: 1.1,
    bid: 1000,
    time: '10:20am',
    rank: 2
  },
  {
    Srno: 5,
    itemName: 'Black ink cartridge',
    supplyLocation: 'silvassa',
    paymentTerms: 'credit',
    desiredQty: 500,
    startPrice: 2100,
    bidChange: 1.1,
    bid: 1000,
    time: '10:20am',
    rank: 2
  },
  {
    Srno: 5,
    itemName: 'Black ink cartridge',
    supplyLocation: 'silvassa',
    paymentTerms: 'credit',
    desiredQty: 500,
    startPrice: 2100,
    bidChange: 1.1,
    bid: 1000,
    time: '10:20am',
    rank: 2
  }
];

export const bidInfoData = [
  {
    srNo: 1,
    itemName: 'black ink cartridge',
    unitCost: 2000,
    totalCost: 8000,
    rank: 2,
    bidTime: '12:00'
  },
  {
    srNo: 2,
    itemName: 'black ink cartridge',
    unitCost: 2000,
    totalCost: 8000,
    rank: 2,
    bidTime: '12:00'
  },
  {
    srNo: 3,
    itemName: 'black ink cartridge',
    unitCost: 2000,
    totalCost: 8000,
    rank: 2,
    bidTime: '12:00'
  },
  {
    srNo: 4,
    itemName: 'black ink cartridge',
    unitCost: 2000,
    totalCost: 8000,
    rank: 2,
    bidTime: '12:00'
  }
];
