export const headCells = [
  { id: 'Srno', label: 'SrNo.', align: 'left', maxWidth: 65 },
  { id: 'field_project_name', label: 'Project name', align: 'left' },
  { id: 'field_statutory_compliance', label: 'Statutary compliance', align: 'left', maxWidth: 100 },
  { id: 'field_site_location', label: 'Site location', align: 'left', minWidth: 100 },
  { id: 'field_admin_contact_person_name', label: 'Admin name', align: 'left', maxWidth: 90 },
  { id: 'field_admin_email_address', label: 'Email id', align: 'left' },
  { id: 'field_admin_contact_number', label: 'Mobile number', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'right' }
];

export const rows = [
  {
    projectName: 'tropical greens',
    statComp: 'stat',
    location: 'silvassa',
    adminName: 'Jethalal',
    email: 'jethia123@gmail.com',
    mobNum: '1234567890',
    altMobNum: '0987654321',
    projectCategory: 'Flat',
    totalBuildings: 10,
    estCost: 1000000000,
    startYear: 2020,
    completionYear: 2022,
    unitCost: 2000000,
    perSqftCost: 2000,
    targetCustomer: 'upper class'
  },
  {
    projectName: 'green city',
    statComp: 'stat',
    location: 'silvassa',
    adminName: 'Jethalal',
    email: 'jethia123@gmail.com',
    mobNum: '1234567890',
    altMobNum: '0987654321',
    projectCategory: 'Flat',
    totalBuildings: 10,
    estCost: 1000000000,
    startYear: 2020,
    completionYear: 2022,
    unitCost: 2000000,
    perSqftCost: 2000,
    targetCustomer: 'upper class'
  },
  {
    projectName: 'park city',
    statComp: 'stat',
    location: 'silvassa',
    adminName: 'Jethalal',
    email: 'jethia123@gmail.com',
    mobNum: '1234567890',
    altMobNum: '0987654321',
    projectCategory: 'Flat',
    totalBuildings: 10,
    estCost: 1000000000,
    startYear: 2020,
    completionYear: 2022,
    unitCost: 2000000,
    perSqftCost: 2000,
    targetCustomer: 'upper class'
  },
  {
    projectName: 'vrundavan',
    statComp: 'stat',
    location: 'silvassa',
    adminName: 'Jethalal',
    email: 'jethia123@gmail.com',
    mobNum: '1234567890',
    altMobNum: '0987654321',
    projectCategory: 'Flat',
    totalBuildings: 10,
    estCost: 1000000000,
    startYear: 2020,
    completionYear: 2022,
    unitCost: 2000000,
    perSqftCost: 2000,
    targetCustomer: 'upper class'
  },
  {
    projectName: 'garden',
    statComp: 'stat',
    location: 'silvassa',
    adminName: 'Jethalal',
    email: 'jethia123@gmail.com',
    mobNum: '1234567890',
    altMobNum: '0987654321',
    projectCategory: 'Flat',
    totalBuildings: 10,
    estCost: 1000000000,
    startYear: 2020,
    completionYear: 2022,
    unitCost: 2000000,
    perSqftCost: 2000,
    targetCustomer: 'upper class'
  }
];
