import faker from 'faker';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { set, sub, formatDistanceToNow, compareAsc } from 'date-fns';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@mui/material';
import { useIdleTimer } from 'react-idle-timer';
// utils
import { mockImgAvatar } from '../../utils/mockImages';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { getNotifications, ReadAllNotification, updateNotification } from '../../apis/CommonApi';
import { getRole, token } from '../../utils/helper';
import { getInvitations, getRfxDetails } from '../../apis/VendorApi';

// ----------------------------------------------------------------------

/* const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: 'Your order is placed',
    description: 'waiting for shipping',
    avatar: null,
    type: 'order_placed',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.findName(),
    description: 'answered to your comment on the Minimal',
    avatar: mockImgAvatar(2),
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new message',
    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new mail',
    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.datatype.uuid(),
    title: 'Delivery processing',
    description: 'Your order is being shipped',
    avatar: null,
    type: 'order_shipped',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false
  }
];
 */
function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.message}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp;
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: <img alt={notification.message} src={notification.avatar} />,
    title
  };
}

export default function NotificationsPopover({ items, setItems }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const totalUnRead = items.filter((item) => item.isUnRead === true).length;

  /* -----------------------------------------------------------------------------------------*/

  function NotificationItem({ notification }) {
    const { avatar, title } = renderContent(notification);

    const monthName = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const formatAMPM = (date) => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours %= 12;
      hours = hours || 12;
      minutes = minutes.toString().padStart(2, '0');
      const strTime = `${hours}:${minutes} ${ampm}`;
      return strTime;
    };
    const time = new Date(notification.timestamp * 1000);

    const handleClick = async (id, goTo, par, status) => {
      await updateNotification(token, getRole(), id);
      await getNotifications(token, getRole()).then((r) => {
        setItems(
          r.data.result.reverse().map((x) => ({
            ...x,
            isUnRead: true,
            type: 'chat_message'
          }))
        );
      });
      //  console.log(par);
      if (goTo) {
        /*  const arr = goTo.split('/');
        arr.splice(arr.length - 1, 1);
        const b = arr.join('/'); */

        await getRfxDetails(token, par)
          .then(async (r) => {
            const todaysDate = new Date();
            const rfxOpenDate = r.data.result[0].schedule[0].open_date * 1000;
            const rfxCloseDate = r.data.result[0].schedule[0].close_date * 1000;
            console.log(r);
            const closeDate = new Date(rfxCloseDate);
            const openDate = new Date(rfxOpenDate);
            const active = compareAsc(todaysDate, openDate);
            const participated = compareAsc(todaysDate, closeDate);

            if (participated === 1) {
              localStorage.setItem('rid', par);
              navigate('/myauction/participated', { state: { rid: par } });
            }
            if (status === 'pending') {
              await getInvitations(token)
                .then((r) => {
                  const a = r.data.result;
                  const b = a.filter((x) => x.rfx_id === par);
                  const c = b[0].invitation_id;
                  localStorage.setItem('invId', c);
                })
                .catch((e) => {
                  console.log(e.message);
                });

              localStorage.setItem('rid', par);
              navigate(`/myauction/accept`, { state: { rid: par } });
            }
            if (status === '1') {
              if (active === 1) {
                localStorage.setItem('rid', par);
                navigate('/myauction/active', { state: { rid: par } });
              } else {
                navigate(`/myauction/upcoming`, { state: { rid: par } });
              }
            }

            console.log(r.data.result);
          })
          .catch((e) => {
            console.log(e.message);
          });

        /*  console.log(b); */
      }
      handleClose();
    };

    return (
      <ListItemButton
        onClick={() =>
          handleClick(
            notification.id,
            notification.link,
            notification.url_parameter,
            notification.status
          )
        }
        disableGutters
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          ...(notification.flag !== '1' && {
            bgcolor: 'action.selected'
          })
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled'
              }}
            >
              <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
              {/* {formatDistanceToNow(new Date(notification.createdAt))} */}
              {`${monthName[time.getMonth()]} ${time.getDate()} ${formatAMPM(time)}`}
            </Typography>
          }
        />
      </ListItemButton>
    );
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = async () => {
    await ReadAllNotification(token, getRole()).then((r) => console.log(r.data));
    await getNotifications(token, getRole()).then((r) => {
      setItems(
        r.data.result.reverse().map((x) => ({
          ...x,
          isUnRead: false,
          type: 'chat_message'
        }))
      );
    });
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {/*  {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider />

        <Scrollbar sx={{ maxHeight: 350 }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {items.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>
        {/*  <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {items.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            component={RouterLink}
            to="/notifications"
            onClick={handleClose}
          >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
