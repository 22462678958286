import { AccessTimeOutlined } from '@material-ui/icons';
import StarIcon from '@mui/icons-material/Star';
import { Box, Grid, Rating, Stack, Typography } from '@mui/material';
import React from 'react';

const Testimonials = () => {
  const a = 1;
  const Item = ({ name, time, message, rating, middle }) => (
    <Stack sx={{ p: 2, backgroundColor: middle && 'RGB(186, 128, 54)', borderRadius: 1 }}>
      <Box>
        <Typography sx={{ fontSize: 20, fontWeight: 700, color: middle && 'white' }}>
          {name}
        </Typography>
        <Stack direction="row" aliignItems="center">
          <AccessTimeOutlined
            style={{
              fontSize: 12,
              marginTop: 3,
              marginRight: 3,
              color: middle ? 'white' : 'gray'
            }}
          />
          <Typography sx={{ fontSize: 12, fontWeight: 500, color: middle ? 'white' : 'gray' }}>
            {time}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography sx={{ color: middle && 'white' }}>"{message}"</Typography>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Rating
          name="disabled"
          value={rating}
          readOnly
          precision={0.5}
          emptyIcon={
            <StarIcon style={{ opacity: 0.55, color: middle && 'white' }} fontSize="inherit" />
          }
        />
      </Box>
    </Stack>
  );
  return (
    <Grid container spacing={1} sx={{ mt: 3 }}>
      <Grid item xs={12} sm={12} md={4}>
        <Item
          name="Pramukh Co."
          time="3 months ago"
          message="This is an excellent concept, the documentation is excellent and helped me get things
      done more efficiently."
          rating={4}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Item
          name="Nagjua Co."
          time="1 months ago"
          message="This is an excellent website, the documentation is excellent and helped me get things
      done more efficiently."
          middle
          rating={5}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Item
          name="Datta Co."
          time="6 months ago"
          message="This is an excellent product, the documentation is excellent and helped me get things
      done more efficiently."
          rating={4.5}
        />
      </Grid>
    </Grid>
  );
};

export default Testimonials;
