import { Box, Card, Collapse, Paper, Stack } from '@mui/material';
import React from 'react';
import Label from '../../../components/Label';
import VendorMenu from './VendorMenu';

const MobileVendorCard = ({ row, index, selectedIndex, setSelectedIndex, getList }) => {
  // const []
  console.log('a');
  return (
    <Paper
      sx={{ borderBottom: '1px solid lightgrey', m: 1, p: 1 }}
      square
      onClick={() => setSelectedIndex(selectedIndex === index ? null : index)}
    >
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box>{index + 1}</Box>
          <Stack>
            <Box>{row.title}</Box>
            <Box style={{ fontSize: 12, color: 'grey' }}>{row.field_mobile_number_buyer}</Box>
          </Stack>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <VendorMenu row={row} getList={getList} />
        </Stack>
      </Stack>
      <Collapse in={selectedIndex === index} timeout="auto" unmountOnExit>
        <Stack sx={{ ml: 3.2, mt: 0.8 }} gap={1}>
          <Box style={{ fontSize: 12, color: 'grey' }}>{row.field_vendor_id}</Box>
          {row.field_category.length > 0 && (
            <Stack flexDirection="row" sx={{ flexWrap: 'wrap' }} gap={1}>
              {row.field_category.map((x) => (
                <Label variant="ghost" color="default" style={{ fontSize: 12, padding: 12 }}>
                  {x.name}
                </Label>
              ))}
            </Stack>
          )}

          <Box sx={{ fontSize: 12 }}>
            <b style={{ marginRight: 10 }}>Services:</b>{' '}
            {row.field_services ? row.field_services.map((s) => s.value).join(', ') : '-'}
          </Box>
          <Box sx={{ fontSize: 12 }}>
            <b style={{ marginRight: 10 }}>Company:</b>{' '}
            {row.company_details.length > 0
              ? row.company_details.map((y) => y.company_name).join(', ')
              : '-'}
          </Box>
          {/* <Box sx={{ fontSize: 12, ml: 1 }}>
            <b style={{ marginRight: 10 }}>Payment Terms:</b> {row.field_payment_term_buyer_product}
          </Box>
          <Box sx={{ fontSize: 12, ml: 1 }}>
            <b style={{ marginRight: 10 }}>Address:</b> {row.field_city}, {row.field_state}{' '}
            {row.field_pin_code}
          </Box> */}
        </Stack>
      </Collapse>
    </Paper>
  );
};

export default MobileVendorCard;
