import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Box, Divider, Stack, Typography, useMediaQuery } from '@mui/material';

const images = [
  { url: 'static/Nirmaanlogo.jpeg' },
  { url: 'static/bgImage1.jpg' },
  { url: 'static/slide1.png' }
];

const ImageDisplay = () => {
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <div style={{ marginTop: '50px' }}>
      {/*  <img src="static/Nirmaanlogo - Copy.jpeg" alt="imagee" width="60%" /> */}
      {/* <SimpleImageSlider
        width="100%"
        height={600}
        images={images}
        showBullets
        autoPlay
        autoPlayDelay={5.0}
        slideDuration={1.2}
      /> */}
      <Carousel
        autoPlay
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        stopOnHover={false}
        interval={5000}
        transitionTime={1200}
        swipeable
        infiniteLoop
      >
        <div>
          <img src="static/slide1.png" alt="1" height={matches ? '600px' : '250px'} width="100%" />
        </div>
        <div>
          <img src="static/slide2.png" alt="2" height={matches ? '600px' : '250px'} width="100%" />
        </div>
        <div>
          <img src="static/slide3.png" alt="2" height={matches ? '600px' : '250px'} width="100%" />
        </div>
      </Carousel>
    </div>
  );
};

export default ImageDisplay;
