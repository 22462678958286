/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Grid, InputAdornment, TextField, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useEffect, useState } from 'react';
import { BiRupee } from 'react-icons/bi';
import { fCurrency } from '../../../../../utils/formatNumber';
import {
  bidSubmit,
  checkStartPrice,
  currentBid,
  submitStartPrice
} from '../../../../../apis/VendorApi';
import { decodedJwt, token } from '../../../../../utils/helper';
import SmallSnackbar from '../../../../../components/SnackBar';

const BidButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: 11,
  height: 40,
  width: 150,
  position: 'relative',
  right: -14,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
}));

const InfoText = styled('sup')(({ theme }) => ({
  fontSize: 10,
  color: 'grey'
}));

const StartPriceInput = (props) => {
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const [exist, setExist] = useState(false);
  const [priceSubmitted, setPriceSubmitted] = useState(false);
  const [startPrice, setStartPrice] = useState('');
  const { pid, type } = props;

  const checkProductStartPrice = async () => {
    const rfx_id = localStorage.getItem('rid');

    const data = {
      uid: decodedJwt.uid,
      // rfx_id,
      pid
    };
    console.log(data, 'data submitted in check start price');
    await checkStartPrice(token, data)
      .then((r) => {
        // console.log(r.data, 'check start price');
        if (r.data.result.length > 0) {
          // setExist(true);
          setStartPrice(r.data.result[0].start_price);
          setFieldValue('price', r.data.result[0].start_price);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    checkProductStartPrice();
  }, [pid]);

  const amtSchema = Yup.object().shape({
    price: Yup.number().required('Enter the amount')
  });

  // console.log(data);

  const formik = useFormik({
    initialValues: {
      price: startPrice || ''
    },
    validationSchema: amtSchema,
    onSubmit: async () => {
      const rfx_id = localStorage.getItem('rid');

      const data = {
        uid: decodedJwt.uid,
        // rfx_id,
        rfq_id: rfx_id,
        pid,
        start_price: values.price
        // type
      };

      await submitStartPrice(token, data)
        .then((r) => {
          // console.log(r.data);
          resetForm();
          setSnack({
            open: true,
            color: 'success',
            message: 'Start price submitted successfully'
          });
          checkProductStartPrice();
          setPriceSubmitted(true);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue
  } = formik;

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {!exist ? (
            <Grid container>
              <Grid
                item
                sm={10}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <TextField
                  type="number"
                  label="Start price"
                  size="small"
                  // disabled={priceSubmitted || exist}
                  sx={{ width: '250px' }}
                  value={values.price}
                  {...getFieldProps('price')}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                  InputProps={{
                    shrink: 'true',
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    endAdornment: (
                      <BidButton
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        // disabled={priceSubmitted || exist}
                      >
                        Submit
                      </BidButton>
                    ),
                    maxLength: 10
                  }}
                />
              </Grid>
              <Grid item sm={2}>
                <Tooltip title="Lowest price among all vendors will be considered as a start price of the product">
                  <IconButton>
                    <InfoOutlinedIcon sx={{ width: 25, height: 25 }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            <Tooltip title="this is not the final start price">
              <div>
                <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                {fCurrency(startPrice)}
              </div>
            </Tooltip>
          )}
        </Form>
      </FormikProvider>

      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </div>
  );
};

export default StartPriceInput;

/*  <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
              {fCurrency(startPrice)} <InfoText>*this is not the final start price</InfoText> */
