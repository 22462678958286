import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import Label from '../../../components/Label';

export const Containerr = styled(Paper)(({ theme }) => ({
  margin: '1rem 0rem',
  padding: '1rem'
}));

export const HeadingText = styled('div')(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '14px',
  fontWeight: 700,
  borderRadius: '7px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const Input = styled('input')({
  display: 'none'
});

export const LabelText = styled('div')({
  color: 'grey',
  fontSize: 14,
  marginBottom: '.5rem'
});

export const Helper = styled('div')({
  color: 'red',
  fontSize: 12,
  marginLeft: 14
});
