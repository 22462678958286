import { CircularProgress } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const Load = styled('div')(({ theme, height, width }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height,
  width,
  color: theme.palette.primary.main
}));

const NoDataFound = ({ height, width }) => {
  const a = 1;
  return (
    <Load height={height || '100vh'} width={width || '100%'}>
      No data found
    </Load>
  );
};

export default NoDataFound;
