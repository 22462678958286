import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import SmallSnackbar from '../../../../components/SnackBar';

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210
    },
    drip: {
      minHeight: '150px',
      maxHeight: '170px',
      color: 'grey',
      backgroundColor: '#FFF7CD',
      padding: 20,
      fontSize: 15
    }
  })
);

const DropFiles = ({ setFieldValue, val }) => {
  const classes = useStyles();
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const fileAdded = (m) => {
    setSnack({
      open: true,
      color: 'success',
      message: `${m} successfully uploaded`
    });
  };

  const fileRemove = (m) => {
    setSnack({
      open: true,
      color: 'error',
      message: `${m} successfully removed`
    });
  };
  return (
    <>
      <DropzoneArea
        showPreviews
        showAlerts={false}
        getFileAddedMessage={fileAdded}
        getFileRemovedMessage={fileRemove}
        getDropRejectMessage={fileRemove}
        showPreviewsInDropzone={false}
        useChipsForPreview
        dropzoneText="Upload file"
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText="Selected files"
        onChange={(files) => setFieldValue('productFile', files)}
        filesLimit={1}
        dropzoneClass={classes.drip}
        initialFiles={val}
      />
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </>
  );
};

export default DropFiles;
