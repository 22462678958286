import { DatePicker, LocalizationProvider } from '@mui/lab';
import frLocale from 'date-fns/locale/fr';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from 'react';

/* const localeMap = {
    en: enLocale,
    fr: frLocale,
    ru: ruLocale,
    de: deLocale,
  };
  
  const maskMap = {
    fr: '__/__/____',
    en: '__/__/____',
    ru: '__.__.____',
    de: '__.__.____',
  };
 */
const DateSearch = (props) => {
  const [value, setValue] = React.useState(null);
  const [isData, setIsData] = React.useState(false);
  const { setDateQuery } = props;

  useEffect(() => {
    if (value) {
      const day = value.getDate().toString();
      const month = (1 + value.getMonth()).toString();
      const year = value.getYear().toString().slice(-2);
      const filter = day.concat('/', month, '/', year);
      setDateQuery(value);
      setIsData(true);
    }
  }, [value]);

  const onClear = () => {
    setDateQuery('');
    setValue(null);
    setIsData(false);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={['year', 'month', 'day']}
          inputFormat="dd/MM/yyyy"
          label="Search by date"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} style={{ fontSize: 10 }} size="medium" />}
        />
        {isData && (
          <Button size="small" sx={{ fontSize: 11 }} onClick={onClear}>
            <ClearIcon />
            Clear
          </Button>
        )}
      </LocalizationProvider>
    </div>
  );
};

export default DateSearch;
