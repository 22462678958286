/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/styles';
import { compareAsc } from 'date-fns';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { UserListToolbar } from '../../../../../components/_dashboard/user';
import FullDialog from '../FullDialog';
import HeaderInfo from '../HeaderInfo';
// import ItemTable from './ItemTable';
import Label from '../../../../../components/Label';
import {
  getProductDetails,
  getTempProductDetails,
  getRfxDetails,
  handleInvitation
} from '../../../../../apis/VendorApi';

import { token } from '../../../../../utils/helper';

import SmallSnackbar from '../../../../../components/SnackBar';
import ProductsTable from './ProductsTable';
import ProductDetails from './ProductDetails';
import Loader from '../Loader';
import Page from '../../../../../components/Page';
import MobileProductCard from './MobileProductCard';

const styles = (theme) => ({
  acceptance: {
    backgroundColor: 'blue',
    // Match [xs, md) and [md + 1, ∞)
    //       [xs, md) and [lg, ∞)
    //       [0px, 900px) and [1200px, ∞)
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
});

const ViewAuction = (props) => {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState();
  const [res, setRes] = useState();
  const [loading, isLoading] = useState(true);
  const [decision, setDecision] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const { acceptancePending, active, menu, participated, upcoming } = props;
  const rid = localStorage.getItem('rid');
  const invitation_id = localStorage.getItem('invId');
  // console.log(rows, 'from view product');
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const search = (event) => {
    setQuery(event.target.value);
  };

  const fetchMasterProducts = async (token, tid) => {
    const d = await getProductDetails(token, tid);
    return d;
  };

  const fetchTempProducts = async (token, tid) => {
    const d = await getTempProductDetails(token, tid);
    return d;
  };

  const getRestProduct = (data) => {
    const temp = [];

    data.map((x) =>
      x.field_product_temp_
        ? fetchTempProducts(token, x.field_product_temp_)
            .then((r) => {
              const b = { ...x, ...r.data.result };
              temp.push(b);
              setRows([...temp].reverse());
              // console.log(r, 'from temp product');
              // console.log(r.result);
            })
            .catch((e) => console.log(e.message, 'at fetching tid'))
        : fetchMasterProducts(token, x.field_product_master_)
            .then((r) => {
              const b = { ...x, ...r.result };
              temp.push(b);
              setRows([...temp].reverse());

              // console.log(r.result);
            })
            .catch((e) => console.log(e.message, 'at fetching tid'))
    );
  };

  const getRfx = async (id) => {
    await getRfxDetails(token, id)
      .then((r) => {
        setRes(...r.data.result);
        getRestProduct(r.data.result[0].product);
        console.log(r.data.result[0], 'rfx by nid');
        isLoading(false);
      })
      .catch((e) => console.log(e.message, 'get rfx'));
  };

  useEffect(() => {
    getRfx(rid);
    if (state) {
      getRfx(state.rid);
    }
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    if (rows) {
      const r = rows.filter(
        (x) =>
          (x.field_product_name &&
            x.field_product_name.toLowerCase().includes(query.toLowerCase())) ||
          /*  x.field_lot.name.toLowerCase().includes(query.toLowerCase()) || */
          (x.field_product_id && x.field_product_id.toLowerCase().includes(query.toLowerCase()))
      );
      return r;
    }
  };

  console.log(rows, 'rowssss');

  const handleDecision = async (action) => {
    // const action = 1;
    setBtnLoad(true);
    await handleInvitation(token, invitation_id, action)
      .then((r) => {
        if (r.data.status === true) {
          if (action === 1) {
            setSnack({
              open: true,
              color: 'info',
              message: 'Invitation accepted'
            });
            // navigate('/myauction/upcoming');
            window.location.href = '/myauction/upcoming';
          }
          if (action === 2) {
            setSnack({
              open: true,
              color: 'info',
              message: 'Invitation rejected'
            });
            // navigate('/myauction');
            window.location.href = '/myauction';
          }
          setBtnLoad(false);
          setDecision(true);
        }
      })
      .catch((e) => {
        setBtnLoad(false);
        console.log(e.message);
      });
  };

  return (
    <Page title="Rfq Details">
      {loading ? (
        <Loader height="75vh" />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <HeaderInfo
                data={res}
                pending={acceptancePending}
                active={active}
                upcoming={upcoming}
                participated={participated}
              />
            </Grid>
            <Grid item sm={12} md={acceptancePending ? 6 : 12} xs={12}>
              <Stack direction="row" spacing={1}>
                <Label sx={{ height: 'auto', padding: 1 }} color="primary">
                  <Stack>
                    <Typography sx={{ fontSize: 11, color: 'gray' }}>Name</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 800 }}>{res.title}</Typography>
                  </Stack>
                </Label>
                <Label sx={{ height: 'auto', padding: 1 }} color="info">
                  <Stack>
                    <Typography sx={{ fontSize: 11, color: 'gray' }}>Unique id</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 800 }}>{res.rfx_id}</Typography>
                  </Stack>
                </Label>

                <Label sx={{ height: 'auto', padding: 1 }} color="default">
                  <Stack>
                    <Typography sx={{ fontSize: 11, color: 'gray' }}>Type</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 800 }}>
                      {res.type.toUpperCase()}
                    </Typography>
                  </Stack>
                </Label>
              </Stack>
            </Grid>
            <Grid item sm={12} md={6} xs={12} align={isMdDown ? 'left' : 'right'}>
              <Stack direction="row" justifyContent="right" alignItems="center" px={1}>
                {acceptancePending && !decision ? (
                  <>
                    <span style={{ fontSize: 14, marginRight: 13 }}>
                      Click accept to participate in auction
                    </span>
                    <Button
                      variant="outlined"
                      style={{ marginRight: 13 }}
                      onClick={() => handleDecision(2)}
                    >
                      Reject
                    </Button>
                    {/*   <div>
                      {' '}
                      <IconButton>
                        <CloseIcon color="primary" />
                      </IconButton>
                      <IconButton>
                        <CheckCircleIcon color="primary" />
                      </IconButton>
                    </div> */}

                    <Button variant="contained" onClick={() => handleDecision(1)}>
                      Accept
                    </Button>
                    {btnLoad && <CircularProgress size={14} sx={{ marginLeft: 1 }} />}
                  </>
                ) : (
                  ''
                )}
              </Stack>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Card>
                <UserListToolbar numSelected={0} filterName={query} onFilterName={search} />
                <ProductsTable
                  rows={!query ? rows : filtered()}
                  setOpen={setOpen}
                  setItem={setItem}
                  active={active}
                  menu={menu}
                  upcoming={upcoming}
                />
              </Card>
            </Grid>
          </Grid>
          <FullDialog open={open} setOpen={setOpen} headName="Product details">
            <ProductDetails data={item} />
          </FullDialog>
        </>
      )}
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </Page>
  );
};

export default ViewAuction;
