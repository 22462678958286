import {
  Card,
  Grid,
  CardHeader,
  Typography,
  Button,
  Stack,
  CardContent,
  Divider
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { Icon } from '@iconify/react';
import EditIcon from '@mui/icons-material/Edit';
import userOutlined from '@iconify/icons-ant-design/user-outlined';
import { getNotifications, updateNotification } from '../../../apis/CommonApi';
import { getRole, token } from '../../../utils/helper';
import Scrollbar from '../../Scrollbar';
import { fDateTimeSuffix } from '../../../utils/formatTime';

const Load = styled('div')(({ theme, height, width }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height,
  width,
  color: theme.palette.primary.main
}));

export default function Activities() {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const getNotification = async () => {
    await getNotifications(token, getRole())
      .then((r) => {
        // console.log(r.data.result);
        setItems(
          r.data.result.reverse().map((x) => ({
            ...x,
            isUnRead: true,
            type: 'chat_message'
          }))
        );
      })
      .catch((e) => console.log(e.message));
  };

  const checkNotifi = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onAction: getNotification,
    debounce: 5000
  });

  useEffect(() => {
    getNotification();
  }, []);
  return (
    <Card sx={{ minHeight: 310, maxHeight: 310 }}>
      <CardHeader
        title="Activities"
        action={
          <Button color="primary" size="small" onClick={() => navigate('/notifications')}>
            View All
          </Button>
        }
      />
      <Divider />
      <CardContent>
        {items.length > 0 ? (
          <Grid container spacing={2}>
            {items.map(
              (x, i) =>
                i < 4 && (
                  <Grid item md={12} sm={12} sx={{ overflow: 'auto' }} key={i}>
                    <Stack spacing={1} direction="row" justifyContent="space-between">
                      <Typography variant="subtitle2" sx={{ fontSize: 12 }} color="primary">
                        {x.message}
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: 11, width: 150, textAlign: 'right' }}
                        color="gray"
                      >
                        {fDateTimeSuffix(x.timestamp * 1000)}
                      </Typography>
                    </Stack>
                    {i !== 3 && <Divider />}
                  </Grid>
                )
            )}
          </Grid>
        ) : (
          <Load height="200px">No activities yet</Load>
        )}
      </CardContent>
    </Card>
  );
}
