import React from 'react';
import { Box, Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import { FileDownloadTwoTone } from '@mui/icons-material';
import { AddCircleTwoTone } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import ProductList from './ProductList';
import TaskBar from '../../../../../components/_dashboard/app/TaskBar';

const Products = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div>
      <TaskBar
        leftItems={() => (
          <>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> RFX Name: </span> TMT RFQ 2-4-24
            </Box>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> RFX ID: </span> RQ/2024/021
            </Box>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search"
              sx={{ mt: '0px !important' }}
              fullWidth={isMdDown}
            />
          </>
        )}
        rightItems={() => (
          <>
            <Button variant="outlined" size="small">
              <FileDownloadTwoTone style={{ fontSize: 20, marginRight: 5 }} />
              CSV
            </Button>
            <Button size="small" variant="contained" onClick={() => navigate('/rfx/products/add')}>
              <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
              Add Product
            </Button>
          </>
        )}
      />
      <ProductList rows={[{}, {}]} />
    </div>
  );
};

export default Products;
