import { Box, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { InfoOutlined } from '@mui/icons-material';
import EditableRow from './EditableRow';
import { submitBuyerNodeProduct } from '../../../../../../../apis/BuyerApi';
import EditableHead from './EditableHead';
import AddRow from './AddRow';
import ListRow from './ListRow';

const ls = require('local-storage');

const arrangedData = [
  {
    val: true,
    name: 'medicine',
    label: 'Medicine rx',
    childPermission: [
      {
        id: 1,
        val: true,
        name: 'typeSwitch',
        label: 'Generic / Brand'
      },
      {
        id: 2,
        val: true,
        name: 'productName',
        label: 'Product Name'
      },
      {
        id: 3,
        val: true,
        name: 'uom',
        label: 'UOM'
      },
      {
        id: 4,
        val: true,
        name: 'desiredQty',
        label: 'Desired Qty'
      },
      {
        id: 5,
        val: true,
        name: 'startPrice',
        label: 'Start Price'
      },
      {
        id: 6,
        val: true,
        name: 'PayTerms',
        label: 'Payment Terms'
      },
      {
        id: 7,
        val: true,
        name: 'confPo',
        label: 'Confirmation of PO'
      },
      {
        id: 8,
        val: true,
        name: 'credit',
        label: 'Credit'
      },
      {
        id: 9,
        val: true,
        name: 'attachment',
        label: 'Attachment'
      }
      // {
      //   id: 10,
      //   val: true,
      //   name: "base",
      //   label: "Base",
      // },
      // {
      //   id: 11,
      //   val: true,
      //   name: "gst",
      //   label: "GST%",
      // },
    ]
  }
];

const EditableProductTable = ({
  productItems,
  setProductItems,
  deliveryCharge,
  rfDet,
  initialVal,
  units,
  setProductAttachments,
  productAttachments,
  commonValues,
  type,
  addRowFormik,
  tableStyle,
  handleEdit,
  setAddItem,
  addItem,
  submitLoading,
  handleSubmitProducts
}) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [shippingInput, setShippingInput] = useState(deliveryCharge || 0);
  const [grandTotal, setGrandTotal] = useState(0.0);
  const [totalDisc, setTotalDisc] = useState(0.0);

  const [previousOrdersList, setPreviousOrdersList] = useState(null);

  useEffect(() => {
    setShippingInput(deliveryCharge);
  }, [deliveryCharge]);

  const handleRowsChange = (index, key, value, multipleVals) => {
    // console.log(index, key, value, multipleVals, 'change');
    const tempData = addItem;
    if (multipleVals) {
      multipleVals.forEach((val) => {
        tempData[val.key] = val.value;
      });
    } else {
      tempData[key] = value;
    }

    addRowFormik.setValues({ ...tempData });
    setAddItem({ ...tempData });

    // setProductItems([...tempData]);
    // console.log(tempData[index], 'change22');
  };

  const currentAppFromStorage = (appId) => {
    const performa = ls('performa');
    // if (performa) {
    //   return performa.find((item) => item.order_no === appId);
    // } else {
    //   return null;
    // }
    return performa ? performa.find((item) => item.order_no === appId) : null;
  };

  const appFromStorage = () => {
    const performa = ls('performa');
    return performa;
  };

  const updateCurrentAppInStorage = (currentData) => {
    const lsData = appFromStorage() || [];
    const restData = lsData.filter((item) => item.rfq_id !== currentData.rfq_id);
    const finalArr = [...restData, currentData];
    // const finalArr = [currentData];
    console.log(finalArr, 'finalArr');
    ls('performa', finalArr);
    return finalArr;
  };

  const updateRxRow = (index, newData) => {
    const tempData = productItems;
    // tempData[index] = { ...newData };
    // if (productItems.length - 1 === index) {
    //   tempData.push(initialVal);
    // }
    // setProductItems([...tempData]);
    setAddItem(newData);
    console.log(newData, 'updaterow');
    return tempData;
  };

  // const addRxRow = (newData) => {
  //   productItems.map((item) => {

  //   })
  //   let tempData = [...productItems, { ...initialVal, ...newData }]
  //   setProductItems(tempData)
  //   return tempData
  // }

  const getRxRow = (medname, rowIndex) => {
    const tempData = productItems;
    let index = null;
    let row = null;
    if (rowIndex) {
      index = rowIndex;
      row = tempData[rowIndex];
    }
    tempData.forEach((item, i) => {
      if (item.medname === medname) {
        index = i;
        row = item;
      }
    });

    return {
      index,
      row
    };
  };

  const calculateGrandTotal = () => {
    const totalArr = productItems.map((x) => (x.rtot ? parseFloat(x.rtot) : 0.0));

    const totalSum = totalArr.reduce((x, y) => x + y, 0);
    setGrandTotal(parseFloat(totalSum.toFixed(2)));

    const discArr = productItems.map((x) => (x.discAmt ? parseFloat(x.discAmt) : 0.0));

    const discSum = discArr.reduce((x, y) => x + y, 0);

    setTotalDisc(discSum.toFixed(2));
  };

  useEffect(() => {
    const currentData = currentAppFromStorage(rfDet.nid);
    if (currentData) {
      currentData.productItems = productItems;
      if (productItems.length > 1) {
        updateCurrentAppInStorage(currentData);
      }

      console.log(currentData, 'currentData1');
    } else {
      updateCurrentAppInStorage({
        rfq_id: rfDet.nid,
        productItems
      });
    }
  }, [productItems]);

  const handleAddMedicines = async (newChipData, index) => {
    // console.log(productItems, newChipData, index, 'adding');

    const filterNewChipData2 = productItems.find(
      (cData) => cData.productName === newChipData.productName
    );
    if (filterNewChipData2 !== undefined) {
      console.log('kk');
    } else {
      const attArr = productAttachments;
      attArr[productItems.length > 0 ? productItems.length : 0] = {
        productName: newChipData.productName,
        file: []
      };
      setProductAttachments(attArr);
      // updateRxRow(index, { ...newChipData });
      console.log(newChipData, index, 'adding');
      updateRxRow(index, {
        ...initialVal,
        ...newChipData
      });
    }
  };

  const addNewMedicine = async (data, mode) => {
    console.log(data, 'params');

    if (data.index.toString()) {
      const { index, row } = getRxRow(data.medname, data.index);
      console.log(data, 'iamhere');
      updateRxRow(data.index, {
        ...data,
        medid: ''
      });
    } else {
      const { index, row } = getRxRow(data.medname);

      if (index == null && row == null) {
        updateRxRow(productItems.length - 1, {
          ...initialVal,
          medid: ''
        });
      }
    }

    if (data.nextCell) {
      data.nextCell();
    }
  };

  const handleRemoveAttachment = (index) => {
    setProductAttachments([...productAttachments.filter((x, i) => i !== index)]);
  };

  const handleChangeAttachment = (file, index) => {
    const attArr = productAttachments;
    attArr[index].file = file;
    setProductAttachments([...attArr]);
  };

  const handleRemoveMedicines = async (newChipData, index) => {
    const filteredArr = productItems.filter((item, i) => i !== index);
    const currentData = currentAppFromStorage(rfDet.nid);
    updateCurrentAppInStorage({
      ...currentData,
      rfq_id: rfDet.nid,
      productItems: filteredArr
    });
    setProductItems(filteredArr);
    handleRemoveAttachment(index);
    console.log(filteredArr, 'removeeee');
  };

  const medicinePermission = arrangedData.filter((x) => x.name === 'medicine');

  const columnPermissions =
    medicinePermission.length > 0 ? medicinePermission[0].childPermission || [] : [];
  console.log(columnPermissions, 'columnPermissions');

  const checkPermission = (columnName) => {
    let res = true;
    if (columnName) {
      const arr = columnPermissions.filter((x) => x.name === columnName);

      if (arr.length > 0) res = arr[0].val;
      // } else {
      //   return true;
      // }
    } else {
      const arr = columnPermissions.map((x) => x.val).filter((y) => y === true);
      res = arr.length;
    }
    return res;
  };

  return (
    <Box sx={{ maxHeight: '88vh', overflow: 'auto', p: 1 }}>
      <Paper elevation={6}>
        {/* <table className={tableStyle.table} style={{ width: '100%' }}>
          <thead
            style={{
              backgroundColor: theme.palette.secondary.lighter
            }}
          >
            <tr>
              <th className={tableStyle.th}>Product Name</th>
              <th className={tableStyle.th}>UOM</th>
              <th className={tableStyle.th}>Desired Qty</th>
              <th className={tableStyle.th}>Start Price</th>
              <th className={tableStyle.th}>Payment Terms</th>
              <th className={tableStyle.th}>confirmation of PO</th>
              <th className={tableStyle.th}>Credit</th>
              <th className={tableStyle.th}>Attachment</th>

              <th className={tableStyle.th}>Total ₹</th>

              <th className={tableStyle.th} />
            </tr>
          </thead>
          {productItems.map((x, index) => (
            <tr key={index}>
              <EditableRow
                row={x}
                index={index}
                key={index}
                isMdDown={isMdDown}
                setProductItems={setProductItems}
                productItems={productItems}
                handleRowsChange={handleRowsChange}
                tableStyle={tableStyle}
                handleAddMedicines={handleAddMedicines}
                addNewMedicine={addNewMedicine}
                handleRemoveMedicines={handleRemoveMedicines}
                units={units}
                productAttachments={productAttachments}
                handleChangeAttachment={handleChangeAttachment}
              />
            </tr>
          ))}
        </table> */}
        <table style={{ borderSpacing: 0, borderRadius: 0 }}>
          <EditableHead
            isMdDown={isMdDown}
            productItems={productItems}
            setProductItems={setProductItems}
            checkPermission={checkPermission}
            addRowFormik={addRowFormik}
            type={type}
          />
          <tr>
            <AddRow
              row={initialVal}
              index={0}
              key={0}
              isMdDown={isMdDown}
              checkPermission={checkPermission}
              columnPermissions={columnPermissions}
              handleRowsChange={handleRowsChange}
              setProductItems={setProductItems}
              productItems={productItems}
              tableStyle={tableStyle}
              rfDet={rfDet}
              initialVal={initialVal}
              units={units}
              setProductAttachments={setProductAttachments}
              productAttachments={productAttachments}
              commonValues={commonValues}
              type={type}
              addRowFormik={addRowFormik}
              setAddItem={setAddItem}
              addItem={addItem}
              handleEdit={handleEdit}
              handleAddMedicines={handleAddMedicines}
              addNewMedicine={addNewMedicine}
              handleChangeAttachment={handleChangeAttachment}
            />
          </tr>
          <tr>
            <td colSpan={7} style={{ height: 40, paddingLeft: 10 }}>
              <div style={{ fontSize: 13, fontWeight: 600, color: theme.palette.secondary.main }}>
                Added Products
              </div>
            </td>
          </tr>
          {productItems.map((x, index) => (
            <tr key={index} className={tableStyle.tableRow}>
              <ListRow
                row={x}
                index={index}
                key={index}
                isMdDown={isMdDown}
                checkPermission={checkPermission}
                columnPermissions={columnPermissions}
                handleEdit={handleEdit}
                handleRowsChange={handleRowsChange}
                handleRemoveMedicines={handleRemoveMedicines}
                productAttachments={productAttachments}
                type={type}
              />
            </tr>
          ))}
          {productItems.length === 0 && (
            <tr>
              <td colSpan={14}>
                <Stack flexDirection="column" alignItems="center" gap={1} my={4}>
                  <InfoOutlined sx={{ color: 'lightgrey', fontSize: 40 }} />
                  <h2 style={{ color: 'lightgrey' }}>No Item Found!</h2>
                  <h6 style={{ color: 'lightgrey' }}>Search and add item from above</h6>
                </Stack>
              </td>
            </tr>
          )}
        </table>
      </Paper>

      <Stack flexDirection="row" justifyContent="flex-end" mt={2}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => handleSubmitProducts()}
          // disabled={orderDetails.c_order_details != null}
          loading={submitLoading}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default EditableProductTable;
