import React, { useEffect, useState } from 'react';
import compareAsc from 'date-fns/compareAsc';
import { getInvitations, handleInvitation } from '../../../../apis/VendorApi';
import { token } from '../../../../utils/helper';
import Loader from '../_components/Loader';
import { Rfx } from '../_components/rfxTable';
import Page from '../../../../components/Page';

const PendingAuction = () => {
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);

  const getList = async () => {
    await getInvitations(token)
      .then((r) => {
        setRows(r.data.result.reverse());
        isLoading(false);
        // console.log(r.data.result);
      })
      .catch((e) => {
        isLoading(false);
        console.log(e.message, 'pending auction table');
      });
  };

  useEffect(() => {
    getList();
  }, []);

  // useEffect(() => {
  //   checkActiveDates();
  // }, [rows]);

  const checkActiveDates = async () => {
    const todaysDate = new Date();
    rows.forEach((x) => {
      const openDate = new Date(x.schedule[0].open_date * 1000);
      const res = compareAsc(todaysDate, openDate);
      if (res === 1) {
        handleInvitation(token, x.invitation_id, 2);
      }
    });
  };

  return (
    <Page title="Pending Rfq">
      {loading ? (
        <Loader height="75vh" />
      ) : (
        <Rfx rows={rows} menu={[{ text: 'View & Respond', goTo: `/myauction/accept` }]} pending />
      )}
    </Page>
  );
};

export default PendingAuction;
