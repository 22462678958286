/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { useFormik } from 'formik';
import {
  Avatar,
  Button,
  Card,
  Dialog,
  FormLabel,
  Grid,
  Select,
  Slide,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { getDatabase, ref, onValue, set, query, equalTo, get, child } from 'firebase/database';
import { useParams } from 'react-router-dom';
import {
  getActiveRfxDetails,
  getCompanyName,
  getProductDetails,
  getTempProductDetails,
  checkCompany,
  getClosingTime
} from '../../../../../apis/VendorApi';

import FullDialog from '../../_components/FullDialog';
import { decodedJwt, token } from '../../../../../utils/helper';
import BidToolbar from './BidToolbar';
import HeaderInfo from '../../_components/HeaderInfo';
import BidTable from './BidTable';
import Label from '../../../../../components/Label';
import Loader from '../../_components/Loader';
import Page from '../../../../../components/Page';
import CompanySelection from './CompanySelection';
import { db } from '../../../../../firebase';

const InfoBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.primary,
  height: '100%'
}));

const BidPage = () => {
  const [query, setQuery] = useState('');
  const rid = localStorage.getItem('rid');
  // const itemName = localStorage.getItem('itemName');
  const [res, setRes] = useState();
  const [endTime, setEndTime] = useState();
  const [rows, setRows] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [loading, isLoading] = useState(true);
  const [openCompany, setOpenCompany] = useState(false);
  const [newBid, setNewBid] = useState();

  const fetchMasterProducts = async (token, tid) => {
    const d = await getProductDetails(token, tid);
    return d;
  };

  const fetchTempProducts = async (token, tid) => {
    const d = await getTempProductDetails(token, tid);
    return d;
  };

  const getRestProduct = (data) => {
    const temp = [];

    data.forEach(async (x, i) => {
      if (data.length - 1 === i) {
        isLoading(false);
      }
      if (x.field_product_temp_) {
        await fetchTempProducts(token, x.field_product_temp_)
          .then((r) => {
            const b = { ...x, ...r.data.result };
            temp.push(b);
            setRows([...temp].reverse());
            // console.log(r, 'from temp product');
            // console.log(r.result);
          })
          .catch((e) => console.log(e.message, 'at fetching tid'));
      } else {
        await fetchMasterProducts(token, x.field_product_master_)
          .then((r) => {
            const b = { ...x, ...r.result };
            temp.push(b);
            setRows([...temp]);
            // console.log(r);
          })
          .catch((e) => console.log(e.message, 'at fetching tid'));
      }
    });
  };

  const getList = async () => {
    await getActiveRfxDetails(token, rid)
      .then((r) => {
        setRes(r.data.result[0]);
        if (r.data) {
          setEndTime(r.data.result[0].schedule[0].close_date);
        }
        getRestProduct(r.data.result[0].product);

        console.log(r.data.result, 'active rfx details');
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    const closeTimeRef = ref(db, `closeTime`);
    onValue(closeTimeRef, (snapshot) => {
      const arr = [];
      // console.log(snapshot.val());
      snapshot.forEach((x) => {
        if (rid === x.val().rfx_id) {
          arr.push(x.val());
        }
      });

      if (arr.length > 0) {
        setEndTime(arr[arr.length - 1].close_date);
      }
      // console.log(arr, 'closeTime updated');
    });
  }, [db]);

  const fetchAllBids = () => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `bid`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const arr = [];
          snapshot.forEach((x) => {
            if (rid === x.val().rid) {
              arr.push(x.val());
            }
          });
          localStorage.setItem('previousBid', JSON.stringify(arr));
          setNewBid(arr[arr.length - 1]);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchAllBids();
  }, []);

  const getCompany = async () => {
    await getCompanyName(token)
      .then((r) => {
        convert(r.data.result);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getList();
    getCompany();
  }, []);

  const convert = (data) => {
    const a = data.map((x) => ({ tid: x.id, name: x.title }));
    // console.log(a);
    setCompanyList(a);
  };

  const search = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) => x.field_product_name.toLowerCase().includes(query.toLowerCase()) /*  ||
        x.field_lot.name.toLowerCase().includes(query.toLowerCase()) */
    );

    return r;
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: '#229A16'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toUpperCase()
    };
  }

  return (
    <>
      <Page title="Rfx Bidding">
        {loading ? (
          <Loader height="75vh" />
        ) : (
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <HeaderInfo data={res} endTime={endTime} active />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                <Label
                  sx={{ height: 'auto', padding: 1, backgroundColor: 'transparent' }}
                  color="primary"
                >
                  <Stack spacing={1} direction="row">
                    {/* <Avatar {...stringAvatar(res.title)} /> */}
                    <Stack>
                      <Typography sx={{ fontSize: 11, color: 'gray' }}>Rfq name</Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 800 }}>{res.title}</Typography>
                    </Stack>
                  </Stack>
                </Label>
                <Label
                  sx={{ height: 'auto', padding: 1, backgroundColor: 'transparent' }}
                  color="info"
                >
                  <Stack spacing={1} direction="row">
                    {/*   <Avatar {...stringAvatar(res.rfx_id)} /> */}
                    <Stack>
                      <Typography sx={{ fontSize: 11, color: 'gray' }}>Unique id</Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 800 }}>{res.rfx_id}</Typography>
                    </Stack>
                  </Stack>
                </Label>

                <Label
                  sx={{ height: 'auto', padding: 1, backgroundColor: 'transparent' }}
                  color="default"
                >
                  <Stack spacing={1} direction="row">
                    {/*   <Avatar {...stringAvatar(res.rfx_id)} /> */}
                    <Stack>
                      <Typography sx={{ fontSize: 11, color: 'gray' }}>Type</Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 800 }}>
                        {res.type.toUpperCase()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Label>
                {companyName && (
                  <Label
                    sx={{ height: 'auto', padding: 1, backgroundColor: 'transparent' }}
                    color="success"
                  >
                    <Tooltip title={`You are representing ${companyName.name} company`}>
                      <Stack spacing={1} direction="row">
                        {/* <Avatar {...stringAvatar(companyName.name)} /> */}
                        <Stack>
                          <Typography sx={{ fontSize: 11, color: 'gray' }}>Company name</Typography>
                          <Typography sx={{ fontSize: 16, fontWeight: 800 }}>
                            {companyName.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Tooltip>
                  </Label>
                )}
              </Stack>
            </Grid>

            <Grid item sm={12}>
              <Card>
                <BidToolbar
                  filterName={query}
                  onFilterName={search}
                  schedule={res.schedule}
                  endTime={endTime}
                  setEndTime={setEndTime}
                  biddingRules={res.biding_rules}
                />
                <BidTable
                  rows={!query ? rows : filtered()}
                  rfxData={res}
                  companyName={companyName}
                  setRows={setRows}
                  fetchAllBids={fetchAllBids}
                />
              </Card>
            </Grid>
          </Grid>
        )}
        <CompanySelection
          res={res}
          companyList={companyList}
          open={openCompany}
          setOpen={setOpenCompany}
          setCompanyName={setCompanyName}
        />
      </Page>
    </>
  );
};

export default BidPage;
