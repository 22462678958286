import {
  Button,
  Card,
  CardContent,
  DialogActions,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { formContext } from '../../FormContainer';
import { HeadingText } from '../../styles';
import FullDialog from '../../_components/FullDialog';
import NewReqContainer from '../reqNewProduct/NewReqContainer';
import { getUnitOfMeasure } from '../../../../../apis/BuyerApi';
import { token } from '../../../../../utils/helper';
import FormLeft from './FormLeft';
import FormRight from './FormRight';
import SubmitForm from './SubmitForm';
import Timeline from './Timeline';

const ProductFormContainer = ({ prod, setSnack, setFlag }) => {
  const [open, setOpen] = useState(false);
  const [units, setUnits] = useState([]);
  const [measure, setMeasure] = useState([]);
  const [chartError, setChartError] = useState('');

  const companyName = localStorage.getItem('companyName');
  const form = useContext(formContext);
  const productState = form.productFormik;
  const newReqState = form.newProFormik;
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    productState;
  const getUnits = async () => {
    await getUnitOfMeasure(token).then((r) => setUnits(r.result));
  };
  useEffect(async () => {
    getUnits();
  }, []);

  useEffect(() => {
    units.map((x) => x.tid === values.unitOfMeasure && setMeasure(x.name));
  }, [values.unitOfMeasure, units]);

  React.useEffect(() => {
    if (!newReqState.isSubmitting && newReqState.isValid) {
      setOpen(false);
    }
  }, [newReqState.isSubmitting]);

  const handleClose = () => {
    setOpen(false);
    newReqState.resetForm();
  };

  const clearProduct = () => {
    setFieldValue('masterProduct', '');
    setFieldValue('productName', '');
    setFieldValue('tempProduct', '');
  };

  const timelineSchema = Yup.object().shape({
    date: Yup.string()
      .required('date is required')
      .test((d) => d !== 'NaN/NaN/NaN'),
    qty: Yup.number().typeError('you must specify a number').required('Quantity is required')
  });
  const timelineFormik = useFormik({
    initialValues: {
      date: '',
      qty: ''
    },
    validationSchema: timelineSchema,
    onSubmit: () => {
      setFieldValue('deliveryChart', [...values.deliveryChart, timelineFormik.values]);
      timelineFormik.resetForm();
    }
  });
  const a = 1;
  return (
    <Card sx={{ margin: 1 }}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} sm={12}>
            <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={2}>
              <HeadingText style={{ backgroundColor: '#fff8ce' }}>
                <div style={{ fontWeight: 700, color: '#7a4f01' }}>Required product or service</div>
              </HeadingText>
              {companyName && (
                <HeadingText style={{ backgroundColor: '#d0f2fe' }}>
                  <div style={{ color: '#04297a', fontWeight: 700 }}>
                    {/* <span style={{ fontWeight: 700 }}>Company:-</span>  */}
                    {companyName}
                  </div>
                </HeadingText>
              )}
              {/* {reqId && (
              <HeadingText color="secondary">
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  <span style={{ fontWeight: 700 }}>Requirement ID:-</span> {reqId}
                </Typography>
              </HeadingText>
            )} */}

              {values.productName && (
                <HeadingText
                  style={{
                    backgroundColor: '#c9facd'
                  }}
                >
                  <div style={{ color: '#35952a', fontWeight: 700 }}>
                    {/*  <span style={{ fontWeight: 700 }}>Product:-</span> */} {values.productName}
                    {values.unitOfMeasure && ` - ${measure}`}
                  </div>
                  <IconButton sx={{ marginLeft: 2 }} onClick={clearProduct}>
                    <ClearIcon sx={{ fontSize: '14px' }} />
                  </IconButton>
                </HeadingText>
              )}
            </Stack>
          </Grid>
          <FormLeft setOpen={setOpen} timelineFormik={timelineFormik} chartError={chartError} />
          <FormRight />
          {/*   <Timeline /> */}
          <SubmitForm
            setSnack={setSnack}
            setChartError={setChartError}
            prod={prod}
            setFlag={setFlag}
          />
          <FullDialog open={open} setOpen={setOpen} lot="New product">
            <NewReqContainer formik={newReqState} />
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                onClick={newReqState.handleSubmit}
                variant="contained"
                loading={newReqState.isSubmitting ? true : undefined}
                disabled={newReqState.isSubmitting}
              >
                Add
              </LoadingButton>
            </DialogActions>
          </FullDialog>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductFormContainer;
