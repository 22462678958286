import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import auctionFill from '@iconify/icons-ri/auction-fill';
import doubleQuoteFill from '@iconify/icons-ri/double-quotes-r';
import hammerSolidFill from '@iconify/icons-ri/hammer-fill';
import { FaQuoteRight } from 'react-icons/fa';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Cookies from 'js-cookie';
import { getRole } from '../../utils/helper';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const role = getRole();

const buyer = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon(pieChart2Fill)
  },
  // {
  //   title: 'View Requirement',
  //   path: '/requirementList',
  //   icon: getIcon(auctionFill)
  // },
  {
    title: 'RFQ',
    path: '/rfq',
    icon: getIcon(doubleQuoteFill)
  },
  {
    title: 'RFX',
    path: '/rfx',
    icon: getIcon(hammerSolidFill)
  },
  {
    title: 'Your Vendors',
    path: '/your-vendors',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: getIcon(fileTextFill)
  }
];

const vendor = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'My Auction',
    path: '/myauction',
    icon: getIcon(auctionFill)
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: getIcon(fileTextFill)
  }
];

function Config() {
  switch (role) {
    case 'buyer':
      return buyer;
    case 'vendor':
      return vendor;
    default:
      return role;
  }
}

const sidebarConfig = Config();

export default sidebarConfig;
