/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Box, Button, Grid, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';

const AppInfo = () => {
  const [rotate, setRotate] = useState(false);
  const matches = useMediaQuery('(min-width:600px)');
  const a = 1;
  const Item = ({ heading, subHeading }) => (
    <Paper variant="outlined" sx={{ p: 1 }}>
      <Stack>
        <Typography sx={{ fontSize: 18, fontWeight: 600 }}>{heading}</Typography>
        <Typography sx={{ fontSize: 14, color: 'gray' }}>{subHeading}</Typography>
      </Stack>
    </Paper>
  );
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item md={4} sm={12} xs={12}>
          <Stack justifyContent="center" alignItems="center">
            <div
              style={{ width: '200px', height: 'auto' }}
              onMouseOver={() => setRotate(true)}
              onMouseOut={() => setRotate(false)}
            >
              <ReactCardFlip isFlipped={rotate} flipDirection="horizontal">
                <div>
                  <img src="static/google.png" alt="aa" height="379px" />
                </div>

                <div>
                  <img src="static/apple.png" alt="aa" />
                </div>
              </ReactCardFlip>
            </div>
          </Stack>
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <Stack spacing={2} sx={{ py: 2 }}>
            <Stack
              direction={matches ? 'row' : 'column'}
              justifyContent="space-between"
              spacing={2}
            >
              <Item
                heading="Easily place requirement"
                subHeading="Buyers can easily place their business requirement from anywhere."
              />
              <Item
                heading="Easy order finalisation"
                subHeading="Place and receive business orders form anywhere around the world."
              />
            </Stack>
            <Stack
              direction={matches ? 'row' : 'column'}
              justifyContent="space-between"
              spacing={2}
            >
              <Item
                heading="Easy for all vendor"
                subHeading="One portal to reach all vendors and lighting fast procurement."
              />
              <Item
                heading="Get notification"
                subHeading="Immediate notification to vendors and procurement made digital."
              />
            </Stack>
            {/* <Stack direction="row" justifyContent="space-evenly" spacing={2}>
              <img src="static/gplay.png" alt="google play" height="128px" width="128px" />
              <img src="static/ios.png" alt="ios" height="128px" width="128px" />
            </Stack> */}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppInfo;
