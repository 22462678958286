/* eslint-disable camelcase */
import {
  Box,
  Grid,
  Typography,
  Link,
  Button,
  Stack,
  Card,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { compareAsc } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AttachmentIcon from '@mui/icons-material/Attachment';
import LinesEllipsis from 'react-lines-ellipsis';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { fDateTimeSuffix } from '../../../../utils/formatTime';
import FullDialog from './FullDialog';
import BiddingRules from './BiddingRules';
import Label from '../../../../components/Label';
import { handleInvitation } from '../../../../apis/VendorApi';
import { token } from '../../../../utils/helper';
import Blinker from './Blinker';

const HeaderStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 1),
  borderRadius: theme.shape.borderRadiusSm,
  /*   backgroundColor: theme.palette.grey[200], */
  height: '100%'
}));

const InfoStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 1),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.info.lighter,
  height: '100%',
  maxHeight: '110%',
  overflow: 'hidden',
  wordWrap: 'break-word',
  width: '100%'
}));

const TypeStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 1),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.warning.lighter,

  height: '100%'
}));

const TitleStyle = styled('span')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 700,
  color: 'grey'
}));

const TimeStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 1),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.error.lighter,
  height: '100%'
}));

const HeaderInfo = ({ data, status, endTime, active, upcoming, participated, pending }) => {
  const [clockState, setClockState] = useState();
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState('warning');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const rid = localStorage.getItem('rid');
  const invitation_id = localStorage.getItem('invId');
  const rules = { advance_dynamics: data.advance_dynamics, biding_rules: data.biding_rules };
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const date = new Date();
  let day = date.getDate();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  const fullDate = `${day}/${month}/${year}`;

  useEffect(() => {
    const timerr = setInterval(() => {
      const date = new Date();
      setClockState(date.toLocaleTimeString());
      // console.log(pathname);
      if (pathname === '/myauction/accept') {
        // console.log(pending);
        checkPendingDates();
      }
      if (pathname === '/myauction/upcoming') {
        // console.log(upcoming);
        checkActiveDates();
      }
    }, 1000);

    return () => {
      clearInterval(timerr);
    };
  }, []);

  const openTime = new Date(data.schedule[0].open_date * 1000);
  const closeTime = active
    ? new Date(endTime * 1000)
    : new Date(data.schedule[0].close_date * 1000);

  // console.log(closeTime, 'from headerinfo');
  const handleRules = () => {
    setOpen(true);
  };

  const handleDecision = async (action) => {
    // const action = 1;
    // console.log(invitation_id, action);
    await handleInvitation(token, invitation_id, action)
      .then((r) => {
        if (r.data.status === true) {
          if (action === 1) {
            navigate('/myauction?flag=1');
          }
          if (action === 2) {
            navigate('/myauction');
          }
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const checkPendingDates = async () => {
    const todaysDate = new Date();
    const result = compareAsc(todaysDate, openTime);

    if (pathname === '/myauction/accept' && pathname !== '/myauction' && result === 1) {
      // handleDecision(2);
      // navigate('/myauction');
    }
    // console.log('checked pending', result);
  };

  const checkActiveDates = async () => {
    const todaysDate = new Date();
    const result = compareAsc(todaysDate, openTime);
    if (pathname === '/myauction/upcoming' && pathname !== '/myauction' && result === 1) {
      navigate('/myauction/active');
    }
    // console.log('checked active', result);
  };

  const checkColor = () => {
    if (active) setColor('success');
    if (pending) setColor('warning');
    if (upcoming) setColor('info');
    if (participated) setColor('success');
  };

  useEffect(() => {
    checkColor();
  }, []);
  return (
    <HeaderStyle>
      <Grid container sx={{ display: 'flex' }} spacing={1}>
        <Grid item sm={12} xs={12} md={4}>
          <InfoStyle>
            <Grid container>
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: isMdDown ? 'center' : 'flex-start',
                  justifyContent: 'center'
                }}
              >
                <span style={{ fontSize: 13, fontWeight: 700 }}>Description: </span>
                <Tooltip title={data.description || 'N/A'} placement="bottom">
                  <Box
                    sx={{
                      fontSize: 11,
                      fontWeight: 400,
                      marginLeft: '10px'
                    }}
                  >
                    <LinesEllipsis
                      text={data.description || 'N/A'}
                      maxLine="2"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </Box>
                </Tooltip>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: isMdDown ? 'center' : 'flex-start',
                  justifyContent: 'center'
                }}
              >
                <span style={{ fontSize: 13, fontWeight: 700 }}>Contact Information: </span>
                <Tooltip title={data.contact_info || 'N/A'} placement="bottom">
                  <Box
                    sx={{
                      fontSize: 11,
                      fontWeight: 400,
                      marginLeft: '10px'
                    }}
                  >
                    {isMdDown ? (
                      <>{data.contact_info || 'N/A'} </>
                    ) : (
                      <LinesEllipsis
                        text={data.contact_info || 'N/A'}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    )}
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </InfoStyle>
        </Grid>
        <Grid item sm={12} xs={12} md={4} align="center">
          <TypeStyle>
            <span style={{ fontSize: 13, fontWeight: 700 }}>Type: Reverse auction(english)</span>
            {data.attachment && (
              <Box>
                <Button size="small" sx={{ width: 190 }}>
                  <AttachmentIcon sx={{ width: 20, height: 20 }} />
                  <Link target="_blank" href={data.attachment} sx={{ textDecoration: 'none' }}>
                    {data.type.toUpperCase()} Attachments
                  </Link>
                </Button>
              </Box>
            )}

            <Box>
              <Button size="small" sx={{ width: 190 }} onClick={handleRules}>
                <InfoOutlinedIcon sx={{ width: 20, height: 20 }} /> <span>View bidding rules </span>
              </Button>
            </Box>
          </TypeStyle>
        </Grid>
        <Grid item sm={12} xs={12} md={4} align="right">
          <TimeStyle>
            <Grid
              container
              align="right"
              sx={{
                paddingBottom: 1,
                height: '100%'
              }}
            >
              <Grid item sm={2} xs={3} align="center">
                <AccessTimeIcon sx={{ width: 34, height: 34, marginTop: 0.5 }} color="primary" />
              </Grid>
              <Grid item align="left" sm={10} xs={9}>
                <Stack>
                  <Box>
                    <span style={{ fontSize: 12, fontWeight: 700 }}>Open time:</span>
                    <span style={{ fontSize: 11, fontWeight: 400, marginLeft: 5 }}>
                      <Label style={{ backgroundColor: 'transparent' }} color="success">
                        {fDateTimeSuffix(openTime)}
                      </Label>
                    </span>
                  </Box>
                  <Box>
                    <span style={{ fontSize: 12, fontWeight: 700 }}>Close time:</span>
                    <span style={{ fontSize: 10, fontWeight: 400, marginLeft: 5 }}>
                      <Label style={{ backgroundColor: 'transparent' }} color="error">
                        {fDateTimeSuffix(closeTime)}
                      </Label>
                    </span>
                  </Box>
                  <Box>
                    <span style={{ fontSize: 12, fontWeight: 700 }}>Current time:</span>
                    <span style={{ fontSize: 10, fontWeight: 400, marginLeft: 5 }}>
                      <Label style={{ backgroundColor: 'transparent' }} color="info">
                        {fullDate} {clockState}
                      </Label>
                    </span>
                  </Box>
                  <Box>
                    <span style={{ fontSize: 12, fontWeight: 700 }}>Status:</span>
                    <span style={{ fontSize: 11, fontWeight: 400, marginLeft: 10 }}>
                      {active ? (
                        <Blinker invisible={false}>
                          <Label color={color}>ACTIVE</Label>
                        </Blinker>
                      ) : (
                        <Label color={color}>
                          {active && 'ACTIVE'}
                          {pending && 'PENDING'}
                          {upcoming && 'UPCOMING'}
                          {participated && 'COMPLETED'}
                        </Label>
                      )}
                    </span>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </TimeStyle>
        </Grid>
      </Grid>
      <FullDialog open={open} setOpen={setOpen} headName="Bidding rules">
        <BiddingRules data={rules} />
      </FullDialog>
    </HeaderStyle>
  );
};

export default HeaderInfo;
