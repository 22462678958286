import { Box, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

const TotalInfo = () => {
  const matches = useMediaQuery('(min-width:600px)');
  // console.log(matches);

  const Item = ({ number, text, last }) => (
    <Stack justifyContent="center" alignItems="center" sx={{ p: 7 }}>
      <Typography sx={{ fontSize: 50, fontWeight: 700, color: 'RGB(186, 128, 54)' }}>
        {number}
      </Typography>
      <Typography sx={{ fontSize: 20, fontWeight: 500, color: 'gray' }}>{text}</Typography>
    </Stack>
  );
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          borderRight: matches ? '1px lightgrey solid' : '',
          borderBottom: !matches ? '1px lightgrey solid' : ''
        }}
      >
        <Item number="200+" text="Total Vendors" />
      </Grid>
      {/* <div>
        <Divider orientation="vertical" />
      </div> */}
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          borderRight: matches ? '1px lightgrey solid' : '',
          borderBottom: !matches ? '1px lightgrey solid' : ''
        }}
      >
        <Item number="100+" text="Total Buyers" />
      </Grid>
      {/* <div>
        <Divider orientation="vertical" />
      </div> */}
      <Grid item xs={12} sm={4} md={4}>
        <Item number="300+" text="Total Products" />
      </Grid>
    </Grid>
  );
};

export default TotalInfo;
