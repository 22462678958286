/* eslint-disable no-nested-ternary */
import { Card, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

const Step = ({ right, data }) => {
  const matches = useMediaQuery('(min-width:1000px)');
  return (
    <Stack
      spacing={2}
      sx={{ p: 2, mb: 7, backgroundColor: right && '#f5f6f8', borderRadius: '16px' }}
    >
      <Typography sx={{ fontSize: 25, fontWeight: 700 }}>Step {data.step}</Typography>
      <Stack direction={matches ? (right ? 'row-reverse' : 'row') : 'column'} spacing={2}>
        <Card>
          <img src={`static/${data.image}`} alt="step" style={{ borderRadius: '16px' }} />
        </Card>
        <div style={{ width: matches ? '50%' : '100%' }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
              marginBottom: '16px',
              textDecoration: 'underline',
              textDecorationColor: '#ba8036'
            }}
          >
            {data.title}
          </Typography>
          <Typography
            sx={{
              fontSize: 14
            }}
          >
            {data.description}
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
};

export default Step;
