import { alpha, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Delivery = styled(Typography)(({ theme }) => ({
  fontSize: 12
}));

export const AmtText = styled(Typography)(({ theme }) => ({
  fontSize: 10
}));
