import { Box, Button, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const TaskBar = ({ leftItems, rightItems, centerItems }) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      flexDirection={isMdDown ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems={isMdDown ? 'flex-start' : 'center'}
      sx={{ backgroundColor: theme.palette.warning.lighter, p: 1, borderRadius: 2, px: 2 }}
      gap={2}
    >
      <Stack
        flexDirection={isMdDown ? 'column' : 'row'}
        gap={2}
        alignItems="center"
        sx={{ width: isMdDown ? '100%' : 'auto' }}
      >
        {leftItems && leftItems()}
      </Stack>
      <Stack
        flexDirection="row"
        gap={2}
        alignItems="center"
        justifyContent="flex-end"
        sx={{ width: isMdDown ? '100%' : 'auto' }}
      >
        {rightItems && rightItems()}
      </Stack>
    </Stack>
  );
};

export default TaskBar;
