/* eslint-disable camelcase */
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect } from 'react';
import { BiRupee } from 'react-icons/bi';

const Item = styled('div')(({ theme, right, bottom }) => ({
  /* backgroundColor: theme.palette.warning.lighter, */
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  // borderRight: !right && 'dashed 1px grey',
  borderBottom: 'dashed 1px grey',
  height: 80,
  padding: '.5rem'
}));

const ItemHeading = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 800,
  wordWrap: 'break-word',
  textAlign: 'center'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));

const BiddingRules = ({ data }) => {
  const { biding_rules, advance_dynamics } = data;
  console.log(data, 'data');
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Grid container sx={{ maxHeight: 400, overflow: 'auto', minWidth: isMdDown ? 'auto' : 500 }}>
        <Grid item sm={6} xs={12}>
          <Stack>
            {advance_dynamics && advance_dynamics.field_do_you_want_to_allow_ties_ && (
              <Item>
                <ItemHeading>
                  {advance_dynamics.field_do_you_want_to_allow_ties_ === '0' ? 'NO' : 'YES'}
                </ItemHeading>
                <ItemDesc>Ties in leading bids</ItemDesc>
              </Item>
            )}
            {advance_dynamics && advance_dynamics.field_do_you_want_to_allow_parti && (
              <Item>
                <ItemHeading>
                  {advance_dynamics.field_do_you_want_to_allow_parti === '0' ? 'NO' : 'YES'}
                </ItemHeading>
                <ItemDesc>Partial quantity bidding</ItemDesc>
              </Item>
            )}

            {biding_rules && biding_rules.field_bid_cushion_limits && (
              <Item>
                <ItemHeading>{biding_rules.field_lower_limit || '-'}</ItemHeading>
                <ItemDesc>bid cushion lower limit</ItemDesc>
              </Item>
            )}

            {biding_rules && biding_rules.field_minimum_bid_change && (
              <Item>
                <ItemHeading>
                  {biding_rules.field_minimum_bid_change === 'Amount' && (
                    <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                  )}
                  {biding_rules.field_change_value || '-'}
                  {biding_rules.field_minimum_bid_change === 'Percentage' && '%'}
                </ItemHeading>
                <ItemDesc>Minimum bid change</ItemDesc>
              </Item>
            )}

            <Item>
              <ItemHeading>15</ItemHeading>
              <ItemDesc>extend auction if new bid (in minutes)</ItemDesc>
            </Item>
            {biding_rules && (
              <>
                {biding_rules.field_minimum_bid_change ? (
                  ''
                ) : (
                  <Item bottom>
                    <ItemHeading>
                      {biding_rules.field_bids_shown_to_supplier_own || '-'}
                    </ItemHeading>
                    <ItemDesc>Bid information shown to suppliers</ItemDesc>
                  </Item>
                )}
              </>
            )}
          </Stack>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Stack>
            {advance_dynamics && advance_dynamics.field_do_you_want_to_allow_busin && (
              <Item right>
                <ItemHeading>
                  {advance_dynamics.field_do_you_want_to_allow_busin === '0' ? 'NO' : 'YES'}
                </ItemHeading>
                <ItemDesc>Business volume discounts</ItemDesc>
              </Item>
            )}

            {advance_dynamics && advance_dynamics.field_do_you_want_to_allow_bid_trans && (
              <Item right>
                <ItemHeading>
                  {advance_dynamics.field_do_you_want_to_allow_bid_trans === '0' ? 'NO' : 'YES'}
                </ItemHeading>
                <ItemDesc>Bid transformation</ItemDesc>
              </Item>
            )}

            {biding_rules && biding_rules.field_bid_cushion_limits && (
              <Item right>
                <ItemHeading>{biding_rules.field_upper_limit || '-'}</ItemHeading>
                <ItemDesc>bid cushion upper limit</ItemDesc>
              </Item>
            )}
            {biding_rules && biding_rules.field_bids_information_shown_to_ && (
              <Item right>
                <ItemHeading>{biding_rules.field_bids_information_shown_to_ || '-'}</ItemHeading>
                <ItemDesc>Bid information shown</ItemDesc>
              </Item>
            )}
            {biding_rules && biding_rules.field_extension_time_minutes_ && (
              <Item bottom={biding_rules.field_minimum_bid_change && false} right>
                <ItemHeading>{biding_rules.field_extension_time_minutes_ || '-'}</ItemHeading>
                <ItemDesc>Bid extention time (in minutes)</ItemDesc>
              </Item>
            )}
          </Stack>
        </Grid>
        {biding_rules && biding_rules.field_minimum_bid_change && (
          <Grid item sm={12} xs={12}>
            <Item bottom right>
              <ItemHeading>{biding_rules.field_bids_shown_to_supplier_own || '-'}</ItemHeading>
              <ItemDesc>Bid information shown to suppliers</ItemDesc>
            </Item>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default BiddingRules;
