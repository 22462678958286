import * as React from 'react';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { formContext } from '../FormContainer';

const filter = createFilterOptions();

export default function ProductInput({ products, productFormik, error, helper, setOpen }) {
  const form = React.useContext(formContext);
  const formik = form.newProFormik;
  const [value, setValue] = React.useState({
    pid: '',
    name: productFormik.values.productName
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    isValid,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting,
    handleChange
  } = formik;

  /* React.useEffect(() => {
    productFormik.setFieldValue('masterProduct', value ? value.pid : '');
    productFormik.setFieldValue('productName', value ? value.name : '');
  }, [value]); */

  return (
    <>
      <Autocomplete
        fullWidth
        disabled={productFormik.values.tempProduct ? true : undefined}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setOpen(true);
            });
          } else if (newValue && newValue.inputValue) {
            setOpen(true);

            setFieldValue('name', newValue.inputValue);
          } else {
            productFormik.setFieldValue('masterProduct', newValue ? newValue.pid : '');
            productFormik.setFieldValue('productName', newValue ? newValue.name : '');
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={products}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }

          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        loading={products.length === 0}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            value={productFormik.values.productName}
            {...productFormik.getFieldProps('productName')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {products.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            error={error}
            helperText={helper}
            label="Search product"
          />
        )}
      />
    </>
  );
}
