import { Grid, Typography } from '@mui/material';
import React from 'react';

const TabBar = () => (
  <>
    <Grid item sm={6} align="left" sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography>New joinee assets</Typography>
    </Grid>
    <Grid item sm={1} sx={{ borderRight: 'solid 1px  gray' }}>
      <Typography variant="caption">5</Typography>
      <br />
      <Typography variant="caption">Bids</Typography>
    </Grid>
    <Grid item sm={1} sx={{ borderRight: 'solid 1px  gray' }}>
      <Typography variant="caption">2</Typography>
      <br />
      <Typography variant="caption">WatchLists</Typography>
    </Grid>
    <Grid item sm={1} sx={{ borderRight: 'solid 1px  gray' }}>
      <Typography variant="caption">3</Typography>
      <br />
      <Typography variant="caption">Bidders</Typography>
    </Grid>
    <Grid item sm={3}>
      <Typography variant="caption">80h 35mins 15s</Typography>
      <br />
      <Typography variant="caption">Auction Ends on 28 Sep, 3:15pm</Typography>
    </Grid>
  </>
);

export default TabBar;
