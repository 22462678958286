/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Breadcrumbs
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { BiRupee } from 'react-icons/bi';
// components
import Page from '../../../../../components/Page';
import Scrollbar from '../../../../../components/Scrollbar';
import { UserListHead } from '../../../../../components/_dashboard/user';
import { fCurrency } from '../../../../../utils/formatNumber';
import ShowRank from './ShowRank';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ProductsTable({ rows, setOpen, setItem }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // console.log(rows, 'from product table');
  const TABLE_HEAD = [
    { id: 'srNo', label: 'SrNo.', align: 'left' },
    { id: 'field_product_id', label: 'Product id', align: 'left' },
    { id: 'name', label: 'Product name', align: 'left' },
    { id: 'category_items', label: 'Category', align: 'left' },
    { id: 'field_start_price', label: 'Bid price', align: 'left' },
    { id: 'rank', label: 'Rank', align: 'left' },
    { id: 'action', label: 'Actions', align: 'right' }
  ];

  const handleClick = (row) => {
    setItem(row);
    setOpen(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const {
                    product_id,
                    p_nid,
                    name,
                    category_items,
                    field_unit_cost,
                    field_start_price,
                    field_product_name
                  } = row;
                  const isItemSelected = selected.indexOf(field_product_name) !== -1;
                  const srNo = index + 1;
                  const category = category_items && Object.values(category_items);
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell>{srNo}</TableCell>
                      <TableCell>{product_id}</TableCell>
                      <TableCell>{field_product_name}</TableCell>
                      <TableCell>
                        {category_items && (
                          <Breadcrumbs separator=">>" maxItems={1} sx={{ fontSize: 13 }}>
                            {category.map((y, i) => (
                              <span key={i}>{y}</span>
                            ))}
                          </Breadcrumbs>
                        )}
                      </TableCell>
                      <TableCell>
                        <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                        {fCurrency(field_start_price)}
                      </TableCell>
                      <TableCell>
                        <ShowRank pNid={p_nid} />
                      </TableCell>
                      <TableCell align="right">
                        <Button size="small" onClick={() => handleClick(row)}>
                          {/*  <VisibilityOutlinedIcon /> &nbsp; */} View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    Not found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}
