import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/styles';
import { checkPropTypes } from 'prop-types';
import { Stack, useTheme } from '@mui/material';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Bar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 5,
  paddingLeft: 17
}));

const Heading = styled('h4')(({ theme }) => ({
  marginLeft: 5,
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center'
}));

export default function FullDialog(props) {
  const {
    open,
    setOpen,
    headName,
    maxWidth = 'md',
    minWidth,
    onClose,
    subHead,
    fullScreen,
    rightAction,
    leftAction,
    minHeight = 400
  } = props;
  const theme = useTheme();

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        minWidth={minWidth}
        fullWidth
        fullScreen={fullScreen}
        open={open}
        onClose={() => (onClose ? onClose() : setOpen(false))}
        TransitionComponent={Transition}
        sx={{ minHeight }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Bar>
            <Heading
              style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 16 }}
            >
              <InfoOutlinedIcon sx={{ width: 32, height: 32 }} />
              <Stack>
                <div>{headName}</div>
                {subHead && <div style={{ fontSize: 12, fontWeight: '300' }}>{subHead}</div>}
              </Stack>
              {leftAction ? leftAction() : ''}
            </Heading>
            <Stack flexDirection="row" gap={2} justifyContent="flex-end" alignItems="center">
              {rightAction ? rightAction() : ''}
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Bar>
        </AppBar>
        {props.children}
      </Dialog>
    </>
  );
}
