import React from 'react';
import { Typography, Button, Box, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Delivery, AmtText } from './styles';
import MyBidRow from './MyBidRow';
import DeliveryCharges from './DeliveryCharges';
import Freebies from './Freebies';
import BidAmount from './BidAmount';
import BidInput from './BidInput';

const MyBid = () => {
  const hello = <h1>My bid</h1>;
  return (
    <div>
      <Typography color="primary" variant="h6" sx={{ opacity: 0.7 }}>
        My Bid
      </Typography>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Items</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Est. Delivery</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MyBidRow
              item="Some Items"
              description="Description"
              quantity="100"
              date="28 sept - 30 sept"
              amt="₹20,00,000"
              amtText="Twenty Lakhs Only"
            />
            <MyBidRow
              item="Some Items"
              description="Description"
              quantity="100"
              date="28 sept - 30 sept"
              amt="₹20,00,000"
              amtText="Twenty Lakhs Only"
            />
            <Freebies />
            <DeliveryCharges />
            <BidAmount />
            <BidInput />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MyBid;
