import * as React from 'react';
import { CSVDownload, CSVLink } from 'react-csv';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Link as Down } from '@mui/material';
import { styled } from '@mui/styles';

const Item = styled(Link)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: '5px 10px',
  textDecoration: 'none',
  color: 'black'
}));

const CsvItem = styled(CSVLink)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: '5px 10px',
  textDecoration: 'none',
  color: 'black'
}));

const ItemText = styled('div')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));
const Iconn = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  color: theme.palette.text.secondary,
  marginRight: '.5rem'
}));

export default function ReportMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { menu, csvReport } = props;

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRoute = (a) => {
    if (a) {
      navigate(`/dashboard/${a}`, { replace: true });
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOp = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClick}
      >
        <FileDownloadIcon />
      </IconButton>

      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menu.map((x, index) => (
          <MenuItem key={index} onClick={handleOp}>
            {x.text === 'CSV' ? (
              <CsvItem {...csvReport}>
                <Iconn component="img" src={`/static/icons/${x.icon}.svg`} />
                <ItemText>{x.text}</ItemText>
              </CsvItem>
            ) : (
              <Item to={x.goTo}>
                <i className="fas fa-file-pdf" />
                <Iconn component="img" src={`/static/icons/${x.icon}.svg`} />
                <ItemText>{x.text}</ItemText>
              </Item>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
/* () => handleRoute(x.goTo) */
