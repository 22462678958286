import React from 'react';
import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { AmtText } from './styles';

const BidAmount = () => (
  <TableRow>
    <TableCell>
      <Typography variant="caption" sx={{ opacity: 0.6 }}>
        Lowest bid amount
      </Typography>
      <Typography variant="body2">₹20,00,000</Typography>
    </TableCell>
    <TableCell align="right" variant="body2">
      {' '}
    </TableCell>
    <TableCell align="right" colSpan={2}>
      <AmtText variant="caption">Suggested bid amount as per trend</AmtText>
      <Typography variant="body2" sx={{ color: 'red' }}>
        ₹20,00,000
      </Typography>
    </TableCell>
  </TableRow>
);

export default BidAmount;
