import React, { useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { compareAsc, intervalToDuration } from 'date-fns';
import { Button, Dialog, Slide } from '@mui/material';
import { styled } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { sleep } from '../../../../../utils/helper';

const InfoBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.primary,
  height: '100%'
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

// Timer to close re auction

const RfxTimer = ({ timerState, closeTime }) => {
  const [timer, setTimer] = useState();
  const [open, setOpen] = useState(false);
  const { seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = timerState;
  const navigate = useNavigate();

  // function to return styled and formated time
  const FormatTimer = () => {
    const timeCompareRes = compareAsc(new Date(), closeTime);
    let day = days;
    let hour = hours;
    let minute = minutes;
    let second = seconds;
    if (timeCompareRes !== 1) {
      if (hours < 10) {
        hour = `0${hours}`;
      }
      if (minutes < 10) {
        minute = `0${minutes}`;
      }
      if (seconds < 10) {
        second = `0${seconds}`;
      }

      if (days < 10) {
        day = `0${days}`;
      }
    }

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      sleep(2000).then(() => {
        setOpen(true);
      });
    }
    return <div>{` ${day} days | ${hour} hrs | ${minute} mins | ${second} secs`}</div>;
  };

  const handleOnAction = () => {
    navigate('/myauction?flag=3');
  };
  return (
    <div>
      {/*   hours - {hours}, minutes- {minutes}, seconds- {seconds} */}
      <FormatTimer />

      <Dialog maxWidth="md" open={open} TransitionComponent={Transition} sx={{ minHeight: 400 }}>
        <InfoBox>
          <h4>Auction completed</h4>
          <Button fullWidth onClick={handleOnAction}>
            Ok
          </Button>
        </InfoBox>
      </Dialog>
    </div>
  );
};

export default RfxTimer;
