import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import { Link, Stack, Checkbox, TextField, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getOtp } from '../../apis/LoginApi';
import SmallSnackbar from '../../components/SnackBar';

// ----------------------------------------------------------------------
export default function LoginField() {
  window.onbeforeunload = null;
  const [res, setRes] = useState('');
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const navigate = useNavigate();
  const LoginSchema = Yup.object().shape({
    mobNumber: Yup.number()
      .typeError('you must specify a number')
      .required('mobile number is required')
      .test(
        'len',
        'Mobile number should be of 10 digits',
        (val) => val && val.toString().length === 10
      )
  });

  const formik = useFormik({
    initialValues: {
      mobNumber: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      const r = await getOtp(values.mobNumber);
      setRes(r);
      console.log(r);
      if (r.status) {
        navigate('/login/otp', {
          state: {
            mobNum: values.mobNumber,
            Otp: r.otp.toString()
          }
        });
      } else if (r.status.toString() === 'false') {
        setSnack({
          open: true,
          color: 'error',
          message: 'This mobile number does not exist'
        });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="phone-number"
            type="text"
            label="Mobile Number"
            {...getFieldProps('mobNumber')}
            error={Boolean(touched.mobNumber && errors.mobNumber)}
            helperText={touched.mobNumber && errors.mobNumber}
            inputProps={{ maxLength: 10 }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting ? true : undefined}
          disabled={values.mobNumber.length !== 10}
        >
          Login
        </LoadingButton>
      </Form>
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </FormikProvider>
  );
}

/* <div style={{ color: 'red', fontSize: 14, fontWeight: 600 }}>
                This mobile number does not exist
              </div> */
