import { Button, Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import DropFiles from './DropFiles';
import Remarks from './Remarks';

const UpdateContainer = () => {
  const a = 1;
  return (
    <Card sx={{ height: '84vh' }}>
      <CardContent>
        <h1>Update</h1>

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Remarks />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DropFiles />
          </Grid>
          <Grid item sm={12}>
            <Button size="large" variant="contained">
              Update
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UpdateContainer;
