import { CircularProgress } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const Load = styled('div')(({ theme, height, width }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height,
  width,
  color: theme.palette.primary.main
}));

const Loader = ({ height, width }) => {
  const a = 1;
  return (
    <Load height={height} width={width}>
      <CircularProgress />
    </Load>
  );
};

export default Loader;
