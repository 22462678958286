import { TextField } from '@mui/material';
import React from 'react';

const Remarks = () => {
  const a = 1;
  return (
    <div>
      <TextField
        id="outlined-multiline-static"
        label="Remark"
        multiline
        rows={10}
        fullWidth
        disabled
      />
    </div>
  );
};

export default Remarks;
