/* eslint-disable camelcase */
import { Box, Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import closestIndexTo from 'date-fns/closestIndexTo';
import { getParticipated, getProductDetails, getRfxDetails } from '../../../apis/VendorApi';
import { token } from '../../../utils/helper';
import DateSearch from './DateSearch';
import { fDateTimeSuffix } from '../../../utils/formatTime';
import { columns } from './data';
import ReportTable from './ReportTable';
import ReportToolbar from './ReportToolbar';
import Scrollbar from '../../../components/Scrollbar';
import Loader from '../myAuction/_components/Loader';
import FullDialog from '../myAuction/_components/FullDialog';
import Summary from './Summary';
import Page from '../../../components/Page';

const ReportContainer = () => {
  const [query, setQuery] = useState('');
  const [dateQuery, setDateQuery] = useState();
  const [rows, setRows] = useState([]);
  const [res, setRes] = useState([]);
  const [loading, isLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [summ, setSumm] = useState();
  localStorage.removeItem('reportRid');
  localStorage.removeItem('reportPid');
  const getList = async () => {
    await getParticipated(token)
      .then((r, i) => {
        setRows(r.data.result.reverse());
        const temRes = r.data.result;

        temRes.forEach(async (x) => {
          await getRfxDetails(token, x.rfx_id)
            .then((r) => {
              const a = [];
              a.push({ ...x, ...r.data.result[0] });
              setRes(a);
              // console.log(a);
            })
            .catch((e) => console.log(e.message));
        });

        isLoading(false);
        // console.log(r.data);
      })
      .catch((e) => console.log(e.message));
  };

  /*  const fetchProducts = async (token, tid) => {
    const d = await getProductDetails(token, tid);
    return d;
  }; */

  /* const getRestProduct = (data) => {
    const temp = [];

    data.map((x) =>
      fetchProducts(token, x.field_product_master_)
        .then((r) => {
          const b = { ...x, ...r.result };
          temp.push(b);
          setRows([...temp]);

          // console.log(r);
        })
        .catch((e) => console.log(e.message, 'at fetching tid'))
    );
  }; */

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  useEffect(() => {
    filtered();
  }, [dateQuery]);

  const filtered = () => {
    let r;

    if (dateQuery) {
      const dates = rows.map((a) => {
        const openTime = new Date(a.schedule[0].open_date * 1000);
        return openTime;
      });
      r = rows.filter((x, i) => {
        const openTime = new Date(x.schedule[0].open_date * 1000);
        // console.log(openTime.toString());
        const closeTime = new Date(x.schedule[0].close_date * 1000);
        const index = closestIndexTo(dateQuery, dates);
        // console.log(index);
        return i === index;
      });
    } else {
      r = rows.filter(
        (x) =>
          x.title.toLowerCase().includes(query.toLowerCase()) ||
          x.unique_id.toLowerCase().includes(query.toLowerCase())
      );
    }
    /*  const r =
      dateQuery !== null
        ? rows.filter((x) => x.openTime.toString().includes(dateQuery))
        : rows.filter(
            (x) => x.rfxName.toLowerCase().includes(query) || x.srNo.toString().includes(query)
          ); */

    return r;
  };
  const csvRows = () => {
    const data = res;
    // console.log(data, 'from repor');
    const finalRows = [];
    data.forEach((x, index) => {
      const srNo = index + 1;
      const open_date = fDateTimeSuffix(x.schedule[0].open_date * 1000);
      const close_date = fDateTimeSuffix(x.schedule[0].close_date * 1000);
      const temp = { ...x, open_date, close_date, srNo };
      finalRows.push(temp);
    });
    return finalRows;
  };

  const csvReport = {
    data: csvRows(),
    headers: columns.map((x) => ({ label: x.label, key: x.key })),
    filename: 'rfxReport.csv'
  };

  const search = (event) => {
    setQuery(event.target.value);
  };
  return (
    <Page title="Reports">
      {loading ? (
        <Loader height="75vh" />
      ) : (
        <>
          <Box sx={{ p: 3 }}>
            <h1>Reports</h1>
          </Box>
          <Card>
            <ReportToolbar
              numSelected={0}
              filterName={query}
              onFilterName={search}
              setDateQuery={setDateQuery}
              csvReport={csvReport}
            />
            <ReportTable
              rows={query || dateQuery ? filtered() : rows}
              columns={columns}
              setSumm={setSumm}
              setOpen={setOpen}
              csvReport={csvReport}
            />
          </Card>
        </>
      )}
      <FullDialog open={open} setOpen={setOpen} headName="Summary">
        <Summary data={summ} />
      </FullDialog>
    </Page>
  );
};

export default ReportContainer;
