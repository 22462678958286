import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import auctionFill from '@iconify/icons-ri/auction-fill';
import androidFilled from '@iconify/icons-ant-design/android-filled';
import barChartOutlined from '@iconify/icons-ant-design/bar-chart-outlined';
import { GoNote } from 'react-icons/go';
import { MdUpcoming } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, CardActions, Button } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

import { getUpcoming } from '../../../apis/VendorApi';
import { token } from '../../../utils/helper';
import Loader from '../../Loader';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(3, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
  alignItems: 'center',
  justifyContent: 'center'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

const Btn = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

// ----------------------------------------------------------------------

const TOTAL = 714000;

export default function UpcomingAuction() {
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);
  const navigate = useNavigate();
  const getList = async () => {
    await getUpcoming(token)
      .then((r) => {
        setRows(r.data.result);
        isLoading(false);
        // console.log(r.data.result);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getList();
  }, []);
  return (
    <RootStyle>
      <IconWrapperStyle>
        <MdUpcoming size={24} />

        {/* <Icon icon={barChartOutlined} width={24} height={24} /> */}
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(rows.length)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Upcoming Auction
      </Typography>

      <Btn
        size="small"
        align="center"
        sx={{ marginTop: 2 }}
        onClick={() => navigate('/myauction?flag=1')}
      >
        View More
      </Btn>
    </RootStyle>
  );
}
