import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/styles';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import React, { useContext, useEffect, useState } from 'react';
import { formContext } from '../FormContainer';
import { HeadingText, Helper, LabelText } from '../styles';
import SelectInput from '../../components/SelectInput';
import DeliveryTable from '../_components/DeliveryTable';
import DateInput from '../_components/DateInput';
import DropFiles from './DropFiles';
import Label from '../../../../components/Label';
import Drop from './Drop';
import { m, pro } from './data';
import { getUnitOfMeasure } from '../../../../apis/BuyerApi';
import { token } from '../../../../utils/helper';

const Containerr = styled(Card)(({ theme }) => ({
  margin: 1
}));

const ProductTimeline = ({ prod, setFlag, setSnack }) => {
  const navigate = useNavigate();
  const [measure, setMeasure] = useState([]);
  const [chartError, setChartError] = useState('');
  const form = useContext(formContext);
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    form.productFormik;
  const companyName = localStorage.getItem('companyName');
  const timelineSchema = Yup.object().shape({
    date: Yup.string()
      .required('date is required')
      .test((d) => d !== 'NaN/NaN/NaN'),
    qty: Yup.number().typeError('you must specify a number').required('Quantity is required')
  });
  const timelineFormik = useFormik({
    initialValues: {
      date: '',
      qty: ''
    },
    validationSchema: timelineSchema,
    onSubmit: () => {
      setFieldValue('deliveryChart', [...values.deliveryChart, timelineFormik.values]);
      timelineFormik.resetForm();
    }
  });

  // console.log(timelineFormik);

  const onDelete = async (i) => {
    const d = values.deliveryChart;
    await d.splice(i, 1);
    setFieldValue('deliveryChart', d);
  };

  const getUnits = async () => {
    await getUnitOfMeasure(token).then((r) => {
      r.result.map((x) => x.tid === values.unitOfMeasure && setMeasure(x.name));
    });
  };
  useEffect(() => {
    getUnits();
  }, []);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleMainSubmit = async () => {
    if (prod.length === 0) {
      if (checkTimelineValidation() === true) {
        setFlag(1);
        await handleSubmit();
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (values.productName !== '') {
        setFlag(1);
        await handleSubmit();
      } else {
        localStorage.removeItem('reqId');
        localStorage.removeItem('nid');
        localStorage.removeItem('companyName');
        setSnack({
          open: true,
          color: 'success',
          message: `Requirement generated successfully`
        });
        sleep(3000).then(() => {
          navigate('/requirementList');
        });
      }
    }
  };

  const checkTimelineValidation = () => {
    let decision;
    if (values.immediate === false) {
      if (values.noOfMonths === values.deliveryChart.length) {
        setChartError('');
        decision = true;
      } else {
        setChartError('Complete requirement timeline is required');
        decision = false;
      }
    } else {
      decision = true;
    }
    return decision;
  };

  const handleProductSubmit = async () => {
    if (checkTimelineValidation() === true) await handleSubmit();
  };

  return (
    <div>
      <Containerr>
        <CardContent>
          <Grid container spacing={5}>
            <Grid item xs={12} md={12} sm={12}>
              <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={2}>
                <HeadingText color="warning">
                  <Typography sx={{ fontSize: '1rem', fontWeight: 700 }}>
                    Required product or service
                  </Typography>
                </HeadingText>
                {companyName && (
                  <HeadingText color="secondary">
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                      <span style={{ fontWeight: 700 }}>Company:-</span> {companyName}
                    </Typography>
                  </HeadingText>
                )}
                {/*  {reqId && (
                  <HeadingText color="secondary">
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                      <span style={{ fontWeight: 700 }}>Requirement ID:-</span> {reqId}
                    </Typography>
                  </HeadingText>
                )} */}

                {values.productName && (
                  <HeadingText color="success">
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                      <span style={{ fontWeight: 700 }}>Product:-</span> {values.productName}
                      {values.unitOfMeasure && ` - ${measure}`}
                    </Typography>
                  </HeadingText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <Stack direction="column" spacing={3}>
                {/* <TextField
                  fullWidth
                  type="text"
                  label="Assured minimum quantity (per month)"
                  {...getFieldProps('minQty')}
                  error={Boolean(touched.minQty && errors.minQty)}
                  helperText={touched.minQty && errors.minQty}
                /> */}
                <div>
                  <SelectInput
                    cat={values.deliveryTerms}
                    getFieldProps={getFieldProps('deliveryTerms')}
                    name="Proposed delivery terms (if any)"
                    dta={pro}
                    error={Boolean(touched.deliveryTerms && errors.deliveryTerms)}
                    helper={touched.deliveryTerms && errors.deliveryTerms}
                  />
                </div>

                {values.deliveryTerms === 'other' && (
                  <TextField
                    fullWidth
                    type="text"
                    label="if other (in days)"
                    {...getFieldProps('OtherDeliveryTerms')}
                  />
                )}

                <div style={{ border: '1px solid lightgrey', padding: 10, borderRadius: 10 }}>
                  <LabelText>Upload product specifications</LabelText>

                  <Drop setFieldValue={setFieldValue} val={values.productFile} />

                  <Helper style={{ marginTop: 4 }}>{errors.productFile}</Helper>
                </div>
                <TextField
                  label="Product description"
                  id="outlined-multiline-static"
                  multiline
                  rows={2}
                  error={Boolean(touched.desc && errors.desc)}
                  helperText={touched.desc && errors.desc}
                  inputProps={{ maxLength: 255 }}
                  {...getFieldProps('desc')}
                  fullWidth
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={7}>
              <div style={{ border: '1px solid lightgrey', padding: 15, borderRadius: 10 }}>
                <Stack direction="column" spacing={5}>
                  <FormControl>
                    <LabelText>Requirement timeline</LabelText>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox checked={values.immediate} {...getFieldProps('immediate')} />
                      }
                      label="Immediate"
                      labelPlacement="end"
                    />
                  </FormControl>
                  {values.immediate ? (
                    <TextField
                      fullWidth
                      type="text"
                      label="Total quantity"
                      {...getFieldProps('totalQty')}
                      error={Boolean(touched.totalQty && errors.totalQty)}
                      helperText={touched.totalQty && errors.totalQty}
                    />
                  ) : (
                    <>
                      <SelectInput
                        cat={values.noOfMonths}
                        name="Number of months"
                        getFieldProps={getFieldProps('noOfMonths')}
                        dta={m}
                      />
                      {values.deliveryChart.length > 0 && (
                        <DeliveryTable months={values.noOfMonths} onDelete={onDelete} />
                      )}

                      {values.deliveryChart.length < values.noOfMonths && (
                        <>
                          <Stack spacing={2} direction="row" justifyContent="right">
                            <DateInput
                              setFieldValue={timelineFormik.setFieldValue}
                              error={Boolean(
                                timelineFormik.touched.date && timelineFormik.errors.date
                              )}
                              helper={timelineFormik.touched.date && timelineFormik.errors.date}
                            />
                            <TextField
                              type="text"
                              label="Quantity"
                              {...timelineFormik.getFieldProps('qty')}
                              error={Boolean(
                                timelineFormik.touched.qty && timelineFormik.errors.qty
                              )}
                              helperText={timelineFormik.touched.qty && timelineFormik.errors.qty}
                            />
                            <Button size="large" onClick={timelineFormik.handleSubmit}>
                              Add
                            </Button>
                          </Stack>
                          <Helper style={{ marginTop: 4 }}>{chartError}</Helper>
                        </>
                      )}
                    </>
                  )}
                </Stack>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} align="right">
              <LoadingButton
                onClick={handleProductSubmit}
                type="submit"
                sx={{ mr: 1 }}
                variant="outlined"
                loading={isSubmitting ? true : undefined}
                disabled={isSubmitting}
              >
                Save & add new product
              </LoadingButton>
              <LoadingButton
                variant="contained"
                onClick={() => handleMainSubmit()}
                disabled={isSubmitting}
                loading={isSubmitting ? true : undefined}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </CardContent>
      </Containerr>
    </div>
  );
};

export default ProductTimeline;
