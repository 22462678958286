import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/styles';
import { BiRupee } from 'react-icons/bi';
import React, { useEffect } from 'react';
import { fDateTimeSuffix } from '../../../../../utils/formatTime';

const Item = styled('div')(({ theme, right, bottom }) => ({
  /* backgroundColor: theme.palette.warning.lighter, */
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  // borderRight: !right && 'dashed 1px grey',
  borderBottom: !bottom && 'dashed 1px grey',
  [theme.breakpoints.down('md')]: {
    borderBottom: 'dashed 1px grey'
  },
  height: 80,
  padding: '.5rem'
}));

const ItemHeading = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 800,
  wordWrap: 'break-word',
  textAlign: 'center'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));

const Summary = ({ data }) => {
  const time = data.schedule[0];
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Grid container sx={{ maxHeight: 400, overflow: 'auto', minWidth: isMdDown ? 'auto' : 400 }}>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item>
              <ItemHeading>{data.unique_id || '-'}</ItemHeading>
              <ItemDesc>Unique id</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{fDateTimeSuffix(time.open_date * 1000) || '-'}</ItemHeading>
              <ItemDesc>Open date</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_upper_limit || '-'}</ItemHeading>
              <ItemDesc>Upper limit</ItemDesc>
            </Item>
            <Item bottom>
              <ItemHeading>
                {data.field_minimum_bid_change === 'Amount' && (
                  <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                )}
                {data.field_change_value || '-'}
                {data.field_minimum_bid_change === 'Percentage' && '%'}
              </ItemHeading>
              <ItemDesc>Bid change</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item right>
              <ItemHeading>{data.title || '-'}</ItemHeading>
              <ItemDesc>Name</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{fDateTimeSuffix(time.close_date * 1000) || '-'}</ItemHeading>
              <ItemDesc>Close date</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{data.field_lower_limit || '-'}</ItemHeading>
              <ItemDesc>Lower limit</ItemDesc>
            </Item>
            <Item bottom right>
              <ItemHeading>{time.round_name || '-'}</ItemHeading>
              <ItemDesc>Round</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        {/*  <Grid item sm={12} xs={12}>
          <Item bottom right>
            <ItemHeading>Very good product </ItemHeading>
            <ItemDesc>Description</ItemDesc>
          </Item>
          
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Summary;
