/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';

import EditableBrandMedCell from './EditableBrandMedCell';
import EditableProductCell from './EditableProductCell';

export default function EditableMedCell({
  width,
  dataList,
  first,
  medName,
  data,
  index,
  setMedType,
  medType,
  // handleSearch,
  handleOnChange,
  placeholder,
  handleKeyPress,
  fieldIndex,
  saltCom,
  isMdDown,
  setIsDisabled,
  checkPermission,
  productItems,
  tableStyle,
  handleAddMedicines,
  addNewMedicine,
  editProduct
}) {
  const [switchCell, setSwitchCell] = useState(false);

  const [inputVal, setInputVal] = useState('');

  const onFieldFocus = () => {
    setSwitchCell(true);
  };

  return (
    <td
      // onClick={() => setSwitchCell(true)}
      onFocus={() => onFieldFocus()}
      // onKeyDown={() => setSwitchCell(true)}
      onBlur={() => {
        setSwitchCell(false);
        // handleKeyPress(`rxfield${index}-${fieldIndex + 1}`)
        if (medType === 'brand' && inputVal === '') {
          // setInputVal(data.label);
        }
      }}
      style={{
        padding: 0,
        // border: first ? 'none' : '1px solid lightgrey',
        borderBottom: switchCell ? '1.5px solid #13098e' : '1px solid lightgrey',
        width: width || '100%'
        // cursor: 'auto',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {data.cartItem === true ? (
          <EditableProductCell
            width={width}
            data={data}
            index={index}
            medType={medType}
            handleKeyPress={handleKeyPress}
            fieldIndex={fieldIndex}
            isMdDown={isMdDown}
            setIsDisabled={setIsDisabled}
            inputVal={inputVal}
            setInputVal={setInputVal}
            setSwitchCell={setSwitchCell}
            productItems={productItems}
            tableStyle={tableStyle}
            handleAddMedicines={handleAddMedicines}
            addNewMedicine={addNewMedicine}
            editProduct={editProduct}
          />
        ) : (
          <EditableBrandMedCell
            width={width}
            data={data}
            index={index}
            medType={medType}
            handleKeyPress={handleKeyPress}
            fieldIndex={fieldIndex}
            isMdDown={isMdDown}
            setIsDisabled={setIsDisabled}
            inputVal={inputVal}
            setInputVal={setInputVal}
            setSwitchCell={setSwitchCell}
            productItems={productItems}
            tableStyle={tableStyle}
            handleAddMedicines={handleAddMedicines}
            addNewMedicine={addNewMedicine}
          />
        )}
      </div>
    </td>
  );
}
