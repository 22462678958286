import { Card, Skeleton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BtnContainer, CompanyBody, CompanyHeader, Item, ItemText, useStyles } from '../../styles';

const CompanyCardSkeleton = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Card>
        <CompanyHeader>
          <div>
            <Skeleton variant="circular" width={50} height={50} />
          </div>
          <Stack justifyContent="right" alignItems="flex-end" sx={{ width: '100%' }}>
            <Skeleton variant="text" sx={{ width: '90%' }} align="right" />
            <Skeleton variant="text" sx={{ width: '50%' }} align="right" />
          </Stack>
        </CompanyHeader>
        <CompanyBody>
          <Item>
            <Skeleton variant="circular" width={18} height={18} sx={{ marginRight: '.5rem' }} />
            <Skeleton variant="text" sx={{ width: '50%' }} />
          </Item>
          <Item>
            <Skeleton variant="circular" width={18} height={18} sx={{ marginRight: '.5rem' }} />
            <Skeleton variant="text" sx={{ width: '50%' }} />
          </Item>

          <Item>
            <Skeleton variant="circular" width={18} height={18} sx={{ marginRight: '.5rem' }} />
            <Skeleton variant="text" sx={{ width: '50%' }} />
          </Item>
        </CompanyBody>

        <BtnContainer>
          <Skeleton variant="rectangular" width={100} height={35} sx={{ borderRadius: 1 }} />
          <Skeleton variant="rectangular" width={100} height={35} sx={{ borderRadius: 1 }} />
        </BtnContainer>
      </Card>
    </>
  );
};

export default CompanyCardSkeleton;
