import { merge } from 'lodash';
import { useEffect, useState } from 'react';
/* import ReactApexChart from 'react-apexcharts'; */
import Chart from 'react-apexcharts';
import { MobileDateRangePicker } from '@mui/lab';
import { compareAsc } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// material
import { Box, Card, CardContent, CardHeader, TextField } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

export default function RfxChart({ pendingRows, upcomingRows, activeRows, participatedRows }) {
  const [value, setValue] = useState([null, null]);
  const [chartData, setChartData] = useState([
    {
      name: 'Rfx',
      data: [pendingRows.length, upcomingRows.length, activeRows.length, participatedRows.length]
    }
  ]);
  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => seriesName
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '20%', borderRadius: 2 }
    },
    xaxis: {
      categories: ['Pending', 'Upcoming', 'Active', 'Participated']
    },
    stroke: {
      curve: 'straight'
    },
    markers: {
      size: 8
    }
  });
  const CHART_DATA = [
    {
      data: [pendingRows.length, upcomingRows.length, activeRows.length, participatedRows.length]
    }
  ];

  const checkFilter = (rows) => {
    const newRows = [];
    if (rows)
      rows.forEach((x) => {
        const openTime = new Date(x.schedule[0].open_date * 1000);
        const start = compareAsc(openTime, value[0]);
        const end = compareAsc(openTime, value[1]);
        if ((start === 0 || start === 1) && (end === 0 || end === -1)) {
          // console.log(x.title);
          newRows.push(x);
        }
      });

    return newRows;
  };

  const handleFilter = () => {
    setChartData([
      {
        name: 'Rfx',
        data: [
          checkFilter(pendingRows).length,
          checkFilter(upcomingRows).length,
          checkFilter(activeRows).length,
          checkFilter(participatedRows).length
        ]
      }
    ]);
  };

  // console.log(activeRows);

  useEffect(() => {
    setChartData([
      {
        name: 'Rfx',
        data: [pendingRows.length, upcomingRows.length, activeRows.length, participatedRows.length]
      }
    ]);
  }, []);

  useEffect(() => {
    if (value[0]) {
      handleFilter();
    }
  }, [value]);

  return (
    <Card>
      <CardContent>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateRangePicker
              startText="Start date"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} size="small" />
                  <Box sx={{ mx: 1 }}> to </Box>
                  <TextField {...endProps} size="small" />
                </>
              )}
            />
          </LocalizationProvider>
        </Box>
        <Chart options={chartOptions} series={chartData} type="line" height={300} />
      </CardContent>

      {/*  <ReactApexChart type="bar" series={chartData} options={chartOptions} height={300} /> */}
    </Card>
  );
}
