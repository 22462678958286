/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import { useAutocomplete, Grid, IconButton, Paper, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { searchProducts } from '../../../../../../../apis/BuyerApi';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block'
  },
  input: {
    // width: 200,
    fontSize: 12,
    paddingLeft: 5,
    border: 'none',
    resize: 'none',
    outline: 'none'
  },
  listbox: {
    width: 'auto',
    fontSize: 12,
    margin: 0,
    marginTop: 8,
    // borderBottomLeftRadius: 6,
    // borderBottomRightRadius: 6,
    // elevation: 6,
    zIndex: 1000,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    // border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#13098e',
      color: 'white',
      cursor: 'pointer'
    },
    '& li:active': {
      backgroundColor: '#13098e',
      color: 'white'
    }
  },
  saltText: {
    fontSize: '10px !important',
    color: 'grey',
    paddingLeft: 8
  }
}));

export default function EditableBrandMedCell({
  width,
  dataList,
  first,
  medName,
  data,
  index,
  setMedType,
  medType,
  handleKeyPress,
  fieldIndex,
  saltCom,
  isMdDown,
  setIsDisabled,
  inputVal,
  setInputVal,
  setSwitchCell,
  productItems,
  tableStyle,
  handleAddMedicines,
  addNewMedicine
}) {
  const classes = useStyles();

  const [selectedMed, setSelectedMed] = useState([]);

  const [searchResult, setSearchResult] = useState([]);

  const handleInputChange = (val, medSalt) => {
    // console.log(val, 'from input change');
    setInputVal(val || '');
    // if (val) fetchMedResults(val || '');
  };

  const goToNextField = (id) => {
    setSwitchCell(false);
    handleKeyPress(id);
  };

  useEffect(() => {
    fetchMedResults();
  }, []);

  const handleAdd = (val, e) => {
    const checkDuplicate = productItems.find((cData) => cData.productName === val.productName);
    if (checkDuplicate === undefined) {
      // console.log(val, 'from handle add')
      handleInputChange(typeof val === 'object' ? val.productName : val);
      // console.log(val, 'hanldleadd0');
      setSelectedMed(val);
      // setInputVal(typeof val == 'object' ? val.productName : val);

      if (typeof val === 'object') {
        goToNextField(`med${index}-${fieldIndex + 1}`);
        handleAddMedicines(val, index);
      } else {
        console.log(val, 'hanldleadd4');

        goToNextField(`med${index}-${fieldIndex + 1}`);
      }
    }
  };

  const fetchMedResults = async (searchTxt) => {
    // const sellerRes = localStorage.getItem("sellerRes");
    // const parsedInfo = sellerRes ? JSON.parse(sellerRes).sellerDetail : {};
    let filterArr = [];

    await searchProducts(searchTxt).then((res) => {
      if (res.data.status === true) {
        const arr = res.data.result ? Object.values(res.data.result) : [];
        filterArr = arr.map((x) => ({
          ...x,
          productId: x.nid,
          productName: x.field_product_name_rfq,
          uom:
            typeof x.field_unit_of_measure === 'string'
              ? x.field_unit_of_measure
              : x.field_unit_of_measure.map((y) => y.default_unit_of_measure).join(', '),
          desiredQty: x.field_minimum_desired_quantity || '',
          startPrice: x.field_start_price,
          payTerms: x.field_payment_term_buyer_product,
          credit: x.field_number_of_days || '',
          confPo: ''
        }));
      }
    });

    setSearchResult(filterArr);
  };

  const getDefaultValue = () => {
    // console.log(data, 'from default value')
    setInputVal(data.productName || '');

    // } else {
    //   setInputVal('');

    // }
    return data.productName;
  };

  useEffect(() => {
    if (data) getDefaultValue();
  }, [data, medType]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    inputValue
  } = useAutocomplete({
    id: `med${index}-${fieldIndex}`,
    options: searchResult,
    getOptionLabel: (option) => option.productName || '',
    freeSolo: true,
    openOnFocus: true,
    onInputChange: (e) => handleInputChange(e ? e.target.value : data.productName),
    onChange: (e, val) => {
      console.log(e, val, 'onchnageeee');
      if (val) {
        if (val.productId === '') {
          console.log('newMed2');
        } else {
          handleAdd({ ...val }, e);
          // handleAdd(val, e);
        }
      }
    },
    inputValue: inputVal,
    multiple: medType === 'generic',
    value: selectedMed,
    disabled: data.cartItem
    // disabled: false,
  });

  const onFieldFocus = () => {
    setSwitchCell(true);

    if (data.type === 'generic') {
      const newArr = data.productName.split(' + ').map((item) => ({
        salt_name: item
      }));

      setSelectedMed(newArr);
      setInputVal('');
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Box {...getRootProps()} onMouseDown={(e) => e.preventDefault()}>
        <input
          className={classes.input}
          {...getInputProps()}
          style={{
            width: '100%',
            height: 25,
            border: 'none'
          }}
          onMouseDown={(e) => e.preventDefault()}
          onBlur={(e) => {
            getInputProps().onBlur();
            console.log(data, e.productId, selectedMed, 'iamhere3');
            if (e.target.value && (data.productId === '' || data.productId === undefined)) {
              console.log(data, e.target.value, selectedMed, 'iamhere4');
              goToNextField(`med${index}-${fieldIndex + 1}`);
              handleAddMedicines({ ...data, productId: '', productName: e.target.value }, index);
            } else if (
              data.productId !== '' &&
              e.target.value &&
              data.productName !== e.target.value
            ) {
              if (selectedMed.productName) {
                console.log(data, selectedMed, 'iamhere5');
                console.log('from blurrrr');
                setInputVal(selectedMed.productName || '');
              }
              const paramObj = {
                ...data,
                nextCell: () => goToNextField(`med${index}-${fieldIndex + 1}`),
                custom: 1,
                index
              };
              console.log(paramObj, 'newMed4');
              addNewMedicine(paramObj);
            }
          }}
        />
      </Box>

      {groupedOptions.length > 0 ? (
        <Paper elevation={6} className={tableStyle.listbox} component="ul" {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })} style={{ padding: '2px 10px' }}>
              {option.productName}
            </li>
          ))}
        </Paper>
      ) : null}
    </div>
  );
}
