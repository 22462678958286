import { Card, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import UserListToolbar from '../components/UserListToolbar';
import DetailsTable from './DetailsTable';
import { getBuyerRequirementProducts } from '../../../apis/BuyerApi';
import { token } from '../../../utils/helper';
import Page from '../../../components/Page';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
  color: theme.palette.primary.main
}));

const DetailsContainer = () => {
  const location = useLocation();
  const { nid } = location.state;
  const [query, setQuery] = useState('');
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);

  const getList = async () => {
    const res = await getBuyerRequirementProducts(token, nid).then((r) => {
      setRows(r.result);
      // console.log(r.result);
      isLoading(false);
    });
  };
  useEffect(() => {
    getList();
  }, []);

  const search = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.nid.toString().includes(query.toLowerCase()) ||
        x.field_product.toLowerCase().includes(query.toLowerCase()) ||
        x.field_site_location.toLowerCase().includes(query.toLowerCase()) ||
        x.field_product.toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };
  return (
    <Page title="Requirement products">
      {loading ? (
        <Loader>
          <CircularProgress />
        </Loader>
      ) : (
        <>
          {rows.length > 0 ? (
            <Card>
              <UserListToolbar numSelected={0} filterName={query} onFilterName={search} />
              <DetailsTable rows={!query ? rows : filtered()} />
            </Card>
          ) : (
            <Loader>No data found</Loader>
          )}
        </>
      )}
    </Page>
  );
};

export default DetailsContainer;
