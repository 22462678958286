/* eslint-disable camelcase */
import * as React from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHeading from '../../../../components/table/TableHeading';
import { Heading, ItemContainer } from '../../../../components/table/styles';
import { getComparator, stableSort } from '../../../../components/table/utils';
import BasicMenu from '../../components/BasicMenu';
import { headCells } from './data';
import FullDialog from '../components/FullDialog';
import { ProjectDetails } from '../projectDetails';

export default function ProjectTable({ rows }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAction = (row) => {
    setOpen(true);
    setDetails(row);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filtered = stableSort(rows, getComparator(order, orderBy));

  const NotFound = filtered.length === 0;

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer sx={{ minWidth: 650, overflow: 'auto', maxHeight: 420 }}>
        <Table>
          <TableHeading
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows && rows.length}
            headCells={headCells}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {filtered
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const {
                  field_project_name,
                  field_statutory_compliance,
                  field_site_location,
                  field_admin_contact_person_name,
                  field_admin_email_address,
                  field_admin_contact_number,
                  field_admin_alternate_contact_nu
                } = row;
                const Srno = index + 1;

                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell>{Srno}</TableCell>
                    <TableCell>{field_project_name}</TableCell>
                    <TableCell>{field_statutory_compliance}</TableCell>
                    <TableCell>{field_site_location}</TableCell>
                    <TableCell>{field_admin_contact_person_name}</TableCell>
                    <TableCell>{field_admin_email_address || 'N/A'}</TableCell>
                    <TableCell>{field_admin_contact_number}</TableCell>

                    <TableCell>
                      <Button size="small" onClick={() => handleAction(row)}>
                        View more
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {NotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                  Not found
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <FullDialog open={open} setOpen={setOpen} headName=" Company projects">
        <ProjectDetails data={details} />
      </FullDialog>
    </Box>
  );
}
