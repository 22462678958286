import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { newProductSchema, productSchema, reqSchema } from './utils/validationSchema';
import FullDialog from './_components/FullDialog';
import PreviewContainer from './preview/PreviewContainer';
import FormSwitcher from './FormSwitcher';
import {
  postProducts,
  createReq,
  submitTempProduct,
  getBuyerCompanies
} from '../../../apis/BuyerApi';
import { token } from '../../../utils/helper';
import { categoryValues, newProductValues, productValues } from './utils/InitialValues';
import SmallSnackbar from '../../../components/SnackBar';
import Page from '../../../components/Page';

export const formContext = React.createContext();

const FormContainer = () => {
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [pro, setPro] = useState([]);
  const [snack, setSnack] = React.useState({
    open: false,
    color: '',
    message: ''
  });
  const [id, setId] = React.useState({
    reqId: '',
    nid: ''
  });
  const navigate = useNavigate();

  const handleDelete = (index) => {
    const data = pro;
    const d = data.splice(index, 1);
    console.log(d);
    console.log(pro);
  };

  const mainFormik = useFormik({
    initialValues: categoryValues,
    validationSchema: reqSchema,
    onSubmit: async () => {
      console.log(mainFormik.values);
      setActiveStep(1);
    }
  });

  const mainSubmit = async () => {
    console.log(mainFormik.values);
    const res = await createReq(token, mainFormik.values);

    if (res.status) {
      setId({
        reqId: res.result,
        nid: res.nid
      });
      await submitProduct(res.result, res.nid);
    }
    return res.status;
  };

  const productFormik = useFormik({
    initialValues: productValues,
    validationSchema: productSchema,
    onSubmit: async () => {
      // console.log(flag);
      if (id.reqId === '') {
        await mainSubmit();
      } else {
        await submitProduct(id.reqId, id.nid);
      }
    }
  });

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const formatProduct = () => {
    const pay = Object.entries(productFormik.values.paymentMode);
    const res = pay.map((x) => x[1] === true && x[0]).filter((x) => x !== false);
    const val = productFormik.values;
    let total = 0;
    val.deliveryChart.map((x) => (total += parseInt(x.qty, 10)));
    const converted = {
      ...val,
      paymentMode: res.toString(),
      confPo:
        productFormik.values.payTerms === 'advance payments' ? productFormik.values.confPo : '',
      onCredit: productFormik.values.payTerms === 'credit' ? productFormik.values.onCredit : '',
      totalQty: !val.immediate ? total.toString() : val.totalQty,
      unitOfMeasure: 1
    };
    return converted;
  };

  const setDefaultValues = () => {
    productFormik.setFieldValue('paymentMode', mainFormik.values.paymentMode);
    productFormik.setFieldValue('payTerms', mainFormik.values.payTerms);
    productFormik.setFieldValue('onCredit', mainFormik.values.onCredit);
    productFormik.setFieldValue('confPo', mainFormik.values.confPo);
  };

  const submitProduct = async (reqId, nid) => {
    const items = formatProduct();
    setPro([...pro, items]);
    console.log(items);
    await postProducts(token, items, reqId, nid)
      .then((res) => {
        console.log(res);
        if (res.status) {
          if (flag === 1) {
            localStorage.removeItem('reqId');
            localStorage.removeItem('nid');
            localStorage.removeItem('companyName');
            setSnack({
              open: true,
              color: 'success',
              message: `Requirement generated successfully`
            });
            sleep(1000).then(() => {
              navigate('/requirementList');
            });

            // window.location.href = '/requirementList';
          } else {
            setSnack({
              open: true,
              color: 'success',
              message: `${productFormik.values.productName} added successfully`
            });
            // productFormik.setSubmitting(false);
            productFormik.resetForm();
            setActiveStep(1);
            setDefaultValues();
          }
        }
        return res.status;
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const newProFormik = useFormik({
    initialValues: newProductValues,
    validationSchema: newProductSchema,
    onSubmit: async () => {
      const res = await submitTempProduct(token, newProFormik.values);
      if (res.status) {
        newProFormik.setSubmitting(false);
        productFormik.setFieldValue('productName', newProFormik.values.name);
        productFormik.setFieldValue('tempProduct', res.result.target_id);
        productFormik.setFieldValue('masterProduct', '');
        productFormik.setFieldValue('unitOfMeasure', newProFormik.values.unitOfMeasure);
        setSnack({
          open: true,
          color: 'success',
          message: `${newProFormik.values.name} requested successfully`
        });
        newProFormik.resetForm();
      }
      //  console.log(newProFormik.values);
    }
  });

  return (
    <formContext.Provider value={{ mainFormik, productFormik, newProFormik, id }}>
      <Page title="New requirement">
        <Stack>
          <FormSwitcher
            mainFormik={mainFormik}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setOpen={setOpen}
            data={pro}
            catLoading={mainFormik.isSubmitting}
            setSnack={setSnack}
            setFlag={setFlag}
            id={id}
          />
        </Stack>
        <FullDialog open={open} setOpen={setOpen} lot="Preview" full>
          <PreviewContainer formik={mainFormik} rows={pro} handleDelete={handleDelete} />
        </FullDialog>
        <SmallSnackbar snack={snack} setSnack={setSnack} />
      </Page>
    </formContext.Provider>
  );
};

export default FormContainer;
