import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LinesEllipsis from 'react-lines-ellipsis';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PhoneIcon from '@mui/icons-material/Phone';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Label from '../../../components/Label';
import FullDialog from './components/FullDialog';
import BankDet from './BankDet';
// import { ReactComponent as GST } from '/static/icons/gst.svg';
const ProfileInfo = ({ data }) => {
  const [open, setOpen] = useState();
  const navigate = useNavigate();
  const a = 1;
  const handleProductAction = () => {
    navigate('/profile/products', { state: { products: data.vendor_product } });
  };
  // console.log(data.vendor_bank[0]);
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item sm={12} xs={12}>
          <h2 style={{ padding: '.5rem', color: 'grey' }}>Profile</h2> <Divider />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Label
            variant="ghost"
            color="warning"
            style={{ fontSize: 20, padding: 15, margin: '10px 0' }}
          >
            {data.vendor_id}
          </Label>
          {/*  <Typography variant="caption" color="gray">
            Vendor id
          </Typography> */}
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <PersonRoundedIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Typography sx={{ fontsize: 14, fontWeight: 600 }}>{data.vendor_name}</Typography>
                <Typography variant="caption" color="gray">
                  Full name
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2, wordWrap: 'break-word' }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <EmailIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Tooltip title={data.vendor_email || 'N/A'} placement="bottom">
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      width: '90%',
                      overflow: ' hidden ',
                      whiteSpace: 'nowrap ',
                      fontsize: 14,
                      fontWeight: 600
                    }}
                  >
                    {data.vendor_email || 'N/A'}
                  </div>
                </Tooltip>

                <Typography variant="caption" color="gray">
                  Email id
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <PhoneAndroidIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Typography sx={{ fontsize: 14, fontWeight: 600 }}>
                  +91 {data.vendor_mobile_number}
                </Typography>
                <Typography variant="caption" color="gray">
                  Mobile number
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        {/*  <Grid item sm={6} xs={12} md={4}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <EqualizerIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Typography sx={{ fontsize: 14, fontWeight: 600 }}>
                  {data.vendor_turnover_last_3_years}
                </Typography>
                <Typography variant="caption" color="gray">
                  Turnover (last 3 years)
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid> */}

        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <LocationOnIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Tooltip title={data.vendor_address || 'N/A'} placement="bottom">
                  <Typography component="div" sx={{ fontsize: 14, fontWeight: 600 }}>
                    <LinesEllipsis
                      text={data.vendor_address || 'N/A'}
                      maxLine="1"
                      ellipsis="..."
                      trimRight
                      basedOn="words"
                    />
                    {/*  {data.vendor_address} */}
                  </Typography>
                </Tooltip>
                <Typography variant="caption" color="gray">
                  Address
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <LocationOnIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Typography sx={{ fontsize: 14, fontWeight: 600 }}>
                  {data.field_services || 'N/A'}
                </Typography>
                <Typography variant="caption" color="gray">
                  Type of service
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        {/* <Grid item sm={6} xs={12} md={6}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <LocationOnIcon color="primary" sx={{ height: 40, width: 40 }} />
              <Stack>
                <Typography sx={{ fontsize: 14, fontWeight: 600 }}>
                  {data.vendor_gst_number}
                </Typography>
                <Typography variant="caption" color="gray">
                  Category
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid> */}
        <Grid item sm={12} xs={12} align="right">
          <Stack spacing={2} direction="row" justifyContent="right">
            {/*  <Button
              variant="outlined"
              onClick={() => setOpen(true)}
              disabled={data.vendor_bank.length === 0}
            >
              Bank details
            </Button> */}
            {data && data.vendor_product.length > 0 && (
              <Button variant="outlined" onClick={handleProductAction}>
                View products
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
      {/* <FullDialog open={open} setOpen={setOpen} headName="Bank details">
        <BankDet target={data.vendor_bank[0]} />
      </FullDialog> */}
    </div>
  );
};

export default ProfileInfo;
