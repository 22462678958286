import { Box, Card, Checkbox, Paper, Stack } from '@mui/material';
import React from 'react';
import { AlternateEmail, Category, LocalPhone, LocationOn } from '@material-ui/icons';
import Label from '../../../../components/Label';

const MobileInviteCard = ({ row, index, isItemSelected, handleClick }) => {
  const cat = row.field_category.map((x) => x.name).join(', ');
  return (
    <Paper
      sx={{ borderBottom: '1px solid lightgrey', p: 1, backgroundColor: isItemSelected }}
      onClick={(event) => handleClick(event, row.vid)}
      square
    >
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Box>{index + 1}</Box>
          <Box>{row.title}</Box>
        </Stack>

        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Label variant="ghost" color="default" style={{ fontSize: 12, padding: 12 }}>
            {row.field_vendor_id}
          </Label>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            // inputProps={{
            //   'aria-labelledby': labelId
            // }}
          />
        </Stack>
      </Stack>
      <Stack sx={{ ml: 1.8 }}>
        {row.field_email_id && (
          <Box sx={{ fontSize: 12, color: 'gray' }}>
            {' '}
            <AlternateEmail style={{ fontSize: 14, marginRight: 10 }} /> {row.field_email_id}
          </Box>
        )}

        <Box sx={{ fontSize: 12, color: 'gray' }}>
          {' '}
          <LocalPhone style={{ fontSize: 14, marginRight: 10 }} />
          {row.field_mobile_number_buyer}
        </Box>
        {row.field_company_address && (
          <Box sx={{ fontSize: 12, color: 'gray' }}>
            {' '}
            <LocationOn style={{ fontSize: 14, marginRight: 10 }} />
            {row.field_company_address}
          </Box>
        )}
        {cat && (
          <Box sx={{ fontSize: 12, color: 'grey' }}>
            {' '}
            <Category style={{ fontSize: 14, marginRight: 10 }} />
            {cat}
          </Box>
        )}
      </Stack>
    </Paper>
  );
};

export default MobileInviteCard;
