/* eslint-disable camelcase */
import * as React from 'react';
import { Button, Divider, Grid, Paper, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHeading from '../../../components/table/TableHeading';
import { Heading, ItemContainer } from '../../../components/table/styles';
import { getComparator, stableSort } from '../../../components/table/utils';
import { headCells } from './data';
import BasicMenu from '../components/BasicMenu';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20vh'
}));

export default function OfficeTable({ data }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Srno');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const rows = data.office_details;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filtered = stableSort(rows, getComparator(order, orderBy));

  const NotFound = filtered.length === 0;

  return (
    <Grid container spacing={5}>
      <Grid item sm={12} xs={12}>
        <h2 style={{ padding: '.5rem', color: 'grey' }}>Office details</h2>
        <Divider />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Paper elevation={3} sx={{ width: '100%', overflow: 'auto' }}>
          <TableContainer sx={{ minWidth: '100%' }}>
            <Table>
              <TableHeading
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows && rows.length}
                headCells={headCells}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {filtered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const {
                      field_contact_person_name,
                      field_designation,
                      field_email_id,
                      field_office_head_office_address,
                      field_text_number
                    } = row;

                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{field_contact_person_name}</TableCell>
                        <TableCell>{field_designation}</TableCell>
                        <TableCell>{field_office_head_office_address}</TableCell>
                        <TableCell>{field_text_number}</TableCell>
                        <TableCell>{field_email_id}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {NotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      Not found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
