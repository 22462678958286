import { getRole } from './utils/helper';

const role = getRole();

const vendor = [
  {
    path: '/',
    breadcrumb: 'Dashboard'
  },
  {
    path: '/myauction',
    breadcrumb: 'My auction',
    children: [
      {
        path: 'accept',
        breadcrumb: 'Pending acceptance'
      },
      {
        path: 'upcoming',
        breadcrumb: 'Upcoming Rfq'
      },
      {
        path: 'participated',
        breadcrumb: 'Participated Rfq'
      },
      {
        path: 'update',
        breadcrumb: 'Update'
      },
      {
        path: 'active',
        breadcrumb: 'Active',
        children: [
          {
            path: 'itemBid',
            breadcrumb: 'Bid'
          },
          {
            path: ':id',
            breadcrumb: ''
          }
        ]
      }
    ]
  },
  {
    path: '/history',
    breadcrumb: 'History'
  },
  {
    path: '/profile',
    breadcrumb: 'Profile',
    children: [
      {
        path: 'products',
        breadcrumb: 'Products'
      }
    ]
  },
  {
    path: '/reports',
    breadcrumb: 'Reports',
    children: [
      {
        path: 'products',
        breadcrumb: 'Products',
        children: [
          {
            path: 'bidhistory',
            breadcrumb: 'Bid history'
          }
        ]
      }
    ]
  },
  {
    path: '/notifications',
    breadcrumb: 'Notifications'
  },
  {
    path: '/contactus',
    breadcrumb: 'Contact us'
  }
];

const buyer = [
  {
    path: '/',
    breadcrumb: 'Dashboard',
    children: [
      {
        path: 'requirementList',
        breadcrumb: 'Requirement list',
        children: [
          {
            path: 'details',
            breadcrumb: 'Product details'
          },
          {
            path: 'new',
            breadcrumb: 'New requirement'
          }
        ]
      },
      {
        path: 'rfq',
        breadcrumb: 'RFQ',
        children: [
          {
            path: 'products',
            breadcrumb: 'Products',
            children: [
              { path: 'add', breadcrumb: 'Add' },
              { path: 'edit', breadcrumb: 'Edit' }
            ]
          },
          {
            path: 'new',
            breadcrumb: 'New requirement'
          },
          {
            path: 'view-quotation',
            breadcrumb: 'View Quotation'
          },
          {
            path: 'add-to-rfx',
            breadcrumb: 'Add To RFX'
          }
        ]
      },
      {
        path: 'rfx',
        breadcrumb: 'RFX',
        children: [
          {
            path: 'products',
            breadcrumb: 'Products',
            children: [
              { path: 'add', breadcrumb: 'Add' },
              { path: 'edit', breadcrumb: 'Edit' }
            ]
          },
          {
            path: 'new',
            breadcrumb: 'New requirement'
          },
          {
            path: 'view-bids',
            breadcrumb: 'View Bids'
          }
        ]
      },
      {
        path: 'profile',
        breadcrumb: 'Profile',
        children: [
          {
            path: 'projects',
            breadcrumb: 'Projects'
          }
        ]
      },
      {
        path: 'reports',
        breadcrumb: 'Reports',
        children: [
          {
            path: 'products',
            breadcrumb: 'Products'
          }
        ]
      },
      {
        path: '/notifications',
        breadcrumb: 'Notifications'
      },
      {
        path: '/contactus',
        breadcrumb: 'Contact us'
      }
    ]
  }
];

function Config() {
  switch (role) {
    case 'buyer':
      return buyer;
    case 'vendor':
      return vendor;
    default:
      return role;
  }
}

export const r = Config();
