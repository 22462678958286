import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { compareAsc } from 'date-fns';
import { Card, Typography, Box, Tabs, Tab } from '@mui/material';
import Page from '../../../components/Page';
import PendingAuction from './pending/PendingAuction';
import UpcomingAuction from './upcoming/UpcomingAuction';
import { ParticipatedAuction } from './participated';
import { ActiveAuction } from './active';

export default function MyAuction() {
  /*  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10); */
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const flag = searchParams.get('flag') || 0;
  const [value, setValue] = React.useState(parseInt(flag, 10));
  localStorage.removeItem('invId');
  localStorage.removeItem('rid');
  localStorage.removeItem('itemName');
  localStorage.removeItem('lotName');
  localStorage.removeItem('previousBid');
  const company = JSON.parse(localStorage.getItem('company'));

  const checkCompanyDates = () => {
    const todaysDate = new Date();
    if (company) {
      const newData = [];
      company.forEach((x) => {
        const closeTime = new Date(x.closeTime * 1000);
        const res = compareAsc(todaysDate, closeTime);
        // console.log(closeTime);
        if (res <= 0) {
          newData.push(x);
        }
        // console.log(res, 'time check result');
      });
      localStorage.setItem('company', JSON.stringify(newData));
    }
  };

  useEffect(() => {
    checkCompanyDates();
  }, [company]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page>
      <Card>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{ position: 'static' }}
        >
          <Tab label="Pending" {...a11yProps(0)} />
          <Tab label="Upcoming" {...a11yProps(1)} />
          <Tab label="Active" {...a11yProps(2)} />
          <Tab label="Participated" {...a11yProps(3)} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <PendingAuction />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <UpcomingAuction />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ActiveAuction />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <ParticipatedAuction />
          </TabPanel>
        </SwipeableViews>
      </Card>
    </Page>
  );
}
