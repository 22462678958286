/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Breadcrumbs,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { BiRupee } from 'react-icons/bi';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
// components
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { UserListHead } from '../../../../components/_dashboard/user';
import BasicMenu from '../BasicMenu';
import TableMenu from '../TableMenu';
import ActionMenu from './ActionMenu';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ProductsTable({ rows, setOpen, setItem, active }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const menu = [
    {
      text: 'View details',
      goTo: '/reports/products',
      icon: 'file-csv-solid'
    },
    {
      text: 'View bid history',
      goTo: '/reports/products/bidhistory',
      icon: 'file-csv-solid'
    }
  ];
  const TABLE_HEAD = [
    { id: 'srNo', label: 'SrNo.', align: 'left' },
    { id: 'field_product_id', label: 'Product id', align: 'left' },
    { id: 'name', label: 'Product name', align: 'left' },
    { id: 'category_items', label: 'Category', align: 'left' },
    { id: 'field_start_price', label: 'Start price', align: 'left' },
    { id: 'action', label: 'Actions', align: 'right' }
  ];
  // console.log(rows, 'from product table');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table size="small">
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const {
                    product_id,
                    field_product_name,
                    category_items,
                    field_unit_cost,
                    p_nid,
                    field_start_price
                  } = row;
                  const isItemSelected = selected.indexOf(field_product_name) !== -1;
                  const srNo = index + 1;
                  const category = Object.values(category_items);
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell>{srNo}</TableCell>
                      <TableCell>{product_id}</TableCell>
                      <TableCell>{field_product_name}</TableCell>
                      <TableCell>
                        <Breadcrumbs separator=">>" maxItems={1} sx={{ fontSize: 13 }}>
                          {category.map((y, i) => (
                            <span key={i}>{y}</span>
                          ))}
                        </Breadcrumbs>
                      </TableCell>
                      <TableCell>
                        <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                        {field_start_price}
                      </TableCell>

                      <TableCell align="right">
                        <ActionMenu setItem={setItem} setOpen={setOpen} row={row} />
                      </TableCell>

                      {/* <TableCell align="right">
                        <Button size="small" onClick={() => handleViewProduct(row)}>
                          <VisibilityOutlinedIcon /> &nbsp; View
                        </Button>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    Not found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}
