/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio
} from '@mui/material';

const BidChange = ({ row, disabled, handleAllBidChange }) => {
  const [data, setData] = useState({
    pid: row.nid,
    bid_change: 'na',
    bid_value: ''
  });

  const handleChange = (key, value) => {
    const newVal = {
      ...data,
      [key]: value
    };
    setData(newVal);
    handleAllBidChange(newVal);
  };

  return (
    <div>
      <FormControl size="small" disabled={disabled}>
        {/* <FormLabel>Minimum Bid Change</FormLabel> */}
        <RadioGroup
          defaultValue="na"
          value={data.bid_change}
          onChange={(e) => handleChange('bid_change', e.target.value)}
          row
        >
          <FormControlLabel
            value="na"
            control={<Radio size="small" />}
            label={<Typography sx={{ fontSize: '0.7rem' }}>N/A</Typography>}
          />
          <FormControlLabel
            value="Amount"
            control={<Radio size="small" />}
            label={<Typography sx={{ fontSize: '0.7rem' }}>Amt</Typography>}
          />
          <FormControlLabel
            value="Percentage"
            control={<Radio size="small" />}
            label={<Typography sx={{ fontSize: '0.7rem' }}>Perc</Typography>}
          />
        </RadioGroup>
      </FormControl>

      <TextField
        size="small"
        placeholder="Enter Change Value"
        type="number"
        sx={{ width: 180 }}
        value={data.bid_value}
        onChange={(e) => handleChange('bid_value', e.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

export default BidChange;
