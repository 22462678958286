import { Box, Stack, TableCell, TableHead, TableRow, Tooltip, useTheme } from '@mui/material';
import React from 'react';
// import { doseData, freqData, typeData, unitData, whenData } from './listData';

// import HeadPopper from './HeadPopper';
import { makeStyles } from '@mui/styles';
import { WarningTwoTone } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  tableHeadCell: {
    // borderRight: "1px solid lightgrey",
    cursor: 'pointer',
    padding: '8px 0',
    textAlign: 'left',
    fontWeight: '500'
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  }
}));

const EditableHead = ({ isMdDown, checkPermission, addRowFormik, type }) => {
  const classes = useStyles();
  const theme = useTheme();

  const ErrorWarning = ({ error }) => {
    if (error) {
      return (
        <span style={{ marginLeft: 5 }}>
          <Tooltip title={error}>
            <WarningTwoTone sx={{ color: theme.palette.error.main, fontSize: 16, mb: -0.4 }} />
          </Tooltip>
        </span>
      );
    }
    return null;
  };

  return (
    <tr
      style={{
        fontSize: 12,
        backgroundColor: 'rgba(185, 128, 54, 0.08)',
        color: theme.palette.primary.main,
        fontWeight: '700'
      }}
    >
      <th />
      <th className={classes.tableHeadCell} style={{ textAlign: 'left', paddingLeft: 10 }}>
        Product Name
      </th>
      {checkPermission('uom') && (
        <th className={classes.tableHeadCell}>
          UOM <ErrorWarning error={addRowFormik.errors.uom || ''} />
          {/* <HeadPopper
            isMdDown={isMdDown}
            dataList={typeData}
            placeholder="Select batch"
            name="uom"
            width={'auto'}
          >
            Batch <ErrorWarning error={addRowFormik.errors.batch || ''} />
          </HeadPopper> */}
        </th>
      )}
      {checkPermission('desiredQty') && (
        <th className={classes.tableHeadCell}>
          Desired Qty <ErrorWarning error={addRowFormik.errors.desiredQty || ''} />
          {/* <HeadPopper
            isMdDown={isMdDown}
            dataList={unitData}
            placeholder="Select expiry"
            name="expiry"
            width={'auto'}
          >
            Expiry <ErrorWarning error={addRowFormik.errors.expiry || ''} />
          </HeadPopper> */}
        </th>
      )}

      {/* {checkPermission("mrp") && (
        <th className={classes.tableHeadCell}>
          <HeadPopper
            isMdDown={isMdDown}
            dataList={doseData}
            placeholder="Select dose"
            name="dose"
            width={"auto"}
          >
            MRP
          </HeadPopper>
        </th>
      )} */}
      {type === 'rfx' && (
        <>
          {checkPermission('startPrice') && (
            <th className={classes.tableHeadCell}>
              <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
                Start Price <ErrorWarning error={addRowFormik.errors.startPrice || ''} />
              </Stack>
            </th>
          )}
        </>
      )}

      {/* {checkPermission("ptr") && (
        <th className={classes.tableHeadCell}>
          <HeadPopper
            isMdDown={isMdDown}
            dataList={whenData}
            placeholder="Select When"
            name="when"
            width={"auto"}
          >
            PTR
          </HeadPopper>
        </th>
      )} */}
      {checkPermission('payTerms') && (
        <th className={classes.tableHeadCell}>
          {' '}
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
            Payment Terms <ErrorWarning error={addRowFormik.errors.payTerms || ''} />
          </Stack>
        </th>
      )}

      {/* {checkPermission('confPo') && (
        <th className={classes.tableHeadCell}>
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
            Confirmation of PO <ErrorWarning error={addRowFormik.errors.confPo || ''} />
          </Stack>
        </th>
      )} */}

      {checkPermission('credit') && (
        <th className={classes.tableHeadCell}>
          Credit
          {/* <HeadPopper
            isMdDown={isMdDown}
            dataList={[{ title: '' }]}
            freeSolo
            placeholder="Select free"
            name="free"
            width={'auto'}
          >
            Free
          </HeadPopper> */}
        </th>
      )}
      {checkPermission('desc') && <th className={classes.tableHeadCell}>Desc</th>}

      {checkPermission('attachment') && (
        <th className={classes.tableHeadCell}>
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
            Attachment <ErrorWarning error={addRowFormik.errors.attachment || ''} />
          </Stack>
          {/* <HeadPopper
            isMdDown={isMdDown}
            dataList={[{ title: '' }]}
            freeSolo
            placeholder="Select schAmt"
            name="schAmt"
            width={'auto'}
          >
            Sch. Amt
          </HeadPopper> */}
        </th>
      )}

      {/* {checkPermission('disc') && (
        <th className={classes.tableHeadCell}>
          <HeadPopper
            isMdDown={isMdDown}
            dataList={[{ title: '' }]}
            freeSolo
            placeholder="Select disc"
            name="disc"
            width={'auto'}
          >
            Disc%
          </HeadPopper>
        </th>
      )} */}

      {/* {checkPermission("base") && (
        <th className={classes.tableHeadCell}>
          <HeadPopper
            isMdDown={isMdDown}
            dataList={[{ title: "" }]}
            freeSolo
            placeholder="Select Duration"
            name="duration"
            width={"auto"}
          >
            Base
          </HeadPopper>
        </th>
      )} */}
      {/* {checkPermission('base') && <th className={classes.tableHeadCell}>Base</th>} */}

      {/* {checkPermission("gst") && (
        <th className={classes.tableHeadCell}>
          <HeadPopper
            isMdDown={isMdDown}
            dataList={[{ title: "" }]}
            freeSolo
            placeholder="Select Duration"
            name="duration"
            width={"auto"}
          >
            GST
          </HeadPopper>
        </th>
      )} */}

      {/* {checkPermission('gst') && <th className={classes.tableHeadCell}>GST%</th>} */}

      {/* {checkPermission("qty") && <th className={classes.tableHeadCell}>Qty</th>}

      {checkPermission("instruction") && (
        <th className={classes.tableHeadCell}>Instruction</th>
      )} */}
      {/* <th className={classes.tableHeadCell} style={{ textAlign: 'right', paddingRight: 10 }}>
        Total
      </th> */}

      <th />
    </tr>
  );
};

export default EditableHead;
