import { DatePicker, LocalizationProvider } from '@mui/lab';
import frLocale from 'date-fns/locale/fr';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from 'react';

/* const localeMap = {
    en: enLocale,
    fr: frLocale,
    ru: ruLocale,
    de: deLocale,
  };
  
  const maskMap = {
    fr: '__/__/____',
    en: '__/__/____',
    ru: '__.__.____',
    de: '__.__.____',
  };
 */
const DateInput = (props) => {
  const [value, setValue] = React.useState(new Date());
  const [isData, setIsData] = React.useState(false);

  useEffect(() => {
    if (value !== null) {
      const day = value.getDate().toString();
      const month = (1 + value.getMonth()).toString();
      const year = value.getFullYear().toString();

      const filter = day.concat('/', month, '/', year);
      props.setFieldValue('date', filter);
      setIsData(true);
    } else {
      props.setFieldValue('date', '');
    }
  }, [value]);

  const onClear = () => {
    setValue(null);
    setIsData(false);
    props.setFieldValue('date', '');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          required
          clearable
          cancelText="cancel"
          minDate={new Date()}
          clearText="clear"
          views={['year', 'month', 'day']}
          inputFormat="dd/MM/yyyy"
          label="Enter date"
          value={value}
          error={props.error}
          helperText={props.helper}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={params && props.helper}
              error={props.error}
              value={value}
            />
          )}
        />
        {/* {isData && (
          <IconButton size="small" sx={{ fontSize: 11, marginLeft: 0.5 }} onClick={onClear}>
            <ClearIcon />
          </IconButton>
        )} */}
      </LocalizationProvider>
    </div>
  );
};

export default DateInput;
