import { Grid, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import * as Yup from 'yup';
import SelectInput from '../../../components/SelectInput';
import { formContext } from '../../FormContainer';
import ProductInput from '../../_components/ProductInput';
import { getUnitOfMeasure, getProductList, productWiseMeasure } from '../../../../../apis/BuyerApi';
import { token } from '../../../../../utils/helper';
import { creditOp, aPo, terms } from '../data';
import Timeline from './Timeline';

const FormLeft = ({ setOpen, timelineFormik, chartError }) => {
  const [units, setUnits] = useState([]);
  const [products, setProducts] = useState([]);
  const [desc, setDesc] = useState('');
  const [selectedProduct, setSelectedProduct] = useState();
  const form = useContext(formContext);
  const productState = form.productFormik;
  const mainState = form.mainFormik;
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    productState;
  const getUnits = async () => {
    /* await getUnitOfMeasure(token).then((r) => setUnits(r.result)); */
    await productWiseMeasure(token, selectedProduct.pid).then((r) => {
      console.log(r);
      setUnits(r.result);
    });
  };
  useEffect(async () => {
    if (values.productName !== '') getUnits();
  }, [selectedProduct]);

  const getDet = async () => {
    await getProductList(token).then((r) => {
      console.log(r.result, 'products list');
      setProducts(r.result);
    });
  };

  useEffect(() => {
    getDet();
  }, []);

  const getDesc = () => {
    const a = products.filter((x) => x.name === values.productName);
    if (a.length > 0) {
      setDesc(a[0].description);
      console.log(a);
    } else {
      setDesc('');
    }
  };

  useEffect(() => {
    getDesc();
  }, [values.productName]);

  return (
    <Grid item xs={12} md={6} sm={12}>
      <Stack direction="column" spacing={3}>
        <div>
          <ProductInput
            products={products}
            productFormik={form.productFormik}
            error={Boolean(touched.productName && errors.productName)}
            helper={touched.productName && errors.productName}
            setOpen={setOpen}
            setSelectedProduct={setSelectedProduct}
          />
          {desc && (
            <Tooltip title={desc || 'N/A'} placement="bottom">
              <div style={{ fontsize: '10px', color: 'gray' }}>
                <LinesEllipsis
                  text={desc || 'N/A'}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="words"
                />
              </div>
            </Tooltip>
          )}
        </div>

        <TextField
          label="Site location"
          id="outlined-multiline-static"
          multiline
          rows={2}
          error={Boolean(touched.location && errors.location)}
          helperText={touched.location && errors.location}
          inputProps={{ maxLength: 255 }}
          {...getFieldProps('location')}
          fullWidth
        />
        {/* <SelectInput
          cat={values.unitOfMeasure}
          name="Unit of measure"
          dta={units}
          getFieldProps={getFieldProps('unitOfMeasure')}
          error={Boolean(touched.unitOfMeasure && errors.unitOfMeasure)}
          helper={touched.unitOfMeasure && errors.unitOfMeasure}
        /> */}

        <div>
          <SelectInput
            cat={values.payTerms || mainState.values.payTerms}
            name="Payment terms"
            dta={terms}
            getFieldProps={getFieldProps('payTerms')}
            error={Boolean(touched.payTerms && errors.payTerms)}
            helper={touched.payTerms && errors.payTerms}
          />
        </div>

        {values.payTerms === 'advance payments' && (
          <div>
            <SelectInput
              cat={values.confPo}
              name="Advance on confirmation of po"
              getFieldProps={getFieldProps('confPo')}
              dta={aPo}
              error={Boolean(touched.confPo && errors.confPo)}
              helper={touched.confPo && errors.confPo}
            />
          </div>
        )}
        {values.payTerms === 'credit' && (
          <div>
            <SelectInput
              cat={values.onCredit}
              name="No. of days on credit"
              getFieldProps={getFieldProps('onCredit')}
              dta={creditOp}
              error={Boolean(touched.onCredit && errors.onCredit)}
              helper={touched.onCredit && errors.onCredit}
            />
          </div>
        )}
        <TextField
          label="Product description"
          id="outlined-multiline-static"
          multiline
          rows={2}
          error={Boolean(touched.desc && errors.desc)}
          helperText={touched.desc && errors.desc}
          inputProps={{ maxLength: 255 }}
          {...getFieldProps('desc')}
          fullWidth
        />
        <Timeline timelineFormik={timelineFormik} chartError={chartError} />
      </Stack>
    </Grid>
  );
};

export default FormLeft;
