import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210
    },
    drip: {
      height: '262px',
      color: 'grey',
      backgroundColor: '#FFF7CD',
      padding: 50
    }
  })
);

const DropFiles = () => {
  const classes = useStyles();
  return (
    <DropzoneArea
      showPreviews
      showAlerts={false}
      showPreviewsInDropzone={false}
      useChipsForPreview
      dropzoneText="Drag and drop files here"
      previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
      previewChipProps={{ classes: { root: classes.previewChip } }}
      previewText="Selected files"
      onChange={(files) => console.log('Files:', files)}
      filesLimit={3}
      dropzoneClass={classes.drip}
    />
  );
};

export default DropFiles;
