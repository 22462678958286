import { Avatar, Box, Stack } from '@mui/material';
import React from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/styles';

const ItemHeading = styled('h3')(({ theme }) => ({
  color: theme.palette.primary.main,
  wordWrap: 'break-word',
  textAlign: 'left'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 14,
  color: theme.palette.text.secondary
}));

const ContactInfo = () => {
  const a = 1;
  return (
    <Stack spacing={5} sx={{ mt: 5 }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar sx={{ backgroundColor: '#f2eadf' }}>
          <EmailOutlinedIcon color="primary" />
        </Avatar>
        <Box>
          <ItemHeading>Email id</ItemHeading>
          <ItemDesc>admnnirmaanprocura@gmail.com</ItemDesc>
        </Box>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar sx={{ backgroundColor: '#f2eadf' }}>
          <LocalPhoneOutlinedIcon color="primary" />
        </Avatar>
        <Box>
          <ItemHeading>Mobile number</ItemHeading>
          <ItemDesc>8780040827</ItemDesc>
        </Box>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar sx={{ backgroundColor: '#f2eadf' }}>
          <AccessTimeIcon color="primary" />
        </Avatar>
        <Box>
          <ItemHeading>Office timings</ItemHeading>
          <ItemDesc>Monday - Saturday 9:30am to 6:00pm</ItemDesc>
        </Box>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Avatar sx={{ backgroundColor: '#f2eadf' }}>
          <LocationOnOutlinedIcon color="primary" />
        </Avatar>
        <Box>
          <ItemHeading>Address</ItemHeading>
          <ItemDesc>Office No. 174, First Floor, A1 wing,</ItemDesc>
          <ItemDesc>Landmark Business Hub,</ItemDesc>
          <ItemDesc>Tokarkhada, Silvassa - 396230,</ItemDesc>
          <ItemDesc>U.T of Dadra & Nagar Haveli and Daman & diu</ItemDesc>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ContactInfo;
