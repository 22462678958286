import {
  Box,
  Divider,
  Grid,
  IconButton,
  Slide,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
  backgroundColor: 'RGB(241, 242, 246)'
}));

const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px'
}));

const Address = styled(Typography)(({ theme }) => ({
  fontSize: 12
}));

const Footer = () => {
  const matches = useMediaQuery('(min-width:900px)');
  return (
    <Container style={{ padding: matches ? '16px 60px' : '16px' }}>
      {/* <Container style={{ padding: '16px', flexDirection: 'column', gap: '10px' }}>
        <Slide direction="up" in appear mountOnEnter unmountOnExit>
          <img src="static/logonew.png" alt="logo" height="50px" width="auto" />
        </Slide>

        <Typography sx={{ letterSpacing: '.3rem', color: 'RGB(186, 128, 54)' }}>
          NIRMAAN CLUB
        </Typography>
        <Stack direction="row" spacing={1}>
          <IconButton>
            <FacebookIcon />
          </IconButton>
          <IconButton>
            <InstagramIcon />
          </IconButton>
          <IconButton>
            <TwitterIcon />
          </IconButton>
        </Stack>
        <Stack justifyContent="center" alignItems="center">
          <Address>Office No. 113, 114 first floor, Balaji platinum</Address>
          <Address>Survey No. 82/1 82/2 82/3 82/4,</Address>
          <Address>Village athal, Silvassa - 396230,</Address>
          <Address>DNH & DD</Address>
        </Stack>
        <Address sx={{ mt: 2 }}>All rights reserved. Copyright © 2022</Address>
      </Container> */}

      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Stack justifyContent="center" alignItems={matches ? 'flex-start' : 'center'}>
            <Typography>Our services</Typography>
            <Address>Requirements</Address>
            <Address>Reverse Auction</Address>
            <Address>Products</Address>
            <Address>Reports</Address>
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Item>
            <img src="static/nplogo.png" alt="logo" height="50px" width="auto" />
            <Typography sx={{ letterSpacing: '.3rem', color: 'RGB(186, 128, 54)' }}>
              NIRMAAN PROCURA
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconButton href="https://www.facebook.com/people/Nirmaan-Procura-LLP/100087883690151/" target="_blank">
                <FacebookIcon />
              </IconButton>
              {/* <IconButton>
                <InstagramIcon />
              </IconButton>
              <IconButton>
                <TwitterIcon />
              </IconButton> */}
            </Stack>
          </Item>
        </Grid>
        <Grid item md={4} xs={12}>
          <Stack justifyContent="flex-end" alignItems={matches ? 'flex-end' : 'center'}>
            <Typography>
              <LocationOnOutlinedIcon style={{ fontSize: 15 }} />
              Office address
            </Typography>
            <Address>Office No. 174, First Floor, A1 wing,</Address>
            <Address>Landmark Business Hub,</Address>
            <Address>Tokarkhada, Silvassa - 396230,</Address>
            <Address>U.T of Dadra & Nagar Haveli and Daman & diu</Address>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
