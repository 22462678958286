import { Button, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { checkAuth, checkOtp, getOtp } from '../apis/LoginApi';
import { getRole, token, userData } from '../utils/helper';
import SmallSnackbar from '../components/SnackBar';

const OtpInput = ({ otp, setAuthOpen }) => {
  const navigate = useNavigate();
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const LoginSchema = Yup.object().shape({
    otpInput: Yup.number()
      .typeError('you must specify a number')
      .required('otp is required')
      .test('len', 'Otp should be of 6 digits', (val) => val && val.toString().length === 6)
  });

  const formik = useFormik({
    initialValues: {
      otpInput: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      const r = await checkOtp(userData.mobile, values.otpInput);
      console.log(r.status);
      // eslint-disable-next-line camelcase
      const { jwt_token, status } = r;
      if (status) {
        Cookies.set('log_response', JSON.stringify(r.user));
        Cookies.set('jwt-token', jwt_token);
        window.location.href = '/home';
        // navigate('/dashboard');
        window.onbeforeunload = null;
        setAuthOpen(false);
        setSnack({
          open: true,
          color: 'success',
          message: 'Logged in successfully'
        });
      } else if (r.status.toString() === 'false') {
        setSnack({
          open: true,
          color: 'error',
          message: 'OTP is incorrect'
        });
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Stack spacing={1}>
      {/* {otp} */}
      <TextField
        type="text"
        label="Enter OTP"
        required
        {...getFieldProps('otpInput')}
        error={Boolean(touched.otpInput && errors.otpInput)}
        helperText={touched.otpInput && errors.otpInput}
        inputProps={{ maxLength: 6 }}
      />
      <LoadingButton
        fullWidth
        type="submit"
        onClick={handleSubmit}
        disabled={values.otpInput.length !== 6}
        loading={isSubmitting ? true : undefined}
      >
        Submit
      </LoadingButton>
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </Stack>
  );
};

export default OtpInput;
