export const headCells = [
  { id: 'Srno', label: 'SrNo.', align: 'left' },
  { id: 'productName', label: 'Product name', align: 'left' },
  { id: 'field_site_location', label: 'Site location', align: 'left' },
  { id: 'field_target_price_buyer', label: 'Target price', align: 'left' },
  { id: 'field_mode_of_payment', label: 'Payment mode', align: 'left' },
  { id: 'field_verification_type', label: 'Status', align: 'left' },
  { id: 'action', label: 'Actions', align: 'right' }
];

const a = {
  field_advance_on_confirmation_of: null,
  field_field_assured_minimum_quan: null,
  field_field_total_quantity: null,
  field_immediate: '0',
  field_mode_of_payment: 'cash, netBanking, cheque',
  field_month_wise_delivery_date: [],
  field_payment_term_buyer_product: 'payment on delivery',
  field_product_description: 'good product',
  field_product_master_: [],
  field_product_temp_: [],
  field_proposed_delivery_terms_bu: null,
  field_site_location: 'daman',
  field_target_price_buyer: '1200',
  field_unit_of_measure_req: [],
  nid: '184',
  number_of_months: '2'
};
