// material
import { Box, Card, CardContent, CardHeader, Skeleton, Stack } from '@mui/material';
// utils

// ----------------------------------------------------------------------

export default function RfxChartSkeleton() {
  return (
    <Card>
      {/*  <CardHeader title="Rfx Chart" /> */}
      <CardContent>
        <Stack spacing={4}>
          <Skeleton width="40%" />
          <Skeleton width="70%" />
          <Skeleton />
          <Skeleton width="50%" />
        </Stack>
      </CardContent>
    </Card>
  );
}
