import React from 'react';
import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const DeliveryCharges = () => (
  <TableRow>
    <TableCell sx={{ minWidth: 170 }}>
      <Typography variant="body2">Delievery charges</Typography>
    </TableCell>
    <TableCell align="right" variant="body2">
      {' '}
    </TableCell>
    <TableCell align="right"> </TableCell>
    <TableCell align="right" variant="body2">
      <Typography
        variant="subtitle2"
        color="primary.light"
        sx={{ fontSize: 13, paddingRight: 1, paddingTop: 1 }}
      >
        FREE
      </Typography>
    </TableCell>
  </TableRow>
);

export default DeliveryCharges;
