/* eslint-disable camelcase */
import * as React from 'react';
import { Tooltip } from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHeading from '../../../components/table/TableHeading';
import { Heading, ItemContainer } from '../../../components/table/styles';
import { getComparator, stableSort } from '../../../components/table/utils';
import { headCells, rows } from './data';
import BasicMenu from '../components/BasicMenu';
import SmallSnackbar from '../../../components/SnackBar';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60vh',
  color: theme.palette.primary.main
}));

export default function ReqTable({ rows, getList }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [snack, setSnack] = React.useState({
    open: false,
    color: '',
    message: ''
  });
  // console.log(rows);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filtered = stableSort(rows, getComparator(order, orderBy));

  const NotFound = filtered.length === 0;

  return (
    <Box sx={{ width: '100%' }}>
      {rows.length > 0 ? (
        <>
          <TableContainer sx={{ minWidth: 650 }}>
            <Table size="small">
              <TableHeading
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows && rows.length}
                headCells={headCells}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {filtered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const {
                      field_company,
                      field_process,
                      field_sector,
                      nid,
                      requirement_id,
                      field_verification_type
                    } = row;
                    const Srno = index + 1;
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell>{Srno}</TableCell>
                        <TableCell>{requirement_id}</TableCell>
                        <TableCell>{field_sector}</TableCell>
                        <TableCell>{field_company}</TableCell>
                        <TableCell>
                          <Tooltip title={field_process || 'N/A'} placement="bottom">
                            <Box
                              sx={{
                                fontSize: 14,
                                fontWeight: 400
                              }}
                            >
                              <LinesEllipsis
                                text={field_process || 'N/A'}
                                maxLine="2"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            </Box>
                          </Tooltip>
                          {/* {field_process} */}
                        </TableCell>
                        <TableCell>{field_verification_type}</TableCell>
                        <TableCell align="right">
                          <BasicMenu
                            menu={[
                              { text: 'View products', goTo: '/requirementList/details' },
                              {
                                text: 'Delete requirement',
                                goTo: '/requirementList',
                                remove: true
                              }
                            ]}
                            getList={getList}
                            nid={nid}
                            requirement_id={requirement_id}
                            setSnack={setSnack}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows
                    }}
                  >
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              {NotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      Not found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{' '}
          <SmallSnackbar snack={snack} setSnack={setSnack} />
        </>
      ) : (
        <Loader>no data found</Loader>
      )}
    </Box>
  );
}
