import { filter } from 'lodash';
import {
  Button,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  useTheme,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  useMediaQuery,
  Checkbox
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AccountBoxTwoTone, KeyboardArrowRightTwoTone } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

// file imports
import FullDialog from '../../../../components/_dashboard/app/FullDialog';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';

import { UserListHead } from '../../../../components/_dashboard/user';
import NotFound from '../../../../components/_dashboard/app/NotFound';
import MobileInviteCard from './MobileInviteCard';
import TaskBar from '../../../../components/_dashboard/app/TaskBar';
import { getVendorForInvitation, submitVendorInvitation } from '../../../../apis/BuyerApi';

/* eslint-disable camelcase */

// const rows = [
//   {
//     id: 1,
//     vendor_id: 1,
//     vendorName: 'Snow',
//     mobileNo: '1234567890',
//     email: 'mitul@gmail.com',
//     address: 'vapi, gujarat',
//     category: 'BIOMERTIC SYSTEM & ACC'
//   },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 }
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const InviteVendors = ({ rowData, type }) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const TABLE_HEAD = [
    { id: 'sr_no', label: 'Sr No', align: 'left' },
    { id: 'vendor_id', label: 'Vendor Id', align: 'center' },

    { id: 'vendorName', label: 'Vendor Name', align: 'center' },
    { id: 'mobileNo', label: 'Mobile Number', align: 'center' },
    {
      id: 'email',
      label: 'Email',
      align: 'center'
    },
    {
      id: 'address',
      label: 'Address',
      align: 'center'
    },
    {
      id: 'category',
      label: 'Category',
      align: 'center'
    }
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.vid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getList = async () => {
    await getVendorForInvitation({
      type,
      nid: rowData.nid
    }).then((res) => {
      if (res.status) {
        setRows(res.results);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  const handleSubmit = async () => {
    await submitVendorInvitation({
      nid: rowData.nid,
      vendor_id: selected
    }).then((res) => {
      if (res.status) {
        enqueueSnackbar('Vendors Invited Successfully!', {
          variant: 'success'
        });
        handleClose();
      } else {
        enqueueSnackbar(res.massage, {
          variant: 'error'
        });
      }
    });
  };

  const SendBtn = () => (
    <Button
      variant="outlined"
      sx={{
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white',
          color: theme.palette.primary.main
        }
      }}
      onClick={() => handleSubmit()}
    >
      Send Invitation <KeyboardArrowRightTwoTone />
    </Button>
  );

  const SearchBar = () => (
    <TextField
      size="small"
      placeholder="Search"
      sx={{ backgroundColor: 'white', borderRadius: 0.8 }}
      fullWidth={isMdDown}
    />
  );

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
          <AccountBoxTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
        </ListItemIcon>
        Invite Vendors
      </MenuItem>
      <FullDialog
        open={open}
        setOpen={setOpen}
        headName="Invite Vendors"
        // subHead="Field marked as * are required"
        fullScreen
        minHeight={500}
        maxWidth="xl"
        minWidth="xl"
        onClose={handleClose}
        rightAction={() => !isMdDown && <SendBtn />}
        leftAction={() => !isMdDown && <SearchBar />}
      >
        <TableContainer>
          <Scrollbar style={{ minWidth: isMdDown ? 'auto' : 850 }}>
            {isMdDown ? (
              <>
                <Stack flexDirection="row" m={1} alignItems="center" gap={1}>
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                  />
                  <SearchBar />
                  <Button variant="contained" onClick={() => handleSubmit()}>
                    Send <KeyboardArrowRightTwoTone />
                  </Button>
                </Stack>

                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = selected.indexOf(row.vid) !== -1;
                    return (
                      <MobileInviteCard
                        row={row}
                        index={index}
                        isItemSelected={isItemSelected}
                        handleClick={handleClick}
                      />
                    );
                  })}
                {filteredUsers.length === 0 && <NotFound />}
              </>
            ) : (
              <Table size="small">
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  selectable
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.vid) !== -1; // change title here for selction
                      const srNo = index + 1;
                      const cat = row.field_category.map((x) => x.name).join(', ');
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.vid)}
                          sx={{ height: 58 }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}

                              // inputProps={{
                              //   'aria-labelledby': labelId
                              // }}
                            />
                          </TableCell>
                          <TableCell align="center">{srNo}</TableCell>
                          <TableCell align="center">{row.field_vendor_id}</TableCell>
                          <TableCell align="left">{row.title}</TableCell>
                          <TableCell align="left"> {row.field_mobile_number_buyer}</TableCell>
                          <TableCell align="center">{row.field_email_id}</TableCell>
                          <TableCell align="center" sx={{ maxWidth: 400 }}>
                            {row.field_company_address}
                          </TableCell>
                          <TableCell align="center">{cat}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 58 * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <NotFound />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            )}
          </Scrollbar>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </FullDialog>
    </>
  );
};

export default InviteVendors;
