import { Divider, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import Label from '../../../components/Label';
import NavTab from '../home/NavTab';
import { vendorData } from './data';
import Step from './Step';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import TabSwitcher from './TabSwitcher';
import Footer from '../home/Footer';

const Guide = () => {
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <Scrollbar>
      <Page title="How it works">
        <div>
          <NavTab />
          <div
            style={{
              padding: matches ? '0px 60px' : '0px 16px',
              marginTop: '50px'
            }}
          >
            <TabSwitcher />
            {/* {vendorData.map((x, i) => (
              <>{(i + 1) % 2 === 0 ? <Step right data={x} /> : <Step data={x} />}</>
            ))} */}
          </div>
        </div>
        <Footer />
      </Page>
    </Scrollbar>
  );
};

export default Guide;
