import { IconButton, Menu, MenuItem, ListItemIcon, useTheme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AccountBoxTwoTone,
  AddBoxTwoTone,
  Inventory2TwoTone,
  RequestQuoteTwoTone,
  ScheduleTwoTone
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AddVendorCompany from './AddVendorCompany';

const VendorMenu = ({ row, getList }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(row, 'menurow');

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        // MenuListProps={{
        //   'aria-labelledby': 'long-button',
        // }}

        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        <AddVendorCompany getList={getList} vendorData={row} />

        {/* <MenuItem
          onClick={() => {
            handleClose();
            navigate('/rfq/add-to-rfx', {
              state: { rfDet: row }
            });
          }}
        >
          <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
            <AddBoxTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
          </ListItemIcon>
          Add To RFX
        </MenuItem> */}
      </Menu>
    </div>
  );
};

export default VendorMenu;
