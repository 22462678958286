import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Bar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 5,
  paddingLeft: 17
}));

const Heading = styled('h4')(({ theme }) => ({
  marginLeft: 5,
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center'
}));

export default function FullDialog(props) {
  const { open, setOpen, headName, full } = props;

  return (
    <>
      <Dialog
        fullScreen={full && true}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        sx={{ minHeight: 600 }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Bar>
            <Heading>{headName}</Heading>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Bar>
        </AppBar>
        {props.children}
      </Dialog>
    </>
  );
}
