const { styled } = require('@mui/styles');

export const ItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  fontSize: 12,
  height: 110
}));

export const Heading = styled('span')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 13
}));
