import { Card, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/styles';
import UserListToolbar from '../components/UserListToolbar';
import { columns } from './data';
import ReqTable from './ReqTable';
import { getBuyerRequirementList } from '../../../apis/BuyerApi';
import { token } from '../../../utils/helper';
import Page from '../../../components/Page';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
  color: theme.palette.primary.main
}));

const ReportContainer = () => {
  const [query, setQuery] = useState('');
  const [loading, isLoading] = useState(true);
  localStorage.removeItem('buyerReportPid');
  const search = (event) => {
    setQuery(event.target.value);
  };
  const [rows, setRows] = useState([]);

  const getList = async () => {
    await getBuyerRequirementList(token).then((r) => {
      setRows(r.result);
      isLoading(false);
    });
  };
  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.requirement_id.toLowerCase().includes(query.toLowerCase()) ||
        x.field_sector.toString().toLowerCase().includes(query.toLowerCase()) ||
        x.field_company.toString().toLowerCase().includes(query.toLowerCase()) ||
        x.field_process.toString().toLowerCase().includes(query.toLowerCase()) ||
        x.field_verification_type.toString().toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  const csvRows = () => {
    const data = rows;
    console.log(data, 'from repor');
    const finalRows = [];
    data.forEach((x, index) => {
      const srNo = index + 1;
      const temp = { ...x, srNo };
      finalRows.push(temp);
    });
    return finalRows;
  };

  const csvReport = {
    data: csvRows(),
    headers: columns.map((x) => ({ label: x.label, key: x.id })),
    filename: 'requirementReport.csv'
  };

  return (
    <Page title="Reports">
      {loading ? (
        <Loader>
          <CircularProgress />
        </Loader>
      ) : (
        <Card>
          <UserListToolbar
            numSelected={0}
            filterName={query}
            onFilterName={search}
            csvReport={csvReport}
            report
          />
          <ReqTable rows={!query ? rows : filtered()} />
        </Card>
      )}
    </Page>
  );
};

export default ReportContainer;
