/* eslint-disable prefer-template */
/* eslint-disable camelcase */
import { Stack, styled, Badge, Chip, Typography, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { BiRupee } from 'react-icons/bi';
import { getDatabase, ref, onValue, set, query, equalTo, child, get } from 'firebase/database';
import { useIdleTimer } from 'react-idle-timer';
import { format, formatDistanceToNow } from 'date-fns';
import Label from '../../../../../components/Label';
import { currentBid, lowestBid } from '../../../../../apis/VendorApi';
import { token, decodedJwt } from '../../../../../utils/helper';
import { fCurrency } from '../../../../../utils/formatNumber';
import Blinker from '../../_components/Blinker';
import LowestBid from './LowestBid';
import { db } from '../../../../../firebase';

const Heading = styled('span')(({ theme }) => ({
  fontSize: 13,
  color: 'grey'
}));

const Item = styled('span')(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginLeft: '5px'
  // margin: '1.5px 5px'
}));

const CurrentBid = ({ pNid }) => {
  const [item, setItem] = React.useState();
  const [bidRank, setBidRank] = React.useState();
  const [rankCheck, setRankCheck] = React.useState(true);

  const rid = localStorage.getItem('rid');

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const checkUpdate = (latest) => {
    const previousBid = localStorage.getItem('previousBid') || [];
    // fetching bids from firebase for the current product
    let rank;
    if (previousBid.length > 0) {
      const dataArr = JSON.parse(previousBid);
      // assigning ranks to the bid amount
      const rankPushArr = [];
      const usersArr = [];
      let finalCount = dataArr.length;
      dataArr.forEach((x) => {
        usersArr.push(x.uid);
        if (pNid === x.bid_pid && rid === x.rid) {
          finalCount -= 1;
          rankPushArr.push({ ...x, bid_rank: finalCount });
        }
      });

      // getting current user rank by bid amount
      const finalArr = [];
      rankPushArr.forEach((x) => {
        if (decodedJwt.uid === x.uid) {
          finalArr.push(x);
        }
      });

      // getting unique users on the current product
      const unique = (value, index, self) => self.indexOf(value) === index;
      const uniqueUser = usersArr.filter(unique);

      // getting user's latest and lowest bid on the product
      const newArr = [];
      uniqueUser.forEach((x) => {
        let filtered = [];
        rankPushArr.forEach((y) => {
          if (x === y.uid) {
            filtered.push(y);
          }
        });
        // pushing latest bid of the user
        if (filtered.length > 0) {
          filtered.sort((a, b) => a.bid_amt - b.bid_amt);
          // console.log(filtered, 'user filtered');
          newArr.push({ ...filtered[0] });
        }
        filtered = [];
      });

      // assigning user rank on current product as per latest bid
      let userCount = 1;
      newArr.sort((a, b) => a.bid_amt - b.bid_amt);
      const lastUserRank = [];
      newArr.forEach((x) => {
        lastUserRank.push({ ...x, user_rank: userCount });
        userCount += 1;
      });

      // console.log(lastUserRank, 'user filtered');

      // setting user's rank
      if (lastUserRank.length > 0) {
        lastUserRank.forEach((x) => {
          if (decodedJwt.uid === x.uid) {
            rank = x.user_rank;
          }
        });
      }

      if (rank !== latest) {
        // console.log('previous', rank, 'latest', latest, 'pid', pNid, 'rank');
        setRankCheck(false);
        fetchAllBids();
        sleep(5000).then(() => {
          setRankCheck(true);
        });
      }
    } else {
      setRankCheck(false);
      fetchAllBids();
      sleep(5000).then(() => {
        setRankCheck(true);
      });
    }
  };

  const fetchAllBids = () => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `bid`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const arr = [];
          snapshot.forEach((x) => {
            if (rid === x.val().rid) {
              arr.push(x.val());
            }
          });
          localStorage.setItem('previousBid', JSON.stringify(arr));
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /*

  const fetchCurrentBid = async () => {
    const d = {
      rid,
      pNid
    };

    await currentBid(token, d)
      .then((r) => {
        //  console.log(r.data.result[0]);
        if (item) {
          // console.log(item.bid_rank, r.data.result[0].bid_rank);
          checkUpdate(item.bid_rank, r.data.result[0].bid_rank, setRankCheck);
        }

        setItem(r.data.result[0]);
      })
      .catch((e) => console.log(e.message));
  };

  React.useEffect(() => {
    fetchCurrentBid();
  }, []);

  const handleChange = () => {
    fetchCurrentBid();
  };

  const getCurremnt = useIdleTimer({
    // timeout: 1000 * 60 * 15,
    onAction: handleChange,
    debounce: 1000
  }); */

  function ordinal_suffix_of(i) {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }

  useEffect(() => {
    const starCountRef = ref(db, `bid`);
    onValue(starCountRef, (snapshot) => {
      const arr = [];
      snapshot.forEach((x) => {
        if (pNid === x.val().bid_pid && rid === x.val().rid && decodedJwt.uid === x.val().uid) {
          arr.push(x.val());
        }
      });
      const newItem = arr.length > 0 && arr[arr.length - 1];
      setItem(newItem);
    });
  }, [db, pNid]);

  useEffect(() => {
    // fetching bids from firebase for the current product
    const bidRef = ref(db, `bid`);
    onValue(bidRef, (snapshot) => {
      const dataArr = [];
      let count = 1;
      snapshot.forEach((x) => {
        if (pNid === x.val().bid_pid && rid === x.val().rid) {
          count += 1;
          dataArr.push({ ...x.val() });
        }
      });

      // assigning ranks to the bid amount
      const rankPushArr = [];
      const usersArr = [];
      let finalCount = count;
      dataArr.forEach((x) => {
        finalCount -= 1;
        rankPushArr.push({ ...x, bid_rank: finalCount });
        usersArr.push(x.uid);
      });

      // getting current user rank by bid amount
      const finalArr = [];
      rankPushArr.forEach((x) => {
        if (decodedJwt.uid === x.uid) {
          finalArr.push(x);
        }
      });

      // getting unique users on the current product
      const unique = (value, index, self) => self.indexOf(value) === index;
      const uniqueUser = usersArr.filter(unique);

      // getting user's latest and lowest bid on the product
      const newArr = [];
      uniqueUser.forEach((x) => {
        let filtered = [];
        rankPushArr.forEach((y) => {
          if (x === y.uid) {
            filtered.push(y);
          }
        });
        // pushing latest bid of the user
        if (filtered.length > 0) {
          filtered.sort((a, b) => a.bid_amt - b.bid_amt);
          // console.log(filtered, 'user filtered');
          newArr.push({ ...filtered[0] });
        }
        filtered = [];
      });

      // assigning user rank on current product as per latest bid
      let userCount = 1;
      newArr.sort((a, b) => a.bid_amt - b.bid_amt);
      const lastUserRank = [];
      newArr.forEach((x) => {
        lastUserRank.push({ ...x, user_rank: userCount });
        userCount += 1;
      });

      // console.log(lastUserRank, 'user filtered');

      // setting user's rank
      if (lastUserRank.length > 0) {
        lastUserRank.forEach((x) => {
          if (decodedJwt.uid === x.uid) {
            setBidRank(x.user_rank);
            checkUpdate(x.user_rank);
          }
        });
      } else {
        checkUpdate(0);
      }
      count = 0;
    });
  }, [db, pNid]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="row"
            sx={{ backgroundColor: '#ece4d9', padding: '5px', borderRadius: 1 }}
          >
            {item ? (
              <>
                <Blinker invisible={rankCheck}>
                  <Label color="error" sx={{ height: 'auto', backgroundColor: 'transparent' }}>
                    <Stack>
                      <Typography sx={{ fontSize: 14, fontWeight: 800 }}>
                        {ordinal_suffix_of(bidRank) || '-'}
                      </Typography>
                      <Typography sx={{ fontSize: 10 }}>Rank</Typography>
                    </Stack>
                  </Label>
                </Blinker>
                <Label color="primary" sx={{ height: 'auto', backgroundColor: 'transparent' }}>
                  <Stack>
                    <Typography sx={{ fontSize: 14, fontWeight: 800 }}>
                      <BiRupee style={{ position: 'relative', top: 2 }} />
                      {fCurrency(item.bid_amt)}
                    </Typography>
                    <Typography sx={{ fontSize: 10, paddingLeft: '5px' }}>Bid</Typography>
                  </Stack>
                </Label>
                <Label color="success" sx={{ height: 'auto', backgroundColor: 'transparent' }}>
                  <Stack>
                    <Typography sx={{ fontSize: 14, fontWeight: 800 }}>
                      {item ? format(new Date(item.bid_time), 'p') : ''}
                    </Typography>
                    <Typography sx={{ fontSize: 10 }}>Time</Typography>
                  </Stack>
                </Label>
              </>
            ) : (
              ''
            )}
            <LowestBid pNid={pNid} />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default CurrentBid;
