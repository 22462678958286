/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  useMediaQuery,
  useTheme,
  Link,
  Box
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
// import {  } from '@material-ui/icons';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import { UserListHead } from '../../../components/_dashboard/user';
import NotFound from '../../../components/_dashboard/app/NotFound';
import AddVendor from './AddVendor';

import VendorMenu from './VendorMenu';
import MobileVendorCard from './MobileVendorCard';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function VendorList({ rows, categories, getList }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const TABLE_HEAD = [
    { id: 'srNo', label: 'SrNo.', align: 'left' },
    { id: 'vendor_id', label: 'Vendor Id', align: 'left' },
    { id: 'vendor_name', label: 'Vendor Name', align: 'left' },
    { id: 'company_dets', label: 'Company Details', align: 'left' },
    // { id: 'business_type', label: 'Type of Busniness', align: 'left' },
    { id: 'category', label: 'Category', align: 'left' },
    { id: 'services', label: 'Services', align: 'left' },
    { id: 'actions', label: 'Actions', align: 'right' }
    // { id: 'field_start_time_minutes_', label: 'Open time', align: 'center' },
    // { id: 'field_close_time_minutes_', label: 'Close time', align: 'center' },
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page sx={{ mt: 2 }}>
      <TableContainer>
        <Scrollbar style={{ minWidth: isMdDown ? 'auto' : 850 }}>
          {isMdDown ? (
            <>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const { title, rfx_id, unique_id, invitation_id, schedule, type } = row;

                  return (
                    <MobileVendorCard
                      row={row}
                      index={index}
                      getList={getList}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                    />
                  );
                })}
              {filteredUsers.length === 0 && (
                <NotFound actionBtn={() => <AddVendor categories={categories} />} />
              )}
            </>
          ) : (
            <Table size="small">
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const title = 'nbj';
                    const isItemSelected = selected.indexOf(title) !== -1;
                    const srNo = index + 1;
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{ height: 58 }}
                      >
                        <TableCell align="center">{srNo}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color="warning"
                            style={{ fontSize: 12, padding: 12 }}
                          >
                            {row.field_vendor_id}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          {row.title}
                          <Box sx={{ color: 'grey', fontSize: 12 }}>
                            {row.field_mobile_number_buyer}
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          <Box>
                            {row.company_details.length > 0
                              ? row.company_details.map((y) => y.company_name).join(', ')
                              : '-'}
                          </Box>
                        </TableCell>

                        <TableCell align="left">
                          {row.field_category.map((x) => x.name).join(', ')}
                        </TableCell>
                        <TableCell align="left">
                          {row.field_services
                            ? row.field_services.map((s) => s.value).join(', ')
                            : '-'}
                        </TableCell>
                        <TableCell align="right">
                          <VendorMenu row={row} getList={getList} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 58 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <NotFound
                        actionBtn={() => <AddVendor categories={categories} getList={getList} />}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </Scrollbar>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}
