import BackupIcon from '@mui/icons-material/Backup';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import SmallSnackbar from '../../../../components/SnackBar';

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 150,
      maxWidth: 170
    },
    drip: {
      minHeight: '130px',
      maxHeight: '130px',
      color: 'grey',
      backgroundColor: '#FFF7CD',

      fontSize: 14
    }
  })
);

const Drop = ({ setFieldValue, val }) => {
  const classes = useStyles();
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const fileAdded = (m) => {
    setSnack({
      open: true,
      color: 'success',
      message: `${m} successfully uploaded`
    });
  };

  const fileRemove = (m) => {
    setSnack({
      open: true,
      color: 'error',
      message: `${m} successfully removed`
    });
    setFieldValue('productFile', []);
  };

  const fileReject = () => {
    setSnack({
      open: true,
      color: 'error',
      message: `Only pdf and image file is allowed`
    });
  };

  return (
    <>
      <DropzoneAreaBase
        fileObjects={val}
        showAlerts={false}
        showPreviews
        previewChipProps={{ classes: { root: classes.previewChip } }}
        showPreviewsInDropzone={false}
        useChipsForPreview
        acceptedFiles={['application/pdf', 'image/jpeg', 'image/png', 'image/bmp']}
        getFileAddedMessage={fileAdded}
        getFileRemovedMessage={fileRemove}
        getDropRejectMessage={fileReject}
        Icon={BackupIcon}
        dropzoneText="Drag and drop file"
        onChange={(files) => console.log('Files:', files)}
        onAdd={(newFileObjs) => {
          console.log('onAdd', val);
          setFieldValue('productFile', newFileObjs);
        }}
        filesLimit={1}
        dropzoneClass={classes.drip}
      />
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </>
  );
};

export default Drop;
