import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  thead: {
    color: 'grey',
    fontSize: 12
  },
  row: {
    fontSize: 9
  },

  date: {
    backgroundColor: 'yellow',
    color: 'black',
    padding: 10,
    borderRadius: 15
  }
});
