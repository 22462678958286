import React, { useEffect, useState } from 'react';
import RfxToolbar from './RfxToolbar';
import FullDialog from '../FullDialog';
import Summary from './Summary';
import AucTable from './AucTable';

const TableContainer = ({ rows, menu, upcoming, active, pending, participated }) => {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [summ, setSumm] = useState();

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.title.toLowerCase().includes(query.toLowerCase()) ||
        x.unique_id.toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  const search = (event) => {
    setQuery(event.target.value);
  };
  return (
    <div>
      <RfxToolbar filterName={query} onFilterName={search} />
      <AucTable
        pending={pending}
        active={active}
        upcoming={upcoming}
        participated={participated}
        rows={!query ? rows : filtered()}
        menu={menu}
        setOpen={setOpen}
        setSumm={setSumm}
      />
      <FullDialog open={open} setOpen={setOpen} headName="Summary">
        <Summary data={summ} />
      </FullDialog>
    </div>
  );
};

export default TableContainer;
