import { AddCircleTwoTone, ScheduleTwoTone } from '@material-ui/icons';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import FullDialog from '../../../../components/_dashboard/app/FullDialog';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const initalState = {
  openDate: null,
  closeDate: null,
  desc: ''
};

const Schedule = ({ handleMenuClose }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initalState);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const handleSubmit = () => {
    console.log(formData);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(initalState);
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          // handleMenuClose();
          setOpen(true);
        }}
      >
        <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
          <ScheduleTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
        </ListItemIcon>
        Schedule
      </MenuItem>
      {/* <Button variant="contained" onClick={() => setOpen(true)}>
        <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
        Schedule
      </Button> */}
      <FullDialog
        open={open}
        setOpen={setOpen}
        headName="Schedule"
        subHead="Field marked as * are required"
        maxWidth="sm"
        minWidth="sm"
        onClose={handleClose}
      >
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  flexDirection={isMdDown ? 'column' : 'row'}
                  alignItems="center"
                  gap={2}
                  justifyContent="center"
                >
                  <DateTimePicker
                    fullWidth
                    label="Open Date & Time"
                    value={formData.openDate}
                    onChange={(newValue) => handleChange('openDate', newValue)}
                    format="DD-MM-YYYY hh:mm A"
                    disablePast
                    required
                    // ampmInClock
                  />
                  -
                  <DateTimePicker
                    fullWidth
                    label="Close Date & Time"
                    value={formData.closeDate}
                    onChange={(newValue) => handleChange('closeDate', newValue)}
                    format="DD-MM-YYYY hh:mm A"
                    disablePast
                    required
                    // ampmInClock
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack flexDirection="row" justifyContent="flex-end">
                <Button variant="contained" onClick={() => handleSubmit()}>
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </FullDialog>
    </>
  );
};

export default Schedule;
