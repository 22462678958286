import { Box, Card, Collapse, Link, Paper, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { AttachmentTwoTone, EventTwoTone } from '@material-ui/icons';
import moment from 'moment';
import Label from '../../../components/Label';
import RfxMenu from './RfxMenu';

const MobileRfxCard = ({ row, index, setSelectedIndex, selectedIndex }) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{ borderBottom: '1px solid lightgrey', m: 1, p: 1 }}
      onClick={() => {
        setSelectedIndex(selectedIndex === index ? null : index);
      }}
      square
    >
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box>{index + 1}</Box>
          <Box>
            {' '}
            <Box sx={{ fontWeight: '600' }}>{row.name}</Box>{' '}
            <Box sx={{ fontSize: 12, color: 'grey' }}>{row.field_rfx_id}</Box>
          </Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <RfxMenu row={row} />
        </Stack>
      </Stack>

      <Collapse in={selectedIndex === index} timeout="auto" unmountOnExit>
        <Stack sx={{ ml: 1.5, mt: 0.8 }} gap={0.8}>
          {row.schedule.length > 0 ? (
            <>
              <Box>
                <Label
                  variant="ghost"
                  color="success"
                  style={{ fontSize: 12, padding: 12, backgroundColor: 'transparent' }}
                >
                  <EventTwoTone style={{ fontSize: 16, marginRight: 8 }} />
                  {moment(
                    parseInt(row.schedule[row.schedule.length - 1].open_date, 10) * 1000
                  ).format('DD-MM-YYYY hh:mm A')}
                </Label>
              </Box>

              <Box>
                <Label
                  variant="ghost"
                  color="error"
                  style={{ fontSize: 12, padding: 12, backgroundColor: 'transparent' }}
                >
                  <EventTwoTone style={{ fontSize: 16, marginRight: 8 }} />
                  {moment(
                    parseInt(row.schedule[row.schedule.length - 1].close_date, 10) * 1000
                  ).format('DD-MM-YYYY hh:mm A')}
                </Label>
              </Box>
            </>
          ) : (
            ''
          )}

          <Box sx={{ fontSize: 12, fontWeight: '600', color: theme.palette.primary.main, ml: 3 }}>
            {row.field_minimum_bid_change === 'Amount' && '₹'}
            {row.field_change_value}
            {row.field_minimum_bid_change === 'Percentage' && '%'} Bid Change
          </Box>

          {row.field_product_catalogue && (
            <Box>
              <AttachmentTwoTone style={{ fontSize: 16, marginRight: 8, marginLeft: 12 }} />
              <Link target="_blank" href={row.field_product_catalogue} sx={{ fontSize: 13 }}>
                Attachment
              </Link>
            </Box>
          )}
        </Stack>
      </Collapse>
    </Paper>
  );
};

export default MobileRfxCard;
