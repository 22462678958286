import { Grid, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import { BiRupee } from 'react-icons/bi';
import React, { useEffect, useState } from 'react';
import { getUnitOfMeasure } from '../../../apis/BuyerApi';
import { token } from '../../../utils/helper';

const Item = styled('div')(({ theme, right, bottom }) => ({
  /* backgroundColor: theme.palette.warning.lighter, */
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRight: !right && 'dashed 1px grey',
  borderBottom: !bottom && 'dashed 1px grey',
  height: 80,
  padding: '.5rem'
}));

const ItemHeading = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 800,
  wordWrap: 'break-word',
  textAlign: 'center'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));

const ProductDetailsPopup = ({ data }) => {
  const [unit, setUnit] = useState('');
  // console.log(data);

  const getUnits = async () => {
    await getUnitOfMeasure(token).then((r) => {
      const a = r.result.filter((x) => x.tid === data.field_unit_of_measure_req[0].target_id);
      setUnit(a[0].name);
      // console.log(unit);
    });
  };

  useEffect(() => {
    getUnits();
  }, []);

  return (
    <div>
      <Grid container sx={{ maxHeight: 480, overflow: 'auto', minWidth: 600 }}>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item>
              <ItemHeading>{data.field_product || 'N/A'}</ItemHeading>
              <ItemDesc>Product name</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>
                <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                {data.field_target_price_buyer || 'N/A'}
              </ItemHeading>
              <ItemDesc>Target price</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_payment_term_buyer_product || 'N/A'}</ItemHeading>
              <ItemDesc>Mode of payment</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_number_of_days || 'N/A'}</ItemHeading>
              <ItemDesc>Number of days on credit</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{(data.field_immediate === '1' ? 'YES' : 'NO') || 'N/A'}</ItemHeading>
              <ItemDesc>Immediate</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item right>
              <ItemHeading>{data.field_site_location || 'N/A'}</ItemHeading>
              <ItemDesc>Location</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{unit || 'N/A'}</ItemHeading>
              <ItemDesc>Unit of measure</ItemDesc>
            </Item>

            <Item right>
              <ItemHeading>{data.field_advance_on_confirmation_of || 'N/A'}</ItemHeading>
              <ItemDesc>Advance on confirmation of PO</ItemDesc>
            </Item>

            <Item right>
              <ItemHeading>{data.field_field_total_quantity || 'N/A'}</ItemHeading>
              <ItemDesc>Total quantity</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{data.field_proposed_delivery_terms_bu || 'N/A'}</ItemHeading>
              <ItemDesc>Proposed delivery terms</ItemDesc>
            </Item>
          </Stack>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Item bottom right>
            <ItemHeading>{data.field_product_description || 'N/A'}</ItemHeading>
            <ItemDesc>Product description</ItemDesc>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductDetailsPopup;
