import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;
/* 
export async function getOtp(num) {
  const data = JSON.stringify({
    mobile: num
  });
  const requestSign = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: data
  };
  const response = await fetch(`${serverUrl}api/get-login-otp`, requestSign);
  const regResponse = await response.json();
  return regResponse;
} */

export async function getOtp(num) {
  const dta = JSON.stringify({
    mobile: num
  });
  let res = {};

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-login-otp`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: dta
  };

  await axios(config)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log(error);
      res = error;
    });

  return res;
}

export async function checkOtp(num, Otp) {
  const dta = JSON.stringify({
    mobile: num,
    otp: Otp
  });
  let res = {};

  const config = {
    method: 'post',
    url: `${serverUrl}api/verify-login-otp`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: dta
  };

  await axios(config)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log(error);
      res = error;
    });

  return res;
}

export async function checkAuth(token, rol) {
  const dta = JSON.stringify({
    role: rol
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/check-current-device-login`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}
