import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { FileDownloadTwoTone } from '@mui/icons-material';
import { AddCircleTwoTone } from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import TaskBar from '../../../../components/_dashboard/app/TaskBar';
import ProductList from './ProductList';
import NewRfx from '../../rfx/newRfx/NewRfx';
import {
  addRfqProductToRfx,
  getBuyerRfqProductDetails,
  getBuyerRfxList
} from '../../../../apis/BuyerApi';
import { rows } from '../../requirementList/data';
import { sleep } from '../../../../utils/helper';

const AddToRfx = () => {
  const [rfxList, setRfxList] = useState([]);
  const [selectedRfx, setSelectedRfx] = useState(null);
  const [productList, setProductList] = useState([]);
  const [bidChange, setBidChange] = useState([]);
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const { rfDet } = location.state;
  const getList = async () => {
    await getBuyerRfxList().then((res) => {
      if (res.status) {
        setRfxList(res.result);
      }
    });
  };

  const getProductList = async () => {
    await getBuyerRfqProductDetails({
      type: 'rfq',
      nid: rfDet.nid
    }).then((res) => {
      if (res.status) {
        setProductList(res.result || []);
      }
    });
  };

  useEffect(() => {
    getList();
    getProductList();
  }, []);

  const handleNewRfx = (res) => {
    sleep(3000).then(() => {
      const result = rfxList.find((x) => x.nid === res);
      console.log(result, rfxList);
      setSelectedRfx(result);
    });
  };

  const handleAddToRfx = async (rfxid) => {
    console.log(rfxid, 'rfxId');
    const selRfx = selectedRfx ? selectedRfx.nid : null;
    const rfxId = rfxid || selRfx;
    if (selected.length > 0 && rfxId) {
      const products = selected.map((x) => {
        const res = bidChange.find((y) => y.pid === x);
        let newVal = res;
        if (res) {
          newVal = {
            pid: res.pid,
            field_minimum_bid_change: res.bid_change,
            field_minimum_change_value: res.bid_value
          };
        } else {
          newVal = {
            pid: x,
            field_minimum_bid_change: '',
            field_minimum_change_value: ''
          };
        }
        return newVal;
      });

      const params = {
        rfx_nid: rfxId,
        p_id: products
      };
      await addRfqProductToRfx(params).then((res) => {
        if (res.status) {
          enqueueSnackbar('Products Added to RFX Successfully!', {
            variant: 'success'
          });
        }
        navigate(-1);
      });
      // console.log(products, 'products');
    } else {
      if (rfxId === null) {
        enqueueSnackbar('Please select RFX to add product!', {
          variant: 'error'
        });
      }

      if (selected.length === 0) {
        enqueueSnackbar('Please select atleast one product!', {
          variant: 'error'
        });
      }
    }
  };

  return (
    <div>
      <TaskBar
        leftItems={() => (
          <>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> RFQ Name: </span> TMT RFQ 2-4-24
            </Box>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> RFQ ID: </span> RQ/2024/021
            </Box>
          </>
        )}
        rightItems={() => (
          <>
            <Autocomplete
              disablePortal
              options={rfxList}
              getOptionLabel={(option) => option.name}
              value={selectedRfx}
              onChange={(event, newValue) => {
                setSelectedRfx(newValue);
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Select RFX" size="small" />}
            />
            <Button variant="contained" size="small" onClick={() => handleAddToRfx()}>
              <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
              Add Product To RFX
            </Button>
            <h3 style={{ color: 'grey' }}>or</h3>
            {selected.length === 0 ? (
              <Button
                variant="contained"
                onClick={() => {
                  enqueueSnackbar('Please select atleast one product!', {
                    variant: 'error'
                  });
                }}
                size="small"
              >
                <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
                New RFX
              </Button>
            ) : (
              <NewRfx getList={getList} onSubmit={(res) => handleAddToRfx(res)} />
            )}
          </>
        )}
      />
      <ProductList
        rows={productList}
        bidChange={bidChange}
        setBidChange={setBidChange}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

export default AddToRfx;

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003
  }
];
