import { Backup, CloudUpload } from '@material-ui/icons';
import { Button, Stack } from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { addProductByCsv } from '../../../../../../apis/BuyerApi';
import { decodedJwt } from '../../../../../../utils/helper';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const useStyles = makeStyles((theme) =>
  createStyles({
    previewChip: {
      minWidth: 150,
      maxWidth: 300
    },
    drip: {
      minHeight: '130px',
      maxHeight: '300px',
      color: 'grey',
      backgroundColor: theme.palette.secondary.lighter,

      fontSize: 14
    }
  })
);

const CsvUpload = ({ submitLoading, setCsvFile, csvFile, handleSubmitProducts }) => {
  const classes = useStyles();

  const fileReject = () => {
    // setSnack({
    //   open: true,
    //   color: 'error',
    //   message: `Only pdf and image file is allowed`
    // });
  };

  return (
    <Stack justifyContent="center" alignItems="center" m={10}>
      {/* <DropzoneAreaBase
        fileObjects={csvFile}
        showAlerts={false}
        showPreviews
        previewChipProps={{ classes: { root: classes.previewChip } }}
        showPreviewsInDropzone={false}
        useChipsForPreview
        // acceptedFiles={['application/pdf', 'image/jpeg', 'image/png', 'image/bmp']}
        acceptedFiles={['text/csv']}
        // getFileAddedMessage={fileAdded}
        // getFileRemovedMessage={fileRemove}
        getDropRejectMessage={fileReject}
        Icon={Backup}
        dropzoneText="Drag and drop file"
        onChange={(files) => console.log('Files:', files)}
        onAdd={(newFileObjs) => {
          setCsvFile(newFileObjs);
        }}
        onDelete={(newFileObjs) => {
          setCsvFile(newFileObjs);
        }}
        filesLimit={1}
        dropzoneClass={classes.drip}
      /> */}

      <Button component="label" variant="contained" startIcon={<CloudUpload />}>
        {csvFile ? csvFile.name : 'Upload CSV file'}

        <VisuallyHiddenInput
          type="file"
          onChange={(e) => setCsvFile(e.target.files[0])}
          // accept="image/jpeg,image/png,image/jpg,application/pdf"
        />
      </Button>

      <LoadingButton
        loading={submitLoading}
        variant="contained"
        sx={{ mt: 10 }}
        onClick={() => handleSubmitProducts()}
      >
        Upload
      </LoadingButton>
    </Stack>
  );
};

export default CsvUpload;
