import { Box, Card, CardContent, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const ItemHeading = styled('h3')(({ theme }) => ({
  color: theme.palette.primary.main,
  wordWrap: 'break-word',
  textAlign: 'left'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 14,
  color: theme.palette.text.secondary
}));

const Title = styled('h1')(({ theme, right }) => ({
  color: theme.palette.text.secondary
}));

const ContactUs = () => {
  const a = 1;
  return (
    <div>
      <Title>Contact us</Title>
      <Stack spacing={5} sx={{ mt: 5 }}>
        <Box>
          <ItemHeading>admnnirmaanprocura@gmail.com</ItemHeading>
          <ItemDesc>Email id</ItemDesc>
        </Box>
        <Box>
          <ItemHeading>8780040827</ItemHeading>
          <ItemDesc>Mobile number</ItemDesc>
        </Box>
      </Stack>
    </div>
  );
};

export default ContactUs;
