import { Button, Dialog, FormLabel, Slide, Stack, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { decodedJwt, token } from '../../../../../utils/helper';
import SelectInput from '../../_components/SelectInput';
import { currentBid, checkCompany } from '../../../../../apis/VendorApi';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CompanySelection = ({ open, setOpen, companyList, res, setCompanyName }) => {
  const rid = localStorage.getItem('rid');
  const company = localStorage.getItem('company') || JSON.stringify([]);
  const [currBid, setCurrBid] = useState('');
  const Formik = useFormik({
    initialValues: {
      company: ''
    },
    validationSchema: Yup.object().shape({
      company: Yup.string().required('company is required')
    }),
    onSubmit: async () => {
      const name = companyList.filter((x) => x.tid === values.company);
      const closeTime = res.schedule[0].close_date;
      const { uid } = decodedJwt;
      setCompanyName(name[0]);
      // setCompanyName(name[0].name);
      /* if (company) {
        const existingCompany = JSON.parse(company);
        existingCompany.push({
          name: name[0].name,
          company_id: name[0].tid,
          rfxId: rid,
          closeTime,
          uid
        });
        localStorage.setItem('company', JSON.stringify(existingCompany));
      } else {
        localStorage.setItem(
          'company',
          JSON.stringify([
            { name: name[0].name, company_id: name[0].tid, rfxId: rid, closeTime, uid }
          ])
        );
      } */

      setOpen(false);
    }
  });

  const checkForCompany = async () => {
    await checkCompany(token, rid).then((r) => {
      // console.log(r.data.result);
      if (r.data.result.length > 0) {
        const a = companyList.filter((x) => x.tid === r.data.result[0].company_id);
        setCompanyName(a[0]);
      } else {
        setOpen(true);
      }
    });
  };

  useEffect(() => {
    checkForCompany();
  }, [companyList]);

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    handleChange,
    setFieldValue
  } = Formik;
  return (
    <Dialog open={open} TransitionComponent={Transition} sx={{ minHeight: 400 }}>
      <Box style={{ height: 'auto', width: 300, padding: 20 }}>
        <Stack spacing={1}>
          <FormLabel>Please select your company you are willing to participate with.</FormLabel>
          <SelectInput
            cat={values.company}
            getFieldProps={getFieldProps('company')}
            name="Company"
            dta={companyList}
            error={Boolean(touched.company && errors.company)}
            helper={touched.company && errors.company}
          />

          <Button variant="contained" onClick={handleSubmit} fullWidth>
            Submit
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default CompanySelection;
