import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Delivery, AmtText } from './styles';

const Freebies = () => (
  <TableRow>
    <TableCell>
      <Typography variant="body2" color="primary.light">
        FREEBIES
      </Typography>
      <Typography variant="caption" sx={{ opacity: 0.6 }}>
        Description
      </Typography>
    </TableCell>
    <TableCell align="right" variant="body2">
      {' '}
    </TableCell>
    <TableCell align="right">
      <Delivery>28 sept - 30 sept</Delivery>
    </TableCell>
    <TableCell align="right" variant="body2">
      <Box>
        <Button size="small" variant="outlined">
          ADD
        </Button>
      </Box>
      <AmtText
        variant="subtitle2"
        color="primary.light"
        sx={{ fontSize: 13, paddingRight: 1, paddingTop: 1 }}
      >
        FREE
      </AmtText>
    </TableCell>
  </TableRow>
);

export default Freebies;
