import {
  Card,
  Grid,
  CardHeader,
  Typography,
  IconButton,
  CardContent,
  Stack,
  Box
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/styles';
import Label from '../../Label';
import { userData, token } from '../../../utils/helper';
import { getBuyerDetails } from '../../../apis/BuyerApi';

const Icon = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.warning.lighter,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 60
}));

const Info = styled('div')(({ theme }) => ({
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '150%',
  marginTop: 10
}));

const Head = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.warning.lighter
}));

const ProIcon = styled(Skeleton)(({ theme, right }) => ({
  width: 90,
  height: 90,
  margin: 'auto',
  border: 'solid white 4px',
  borderRadius: '50%',
  marginTop: '10px'
}));

const ProText = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13
}));

export default function UserProfileSkeleton() {
  const navigate = useNavigate();
  const pic = userData.picture;

  return (
    <>
      <Card sx={{ minHeight: 310 }}>
        <Head
          title="Profile"
          action={
            <IconButton
              aria-label="add to favorites"
              color="primary"
              onClick={() => navigate('/profile')}
            >
              <EditIcon />
            </IconButton>
          }
        />
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Icon>
              <ProIcon variant="circular" width={90} height={90} />
            </Icon>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Info>
              <Skeleton variant="text" sx={{ width: '50%' }} />
              <Skeleton variant="text" sx={{ width: '50%' }} />
              <Skeleton variant="text" sx={{ width: '50%' }} />
              <Skeleton variant="text" sx={{ width: '50%' }} />
            </Info>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
