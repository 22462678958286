import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import { useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import { Link } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { buyerData, vendorData } from './data';
import Step from './Step';
import Scrollbar from '../../../components/Scrollbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function TabSwitcher() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Notice = () => (
    <Typography sx={{ color: 'gray' }}>
      <InfoOutlinedIcon style={{ fontSize: 14, color: 'grey' }} /> First you have to register in our
      portal to use it.{' '}
      <Link sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact')}>
        click here to register
      </Link>{' '}
    </Typography>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Vendor" {...a11yProps(0)} />
          <Tab label="Customer" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{ height: '90vh' }}>
        <Scrollbar style={{ height: '85vh' }}>
          <Notice />
          {vendorData.map((x, i) => (
            <>{(i + 1) % 2 === 0 ? <Step right data={x} /> : <Step data={x} />}</>
          ))}
        </Scrollbar>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Scrollbar style={{ height: '85vh' }}>
          <Notice />
          {buyerData.map((x, i) => (
            <>{(i + 1) % 2 === 0 ? <Step right data={x} /> : <Step data={x} />}</>
          ))}
        </Scrollbar>
      </TabPanel>
    </Box>
  );
}
