import { Breadcrumbs, Grid, Link, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import { BiRupee } from 'react-icons/bi';
import React, { useEffect } from 'react';
import Scrollbar from '../../../../components/Scrollbar';

const Item = styled('div')(({ theme, right, bottom }) => ({
  /* backgroundColor: theme.palette.warning.lighter, */
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRight: !right && 'dashed 1px grey',
  borderBottom: !bottom && 'dashed 1px grey',
  height: 80,
  padding: '.5rem'
}));

const ItemHeading = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 800,
  wordWrap: 'break-word',
  textAlign: 'center'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));

const ProductDetails = ({ data }) => {
  const category = Object.values(data.category_items);
  return (
    <Scrollbar style={{ maxHeight: 400, minWidth: 500 }}>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item>
              <ItemHeading>{data.product_id}</ItemHeading>
              <ItemDesc>Product id</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>
                <Breadcrumbs
                  separator=">>"
                  maxItems={1}
                  sx={{ fontSize: 14, fontWeight: 800 }}
                  color="primary"
                >
                  {category.map((y, i) => (
                    <span key={i}>{y}</span>
                  ))}
                </Breadcrumbs>
              </ItemHeading>
              <ItemDesc>Category</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_unit_of_measure}</ItemHeading>
              <ItemDesc>Unit of measure</ItemDesc>
            </Item>
            <Item bottom>
              <ItemHeading>{data.field_minimum_desired_quantity}</ItemHeading>
              <ItemDesc>Desired quantity</ItemDesc>
            </Item>
            {/* <Item>
              <ItemHeading>{data.field_historical_cost}</ItemHeading>
              <ItemDesc>Historical cost</ItemDesc>
            </Item> */}
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item right>
              <ItemHeading>{data.field_product_name}</ItemHeading>
              <ItemDesc>Product name</ItemDesc>
            </Item>
            {/* <Item right>
              <ItemHeading>{data.field_lot.name}</ItemHeading>
              <ItemDesc>Lot name</ItemDesc>
            </Item> */}
            {/* <Item right>
              <ItemHeading>{data.field_unit_cost}</ItemHeading>
              <ItemDesc>Unit cost</ItemDesc>
            </Item> */}
            <Item right>
              <ItemHeading>{data.field_start_price}</ItemHeading>
              <ItemDesc>Start price</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>
                {data.field_minimum_bid_change === 'Amount' && (
                  <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                )}
                {data.field_minimum_change_value || '-'}
                {data.field_minimum_bid_change === 'Percentage' && '%'}
              </ItemHeading>
              <ItemDesc>Bid change</ItemDesc>
            </Item>
            <Item bottom right>
              <ItemHeading>
                {data.field_upload_product_catalogue_ ? (
                  <Link target="_blank" href={data.field_upload_product_catalogue_}>
                    View
                  </Link>
                ) : (
                  '-'
                )}
              </ItemHeading>
              <ItemDesc>Product catalogue</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        {/* <Grid item sm={12} xs={12}>
          <Item bottom right>
            <ItemHeading>
              {data.field_upload_product_catalogue_ && (
                <Link target="_blank" href={data.field_upload_product_catalogue_}>
                  View
                </Link>
              )}
            </ItemHeading>
            <ItemDesc>Product catalogue</ItemDesc>
          </Item>
        </Grid> */}
      </Grid>
    </Scrollbar>
  );
};

export default ProductDetails;
