import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { FaRegFilePdf, FaRegImage } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Backup, Close, CloudUpload, Delete } from '@material-ui/icons';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import Label from '../../../../../components/Label';
import { rfqProductSchema } from '../../../../../utils/formikSchema';
import { aPo, creditOp, serv, terms } from '../../../../../utils/helper';
import {
  getCategory,
  getUnitOfMeasure,
  submitBuyerNodeProduct
} from '../../../../../apis/BuyerApi';

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 150,
      maxWidth: 170
    },
    drip: {
      minHeight: '130px',
      maxHeight: '130px',
      color: 'grey',
      backgroundColor: '#FFF7CD',

      fontSize: 14
    }
  })
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const stateList = [
  { id: 1, name: 'Gujarat' },
  { id: 2, name: 'Maharashtra' },
  { id: 3, name: 'Rajasthan' },
  { id: 4, name: 'Delhi' }
];

const cityList = [
  { id: 1, name: 'Vapi' },
  { id: 2, name: 'Mumbai' },
  { id: 3, name: 'Surat' },
  { id: 4, name: 'Ahmedabad' }
];

const AddEditProductForm = () => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { rfDet, type, editData } = location.state;
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [currentAttachment, setCurrentAttachment] = useState(
    editData ? editData.field_product_catalog : ''
  );
  const [loading, setLoading] = useState(false);
  console.log(editData, 'editData');
  const initialVals = {
    productName: editData ? editData.field_product_name_rfq : '',
    category: '',
    subCategory: '',
    bidChange: '',
    changeValue: '',
    services: {
      service: false,
      serviceWithMaterial: false,
      materialOnly: false
    },
    unitOfMeasure: [],
    desiredQty: '',
    startPrice: '',
    payTerms: '',
    confPo: '',
    onCredit: '',
    state: '',
    city: '',
    pincode: '',
    productFile: [],
    desc: '',
    serviceType: ''
  };

  const fetchCategories = async () => {
    await getCategory().then((res) => {
      setCategories(res.result.map((x) => ({ tid: x.pid, name: x.name })));
    });
  };

  const fetchUnits = async () => {
    await getUnitOfMeasure().then((res) => {
      setUnits(res.result);
    });
  };
  useEffect(() => {
    fetchCategories();
    fetchUnits();
    if (editData) {
      if (editData.product_cat.length > 0) {
        setFieldValue('category', editData.product_cat[0].tid);
      }

      if (editData.field_state) {
        setFieldValue('state', editData.field_state);
      }
      if (editData.field_city) {
        setFieldValue('city', editData.field_city);
      }
      if (editData.field_pin_code) {
        setFieldValue('pincode', editData.field_pin_code);
      }
      if (editData.field_services) {
        const servArr = editData.field_services.split(',');
        // console.log(servArr, 'servArr');
        if (servArr.includes('Service')) setFieldValue('services.service', true);
        if (servArr.includes('Service With Material'))
          setFieldValue('services.serviceWithMaterial', true);
        if (servArr.includes('Material Only')) setFieldValue('services.materialOnly', true);
      }

      if (editData.field_unit_of_measure) {
        setFieldValue(
          'unitOfMeasure',
          editData.field_unit_of_measure.map((x) => x.default_unit_of_measure)
        );
      }

      if (editData.field_minimum_desired_quantity) {
        setFieldValue('desiredQty', editData.field_minimum_desired_quantity);
      }

      if (editData.field_start_price) {
        setFieldValue('startPrice', editData.field_start_price);
      }
      if (editData.field_payment_term_buyer_product) {
        setFieldValue('payTerms', editData.field_payment_term_buyer_product);
      }
      if (editData.field_number_of_days) {
        setFieldValue('onCredit', editData.field_number_of_days);
      }
      if (editData.field_services) {
        setFieldValue('serviceType', editData.field_services);
      }
      // if (editData.field_number_of_days) {
      //   setFieldValue('onCredit', editData.field_number_of_days);
      // }
      if (editData.description) {
        setFieldValue('desc', editData.description);
      }
    }
  }, []);

  const fileReject = () => {
    // setSnack({
    //   open: true,
    //   color: 'error',
    //   message: `Only pdf and image file is allowed`
    // });
  };

  const productFormik = useFormik({
    initialValues: initialVals,
    validationSchema: rfqProductSchema,
    onSubmit: async () => {
      setLoading(true);
      const keys = Object.keys(values.services);
      const filteredServices = keys.filter((key) => values.services[key]);
      const newServ = filteredServices.map((y) => serv.find((x) => x.key === y).value);
      const uom = values.unitOfMeasure.map((x) => units.find((y) => y.name === x).tid);
      const params = {
        type,
        nid: rfDet.nid,
        field_services: newServ,
        unitOfMeasure: uom,
        product_cat: parseInt(values.category, 10),
        name: values.productName,
        // field_minimum_bid_change: 'Amount',
        field_minimum_desired_quantity: values.desiredQty,
        // change_value: 10,
        field_start_price: values.startPrice,
        field_payment_term_buyer_product: values.payTerms,
        field_number_of_days: values.onCredit,
        field_state: values.state,
        field_city: values.city,
        field_pin_code: values.pincode,
        field_description_field: values.desc,
        productFile: values.productFile
      };
      if (editData) params.pid = editData.nid;

      if (currentAttachment == null) params.productFile = 2;
      if (currentAttachment) params.productFile = 1;
      if (values.productFile.length > 0) params.productFile = values.productFile;
      await submitBuyerNodeProduct(params)
        .then((res) => {
          setLoading(false);
          if (res.status) {
            if (editData) {
              enqueueSnackbar('Product Updated Successfully!', {
                variant: 'success'
              });
            } else {
              enqueueSnackbar('Product Added Successfully!', {
                variant: 'success'
              });
            }

            navigate(-1);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  });

  const handleDeleteAttachment = () => {
    setCurrentAttachment(null);
  };

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange
  } = productFormik;
  console.log(values, errors, 'values');
  const { service, serviceWithMaterial, materialOnly } = values.services;
  return (
    <Box mx={2}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        flexWrap="wrap"
      >
        {/* <h3>Add Product</h3> */}
        <Box>
          <Label
            sx={{ height: 'auto', padding: 1, backgroundColor: 'transparent' }}
            color="primary"
          >
            <Stack spacing={1} direction="row">
              {/* <Avatar {...stringAvatar(res.title)} /> */}
              <Stack>
                <Typography sx={{ fontSize: 11, color: 'gray' }}>
                  {type.toUpperCase()} Id
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: '600' }}>
                  {type === 'rfq' ? rfDet.field_rfq_id : rfDet.field_rfx_id}
                </Typography>
              </Stack>
            </Stack>
          </Label>
          <Label
            sx={{ height: 'auto', padding: 1, backgroundColor: 'transparent' }}
            color="primary"
          >
            <Stack spacing={1} direction="row">
              {/* <Avatar {...stringAvatar(res.title)} /> */}
              <Stack>
                <Typography sx={{ fontSize: 11, color: 'gray' }}>
                  {type.toUpperCase()} name
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: '700' }}>
                  {' '}
                  {type === 'rfq' ? rfDet.title : rfDet.name}
                </Typography>
              </Stack>
            </Stack>
          </Label>

          <Typography sx={{ color: theme.palette.error.main, fontSize: '.8rem' }}>
            Field marked with * is mandatory
          </Typography>
        </Box>

        {/* <Box></Box> */}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Stack gap={3}>
            <FormControl
              fullWidth
              size="small"
              error={touched.category && errors.category}
              helperText={touched.category && errors.category}
              required
            >
              <InputLabel>Select Category</InputLabel>
              <Select
                value={values.category}
                label="Select Category"
                fullWidth
                {...getFieldProps('category')}
                defaultValue={values.category}
              >
                {categories.map((x) => (
                  <MenuItem value={x.tid}>{x.name}</MenuItem>
                ))}

                {/* <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
            </FormControl>

            {/* <FormControl
              fullWidth
              size="small"
              error={touched.subCategory && errors.subCategory}
              helperText={touched.subCategory && errors.subCategory}
              required
            >
              <InputLabel>Select Sub Category</InputLabel>
              <Select
                value={values.subCategory}
                label="Select Sub Category"
                fullWidth
                {...getFieldProps('subCategory')}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl> */}
            {/* <FormControl size="small">
              <FormLabel>Minimum Bid Change</FormLabel>
              <RadioGroup defaultValue="na" row {...getFieldProps('bidChange')}>
                <FormControlLabel
                  value="na"
                  control={<Radio size="small" />}
                  label={<Typography sx={{ fontSize: '0.9rem' }}>N/A</Typography>}
                />
                <FormControlLabel
                  value="amount"
                  control={<Radio size="small" />}
                  label={<Typography sx={{ fontSize: '0.9rem' }}>Amount</Typography>}
                />
                <FormControlLabel
                  value="percentage"
                  control={<Radio size="small" />}
                  label={<Typography sx={{ fontSize: '0.9rem' }}>Percentage</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Change Value"
              // multiline
              // rows={2}
              size="small"
              error={Boolean(touched.changeValue && errors.changeValue)}
              helperText={touched.changeValue && errors.changeValue}
              inputProps={{ maxLength: 255 }}
              {...getFieldProps('changeValue')}
              fullWidth
            /> */}

            <FormControl
              fullWidth
              size="small"
              error={touched.state && errors.state}
              helperText={touched.state && errors.state}
              required
            >
              <InputLabel>Select State</InputLabel>
              <Select
                value={values.state}
                label="Select State"
                fullWidth
                {...getFieldProps('state')}
              >
                {stateList.map((x) => (
                  <MenuItem value={x.name}>{x.name}</MenuItem>
                ))}
                {/*               
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
              {touched.state && <FormHelperText>{errors.state}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth size="small" required error={touched.city && errors.city}>
              <InputLabel>Select City</InputLabel>
              <Select value={values.city} label="Select City" fullWidth {...getFieldProps('city')}>
                {cityList.map((x) => (
                  <MenuItem value={x.name}>{x.name}</MenuItem>
                ))}
              </Select>
              {touched.city && <FormHelperText>{errors.city}</FormHelperText>}
            </FormControl>

            <TextField
              label="Pincode"
              // multiline
              // rows={2}
              size="small"
              type="number"
              error={Boolean(touched.pincode && errors.pincode)}
              helperText={touched.pincode && errors.pincode}
              inputProps={{ maxLength: 6 }}
              {...getFieldProps('pincode')}
              fullWidth
            />

            <DropzoneAreaBase
              fileObjects={values.productFile}
              showAlerts={false}
              showPreviews
              previewChipProps={{ classes: { root: classes.previewChip } }}
              showPreviewsInDropzone={false}
              useChipsForPreview
              acceptedFiles={['application/pdf', 'image/jpeg', 'image/png', 'image/bmp']}
              // getFileAddedMessage={fileAdded}
              // getFileRemovedMessage={fileRemove}
              getDropRejectMessage={fileReject}
              Icon={Backup}
              dropzoneText="Drag and drop file"
              onChange={(files) => console.log('Files:', files)}
              onAdd={(newFileObjs) => {
                setFieldValue('productFile', newFileObjs);
              }}
              onDelete={(newFileObjs) => {
                setFieldValue('productFile', newFileObjs);
              }}
              filesLimit={1}
              dropzoneClass={classes.drip}
            />

            <Box>
              <h6>Current file</h6>

              {currentAttachment && (
                <Box>
                  Current File: &nbsp;
                  <Link target="_blank" href={currentAttachment}>
                    Attachment
                  </Link>
                  <IconButton onClick={() => handleDeleteAttachment()}>
                    <Delete />
                  </IconButton>
                </Box>
              )}

              {/* <Box>
                  {editData.field_product_catalog.split('/').pop().split('.').pop() === 'pdf' ? (
                    editData.field_product_catalog.split('/').pop()
                  ) : (
                    <img src={editData.field_product_catalog} alt="img" height={100} width={100} />
                  )}
                </Box> */}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack gap={3}>
            <FormControl
              fullWidth
              size="small"
              error={touched.serviceType && errors.serviceType}
              helperText={touched.serviceType && errors.serviceType}
              required
            >
              <InputLabel>Select Service Type</InputLabel>
              <Select
                value={values.serviceType}
                label="Select Service Type"
                fullWidth
                {...getFieldProps('serviceType')}
              >
                {/* {serviceTypeList.map((x) => (
                <MenuItem value={x.name}>{x.name}</MenuItem>
              ))} */}

                <MenuItem value="Service">Service</MenuItem>
                <MenuItem value="Material Only">Material Only</MenuItem>
                <MenuItem value="Service With Material">Service With Material</MenuItem>
              </Select>
              {touched.serviceType && <FormHelperText>{errors.serviceType}</FormHelperText>}
            </FormControl>
            {/* <FormControl
              variant="standard"
              error={touched.services && errors.services}
              helperText={touched.services && errors.services}
              component="fieldset"
              sx={{ border: '1px solid lightgrey', p: 1, borderRadius: 1 }}
              size="small"
            >
              <FormGroup aria-label="position" column>
                <FormLabel component="legend">Services</FormLabel>
                <Stack direction="row" flexWrap="wrap">
                  <FormControlLabel
                    value={values.services.service}
                    control={
                      <Checkbox
                        {...getFieldProps('services.service')}
                        checked={service}
                        size="small"
                      />
                    }
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Service</Typography>}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={values.services.serviceWithMaterial}
                    control={
                      <Checkbox
                        {...getFieldProps('services.serviceWithMaterial')}
                        checked={serviceWithMaterial}
                        size="small"
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '0.9rem' }}>Service With Material</Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={values.services.materialOnly}
                    control={
                      <Checkbox
                        {...getFieldProps('services.materialOnly')}
                        checked={materialOnly}
                        size="small"
                      />
                    }
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Material Only</Typography>}
                    labelPlacement="end"
                  />
                </Stack>
              
              </FormGroup>
              <FormHelperText> {touched.services && errors.services}</FormHelperText>
            </FormControl> */}
            <TextField
              label="Product Name"
              required
              // multiline
              // rows={2}
              size="small"
              error={Boolean(touched.productName && errors.productName)}
              helperText={touched.productName && errors.productName}
              inputProps={{ maxLength: 255 }}
              {...getFieldProps('productName')}
              fullWidth
            />

            <FormControl
              size="small"
              error={touched.unitOfMeasure && errors.unitOfMeasure}
              required
            >
              <InputLabel>Unit Of Measure</InputLabel>
              <Select
                multiple
                value={values.unitOfMeasure}
                onChange={(e) => {
                  setFieldValue(
                    'unitOfMeasure',
                    typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                  );
                }}
                input={<OutlinedInput label="Unit Of Measure" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {units.map((x) => (
                  <MenuItem
                    key={x.tid}
                    value={x.name}
                    // style={getStyles(name, personName, theme)}
                  >
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
              {touched.unitOfMeasure && <FormHelperText>{errors.unitOfMeasure}</FormHelperText>}
            </FormControl>
            <TextField
              label="Minimum Desired Quantity"
              required
              // multiline
              // rows={2}
              type="number"
              size="small"
              error={Boolean(touched.desiredQty && errors.desiredQty)}
              helperText={touched.desiredQty && errors.desiredQty}
              // inputProps={{ maxLength: 255 }}
              {...getFieldProps('desiredQty')}
              fullWidth
            />
            <TextField
              label="Start Price"
              required
              // multiline
              // rows={2}
              type="number"
              size="small"
              error={Boolean(touched.startPrice && errors.startPrice)}
              helperText={touched.startPrice && errors.startPrice}
              // inputProps={{ maxLength: 255 }}
              {...getFieldProps('startPrice')}
              fullWidth
            />

            <FormControl fullWidth size="small" error={touched.payTerms && errors.payTerms}>
              <InputLabel>Payment Terms</InputLabel>
              <Select
                value={values.payTerms}
                label="Payment Terms"
                fullWidth
                {...getFieldProps('payTerms')}
              >
                {terms.map((x) => (
                  <MenuItem value={x.tid}>{x.name}</MenuItem>
                ))}
              </Select>
              {touched.payTerms && <FormHelperText>{errors.payTerms}</FormHelperText>}
            </FormControl>
            {values.payTerms === 'credit' && (
              <FormControl fullWidth size="small">
                <InputLabel>No. Of Days</InputLabel>
                <Select
                  value={values.onCredit}
                  label="No. Of Days"
                  fullWidth
                  {...getFieldProps('onCredit')}
                >
                  {creditOp.map((x) => (
                    <MenuItem value={x.tid}>{x.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {values.payTerms === 'advance payments' && (
              <FormControl fullWidth size="small">
                <InputLabel>Advance on confirmation of po</InputLabel>
                <Select
                  value={values.confPo}
                  label="Advance on confirmation of po"
                  fullWidth
                  {...getFieldProps('confPo')}
                >
                  {aPo.map((x) => (
                    <MenuItem value={x.tid}>{x.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <TextField
              label="Description"
              // required
              multiline
              rows={2}
              // type="number"
              size="small"
              error={Boolean(touched.desc && errors.desc)}
              helperText={touched.desc && errors.desc}
              inputProps={{ maxLength: 255 }}
              {...getFieldProps('desc')}
              fullWidth
            />
            <Stack alignItems="flex-end">
              <Box>
                <LoadingButton variant="contained" loading={loading} onClick={() => handleSubmit()}>
                  Submit
                </LoadingButton>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* <Paper square={false} variant="outlined">
            a
          </Paper> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditProductForm;
