/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  useMediaQuery,
  useTheme,
  Box,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Collapse,
  Typography,
  TableHead
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AccountBoxTwoTone,
  AddBoxTwoTone,
  Inventory2TwoTone,
  RequestQuoteTwoTone,
  ScheduleTwoTone
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
// import {  } from '@material-ui/icons';
// components
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import MobileProductCard from '../../rfq/newRfq/product/MobileProductCard';
import { UserListHead } from '../../../../components/_dashboard/user';
import NotFound from '../../../../components/_dashboard/app/NotFound';
import MobileQuotationCard from './MobileQuotationCard';
import { ordinalSuffixOf } from '../../../../utils/helper';
import BidHistoryRow from './BidHistoryRow';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function Row(props) {
  const { row, bidList, index, showName } = props;
  const [open, setOpen] = useState(false);
  const [historyRows, setHistoryRows] = useState([]);

  const getHistory = () => {
    const list = bidList.filter((x) => x.pid_nid === row.nid);

    if (showName) {
      const sortedList = list.sort((a, b) => parseFloat(a.bid_amt) - parseFloat(b.bid_amt));
      const uniqueBids = [];
      const uniqueNames = new Set();
      sortedList.forEach((bid) => {
        if (!uniqueNames.has(bid.user_full_name)) {
          uniqueNames.add(bid.user_full_name);
          uniqueBids.push(bid);
        }
      });
      // for (const bid of sortedList) {
      //   if (!uniqueNames.has(bid.user_full_name)) {
      //     uniqueNames.add(bid.user_full_name);
      //     uniqueBids.push(bid);
      //   }
      // }
      uniqueBids.forEach((bid, index) => {
        const rank = index + 1;
        bid.bid_rank = rank.toString();
      });
      setHistoryRows([...uniqueBids]);
      // console.log(uniqueBids, 'uniqueBids');
    } else {
      setHistoryRows([...list]);
    }
  };
  useEffect(() => {
    getHistory();
  }, [bidList, showName]);

  const first = historyRows.find((x) => x.bid_rank === '1');
  // console.log(first);

  return (
    <>
      <TableRow
        hover
        key={row.nid}
        tabIndex={-1}
        sx={{ '& > *': { borderBottom: 'unset' }, height: 58 }}
        onClick={() => setOpen(!open)}
      >
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="left">
          <Label variant="ghost" color="warning" style={{ fontSize: 12, padding: 12 }}>
            {row.title}
          </Label>
        </TableCell>
        <TableCell align="left">
          <Box sx={{ fontWeight: '600' }}>{row.field_product_name_rfq}</Box>
          <Box sx={{ fontSize: 12, color: 'gray' }}>wood >> plywood</Box>
          <Box sx={{ fontSize: 12, color: 'gray' }}>{row.field_services}</Box>
        </TableCell>
        {/* <TableCell align="center">
          <Stack flexDirection="row" sx={{ flexWrap: 'wrap', maxWidth: 200 }} gap={1}>
            {row.field_unit_of_measure.map((x) => (
              <Label variant="ghost" color="default" style={{ fontSize: 12, padding: 12 }}>
                {x.default_unit_of_measure}
              </Label>
            ))}
          </Stack>
        </TableCell> */}
        <TableCell align="center">{row.field_minimum_desired_quantity}</TableCell>
        <TableCell align="center">₹{row.field_start_price}</TableCell>
        <TableCell align="center">{row.field_payment_term_buyer_product}</TableCell>
        <TableCell align="center">
          {row.field_city}, {row.field_state} {row.field_pin_code}
        </TableCell>
        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />

        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack flexDirection="row" gap={2}>
                <Typography variant="h6" gutterBottom component="div">
                  Bid Details
                </Typography>
                {first && (
                  <>
                    {showName && (
                      <Label variant="ghost" color="info" style={{ fontSize: 12, padding: 12 }}>
                        {ordinalSuffixOf('1')} &nbsp;
                        {first.user_full_name}
                      </Label>
                    )}

                    <Label variant="ghost" color="success" style={{ fontSize: 12, padding: 12 }}>
                      L1 - ₹ {first.bid_amt}
                    </Label>
                  </>
                )}
              </Stack>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr No.</TableCell>
                    {showName && <TableCell>User Name</TableCell>}
                    <TableCell>Bid Amount</TableCell>
                    <TableCell>Total Cost</TableCell>
                    <TableCell>Rank</TableCell>
                    <TableCell>Desired Qty</TableCell>
                    <TableCell align="right">Bid Time</TableCell>
                    {showName && <TableCell align="right">Bid History</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyRows.map((historyRow, ind) => (
                    <BidHistoryRow
                      historyRow={historyRow}
                      ind={ind}
                      showName={showName}
                      bidList={bidList}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function QuotationList({ rows, bidList, rfDet }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showName, setShowName] = useState(false);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleViewNames = () => {
    console.log(rfDet.schedule);
    if (rfDet.schedule.length > 0) {
      const sch = rfDet.schedule[rfDet.schedule.length - 1];
      const currentDate = moment(new Date()).format('DD-MM-YYYY HH:mm');
      const rfqCloseDate = moment(parseInt(sch.close_date, 10) * 1000).format('DD-MM-YYYY HH:mm');
      const res = moment(currentDate).isBefore(rfqCloseDate);
      // console.log(res, currentDate, rfqCloseDate, 'res');
      if (!res) {
        setShowName(true);
      } else {
        setShowName(false);
      }
    } else {
      setShowName(true);
    }
  };

  useEffect(() => {
    handleViewNames();
  }, []);

  // [
  //   { id: 'srNo', label: 'SrNo.', align: 'left' },
  //   // { id: 'rfq_id', label: 'RFQ Id', align: 'left' },
  //   { id: 'user_name', label: 'Bid User Name', align: 'left' },
  //   { id: 'company_name', label: 'Company Name', align: 'left' },
  //   { id: 'product_name', label: 'Bid Product Name', align: 'center' },
  //   { id: 'amount', label: 'Bid Amount', align: 'center' },
  //   { id: 'totalCost', label: 'Bid Total Cost', align: 'center' },
  //   { id: 'rank', label: 'Bid Rank', align: 'center' },
  //   { id: 'desiredQty', label: 'Desired Qty', align: 'center' },
  //   { id: 'time', label: 'Quotation Time', align: 'right' },
  // ]

  const TABLE_HEAD = [
    { id: 'srNo', label: 'SrNo.', align: 'left' },
    { id: 'product_id', label: 'Product Id', align: 'left' },
    { id: 'product_name', label: 'Product Name', align: 'left' },
    // { id: 'services', label: 'Services', align: 'center' },
    // { id: 'uom', label: 'Unit Of Measure', align: 'center' },
    { id: 'min_desired_qty', label: 'Min Desired Qty', align: 'center' },
    { id: 'start_price', label: 'Start Price', align: 'center' },
    { id: 'Payment_terms', label: 'Payment Terms', align: 'center' },
    { id: 'address', label: 'Address', align: 'center' },

    // { id: 'field_start_time_minutes_', label: 'Open time', align: 'center' },
    // { id: 'field_close_time_minutes_', label: 'Close time', align: 'center' },
    { id: 'actions', label: 'Actions', align: 'right' }
    // { id: 'field_start_time_minutes_', label: 'Open time', align: 'center' },
    // { id: 'field_close_time_minutes_', label: 'Close time', align: 'center' },
    // { id: 'actions', label: 'Actions', align: 'right' }
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Page sx={{ mt: 2 }}>
      <TableContainer>
        <Scrollbar style={{ minWidth: isMdDown ? 'auto' : 850 }}>
          {isMdDown ? (
            <>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const { title, rfx_id, unique_id, invitation_id, schedule, type } = row;

                  return (
                    <MobileProductCard
                      row={row}
                      index={index}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                    />
                  );
                })}
              {filteredUsers.length === 0 && <NotFound />}
            </>
          ) : (
            <Table size="small">
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const title = row.field_product_name_rfq;
                    const isItemSelected = selected.indexOf(title) !== -1;
                    const srNo = index + 1;
                    return <Row row={row} bidList={bidList} index={index} showName={showName} />;
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 58 * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <NotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </Scrollbar>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}
