import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/styles';
import { checkPropTypes } from 'prop-types';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const Bar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 5,
  paddingLeft: 17
}));

const Heading = styled('h4')(({ theme }) => ({
  marginLeft: 5,
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center'
}));

export default function FullDialog(props) {
  const { open, setOpen, headName } = props;

  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        sx={{ minHeight: 400 }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Bar>
            <Heading style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <InfoOutlinedIcon sx={{ width: 32, height: 32, mr: 1 }} />
              <div>{headName}</div>
            </Heading>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Bar>
        </AppBar>
        {props.children}
      </Dialog>
    </>
  );
}
