import { useState } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Cookies from 'js-cookie';
// material
import { styled, alpha } from '@mui/material/styles';
import {
  ClickAwayListener,
  IconButton,
  Breadcrumbs,
  Link,
  Typography,
  Box,
  useTheme
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { r } from '../../breadcrumbs';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 60;
console.log(process.env.REACT_APP_BASE_URL);

/* const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
})); */

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const breadcrumbs = useBreadcrumbs(r, { disableDefaults: true });
  const theme = useTheme();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // const prev = breadcrumbs.map(({ match }) => match.pathname);
  // console.log(breadcrumbs, 'breadcrumbs');

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onBack = () => {
    const prev = breadcrumbs.map(({ match }) => match.pathname);
    if (prev.length > 1) navigate(-1);
    // if (prev.length > 1) navigate(prev[prev.length - 2]);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {pathname !== '/dashboard' && pathname !== '/buyer/dashboard' && (
              <IconButton onClick={() => onBack()} sx={{ marginRight: 1 }}>
                <ArrowBackIcon />
              </IconButton>
            )}

            <Breadcrumbs aria-label="breadcrumb">
              {breadcrumbs.map(({ match, breadcrumb }, index) => (
                <span key={match.pathname}>
                  {breadcrumbs.length - 1 === index ? (
                    <Box sx={{ fontWeight: 700, fontSize: 16, color: theme.palette.primary.main }}>
                      {breadcrumb}
                    </Box>
                  ) : (
                    <Link
                      key={index}
                      underline="hover"
                      color="inherit"
                      style={{ cursor: 'pointer' }}
                      // onClick={() => navigate(match.pathname)}
                    >
                      {breadcrumb}
                    </Link>
                  )}
                </span>
              ))}
            </Breadcrumbs>
          </div>
        )}

        {/*  <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleClose}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide> */}
      </div>
    </ClickAwayListener>
  );
}
