import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormHelperText, Grid, Stack } from '@mui/material';
import { formContext } from '../FormContainer';

export default function CheckBoxInput({ formik }) {
  const form = React.useContext(formContext);
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;
  const { cash, card, netBanking, cheque } = values.paymentMode;

  const error = [cash, card, netBanking, cheque].filter((v) => v).length < 1;
  return (
    <FormControl
      variant="standard"
      error={touched.paymentMode && errors.paymentMode}
      helperText={touched.paymentMode && errors.paymentMode}
      component="fieldset"
      sx={{ border: '1px solid lightgrey', p: 1, borderRadius: 1 }}
    >
      <FormGroup aria-label="position" column>
        <FormLabel component="legend">Mode of payment</FormLabel>
        <Grid container>
          <Grid item sm={6}>
            <Stack direction="column">
              <FormControlLabel
                value={values.paymentMode.cash}
                control={<Checkbox {...getFieldProps('paymentMode.cash')} checked={cash} />}
                label="Cash"
                labelPlacement="end"
              />
              <FormControlLabel
                value={values.paymentMode.card}
                control={<Checkbox {...getFieldProps('paymentMode.card')} checked={card} />}
                label="Debit / Credit card"
                labelPlacement="end"
              />
            </Stack>
          </Grid>
          <Grid item sm={6}>
            <Stack direction="column">
              <FormControlLabel
                value={values.paymentMode.netBanking}
                control={
                  <Checkbox {...getFieldProps('paymentMode.netBanking')} checked={netBanking} />
                }
                label="Net banking"
                labelPlacement="end"
              />
              <FormControlLabel
                value={values.paymentMode.cheque}
                control={<Checkbox {...getFieldProps('paymentMode.cheque')} checked={cheque} />}
                label="Cheque"
                labelPlacement="end"
              />
            </Stack>
          </Grid>
        </Grid>
      </FormGroup>
      <FormHelperText> {touched.paymentMode && errors.paymentMode}</FormHelperText>
    </FormControl>
  );
}
