/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  useTheme,
  useMediaQuery,
  Box,
  Stack
} from '@mui/material';
import { BiRupee } from 'react-icons/bi';
import { useStyles } from '../../_components/tableStyles';
import { bidDetails } from '../../../../../apis/VendorApi';
import { token } from '../../../../../utils/helper';
import Loader from '../../_components/Loader';
import { fDateTimeSuffix } from '../../../../../utils/formatTime';
import NoDataFound from '../../../../../components/NoDataFound';
import { fCurrency } from '../../../../../utils/formatNumber';
import Label from '../../../../../components/Label';

const BidInfo = (props) => {
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);
  const classes = useStyles();
  const { pNid } = props;
  const rid = localStorage.getItem('rid');
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const getData = async () => {
    const d = {
      rid,
      pNid
    };
    await bidDetails(token, d)
      .then((r) => {
        // console.log(r.data.result);
        setRows(r.data.result.reverse());
        isLoading(false);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader height="300px" width={isMdDown ? '300px' : '500px'} />
      ) : (
        <>
          {isMdDown ? (
            <Box sx={{ maxHeight: 300, minHeight: 300 }}>
              {rows.map((row, index) => {
                const { bid_amt, bid_id, bid_unit_cost, bid_total_cost, bid_time, bid_rank } = row;
                const Srno = index + 1;
                const time = new Date(bid_time * 1000);

                return (
                  <Stack flexDirection="row" gap={3} sx={{ borderBottom: '.5px solid grey', p: 1 }}>
                    <Box sx={{ color: 'gray', fontSize: 13, fontWeight: '600' }}>{Srno}. </Box>
                    <Stack>
                      <Box
                        sx={{ color: theme.palette.primary.main, fontSize: 13, fontWeight: '600' }}
                      >
                        ₹ {fCurrency(bid_total_cost)}
                      </Box>
                      <Box sx={{ color: 'gray', fontSize: 10 }}>Total Cost</Box>
                    </Stack>
                    <Stack>
                      <Box
                        sx={{ color: theme.palette.primary.main, fontSize: 13, fontWeight: '600' }}
                      >
                        ₹ {fCurrency(bid_amt)}
                      </Box>
                      <Box sx={{ color: 'gray', fontSize: 10 }}>Bid Amt</Box>
                    </Stack>
                    <Stack>
                      <Label sx={{ height: 'auto', padding: 1 }} color="success">
                        <Box>{bid_rank} Rank</Box>
                      </Label>

                      <Box sx={{ color: 'gray', fontSize: 9, mt: 0.6 }}>
                        {fDateTimeSuffix(time)}
                      </Box>
                    </Stack>
                  </Stack>
                );
              })}
            </Box>
          ) : (
            <TableContainer sx={{ maxHeight: 300, minHeight: 300, minWidth: 500 }}>
              <Table stickyHeader aria-label="sticky table" className={classes.tbl}>
                {rows.length > 0 ? (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className={classes.thead}>
                          SrNo.
                        </TableCell>
                        {/* 
                    <TableCell align="left" className={classes.thead}>
                      Unit cost (in Rs)
                    </TableCell> */}
                        <TableCell align="left" className={classes.thead}>
                          Total cost (in Rs)
                        </TableCell>
                        <TableCell align="left" className={classes.thead}>
                          Bid amount
                        </TableCell>
                        <TableCell align="left" className={classes.thead}>
                          Rank
                        </TableCell>
                        <TableCell align="left" className={classes.thead}>
                          Bid time
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rows.map((row, index) => {
                        const {
                          bid_amt,
                          bid_id,
                          bid_unit_cost,
                          bid_total_cost,
                          bid_time,
                          bid_rank
                        } = row;
                        const Srno = index + 1;
                        const time = new Date(bid_time * 1000);

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            className={classes.row}
                          >
                            <TableCell>{Srno}</TableCell>
                            {/* <TableCell>
                          <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                          {fCurrency(bid_unit_cost)}
                        </TableCell> */}
                            <TableCell>
                              <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                              {fCurrency(bid_total_cost)}
                            </TableCell>
                            <TableCell>
                              <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                              {fCurrency(bid_amt)}
                            </TableCell>
                            <TableCell>{bid_rank}</TableCell>
                            <TableCell>{fDateTimeSuffix(time)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                ) : (
                  <NoDataFound height="300px" />
                )}
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  );
};

export default BidInfo;
