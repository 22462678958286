import { Box, IconButton, TableRow, Tooltip, useTheme } from '@mui/material';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { styled, makeStyles, withStyles } from '@mui/styles';
import LinesEllipsis from 'react-lines-ellipsis';
import { FiEdit, FiEdit2, FiEdit3, FiTrash2 } from 'react-icons/fi';
import { ProductContext } from '..';

const ls = require('local-storage');

const widthArr = [
  {
    name: 'med',
    ratio: 2.7,
    percentage: 30,
    mdPercentage: 30
  },
  {
    name: 'uom',
    ratio: 0.63,
    percentage: 10,
    mdPercentage: 7
  },
  {
    name: 'desiredQty',
    ratio: 0.63,
    percentage: 12,
    mdPercentage: 7
  },
  {
    name: 'startPrice',
    ratio: 0.72,
    percentage: 12,
    mdPercentage: 8
  },
  {
    name: 'payTerms',
    ratio: 0.81,
    percentage: 12,
    mdPercentage: 9
  },
  // {
  //   name: 'confPo',
  //   ratio: 0.63,
  //   percentage: 10,
  //   mdPercentage: 6
  // },
  {
    name: 'credit',
    ratio: 0.99,
    percentage: 10,
    mdPercentage: 11
  },
  {
    name: 'attachment',
    ratio: 0.99,
    percentage: 15,
    mdPercentage: 11
  }
  // {
  //   name: 'disc',
  //   ratio: 0.99,
  //   percentage: 6,
  //   mdPercentage: 11
  // },
  // {
  //   name: 'base',
  //   ratio: 0.81,
  //   percentage: 7,
  //   mdPercentage: 10
  // },

  // {
  //   name: 'gst',
  //   ratio: 1.08,
  //   percentage: 7,
  //   mdPercentage: 7
  // }
];
const useStyles = makeStyles({
  tableCell: {
    width: '100%',
    height: 25,
    fontSize: 12,
    padding: 0,
    paddingLeft: 5,
    borderBottom: '1px solid lightgrey'
  }
});

const ListRow = ({
  row,
  index,
  isMdDown,
  checkPermission,
  columnPermissions,
  handleEdit,
  handleRowsChange,
  handleRemoveMedicines,
  productItems,
  productAttachments,
  type
}) => {
  const classes = useStyles();
  const [medType, setMedType] = useState('brand');
  const [isDisabled, setIsDisabled] = useState(false);
  const theme = useTheme();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [inputRefsArray] = useState(() => Array.from({ length: 7 }, () => createRef()));

  // console.log(doctorProfileDetails)

  const handleKeyPress = (nextField) => {
    console.log(nextField, 'next');
    if (typeof nextField !== 'object') {
      const nextInput = document.querySelector(`input[id=${nextField}]`);
      nextInput.disabled = false;
      nextInput.focus();
      nextInput.select();
      setCurrentIndex((prevIndex) => {
        // calculate the next input index, next input after the final input will be again the first input. you can change the logic here as per your needs
        const nextIndex = prevIndex < 7 - 1 ? prevIndex + 1 : 0;
        return nextIndex;
      });
    }
  };

  useEffect(() => {
    if (row.label === '') {
      //
      if (productItems.length - 1 === index) {
        setIsDisabled(true);
      }
    } else {
      setMedType(row.type);
      setIsDisabled(false);
    }
  }, [row]);

  useEffect(() => {
    // focus the firs iput initially
    if (inputRefsArray[0].current) {
      inputRefsArray[0].current.focus();
    }

    // add the event listener for keyup keyboard event
    window.addEventListener('keyup', handleKeyPress, false);

    // remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, []);

  //  console.log(items, 'rowww');
  // useEffect(() => {
  //   if (items.key != '') updateMedRow(items);
  //   // setItems(row);
  // }, [items]);

  const handleOnChange = (key, value, multipleVals) => {
    console.log(key, value, multipleVals, row, 'tempDat');
    let tempData = row;
    if (multipleVals) {
      multipleVals.forEach((val) => {
        tempData[val.key] = val.value;
      });
    } else {
      tempData = { ...row, [key]: value };
    }
    // setItems(tempData);
    // updateMedRow(tempData);
    handleRowsChange(index, key, value, multipleVals);
  };

  const calculateWidth = (columnName) => {
    const arr = widthArr.filter((x) => x.name === columnName);
    if (arr.length > 0) {
      const perc = isMdDown ? arr[0].mdPercentage : arr[0].percentage;
      // console.log(calc, colCount, 'calculated width')
      const notShown = columnPermissions.filter((x) => x.val === false && x.name !== 'typeSwitch');
      let ttl = 0;
      let dividedPerc = 0;

      if (notShown.length > 0) {
        notShown.forEach((y) => {
          const item = widthArr.filter((z) => z.name === y.name);
          if (item.length > 0) {
            // console.log(item[0].percentage, 'width ttl')
            ttl += item[0].percentage;
          }
        });
        dividedPerc = ttl / (widthArr.length - notShown.length);
      } else {
        dividedPerc = 0;
      }

      dividedPerc = `${dividedPerc + perc}%`;
      // console.log(ttl, dividedPerc, notShown, widthArr, 'width ttl')
      return dividedPerc;
    }
  };

  return (
    <>
      <td
        style={{
          fontSize: 13,
          fontWeight: 600,
          color: theme.palette.secondary.main,
          paddingLeft: 7,
          paddingRight: 7
        }}
      >
        {index + 1}.
      </td>
      <td
        className={classes.tableCell}
        style={{
          borderRight: '1px solid lightgrey',

          width: calculateWidth('med') || '100%'
        }}
      >
        <Box> {row.productName}</Box>
      </td>

      {checkPermission('batch') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('uom') || 'auto'
          }}
        >
          {row.uom}
        </td>
      )}

      {checkPermission('expiry') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('expiry') || 'auto'
          }}
        >
          {row.desiredQty}
        </td>
      )}

      {type === 'rfx' && (
        <>
          {checkPermission('mrp') && (
            <td
              className={classes.tableCell}
              style={{
                width: calculateWidth('mrp') || 'auto'
              }}
            >
              {row.startPrice}
            </td>
          )}
        </>
      )}

      {checkPermission('ptr') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('ptr') || 'auto'
          }}
        >
          {row.payTerms}
        </td>
      )}

      {/* {checkPermission('qty') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('qty') || 'auto'
          }}
        >
          {row.confPo || '-'}
        </td>
      )} */}

      {checkPermission('free') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('free') || 'auto'
          }}
        >
          {row.credit || '-'}
        </td>
      )}

      {checkPermission('desc') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('free') || 'auto'
          }}
        >
          {row.desc || '-'}
        </td>
      )}

      {checkPermission('attachment') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('attachment') || 'auto'
          }}
        >
          <LinesEllipsis
            text={row.attachment.length > 0 ? row.attachment[0].file.path : 'N/A'}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        </td>
      )}

      {/* {checkPermission('disc') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('disc') || 'auto'
          }}
        >
          {row.disc}
        </td>
      )} */}

      {/* {checkPermission('base') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('base') || 'auto'
          }}
        >
          {row.base}
        </td>
      )} */}

      {/* {checkPermission('gst') && (
        <td
          className={classes.tableCell}
          style={{
            width: calculateWidth('gst') || 'auto'
          }}
        >
          {row.gst}
        </td>
      )} */}
      {/* 
      <td
        style={{
          padding: 0,
          paddingRight: 10,
          // width: 20,
          backgroundColor: theme.palette.error[100],
          textAlign: 'right',
          fontSize: 12
        }}
      >
        {formatNumber(row.total)}
      </td> */}

      <td style={{ padding: 0, width: 20 }}>
        <Tooltip title="remove" arrow>
          <IconButton onClick={() => handleRemoveMedicines(row, index)}>
            <FiTrash2 style={{ fontSize: 14, color: theme.palette.primary[300] }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit Item" arrow>
          <IconButton onClick={() => handleEdit(row, index)}>
            <FiEdit3 style={{ fontSize: 14, color: theme.palette.secondary[400] }} />
          </IconButton>
        </Tooltip>
      </td>
    </>
  );
};

export default ListRow;

// const mapStateToProps = (state) => ({
//   doctorProfileDetails: state.get('profileReducer').doctorProfileDetails,
// });

// export default connect(
//   mapStateToProps,
//   {

//   }
// )(EditableRow);
