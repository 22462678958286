import { Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserListToolbar from '../../components/UserListToolbar';
// import { rows } from './data';
import ProjectTable from './ProjectTable';

const ProjectContainer = () => {
  const [query, setQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const rows = state;

  const search = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.field_project_name.toLowerCase().includes(query.toLowerCase()) ||
        x.field_admin_contact_number.toString().includes(query.toLowerCase()) ||
        x.field_admin_email_address.toLowerCase().includes(query.toLowerCase()) ||
        x.field_admin_contact_person_name.toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };
  return (
    <Card sx={{ margin: 1, overflow: 'auto' }}>
      <UserListToolbar numSelected={0} filterName={query} onFilterName={search} />
      <ProjectTable rows={!query ? rows : filtered()} />
    </Card>
  );
};

export default ProjectContainer;
