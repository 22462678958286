import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { Field, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SelectInput from '../components/SelectInput';
import { HeadingText } from './styles';
import { token, userData } from '../../../utils/helper';
import { getBuyerCompanies } from '../../../apis/BuyerApi';
import { aPo, terms, creditOp } from './addProduct/data';
import CheckBoxInput from './_components/CheckBoxInput';

const c = [
  {
    tid: 'real Estate',
    name: 'real Estate'
  }
];

const CategoryForm = ({ mainFormik }) => {
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    handleChange,
    setFieldValue
  } = mainFormik;
  const getCompany = async () => {
    await getBuyerCompanies(token)
      .then((r) => {
        console.log(r);
        setData(r.result);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getCompany();
  }, []);

  const convert = () => {
    const a = data.map((x) => ({ tid: x.id, name: x.title }));
    console.log(a);
    setList(a);
  };
  useEffect(() => {
    convert();
  }, [data]);
  /*  const changer = (i, e) => {
    const t = values.pro;
    t[i] = e.target.value;
    t.push('');

    setFieldValue('pro', t);
  }; */

  return (
    <div>
      <Card>
        <CardContent>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} sm={12}>
              <HeadingText style={{ backgroundColor: '#fff8ce', color: '#7a4f01' }}>
                New requirement form
              </HeadingText>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={2}>
                <SelectInput
                  cat={values.company}
                  getFieldProps={getFieldProps('company')}
                  name="Company"
                  dta={list}
                  error={Boolean(touched.company && errors.company)}
                  helper={touched.company && errors.company}
                />
                <SelectInput
                  cat={values.category}
                  getFieldProps={getFieldProps('category')}
                  name="Bussiness Name"  // change name Category to Bussiness Name 
                  dta={c}
                  error={Boolean(touched.category && errors.category)}
                  helper={touched.category && errors.category}
                />
                <TextField
                  label="Requirement highlights"
                  id="outlined-multiline-static"
                  placeholder="Please Mention special conditions of this requirement, if any"
                  multiline
                  rows={3}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  inputProps={{ maxLength: 255 }}
                  fullWidth
                  {...getFieldProps('description')}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={2}>
                <CheckBoxInput formik={mainFormik} />
                <div>
                  <SelectInput
                    cat={values.payTerms}
                    name="Payment terms"
                    dta={terms}
                    getFieldProps={getFieldProps('payTerms')}
                    error={Boolean(touched.payTerms && errors.payTerms)}
                    helper={touched.payTerms && errors.payTerms}
                  />
                </div>
                {values.payTerms === 'advance payments' && (
                  <div>
                    <SelectInput
                      cat={values.confPo}
                      name="Advance on confirmation of po"
                      getFieldProps={getFieldProps('confPo')}
                      dta={aPo}
                      error={Boolean(touched.confPo && errors.confPo)}
                      helper={touched.confPo && errors.confPo}
                    />
                  </div>
                )}
                {values.payTerms === 'credit' && (
                  <div>
                    <SelectInput
                      cat={values.onCredit}
                      name="No. of days on credit"
                      getFieldProps={getFieldProps('onCredit')}
                      dta={creditOp}
                      error={Boolean(touched.onCredit && errors.onCredit)}
                      helper={touched.onCredit && errors.onCredit}
                    />
                  </div>
                )}
              </Stack>
            </Grid>

            {/*   {values.pro.map((p, i) => (
            <Grid item sm={6} key={i}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-autowidth-label">category</InputLabel>
                <Select
                  {...getFieldProps}
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={p}
                  label="category"
                  fullWidth
                  onChange={(e) => changer(i, e)}
                >
                  {c &&
                    c.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div style={{ color: 'red', fontSize: 12, marginLeft: 14 }}>
                {touched.category && errors.category}
              </div>
            </Grid>
          ))} */}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default CategoryForm;
