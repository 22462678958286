/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { BiRupee } from 'react-icons/bi';
import { token } from '../../../../../utils/helper';
import { bidDetails } from '../../../../../apis/VendorApi';
import Label from '../../../../../components/Label';
import { fCurrency } from '../../../../../utils/formatNumber';

const ShowRank = (pNid) => {
  const [rows, setRows] = useState();
  const rid = localStorage.getItem('rid');
  // console.log(pNid);
  const getData = async () => {
    const d = {
      rid,
      pNid
    };
    await bidDetails(token, d)
      .then((r) => {
        // console.log(r.data);
        setRows(r.data.result.reverse()[0]);
        console.log(r.data.result.reverse(), 'show rank', pNid);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getData();
  }, [pNid]);

  function ordinal_suffix_of(i) {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }

  return (
    <Stack direction="row" spacing={1}>
      <Label color="success">{ordinal_suffix_of(rows ? rows.bid_rank : 0)}</Label>
      <Label>
        <BiRupee />
        {fCurrency(rows && rows.bid_amt)}
      </Label>
    </Stack>
  );
};

export default ShowRank;
