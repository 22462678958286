import { getRole } from '../../../../utils/helper';

export const categoryValues = {
  company: '',
  category: '',
  description: '',
  paymentMode: {
    cash: false,
    card: false,
    netBanking: false,
    cheque: false
  },
  payTerms: '',
  confPo: '',
  onCredit: ''
};
export const productValues = {
  masterProduct: '',
  tempProduct: '',
  productName: '',
  productFile: [],
  location: '',
  targetPrice: '',
  paymentMode: {
    cash: false,
    card: false,
    netBanking: false,
    cheque: false
  },
  immediate: false,
  noOfMonths: 0,
  deliveryChart: [],
  payTerms: '',
  confPo: '',
  onCredit: '',
  deliveryTerms: '',
  totalQty: '',
  otherDeliveryTerms: '',
  desc: '',
  unitOfMeasure: ''
};

export const newProductValues = {
  category: '',
  otherCategory: '',
  description: '',
  name: '',
  unitOfMeasure: '',
  productFile: [],
  role: getRole()
};
