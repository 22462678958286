/* eslint-disable camelcase */
import { Card, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { BiRupee } from 'react-icons/bi';
import Label from '../../../../../components/Label';
import { fCurrency } from '../../../../../utils/formatNumber';

const Heading = styled('span')(({ theme }) => ({
  fontSize: 10,
  color: 'grey'
}));

const Item = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 800,
  color: theme.palette.primary.main
}));

const ItemColumn = ({ row, bidingRules }) => {
  const a = 1;
  const {
    field_product_name,
    field_state,
    field_city,
    field_payment_term_buyer_product,
    field_unit_of_measure
  } = row;
  // console.log('hello pn', row);
  return (
    <Stack direction="row" spacing={1}>
      <Stack spacing={1} sx={{ width: '100%', height: '100%' }}>
        <div>
          <Stack>
            <Item>
              {field_product_name || '-'}
              {field_unit_of_measure && ` - ${field_unit_of_measure}`}
            </Item>
            <Typography sx={{ fontSize: 10, color: 'gray' }}>Product name</Typography>
          </Stack>
        </div>

        <div>
          <Stack>
            <Item>{field_payment_term_buyer_product || '-'}</Item>
            <Typography sx={{ fontSize: 10, color: 'gray' }}>payment terms</Typography>
          </Stack>
        </div>
        <div>
          <Stack>
            <Item>
              {field_state || '-'}, {field_city || '-'}
            </Item>
            <Typography sx={{ fontSize: 10, color: 'gray' }}>Place</Typography>
          </Stack>
        </div>
      </Stack>
    </Stack>
  );
};

export default ItemColumn;
