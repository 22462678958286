/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import * as React from 'react';
import { styled } from '@mui/styles';
import Box from '@mui/material/Box';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AnimatePresence, motion } from 'framer-motion';
import { child, get, getDatabase, onValue, ref } from 'firebase/database';
import { Chip, Collapse, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useIdleTimer } from 'react-idle-timer';
import Table from '@mui/material/Table';
import { BiRupee } from 'react-icons/bi';
import TableBody from '@mui/material/TableBody';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import BidInput from './BidInput';
import { UserListHead } from '../../../../../components/_dashboard/user';
import { currentBid } from '../../../../../apis/VendorApi';
import { token } from '../../../../../utils/helper';
import CurrentBid from './CurrentBid';
import { fCurrency } from '../../../../../utils/formatNumber';
import Label from '../../../../../components/Label';
import DetailsColumn from './DetailsColumn';
import ItemColumn from './ItemColumn';
import LowestBid from './LowestBid';
import FullDialog from '../../_components/FullDialog';
import BidInfo from './BidInfo';
import './styles.css';
import { db } from '../../../../../firebase';
import StartPriceInput from './StartPriceInput';
import MobileProductBid from './MobileProductBid';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {}
}));

const Heading = styled('span')(({ theme }) => ({
  fontSize: 12,
  color: 'grey'
}));

const Item = styled('span')(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginLeft: '5px'
  // margin: '1.5px 5px'
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'Srno', label: 'SrNo.', align: 'left', maxWidth: 40 },
  { id: 'name', label: '', align: 'left', maxWidth: 150 },
  { id: 'details', label: '', align: 'left', minWidth: 220 },
  /*   { id: 'currentBid', label: 'Current bid', align: 'left', minWidth: 170 }, */
  { id: 'actions', label: 'Recent activity', align: 'right' }
];

export default function BidTable({ rows, rfxData, companyName, fetchAllBids }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [triggered, setTriggered] = React.useState(false);
  const [sendAtFirst, setSendAtFirst] = React.useState();
  const [timer, setTimer] = React.useState(20);
  const { biding_rules } = rfxData;
  const rid = localStorage.getItem('rid');

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    setData(rows);
  }, [rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filtered = stableSort(data, getComparator(order, orderBy));

  const NotFound = filtered.length === 0;

  const handleClick = () => {
    setOpen(true);
  };

  // function to update the array as per the bid submit

  /*  React.useEffect(() => {
    console.log(triggered, 'trigger');
    const arr = [];
    let latestPid;
    const starCountRef = ref(db, `bid`);
    onValue(starCountRef, (snapshot) => {
      snapshot.forEach((x) => {
        if (rid === x.val().rid) {
          arr.push(x.val());
        }
      });
      //  const low = arr.length > 0 ? arr[arr.length - 1].bid_amt : 0;
      latestPid = arr.length > 0 && arr[arr.length - 1].bid_pid;
    });

    if (triggered) {
      console.log(triggered, latestPid, 'trigger status');
      const dataArr = data;
      let index;
      let item;

      if (latestPid) {
        dataArr.forEach((x, i) => {
          if (latestPid === x.p_nid) {
            index = i;
            item = x;
          }
        });
        const splicedArr = dataArr.filter((x, i) => i !== index);
        splicedArr.unshift(item);
        setData(splicedArr);
      }
    }
  }, [db, triggered]); */

  const updateRows = () => {
    const arr = [];
    let latestPid;
    const dbRef = ref(getDatabase());

    if (triggered) {
      get(child(dbRef, 'bid'))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((x) => {
              if (rid === x.val().rid) {
                arr.push(x.val());
              }
            });
            //  const low = arr.length > 0 ? arr[arr.length - 1].bid_amt : 0;
            latestPid = arr.length > 0 && arr[arr.length - 1].bid_pid;
            console.log(triggered, latestPid, 'trigger status');
            const dataArr = data;
            let index;
            let item;

            if (latestPid) {
              setSendAtFirst(latestPid);
              dataArr.forEach((x, i) => {
                if (latestPid === x.p_nid) {
                  index = i;
                  item = x;
                }
              });
              const splicedArr = dataArr.filter((x, i) => i !== index);
              splicedArr.unshift(item);
              setData(splicedArr);
            }
          } else {
            console.log('No data available');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  React.useEffect(() => {
    const bidChecker = setInterval(() => updateRows(), 3000);

    return () => clearInterval(bidChecker);
  }, [triggered]);

  const handleOnIdle = () => {
    console.log('user is idle, trigger is true');
    setTriggered(true);
  };

  const handleOnActive = () => {
    console.log('user is active now, trigger is false', getRemainingTime());
    setTriggered(false);
  };

  const { getRemainingTime, reset, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 1,
    onIdle: handleOnIdle,
    onActive: handleOnActive
  });

  return (
    <Box sx={{ width: '100%' }}>
      <TransitionGroup>
        {isMdDown ? (
          <Box sx={{ mt: 2 }}>
            <h3 style={{ color: 'grey', marginBottom: 10, marginLeft: 10 }}>Items</h3>
            {data.map((row, index) => {
              const Srno = index + 1;

              return (
                <MobileProductBid
                  row={row}
                  bidingRules={biding_rules}
                  rfxData={rfxData}
                  companyName={companyName}
                  data={data}
                  fetchAllBids={fetchAllBids}
                  sendAtFirst={sendAtFirst}
                  index={index}
                />
              );
            })}
          </Box>
        ) : (
          <TableContainer sx={{ minWidth: 650 }}>
            <Table size="small">
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={headCells}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

                {data.map((row, index) => {
                  const { p_nid } = row;
                  const Srno = index + 1;

                  return (
                    <StyledTableRow
                      tabIndex={-1}
                      key={p_nid}
                      className={sendAtFirst === p_nid && 'element'}
                    >
                      <StyledTableCell>{Srno}</StyledTableCell>
                      <StyledTableCell>
                        <ItemColumn row={row} />
                      </StyledTableCell>
                      <StyledTableCell sx={{ borderRight: '1px rgba(241, 243, 244, 1) solid' }}>
                        <DetailsColumn row={row} bidingRules={biding_rules} rfxData={rfxData} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack spacing={2}>
                          {rfxData.type === 'rfq' ? (
                            <StartPriceInput pid={p_nid} type={rfxData.type} />
                          ) : (
                            <BidInput
                              data={row}
                              pNid={p_nid}
                              bidingRules={biding_rules}
                              companyName={companyName}
                              rows={data}
                              fetchAllBids={fetchAllBids}
                            />
                          )}
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: 53 * emptyRows
                    }}
                  >
                    <StyledTableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>

              {NotFound && (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      Not found
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}

        {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      </TransitionGroup>
    </Box>
  );
}
