/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useLocation, useParams } from 'react-router-dom';

import { UserListToolbar } from '../../../../components/_dashboard/user';
import { fDateTimeSuffix } from '../../../../utils/formatTime';
// import ItemTable from './ItemTable';
import Label from '../../../../components/Label';
import { bidDetails, getProductDetails } from '../../../../apis/VendorApi';
import { token } from '../../../../utils/helper';
import SmallSnackbar from '../../../../components/SnackBar';

import Loader from '../../../../components/Loader';
import ReportProductToolbar from './BidToolbar';
import BidTable from './BidTable';
import { columns } from './data';

const styles = (theme) => ({
  acceptance: {
    backgroundColor: 'blue',
    // Match [xs, md) and [md + 1, ∞)
    //       [xs, md) and [lg, ∞)
    //       [0px, 900px) and [1200px, ∞)
    [theme.breakpoints.down('md')]: {
      align: 'center'
    }
  }
});

const ProductContainer = (props) => {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState();
  const [res, setRes] = useState();
  const [loading, isLoading] = useState(true);
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const rid = localStorage.getItem('reportRid');
  // const pNid = localStorage.getItem('reportPid');
  const { state } = useLocation();
  console.log(state);
  const search = (event) => {
    setQuery(event.target.value);
  };

  const getData = async () => {
    const d = {
      rid,
      pNid: state.row.p_nid
    };
    await bidDetails(token, d)
      .then((r) => {
        // console.log(r.data);
        setRows(r.data.result.reverse());
        isLoading(false);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter((x) => x.bid_rank.toString().includes(query));
    return r;
  };

  const csvRows = () => {
    const data = rows;
    // console.log(data, 'from repor');
    const finalRows = [];
    data.forEach((x, index) => {
      const srNo = index + 1;
      const bid_time = fDateTimeSuffix(x.bid_time * 1000);
      const temp = { ...x, bid_time, srNo };
      finalRows.push(temp);
    });
    return finalRows;
  };

  const csvReport = {
    data: csvRows(),
    headers: columns.map((x) => ({ label: x.label, key: x.key })),
    filename: 'bidReports.csv'
  };

  return (
    <>
      {loading ? (
        <Loader height="75vh" />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Stack direction="row" spacing={1}>
                <Label sx={{ height: 'auto', padding: 1 }} color="primary">
                  <Stack>
                    <Typography sx={{ fontSize: 11, color: 'gray' }}>Product name</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 800 }}>
                      {state.row.field_product_name || '-'}
                    </Typography>
                  </Stack>
                </Label>
                <Label sx={{ height: 'auto', padding: 1 }} color="info">
                  <Stack>
                    <Typography sx={{ fontSize: 11, color: 'gray' }}>Product id</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 800 }}>
                      {state.row.product_id || '-'}
                    </Typography>
                  </Stack>
                </Label>
              </Stack>
            </Grid>

            <Grid item sm={12}>
              <Card>
                <ReportProductToolbar
                  numSelected={0}
                  filterName={query}
                  onFilterName={search}
                  csvReport={csvReport}
                />
                <BidTable rows={!query ? rows : filtered()} setOpen={setOpen} setItem={setItem} />
              </Card>
            </Grid>
          </Grid>
        </>
      )}
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </>
  );
};

export default ProductContainer;
