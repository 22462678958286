/* eslint-disable camelcase */
import * as React from 'react';
import { CircularProgress, Divider, Fade, Grid, Paper, Skeleton, styled } from '@mui/material';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20vh'
}));

export default function OfficeTableSkeleton() {
  return (
    <Grid container spacing={5}>
      <Grid item sm={12} xs={12}>
        <h2 style={{ padding: '.5rem', color: 'grey' }}>Office details</h2>
        <Divider />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Paper elevation={3} sx={{ width: '100%', overflow: 'auto' }}>
          <Loader>
            <CircularProgress color="primary" />
          </Loader>
        </Paper>
      </Grid>
    </Grid>
  );
}
