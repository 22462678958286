import { AddCircleTwoTone, Backup, Close, CloudUpload } from '@material-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import FullDialog from '../../../components/_dashboard/app/FullDialog';
import { createNewVendor, getCategory, uploadVendor } from '../../../apis/BuyerApi';
import { decodedJwt, serv, userData } from '../../../utils/helper';

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 150,
      maxWidth: 170
    },
    drip: {
      minHeight: '130px',
      maxHeight: '130px',
      color: 'grey',
      backgroundColor: '#FFF7CD',

      fontSize: 14
    }
  })
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const AddVendor = ({ categories, getList }) => {
  const initalCatState = {
    cat: '',
    subCat: ''
  };
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedCat, setSelectedCat] = useState(initalCatState);
  const [excelFile, setExcelFile] = useState();

  const initalState = {
    vendorName: '',
    mobile: '',
    categories: [],
    services: {
      service: false,
      serviceWithMaterial: false,
      materialOnly: false
    },
    secondOwner: '',
    email: '',
    address: ''
  };

  const formik = useFormik({
    initialValues: initalState,
    validationSchema: Yup.object().shape({
      vendorName: Yup.string().nullable().required('product name is required'),
      mobile: Yup.number()
        .typeError('you must specify a number')
        .required('Mobile Number is required'),
      categories: Yup.array().min(1, 'Select atleast one category').required('Category is required')
    }),
    onSubmit: async () => {
      setSubmitting(true);

      let newServ = '';
      if (values.services) {
        const keys = Object.keys(values.services);
        const filteredServices = keys.filter((key) => values.services[key]);
        newServ = filteredServices.map((y) => serv.find((x) => x.key === y).value);
      }

      await createNewVendor({
        field_services: newServ ? newServ.join(',') : '',
        field_category: values.categories.map((x) => x.cat).join(','),
        name: values.vendorName,
        field_add_another_partner: values.secondOwner,
        field_mobile_number_buyer: values.mobile,
        field_email_id: values.email,
        field_owner_address: values.address
      }).then((res) => {
        if (res.status) {
          getList();
          enqueueSnackbar('Venodor Added Successfully!', {
            variant: 'success'
          });
          handleClose();
        }
      });
      setSubmitting(false);
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    setFieldError,
    setSubmitting,
    resetForm
  } = formik;

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleCatChange = (key, value) => {
    setSelectedCat({
      ...selectedCat,
      [key]: value
    });
  };

  const handleAddCategory = () => {
    if (selectedCat.cat === '') {
      setFieldError('categories', 'Please select category ');
    } else {
      setFieldValue('categories', [...values.categories, selectedCat]);
      setSelectedCat(initalCatState);
    }
  };

  const fileReject = () => {
    // setSnack({
    //   open: true,
    //   color: 'error',
    //   message: `Only pdf and image file is allowed`
    // });
  };

  const handleBulkUpload = async () => {
    console.log(excelFile);
    if (excelFile) {
      const formdata = new FormData();
      formdata.append('role', 'buyer');
      formdata.append('uid', decodedJwt.uid);
      formdata.append('csv_file', excelFile, 'test - Sheet1.csv');

      await uploadVendor(formdata)
        .then((res) => {
          console.log(res, 'ressssssssss');
          if (res.data.status === true) {
            enqueueSnackbar('Bulk Vendor Uploaded Successfully!', {
              variant: 'success'
            });
            getList();
            setExcelFile([]);
            handleClose();
          }
        })
        .catch((e) => console.log(e));
    } else {
      enqueueSnackbar('Please Upload the csv file', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)} size="small">
        <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
        Add Vendor
      </Button>
      <FullDialog
        open={open}
        setOpen={setOpen}
        headName="Add Vendor"
        subHead="Field marked as * are required"
        maxWidth="lg"
        minWidth="lg"
        onClose={handleClose}
      >
        <Box p={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Vendor Name"
                    placeholder=""
                    size="small"
                    required
                    {...getFieldProps('vendorName')}
                    error={Boolean(touched.vendorName && errors.vendorName)}
                    helperText={touched.vendorName && errors.vendorName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Second Owner Name"
                    placeholder=""
                    size="small"
                    // required
                    {...getFieldProps('secondOwner')}
                    error={Boolean(touched.secondOwner && errors.secondOwner)}
                    helperText={touched.secondOwner && errors.secondOwner}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Vendor Mobile Number"
                    placeholder=""
                    size="small"
                    type="number"
                    {...getFieldProps('mobile')}
                    error={Boolean(touched.mobile && errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email ID"
                    placeholder=""
                    size="small"
                    type="email"
                    // required
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Owner Residence Address"
                    placeholder=""
                    size="small"
                    // type="email"
                    multiline
                    rows={5}
                    // required
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="standard"
                    error={touched.services && errors.services}
                    helperText={touched.services && errors.services}
                    component="fieldset"
                    sx={{ border: '1px solid lightgrey', p: 1, borderRadius: 1 }}
                    size="small"
                  >
                    <FormGroup aria-label="position" column>
                      <FormLabel component="legend">Services</FormLabel>
                      <Stack direction="row" flexWrap="wrap">
                        <FormControlLabel
                          value={values.services.service}
                          control={
                            <Checkbox
                              {...getFieldProps('services.service')}
                              checked={values.services.service}
                              size="small"
                            />
                          }
                          label={<Typography sx={{ fontSize: '0.9rem' }}>Service</Typography>}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value={values.services.serviceWithMaterial}
                          control={
                            <Checkbox
                              {...getFieldProps('services.serviceWithMaterial')}
                              checked={values.services.serviceWithMaterial}
                              size="small"
                            />
                          }
                          label={
                            <Typography sx={{ fontSize: '0.9rem' }}>
                              Service With Material
                            </Typography>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value={values.services.materialOnly}
                          control={
                            <Checkbox
                              {...getFieldProps('services.materialOnly')}
                              checked={values.services.materialOnly}
                              size="small"
                            />
                          }
                          label={<Typography sx={{ fontSize: '0.9rem' }}>Material Only</Typography>}
                          labelPlacement="end"
                        />
                      </Stack>
                    </FormGroup>
                    <FormHelperText> {touched.services && errors.services}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack
                    flexDirection="column"
                    gap={1}
                    sx={{ border: '1px solid lightgrey', p: 1, borderRadius: 1 }}
                  >
                    <small style={{ marginBottom: 10 }}>Select Vendor's Product Category </small>
                    {values.categories.map((x, index) => (
                      <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        sx={{ borderBottom: '1px solid lightgrey' }}
                      >
                        <Box sx={{ width: '100%' }}>
                          <span style={{ color: 'grey', fontSize: 12, marginRight: 10 }}>
                            {' '}
                            {index + 1}.
                          </span>

                          <span
                            style={{
                              fontWeight: '600',
                              color: theme.palette.primary.main,
                              fontSize: 14
                            }}
                          >
                            {categories.find((y) => y.tid === x.cat).name}
                          </span>
                        </Box>

                        <Box>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setFieldValue(
                                'categories',
                                values.categories.filter((y, i) => i !== index)
                              )
                            }
                          >
                            <Close />
                          </IconButton>
                        </Box>
                      </Stack>
                    ))}

                    <FormControl
                      fullWidth
                      size="small"
                      // error={touched.category && errors.category}
                      // helperText={touched.category && errors.category}
                      required
                    >
                      <InputLabel>Select Category</InputLabel>
                      <Select
                        value={selectedCat.cat}
                        onChange={(e) => handleCatChange('cat', e.target.value)}
                        label="Select Category"
                        fullWidth
                      >
                        {categories.map((x) => (
                          <MenuItem value={x.tid}>{x.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* <FormControl
                  fullWidth
                  size="small"
                  // sx={{ mt: 2 }}
                  // error={touched.subCategory && errors.subCategory}
                  // helperText={touched.subCategory && errors.subCategory}
                  required
                >
                  <InputLabel>Select Sub Category</InputLabel>
                  <Select
                    value={selectedCat.subCat}
                    onChange={(e) => handleCatChange('subCat', e.target.value)}
                    label="Select Sub Category"
                    fullWidth
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl> */}
                    <Button variant="outlined" onClick={() => handleAddCategory()}>
                      Add
                    </Button>
                  </Stack>

                  {touched.categories && errors.categories && (
                    <FormHelperText error>{errors.categories}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack flexDirection="row" justifyContent="flex-start">
                    <LoadingButton
                      variant="contained"
                      onClick={() => handleSubmit()}
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1}>
              <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                <h1>OR</h1>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                {/* <DropzoneAreaBase
                  fileObjects={excelFile}
                  showAlerts={false}
                  showPreviews
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  // acceptedFiles={['csv']}
                  // getFileAddedMessage={fileAdded}
                  // getFileRemovedMessage={fileRemove}
                  getDropRejectMessage={fileReject}
                  Icon={Backup}
                  dropzoneText="Drag and drop excel file"
                  onChange={(files) => console.log('Files:', files)}
                  onAdd={(newFileObjs) => {
                    setExcelFile(newFileObjs);
                  }}
                  onDelete={(newFileObjs) => {
                    setExcelFile(newFileObjs);
                  }}
                  filesLimit={1}
                  dropzoneClass={classes.drip}
                /> */}
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  {excelFile ? excelFile.name : 'Upload CSV file'}

                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => setExcelFile(e.target.files[0])}
                    // accept="image/jpeg,image/png,image/jpg,application/pdf"
                  />
                </Button>
                <Button variant="contained" sx={{ mt: 10 }} onClick={() => handleBulkUpload()}>
                  Bulk Upload
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </FullDialog>
    </>
  );
};

export default AddVendor;
