import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx, Width }) {
  return <Box component="img" src="/static/Nirmaanprocuralogo.jpg" sx={{ width: Width, ...sx }} />;
}
