import { Box, Button, Card, Grid, Stack, useTheme } from '@mui/material';
import React from 'react';
import Label from '../../../../../components/Label';
import { fDateTimeSuffix } from '../../../../../utils/formatTime';

import BasicMenu from '../BasicMenu';

const MobileCard = ({
  row,
  selected,
  index,
  menu,
  rid,
  invitationId,
  lotName,
  pending,
  upcoming,
  active,
  participated,
  handleSumm
}) => {
  const { title, schedule, type } = row;
  const openTime = new Date(schedule[0].open_date * 1000);
  const closeTime = new Date(schedule[0].close_date * 1000);
  const isItemSelected = selected.indexOf(title) !== -1;
  const srNo = index + 1;
  const theme = useTheme();
  return (
    <Card>
      <Grid container p={1}>
        <Grid item xs={12}>
          <Stack flexDirection="row" justifyContent="space-between">
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <Box sx={{ color: 'grey', fontSize: 12 }}>{srNo}.</Box>
              <Box sx={{ color: theme.palette.primary.main, fontWeight: '600' }}>{title}</Box>
              <Label variant="ghost" color="default" style={{ fontSize: 12, padding: 12 }}>
                {type.toUpperCase()}
              </Label>
            </Stack>
            <BasicMenu
              menu={menu}
              rid={rid}
              invitationId={invitationId}
              lotName={lotName}
              pending={pending}
              upcoming={upcoming}
              active={active}
              participated={participated}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack flexDirection="row" gap={1} sx={{ ml: 2.2 }}>
            <Box sx={{ color: 'grey', fontSize: 12, ml: 1 }}>{row.unique_id}</Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack justifyContent="flex-end">
            <Label variant="ghost" color="success" style={{ fontSize: 12, padding: 12 }}>
              {fDateTimeSuffix(openTime)}
            </Label>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ ml: 2.2 }}>
            <Button
              size="small"
              onClick={() => handleSumm(row)}
              sx={{ textDecoration: 'underline', color: theme.palette.secondary.main }}
            >
              {/* <VisibilityOutlinedIcon /> &nbsp; */} View Summary
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Stack justifyContent="flex-end">
            <Label variant="ghost" color="error" style={{ fontSize: 12, padding: 12 }}>
              {fDateTimeSuffix(closeTime)}
            </Label>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MobileCard;
