/* eslint-disable camelcase */
import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export async function getVendorDetails(token) {
  const dta = JSON.stringify({});

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-vendor-details`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config)
    .then((response) => {
      console.log('api/get-vendor-details');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-vendor-details');
      return error;
    });
}

export async function getProductDetails(token, tid) {
  const dta = JSON.stringify({
    role: 'vendor',
    target_id: tid
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-product-details-tid`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  const d = await axios(config)
    .then((response) => {
      console.log('api/get-product-details-tid');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'api/get-product-details-tid');
      return error;
    });

  return d;
}

export async function getInvitations(token) {
  const dta = JSON.stringify({
    role: 'vendor'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/vendor-invitation`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function getRfxDetails(token, rfx_id) {
  const dta = JSON.stringify({
    role: 'vendor',
    rfx_id
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-rfx-by-nid`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function handleInvitation(token, invitation_id, action_type) {
  const dta = JSON.stringify({
    role: 'vendor',
    invitation_id,
    action_type
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/invitation-reply-from-vendor`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function getUpcoming(token) {
  const dta = JSON.stringify({
    role: 'vendor'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-upcoming-rfx`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function getParticipated(token) {
  const dta = JSON.stringify({
    role: 'vendor'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-participated-rfx`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function getActiveRfx(token) {
  const dta = JSON.stringify({
    role: 'vendor'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-active-rfx`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function getActiveRfxDetails(token, rfx_id) {
  const dta = JSON.stringify({
    role: 'vendor',
    rfx_id
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-active-rfx-by-id`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function bidSubmit(token, data) {
  const dta = JSON.stringify({
    role: 'vendor',
    bid_rfx_id: data.rid,
    bid_pid: data.bid_pid,
    bid_amt: data.bid_amt,
    bid_unit_cost: data.bid_unit_cost,
    bid_total_cost: data.bid_total_cost,
    company_id: data.company_id
  });
  // console.log(dta);
  const config = {
    method: 'post',
    url: `${serverUrl}api/submit-bid`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function bidDetails(token, data) {
  const dta = JSON.stringify({
    role: 'vendor',
    bid_rfx_id: data.rid,
    bid_pid: data.pNid
  });
  // console.log(dta);
  const config = {
    method: 'post',
    url: `${serverUrl}api/get-bid-details`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function currentBid(token, data) {
  const dta = JSON.stringify({
    role: 'vendor',
    bid_rfx_id: data.rid,
    bid_pid: data.pNid
  });
  // console.log(dta);
  const config = {
    method: 'post',
    url: `${serverUrl}api/get-current-bid-and-rank`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function lowestBid(token, data) {
  const dta = JSON.stringify({
    role: 'vendor',
    bid_rfx_id: data.rid,
    bid_pid: data.pNid
  });
  // console.log(dta);
  const config = {
    method: 'post',
    url: `${serverUrl}api/check-current-bid-lowest-price`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function getBankDetails(token, tid) {
  const dta = JSON.stringify({
    role: 'vendor',
    target_id: tid
  });
  // console.log(dta);
  const config = {
    method: 'post',
    url: `${serverUrl}api/get-bank-details`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function getTempProductDetails(token, tid) {
  const dta = JSON.stringify({
    role: 'vendor',
    target_id: tid
  });
  // console.log(tid);
  const config = {
    method: 'post',
    url: `${serverUrl}api/get-product-temp-details-tid`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function submitStartPrice(token, data) {
  const dta = JSON.stringify({
    role: 'vendor',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/rfx-product-start-price`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function checkStartPrice(token, data) {
  const dta = JSON.stringify({
    role: 'vendor',
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/check-product-start-price`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function getCompanyName(token) {
  const dta = JSON.stringify({
    role: 'vendor'
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-company-name`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function checkCompany(token, bid_rfx_id) {
  const dta = JSON.stringify({
    role: 'vendor',
    bid_rfx_id
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/check-company-for-bid`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function checkAnyBid(token, bid_rfx_id) {
  const dta = JSON.stringify({
    bid_rfx_id
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/bid-count`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function updateAuctionCloseTime(token, data) {
  const dta = JSON.stringify({
    ...data
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/incremental-closing-time-update`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function getClosingTime(token, rfx_id) {
  const dta = JSON.stringify({
    role: 'vendor',
    rfx_id
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/get-closing-time-for-bid`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}
