/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserListToolbar } from '../../../../../components/_dashboard/user';
import FullDialog from '../../_components/FullDialog';
import HeaderInfo from '../../_components/HeaderInfo';
// import ItemTable from './ItemTable';
import Label from '../../../../../components/Label';
import {
  getProductDetails,
  getTempProductDetails,
  getRfxDetails
} from '../../../../../apis/VendorApi';

import { token } from '../../../../../utils/helper';

import SmallSnackbar from '../../../../../components/SnackBar';
import ProductsTable from './ProductsTable';
import ProductDetails from './ProductDetails';
import Loader from '../../_components/Loader';
import Page from '../../../../../components/Page';

const ViewAuction = () => {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState();
  const [res, setRes] = useState();
  const [loading, isLoading] = useState(true);
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const rid = localStorage.getItem('rid');
  const search = (event) => {
    setQuery(event.target.value);
  };

  const fetchMasterProducts = async (token, tid) => {
    const d = await getProductDetails(token, tid);
    return d;
  };

  const fetchTempProducts = async (token, tid) => {
    const d = await getTempProductDetails(token, tid);
    return d;
  };

  const getRestProduct = (data) => {
    const temp = [];
    console.log('praven', data);
    data.map((x) =>
      x.field_product_temp_
        ? fetchTempProducts(token, x.field_product_temp_)
          .then((r) => {
            const b = { ...x, ...r.data.result };
            temp.push(b);
            setRows([...temp].reverse());
            // console.log(r, 'from temp product');
            // console.log(r.result);
          })
          .catch((e) => console.log(e.message, 'at fetching tid'))
        : fetchMasterProducts(token, x.field_product_master_)
          .then((r) => {
            const b = { ...x, ...r.result };
            temp.push(b);
            setRows([...temp].reverse());
            // console.log(r.result);
          })
          .catch((e) => console.log(e.message, 'at fetching tid'))
    );
  };

  const getRfx = async (id) => {
    await getRfxDetails(token, id)
      .then((r) => {
        setRes(...r.data.result);
        getRestProduct(r.data.result[0].product);
        console.log(r.data.result[0], 'rfx by nid');
        isLoading(false);
      })
      .catch((e) => console.log(e.message, 'get rfx'));
  };

  useEffect(() => {
    getRfx(rid);
    if (state) {
      getRfx(state.rid);
    }
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.name.toLowerCase().includes(query.toLowerCase()) ||
        /*  x.field_lot.name.toLowerCase().includes(query.toLowerCase()) || */
        x.field_product_id.toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  return (
    <Page title="Rfq Details">
      {loading ? (
        <Loader height="75vh" />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <HeaderInfo data={res} participated />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
              <Stack direction="row" spacing={1}>
                <Label sx={{ height: 'auto', padding: 1 }} color="primary">
                  <Stack>
                    <Typography sx={{ fontSize: 11, color: 'gray' }}>Rfq name</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 800 }}>{res.title}</Typography>
                  </Stack>
                </Label>
                <Label sx={{ height: 'auto', padding: 1 }} color="info">
                  <Stack>
                    <Typography sx={{ fontSize: 11, color: 'gray' }}>Unique id</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 800 }}>{res.rfx_id}</Typography>
                  </Stack>
                </Label>
              </Stack>
            </Grid>
            <Grid item sm={12}>
              <Card>
                <UserListToolbar numSelected={0} filterName={query} onFilterName={search} />
                <ProductsTable
                  rows={!query ? rows : filtered()}
                  setOpen={setOpen}
                  setItem={setItem}
                />
              </Card>
            </Grid>
          </Grid>
          <FullDialog open={open} setOpen={setOpen} headName="Product details">
            <ProductDetails data={item} />
          </FullDialog>
        </>
      )}
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </Page>
  );
};

export default ViewAuction;
