import { Navigate } from 'react-router-dom';
import { RequirementList } from '../pages/buyer/requirementList';
import DashboardLayout from '../layouts/dashboard';
import BuyerDashboardApp from '../pages/BuyerDashboardApp';
import { ProductDetails } from '../pages/buyer/productDetails';
import { NewReqForm } from '../pages/buyer/newRequirementForm';
import { BuyerProfile } from '../pages/buyer/profile';
import { Projects } from '../pages/buyer/profile/projectsTable';
import { ReqReport } from '../pages/buyer/reqReports';
import { Notifications } from '../pages/notifications';
import { ProductReports } from '../pages/buyer/reqReports/productReports';
import ContactUs from '../pages/ContactUs';
import { ReqForm2 } from '../pages/buyer/reqForm2.0';
import Rfq from '../pages/buyer/rfq';
import Products from '../pages/buyer/rfq/newRfq/product';
import AddEditProductForm from '../pages/buyer/rfq/newRfq/product/AddEditProductForm';
import ViewQuotation from '../pages/buyer/rfq/view-quotation';
import AddToRfx from '../pages/buyer/rfq/add-to-rfx';
import Rfx from '../pages/buyer/rfx';
import RfxProducts from '../pages/buyer/rfx/newRfx/product';
import RfxAddEditProductForm from '../pages/buyer/rfx/newRfx/product/AddEditProductForm';
import RfxViewQuotation from '../pages/buyer/rfx/view-quotation';
import OnboardVendor from '../pages/buyer/onboardVendor';
import YourVendors from '../pages/buyer/yourVendors';
import AddPage from '../pages/buyer/rfq/newRfq/product/add';
import ComingSoon from '../pages/ComingSoon';
import VendorInvitation from '../pages/buyer/commonPages/vendorInvitation';

export const buyerRoutes = {
  path: '/',
  element: <DashboardLayout />,
  children: [
    { element: <Navigate to="/dashboard" replace />, index: true },
    { path: 'dashboard', element: <BuyerDashboardApp /> },
    { path: 'requirementList', element: <RequirementList /> },
    { path: 'profile', element: <BuyerProfile /> },
    { path: 'contactus', element: <ContactUs /> },
    // { path: 'reports', element: <ReqReport /> },
    { path: 'reports', element: <ComingSoon /> },
    { path: 'reports/products', element: <ProductReports /> },
    { path: 'notifications', element: <Notifications /> },
    { path: 'profile/projects', element: <Projects /> },
    { path: 'rfq', element: <Rfq /> },
    { path: 'rfq/products', element: <Products /> },
    { path: 'rfq/products/add', element: <AddPage /> },
    { path: 'rfq/products/edit', element: <AddEditProductForm /> },
    { path: 'rfq/view-quotation', element: <ViewQuotation /> },
    { path: 'rfq/vendor-invitation', element: <VendorInvitation /> },
    { path: 'rfq/add-to-rfx', element: <AddToRfx /> },
    { path: 'rfx', element: <Rfx /> },
    { path: 'rfx/products', element: <Products /> },
    { path: 'rfx/products/add', element: <AddPage /> },
    { path: 'rfx/products/edit', element: <AddEditProductForm /> },
    { path: 'rfx/vendor-invitation', element: <VendorInvitation /> },
    { path: 'rfx/view-bids', element: <RfxViewQuotation /> },
    { path: 'your-vendors', element: <YourVendors /> },

    // { path: 'rfq/newrfq', element: <AddRfq /> },

    /* {
      path: 'requirementList/new',
      element: <NewReqForm />
    }, */
    {
      path: 'requirementList/new',
      element: <ReqForm2 />
    },
    { path: 'requirementList/details', element: <ProductDetails /> }
  ]
};
