import { Card, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/styles';
import UserListToolbar from '../components/UserListToolbar';
import { rows } from './data';
import ReqTable from './ReqTable';
import { getBuyerRequirementList } from '../../../apis/BuyerApi';
import { token } from '../../../utils/helper';
import Page from '../../../components/Page';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
  color: theme.palette.primary.main
}));

const ReqContainer = () => {
  const [searchParams] = useSearchParams();
  const flag = searchParams.get('flag') || '';
  const [query, setQuery] = useState(flag);
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);
  localStorage.removeItem('reqId');
  localStorage.removeItem('nid');
  localStorage.removeItem('companyName');

  // console.log(flag);
  const search = (event) => {
    setQuery(event.target.value);
  };

  const getList = async () => {
    await getBuyerRequirementList(token).then((r) => {
      // console.log(r.result);
      setRows(r.result.reverse());
      isLoading(false);
    });
  };
  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.requirement_id.toLowerCase().includes(query.toLowerCase()) ||
        x.field_sector.toString().toLowerCase().includes(query.toLowerCase()) ||
        x.field_process.toString().toLowerCase().includes(query.toLowerCase()) ||
        x.field_company.toString().toLowerCase().includes(query.toLowerCase()) ||
        x.field_verification_type.toString().toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  return (
    <Page title="Requirement list">
      {loading ? (
        <Loader>
          <CircularProgress />
        </Loader>
      ) : (
        <Card>
          <UserListToolbar numSelected={0} filterName={query} onFilterName={search} list />
          <ReqTable rows={!query ? rows : filtered()} getList={getList} />
        </Card>
      )}
    </Page>
  );
};

export default ReqContainer;
