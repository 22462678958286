import { Card, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import UserListToolbar from './UserListToolbar';
// import { rows } from './data';
import ProductTable from './ProductTable';
import { getProductDetails } from '../../../../apis/VendorApi';
import { token } from '../../../../utils/helper';
import Page from '../../../../components/Page';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh'
}));

const ProductContainer = () => {
  const [query, setQuery] = useState('');
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState({});
  const [loading, isLoading] = useState(true);
  const location = useLocation();
  const { state } = location;
  const data = state.products;
  // console.log(data);

  const fetchProducts = async (token, tid) => {
    const d = await getProductDetails(token, tid);
    return d;
  };

  const getProducts = async () => {
    const temp = [];
    if (data.length === 0) {
      isLoading(false);
    }
    // console.log(data[0].field_product_master_, 'product container');
    data.map((x, i) =>
      fetchProducts(token, x.field_product_master_).then((r) => {
        // console.log(r.result, 'product master');
        const b = { ...x, ...r.result };
        temp.push(b);
        // setItem({ ...x, ...r.result });
        setRows([...temp]);
        isLoading(false);
        // console.log(rows);
      })
    );
  };

  useEffect(() => {
    getProducts();
  }, []);

  const search = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.name.toLowerCase().includes(query.toLowerCase()) ||
        x.field_product_id.toString().toLowerCase().includes(query.toLowerCase()) ||
        x.field_place_of_supply.toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };
  return (
    <Page title="Products">
      {loading ? (
        <Loader>
          <CircularProgress color="primary" />
        </Loader>
      ) : (
        <Card sx={{ margin: 1, overflow: 'auto' }}>
          <UserListToolbar numSelected={0} filterName={query} onFilterName={search} />
          <ProductTable rows={!query ? rows : filtered()} />
        </Card>
      )}
    </Page>
  );
};

export default ProductContainer;
