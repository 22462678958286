import React, { useState, useEffect } from 'react';
import Loader from '../_components/Loader';
import { Rfx } from '../_components/rfxTable';
import { getParticipated } from '../../../../apis/VendorApi';
import { token } from '../../../../utils/helper';
import Page from '../../../../components/Page';

const ParticipatedAuction = () => {
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);

  const getList = async () => {
    await getParticipated(token)
      .then((r) => {
        setRows(r.data.result.reverse());
        isLoading(false);
        // console.log(r.data);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Page title="Participated Rfq">
      {loading ? (
        <Loader height="75vh" />
      ) : (
        <Rfx
          rows={rows}
          menu={[{ text: 'view participated rfq', goTo: `/myauction/participated` }]}
          participated
        />
      )}
    </Page>
  );
};

export default ParticipatedAuction;
