import React, { useEffect, useState } from 'react';
import Loader from '../_components/Loader';
import { getActiveRfx } from '../../../../apis/VendorApi';
import { token } from '../../../../utils/helper';
import { Rfx } from '../_components/rfxTable';
import Page from '../../../../components/Page';

const ActiveAuction = () => {
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);

  const getList = async () => {
    await getActiveRfx(token)
      .then((r) => {
        setRows(r.data.result.reverse());
        isLoading(false);
        // console.log(r.data.result, 'active rfx');
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Page title="Active Rfq">
      {loading ? (
        <Loader height="75vh" />
      ) : (
        <Rfx rows={rows} menu={[{ text: 'View and Bid', goTo: `/myauction/active` }]} active />
      )}
    </Page>
  );
};

export default ActiveAuction;
