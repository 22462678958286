import { FormControl, InputLabel, MenuItem, Select, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import { rfqStatus } from '../../../../utils/helper';
import { updateInvitationStatus } from '../../../../apis/BuyerApi';

const InvitationStatus = ({ row }) => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setValue(row.field_status);
  }, [row]);

  const handleChange = async (newVal) => {
    setValue(newVal);

    await updateInvitationStatus({
      nid: row.nid,
      status_id: newVal
    })
      .then((res) => {
        if (res.data.status) {
          enqueueSnackbar('Invitation status updated Successfully!', {
            variant: 'success'
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <FormControl
        fullWidth
        size="small"
        //   error={touched.serviceType && errors.serviceType}
        //   helperText={touched.serviceType && errors.serviceType}
        sx={{ width: isMdDown ? '100%' : 300 }}
        required
      >
        <InputLabel>Invitation Status</InputLabel>
        <Select
          value={value}
          label="Invitation Status"
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
        >
          {rfqStatus.map((x) => (
            <MenuItem value={x.id}>{x.label}</MenuItem>
          ))}
        </Select>
        {/* {touched.serviceType && <FormHelperText>{errors.serviceType}</FormHelperText>} */}
      </FormControl>
    </div>
  );
};

export default InvitationStatus;
