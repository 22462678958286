import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField
} from '@mui/material';
import React, { useContext } from 'react';
import { formContext } from '../../FormContainer';
import { Helper, LabelText } from '../../styles';
import SelectInput from '../../../components/SelectInput';
import { m, pro } from '../data';
import DeliveryTable from '../../_components/DeliveryTable';
import DateInput from '../../_components/DateInput';

const Timeline = ({ timelineFormik, chartError }) => {
  const a = 1;
  const form = useContext(formContext);
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    form.productFormik;

  const onDelete = async (i) => {
    const d = values.deliveryChart;
    await d.splice(i, 1);
    setFieldValue('deliveryChart', d);
  };

  return (
    <>
      <LabelText>Requirement timeline</LabelText>
      <FormControl>
        <FormControlLabel
          value="end"
          control={<Checkbox checked={values.immediate} {...getFieldProps('immediate')} />}
          label="Immediate"
          labelPlacement="end"
        />
      </FormControl>
      {values.immediate && (
        <TextField
          fullWidth
          type="text"
          label="Total quantity"
          {...getFieldProps('totalQty')}
          error={Boolean(touched.totalQty && errors.totalQty)}
          helperText={touched.totalQty && errors.totalQty}
        />
      )}
      {!values.immediate && (
        <>
          <SelectInput
            cat={values.noOfMonths}
            name="Number of months"
            getFieldProps={getFieldProps('noOfMonths')}
            dta={m}
          />
          {values.deliveryChart.length > 0 && (
            <DeliveryTable months={values.noOfMonths} onDelete={onDelete} />
          )}

          {values.deliveryChart.length < values.noOfMonths && (
            <>
              <Stack spacing={2} direction="row">
                <DateInput
                  setFieldValue={timelineFormik.setFieldValue}
                  error={Boolean(timelineFormik.touched.date && timelineFormik.errors.date)}
                  helper={timelineFormik.touched.date && timelineFormik.errors.date}
                />
                <TextField
                  type="text"
                  label="Quantity"
                  {...timelineFormik.getFieldProps('qty')}
                  error={Boolean(timelineFormik.touched.qty && timelineFormik.errors.qty)}
                  helperText={timelineFormik.touched.qty && timelineFormik.errors.qty}
                />
                <Button size="large" onClick={timelineFormik.handleSubmit}>
                  Add
                </Button>
              </Stack>
              <Helper style={{ marginTop: 4 }}>{chartError}</Helper>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Timeline;
