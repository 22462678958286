import { AddCircleTwoTone, Backup, Delete, EditTwoTone } from '@material-ui/icons';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import FullDialog from '../../../../components/_dashboard/app/FullDialog';
import { createRfq } from '../../../../apis/BuyerApi';
import { getBase64, getFileFromUrl } from '../../../../utils/helper';

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 150,
      maxWidth: 170
    },
    drip: {
      minHeight: '130px',
      maxHeight: '130px',
      color: 'grey',
      backgroundColor: '#FFF7CD',

      fontSize: 14
    }
  })
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const valSchema = Yup.object().shape({
  rfqName: Yup.string().nullable().required('RFQ name is required')
  // attachment: Yup.array()
  //   .min(1, 'Select atleast one file')
  //   .required('Attachment is required')
  //   .nullable()
});

const NewRfq = ({ getList, editData }) => {
  const initalState = {
    rfqName: editData ? editData.title : '',
    attachment: [],
    desc: editData ? editData.field_description : ''
  };
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState(
    editData ? editData.field_rfq_attachment : ''
  );
  const classes = useStyles();
  const theme = useTheme();

  const handleRfqSubmit = async () => {
    setLoading(true);
    const params = {
      field_rfq_attachment: values.attachment,
      rfq_name: values.rfqName,
      field_description: values.desc
    };

    if (editData) {
      params.rfq_id = editData.nid;
    }

    if (currentAttachment == null) params.field_rfq_attachment = 2;
    if (currentAttachment) params.field_rfq_attachment = 1;
    if (values.attachment.length > 0) params.field_rfq_attachment = values.attachment;
    await createRfq(params)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          if (editData) {
            enqueueSnackbar('RFQ Updated Successfully!', {
              variant: 'success'
            });
          } else {
            enqueueSnackbar('New RFQ Created Successfully!', {
              variant: 'success'
            });
          }

          getList();
          handleClose();
          resetForm();
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: initalState,
    validationSchema: valSchema,
    onSubmit: async () => {
      handleRfqSubmit();
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    resetForm
  } = formik;

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const fileReject = () => {
    // setSnack({
    //   open: true,
    //   color: 'error',
    //   message: `Only pdf and image file is allowed`
    // });
  };

  const handleDeleteAttachment = () => {
    setCurrentAttachment(null);
  };

  return (
    <>
      {editData ? (
        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 0 }}>
            <EditTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
          </ListItemIcon>
          Edit RFQ
        </MenuItem>
      ) : (
        <Button variant="contained" onClick={() => setOpen(true)} size="small">
          <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
          {editData ? 'Update' : 'New'} RFQ
        </Button>
      )}

      <FullDialog
        open={open}
        setOpen={setOpen}
        headName={editData ? 'Update RFQ' : 'New RFQ'}
        subHead="Field marked as * are required"
        maxWidth="sm"
        minWidth="sm"
        onClose={handleClose}
      >
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="RFQ Name"
                placeholder=""
                {...getFieldProps('rfqName')}
                error={touched.rfqName && errors.rfqName}
                helperText={touched.rfqName && errors.rfqName}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DropzoneAreaBase
                fileObjects={values.attachment}
                showAlerts={false}
                showPreviews
                previewChipProps={{ classes: { root: classes.previewChip } }}
                showPreviewsInDropzone={false}
                useChipsForPreview
                acceptedFiles={['application/pdf', 'image/jpeg', 'image/png', 'image/bmp']}
                // getFileAddedMessage={fileAdded}
                // getFileRemovedMessage={fileRemove}
                getDropRejectMessage={fileReject}
                Icon={Backup}
                dropzoneText="Drag and drop file"
                onChange={(files) => console.log('Files:', files)}
                onAdd={(newFileObjs) => {
                  // handleChange('attachment', newFileObjs);
                  setFieldValue('attachment', newFileObjs);
                }}
                onDelete={(newFileObjs) => {
                  // handleChange('attachment', newFileObjs);
                  setFieldValue('attachment', newFileObjs);
                }}
                filesLimit={1}
                dropzoneClass={classes.drip}
              />

              <FormHelperText>Max 2mb file size. Only pdf png jpeg jpg is allowed.</FormHelperText>
              {currentAttachment && (
                <Box>
                  Current File: &nbsp;
                  <Link target="_blank" href={currentAttachment}>
                    Attachment
                  </Link>
                  <IconButton onClick={() => handleDeleteAttachment()}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Description"
                placeholder=""
                // value={formData.desc}
                // onChange={(e) => handleChange('desc', e.target.value)}
                {...getFieldProps('desc')}
                multiline
                rows={3}
                // required
                // error={Boolean(touched.description && errors.description)}
                // helperText={touched.description && errors.description}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack flexDirection="row" justifyContent="flex-end">
                <LoadingButton loading={loading} variant="contained" onClick={() => handleSubmit()}>
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </FullDialog>
    </>
  );
};

export default NewRfq;
