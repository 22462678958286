/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable prefer-template */
import { styled } from '@mui/styles';
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Button,
  Typography,
  Box,
  useMediaQuery,
  useScrollTrigger,
  Fab
} from '@mui/material';
import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CssBaseline from '@mui/material/CssBaseline';
import NavTab from './NavTab';
import TotalInfo from './TotalInfo';
import Testimonials from './Testimonials';
import AppInfo from './AppInfo';
import HowItWorks from './HowItWorks';
import Footer from './Footer';
import Page from '../../../components/Page';
import ImageDisplay from './ImageDisplay';
import Scrollbar from '../../../components/Scrollbar';
import ScrollTop from './ScrollTop';

const Container = styled('div')(({ theme }) => ({
  backgroundImage: "url('public/static/bgImagetwo.jpg')"
}));

const images = [{ url: 'static/bg.png' }, { url: 'static/bgImage1.jpg' }];

const LandingPage = (props) => {
  const a = 1;
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Scrollbar>
        <Page title="Home">
          <NavTab />
          <ImageDisplay />
          <div
            style={{
              padding: matches ? '60px' : '16px'
            }}
          >
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <TotalInfo />
              </Grid>
              <Grid item xs={12}>
                <HowItWorks />
              </Grid>
              <Grid item xs={12}>
                <Testimonials />
              </Grid>
              <Grid item xs={12}>
                <AppInfo />
              </Grid>
            </Grid>
          </div>
          <Footer />
        </Page>
      </Scrollbar>
      {/* <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop> */}
    </>
  );
};

export default LandingPage;
