import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export async function getNotifications(token, role) {
  const dta = JSON.stringify({
    role
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/notification`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function updateNotification(token, role, id) {
  const dta = JSON.stringify({
    role,
    id
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/update-notification`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function ReadAllNotification(token, role) {
  const dta = JSON.stringify({
    role
  });

  const config = {
    method: 'post',
    url: `${serverUrl}api/read-all-notification`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}

export async function submitContactUsForm(data) {
  const dta = JSON.stringify({
    ...data
  });
  console.log(dta);
  const config = {
    method: 'post',
    url: `${serverUrl}api/contact-us`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: dta
  };

  return axios(config);
}
