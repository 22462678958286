import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { FileDownloadTwoTone } from '@mui/icons-material';
import TaskBar from '../../../components/_dashboard/app/TaskBar';
import AddVendor from './AddVendor';
import VendorList from './VendorList';
import { getCategory, vendorList } from '../../../apis/BuyerApi';

const YourVendors = () => {
  const [rows, setRows] = useState([]);
  const [query, setQuery] = useState('');
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    await getCategory().then((res) => {
      setCategories(res.result.map((x) => ({ tid: x.pid, name: x.name })));
    });
  };

  const getList = async () => {
    await vendorList().then((res) => {
      if (res.data.status) {
        setRows(res.data.result);
      }
    });
  };

  useEffect(() => {
    fetchCategories();
    getList();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.field_vendor_id.toLowerCase().includes(query.toLowerCase()) ||
        x.title.toString().toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  return (
    <div>
      <TaskBar
        leftItems={() => (
          <>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search"
              sx={{ mt: '0px !important' }}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              fullWidth
            />
          </>
        )}
        rightItems={() => (
          <>
            <Button variant="outlined" size="small">
              <FileDownloadTwoTone style={{ fontSize: 20, marginRight: 5 }} />
              CSV
            </Button>
            <AddVendor categories={categories} getList={getList} />
          </>
        )}
      />
      <VendorList rows={!query ? rows : filtered()} getList={getList} categories={categories} />
    </div>
  );
};

export default YourVendors;
