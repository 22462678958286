import * as React from 'react';
import { CSVDownload, CSVLink } from 'react-csv';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Link as Down } from '@mui/material';
import { styled } from '@mui/styles';

const Item = styled(Link)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: '5px 10px',
  textDecoration: 'none',
  color: 'black'
}));

const CsvItem = styled(CSVLink)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: '5px 10px',
  textDecoration: 'none',
  color: 'black'
}));

const ItemText = styled('div')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));
const Iconn = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  color: theme.palette.text.secondary,
  marginRight: '.5rem'
}));

export default function TableMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { menu, rid, products, pid, setOpen } = props;

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOp = () => {
    localStorage.setItem('reportRid', rid);
    setAnchorEl(null);
    if (products) {
      setOpen(true);
    }
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menu.map((x, index) => (
          <MenuItem key={index} onClick={handleOp}>
            <Link style={{ textDecoration: 'none', color: 'grey' }} to={`${x.goTo}`}>
              {x.text}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
/* () => handleRoute(x.goTo) */
