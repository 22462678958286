import { Navigate } from 'react-router-dom';
import { ViewAuction } from '../pages/vendor/myAuction/_components/ViewRfx';
import { MyAuction } from '../pages/vendor/myAuction';
import { BidPage } from '../pages/vendor/myAuction/active';
import { Profile } from '../pages/vendor/profile';
import { Report } from '../pages/vendor/auctionReports';
import { AuctionUpdate } from '../pages/vendor/auctionUpdate';
import DashboardLayout from '../layouts/dashboard';
import DashboardApp from '../pages/DashboardApp';
import ProductsBid from '../pages/vendor/ProductsBid';
import { Products } from '../pages/vendor/profile/productsTable';
import { Notifications } from '../pages/notifications';
import { ReportProducts } from '../pages/vendor/auctionReports/productReports';
import { BidHistory } from '../pages/vendor/auctionReports/productsBid';
import ContactUs from '../pages/ContactUs';
import { ViewParticipatedAuction } from '../pages/vendor/myAuction/participated/participatedRfx';

export const vendorRoutes = {
  path: '/',
  element: <DashboardLayout />,
  children: [
    { element: <Navigate to="/dashboard" replace />, index: true },
    { path: 'dashboard', element: <DashboardApp /> },
    { path: 'myauction', element: <MyAuction /> },
    { path: 'products', element: <ProductsBid /> },
    { path: 'contactus', element: <ContactUs /> },
    { path: 'reports', element: <Report /> },
    { path: 'reports/products', element: <ReportProducts /> },
    { path: 'reports/products/bidhistory', element: <BidHistory /> },
    {
      path: 'profile',
      element: <Profile />
    },
    { path: 'notifications', element: <Notifications /> },
    { path: 'profile/products', element: <Products /> },
    {
      path: 'myauction/accept',
      element: <ViewAuction acceptancePending />
    },
    {
      path: 'myauction/upcoming',
      element: <ViewAuction upcoming />
    },
    {
      path: 'myauction/participated',
      element: <ViewParticipatedAuction />
    },
    { path: 'myauction/update', element: <AuctionUpdate /> },
    {
      path: 'myauction/active',
      element: <BidPage active />
    }
    /*  {
      path: 'myauction/active/itemBid',
      element: <BidPage />
    } */
  ]
};
