import React from 'react';
import { Avatar, Box, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/styles';

import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import NavTab from '../home/NavTab';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import Footer from '../home/Footer';

const Title = styled('h1')(({ theme }) => ({
  color: theme.palette.text.secondary
}));

const Contact = () => {
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <Scrollbar>
      <Page title="Contact us">
        <div>
          <NavTab />
        </div>
        <div style={{ padding: matches ? '60px' : '16px', paddingTop: matches ? '60px' : '50px' }}>
          <Grid container spacing={5}>
            <Grid item sm={7} xs={12}>
              <Title>Contact us</Title>
              <ContactInfo />
            </Grid>
            <Grid item sm={5} xs={12}>
              <ContactForm />
            </Grid>
          </Grid>
        </div>
        <div>
          <Footer />
        </div>
      </Page>
    </Scrollbar>
  );
};

export default Contact;
