import { Breadcrumbs, Grid, Link, Stack, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/styles';
import { BiRupee } from 'react-icons/bi';
import React, { useEffect } from 'react';
import Scrollbar from '../../../../../components/Scrollbar';
import { token } from '../../../../../utils/helper';
import { fCurrency } from '../../../../../utils/formatNumber';

const Item = styled('div')(({ theme, right, bottom }) => ({
  /* backgroundColor: theme.palette.warning.lighter, */
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  // borderRight: !right && 'dashed 1px grey',
  borderBottom: !bottom && 'dashed 1px grey',
  height: 80,
  padding: '.5rem'
}));

const ItemHeading = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 800,
  wordWrap: 'break-word',
  textAlign: 'center'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));

const ProductDetails = ({ data }) => {
  const category = Object.values(data.category_items);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Scrollbar style={{ maxHeight: 400, minWidth: isMdDown ? 'auto' : 500 }}>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item>
              <ItemHeading>{data.product_id || '-'}</ItemHeading>
              <ItemDesc>Product id</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>
                <Breadcrumbs
                  separator=">>"
                  maxItems={1}
                  sx={{ fontSize: 14, fontWeight: 800 }}
                  color="primary"
                >
                  {category.map((y, i) => (
                    <span key={i}>{y}</span>
                  ))}
                </Breadcrumbs>
              </ItemHeading>
              <ItemDesc>Category</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_unit_of_measure || '-'}</ItemHeading>
              <ItemDesc>Unit of measure</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_minimum_desired_quantity || '-'}</ItemHeading>
              <ItemDesc>Desired quantity</ItemDesc>
            </Item>
            <Item>
              <Item bottom right>
                <ItemHeading>
                  {data.field_product_catalog ? (
                    <Link target="_blank" href={data.field_product_catalog}>
                      View
                    </Link>
                  ) : (
                    '-'
                  )}
                </ItemHeading>
                <ItemDesc>Product catalogue</ItemDesc>
              </Item>
            </Item>
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item right>
              <ItemHeading>{data.field_product_name || '-'}</ItemHeading>
              <ItemDesc>Product name</ItemDesc>
            </Item>
            {/* <Item right>
              <ItemHeading>{data.field_lot.name || '-'}</ItemHeading>
              <ItemDesc>Lot name</ItemDesc>
            </Item> */}
            <Item right>
              <ItemHeading>
                {/* <BiRupee style={{ position: 'relative', top: 2, left: 2 }} /> */}
                {/* {fCurrency(data.field_unit_cost) || '-'} */}
                {data.field_payment_term_buyer_product || '-'}
              </ItemHeading>
              <ItemDesc>Payment Terms</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>
                <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                {fCurrency(data.field_unit_cost) || '-'}
              </ItemHeading>
              <ItemDesc>Start price</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>
                {/* <BiRupee style={{ position: 'relative', top: 2, left: 2 }} /> */}
                {data.field_state || '-'},{data.field_city || '-'},
              </ItemHeading>
              <ItemDesc>Place</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>
                {data.field_minimum_bid_change ? (
                  <>
                    {data.field_minimum_bid_change.toLowerCase() === 'amount' && (
                      <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                    )}
                    {data.field_minimum_change_value}
                    {data.field_minimum_bid_change.toLowerCase() === 'percentage' && '%'}
                  </>
                ) : (
                  '-'
                )}
              </ItemHeading>
              <ItemDesc>Bid change</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Item bottom right>
            <ItemDesc>{data.field_description_field}</ItemDesc>
          </Item>
        </Grid>
      </Grid>
    </Scrollbar>
  );
};

export default ProductDetails;
