import { Grid, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { token } from '../../../utils/helper';
import { getBankDetails } from '../../../apis/VendorApi';

const Item = styled('div')(({ theme, right, bottom }) => ({
  /* backgroundColor: theme.palette.warning.lighter, */
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRight: !right && 'dashed 1px grey',
  borderBottom: !bottom && 'dashed 1px grey',
  height: 80,
  padding: '.5rem'
}));

const ItemHeading = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 800,
  wordWrap: 'break-word',
  textAlign: 'center'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));

const BankDet = ({ target }) => {
  const [data, setData] = useState();
  // console.log(target.target_id);
  const getBankDet = async () => {
    const a = await getBankDetails(token, target.target_id);
    console.log(a.data.result, 'bank details');
    setData(a.data.result);
  };

  useEffect(() => {
    getBankDet();
  }, []);
  //  console.log(target[0].target_id);
  return (
    <div>
      <Grid container sx={{ maxHeight: 400, overflow: 'auto', minWidth: 400 }}>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item>
              <ItemHeading>{(data && data.field_account_name) || '-'}</ItemHeading>
              <ItemDesc>Account name</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{(data && data.field_account_name) || '-'}</ItemHeading>
              <ItemDesc>Account type</ItemDesc>
            </Item>
            <Item bottom>
              <ItemHeading>{(data && data.field_bank_name) || '-'}</ItemHeading>
              <ItemDesc>Bank name</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item right>
              <ItemHeading>{(data && data.field_branch_name) || '-'}</ItemHeading>
              <ItemDesc>Branch</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{(data && data.field_account_number) || '-'}</ItemHeading>
              <ItemDesc>Account number</ItemDesc>
            </Item>
            <Item bottom right>
              <ItemHeading>{(data && data.field_ifsc_code) || '-'}</ItemHeading>
              <ItemDesc>IFSC code</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        {/*  <Grid item sm={12} xs={12}>
          <Item bottom right>
            <ItemHeading>Very good product </ItemHeading>
            <ItemDesc>Description</ItemDesc>
          </Item>
          
        </Grid> */}
      </Grid>
    </div>
  );
};

export default BankDet;
