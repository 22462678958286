import React from 'react';
import { TextField, Button } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const BidInput = () => (
  <>
    <TableRow>
      <TableCell>
        <span style={{ opacity: 0.9 }}>YOUR BID AMOUNT</span>
      </TableCell>
      <TableCell align="right" variant="body2" colSpan={3}>
        <TextField id="outlined-basic" label="bid amount" variant="outlined" />
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell colSpan={4} align="center">
        <Button variant="contained" color="error" size="large" fullWidth>
          Try smart price bid
        </Button>{' '}
        OR{' '}
        <Button variant="contained" color="primary" size="large" fullWidth>
          place bid
        </Button>
      </TableCell>
    </TableRow>
  </>
);

export default BidInput;
