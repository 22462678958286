import { Icon } from '@iconify/react';
import windowsFilled from '@iconify/icons-ant-design/windows-filled';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, CardActions, Button } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(3, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter,
  alignItems: 'center',
  justifyContent: 'center'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

const Btn = styled(Button)(({ theme }) => ({
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

// ----------------------------------------------------------------------

const TOTAL = 1723315;

export default function Completed({ data }) {
  const total = data.filter((x) => x.field_verification_type === 'Completed');
  const navigate = useNavigate();
  return (
    <RootStyle>
      <IconWrapperStyle>
        <IoCheckmarkDoneCircleOutline size={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(total.length)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Completed
      </Typography>
      <Btn
        size="small"
        align="center"
        sx={{ marginTop: 2 }}
        onClick={() => navigate('/requirementList?flag=completed')}
      >
        View More
      </Btn>
    </RootStyle>
  );
}
