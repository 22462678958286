import { Box, Stack, TableCell, TextField, useTheme } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

const FormWrapper = styled('div')`
  .MuiInput-input {
    font-size: 14px;
    padding: 5px;
    min-width: 20px;
    border: none !important;
  }

  .MuiTextField-root {
    border: none !important;
  }

  .MuiOutlinedInput-root {
    border: none !important;
  }
`;

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
    height: 40
    // width: 60,
  }
}));

const EditableTextCell = ({
  width,
  handleOnChange,
  name,
  val,
  data,
  fieldIndex,
  index,
  handleKeyPress,
  isDisabled,
  type,
  startAdorn
}) => {
  const classes = useStyles();
  const [switchCell, setSwitchCell] = useState(false);
  const [value, setValue] = useState('');
  const theme = useTheme();

  useEffect(() => {
    setValue(val || '');
  }, [val]);

  const handleOnBlur = (e) => {
    const newVal = e.target.value;

    setValue(e.target.value);

    handleOnChange(name, e.target.value);
  };

  const handleInputChange = (newVal) => {
    console.log(newVal);
    setValue(newVal);
  };

  // commenrt

  return (
    <td
      // onClick={() => setSwitchCell(true)}
      onFocus={() => setSwitchCell(true)}
      onKeyDown={() => setSwitchCell(true)}
      onBlur={() => setSwitchCell(false)}
      onWheel={(e) => e.target.blur()}
      style={{
        padding: 0,
        paddingLeft: 10,
        borderBottom: switchCell
          ? `1.5px solid ${theme.palette.secondary.main}`
          : '1px solid lightgrey',
        width: width || 'auto'
      }}
    >
      {/* <FormWrapper>
        <TextField
          fullWidth
          variant='standard'
          InputProps={{
            // disableUnderline: true,
            classes: {
              root: classes.noBorder,
            },
          }}
        />
      </FormWrapper> */}
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {startAdorn && <Box sx={{ color: 'grey', ml: 0.5 }}>{startAdorn}</Box>}
        <input
          id={`med${index}-${fieldIndex}`}
          type={type || 'text'}
          value={value}
          disabled={isDisabled}
          onChange={(e) => handleInputChange(e.target.value)}
          onBlur={(e) => val !== e.target.value && handleOnBlur(e)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              handleKeyPress(`med${index + 1}-${0}`);
            }
          }}
          style={{
            border: 'none',
            height: 40,
            width: '100%',
            fontSize: 12,
            outline: 'none'
          }}
        />
      </Stack>
    </td>
  );
};

export default EditableTextCell;
