/* eslint-disable prefer-template */
/* eslint-disable radix */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import {
  getDatabase,
  ref,
  onValue,
  set,
  push,
  get,
  child,
  query,
  equalTo
} from 'firebase/database';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { compareAsc, intervalToDuration } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';

// material
import { alpha, styled } from '@mui/material/styles';

import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Dialog,
  Slide,
  Button,
  useTheme,
  useMediaQuery,
  Stack
} from '@mui/material';
import Label from '../../../../../components/Label';
import { checkAnyBid, updateAuctionCloseTime } from '../../../../../apis/VendorApi';
import { token } from '../../../../../utils/helper';
import RfxTimer from './RfxTimer';
import { db } from '../../../../../firebase';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    gap: 10
  },
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  flexWrap: 'wrap'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  fontSize: 12,
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': {
    width: 320,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    boxShadow: theme.customShadows.z8
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

BidToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function BidToolbar({
  filterName,
  onFilterName,
  schedule,
  endTime,
  setEndTime,
  biddingRules
}) {
  const [bid, setBid] = useState();
  const [closeTime, setCloseTime] = useState(new Date(endTime * 1000));
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const close = new Date(endTime * 1000);
  const timerState = useTimer({
    expiryTimestamp: close
  });

  const { seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = timerState;
  const navigate = useNavigate();
  const rid = localStorage.getItem('rid');
  // const openTime = new Date(open_date * 1000);

  useEffect(() => {
    const close = new Date(endTime * 1000);
    restart(close);
    setCloseTime(close);
  }, [endTime]);

  const getBidChange = async () => {
    await checkAnyBid(token, rid)
      .then((r) => {
        setBid(r.data.result[0].total);
      })
      .catch((e) => {
        console.log(e, 'error');
      });
  };

  const updateClosingTime = async (data) => {
    await updateAuctionCloseTime(token, data)
      .then((r) => {
        // console.log(r, 'closing time updated');
      })
      .catch((e) => {
        console.log(e, 'error');
      });

    const updateCloseTimeRef = ref(db, 'closeTime');
    const newBidRef = push(updateCloseTimeRef);

    set(newBidRef, {
      ...data
    });
  };

  const checkBid = async () => {
    await checkAnyBid(token, rid)
      .then((r) => {
        const latestResult = r.data.result[0].total;
        const lastBid = bid;
        const extendTime = parseInt(biddingRules.field_incremental_closing_time_m);
        setBid(latestResult);
        if (lastBid) {
          if (lastBid !== latestResult) {
            const newTime = parseInt(endTime) + extendTime * 60;
            console.log(newTime, 'from bid check');
            setEndTime(newTime);
            const data = {
              rfx_id: rid,
              close_date: newTime
            };
            updateClosingTime(data);
          }
        }
      })
      .catch((e) => {
        console.log(e, 'error');
      });
  };

  useEffect(() => {
    const lastMinChecker = setInterval(() => {
      const lastMin = new Date(endTime * 1000 - 60000);
      const r = compareAsc(new Date(), lastMin);
      if (r === 1) {
        getBidChange();
        checkBid();
      } else {
        setBid();
      }
    }, 1000);

    return () => clearInterval(lastMinChecker);
  }, [endTime, bid]);

  useEffect(() => {
    const r = compareAsc(new Date(), closeTime);
    if (r === 1) {
      navigate('/myauction?flag=3');
    }
  }, []);

  /* const formatTimer = (time) => {
    // setTimer('');
    const r = compareAsc(new Date(), time);
    // console.log(r);
    if (r !== 1) {
      const a = intervalToDuration({
        start: new Date(),
        end: time
      });

      let day = a.days;
      let hour = a.hours;
      let minute = a.minutes;
      let second = a.seconds;
      if (a.days === 0 && a.hours === 0 && a.minutes === 0 && a.seconds === 0) {
        setOpen(true);
      }
      if (a.hours < 10) {
        hour = `0${a.hours}`;
      }
      if (a.minutes < 10) {
        minute = `0${a.minutes}`;
      }
      if (a.seconds < 10) {
        second = `0${a.seconds}`;
      }

      if (a.days < 10) {
        day = `0${a.days}`;
      }
      const actualTime = `${day} days | ${hour} hours | ${minute} minutes | ${second} seconds`;
      setTimer('');
      console.log('actualTime ', actualTime);
    
      // console.log(`${day} days | ${hour} hours | ${minute} minutes | ${second} seconds`);
    }
  }; */

  useEffect(() => {
    const r = compareAsc(new Date(), closeTime);
    if (r === 1) {
      navigate('/myauction?flag=3');
    }
  }, []);

  return (
    <RootStyle>
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder="Search..."
        style={{ height: 50 }}
        startAdornment={
          <InputAdornment position="start">
            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        }
      />
      <Label
        variant="ghost"
        color="error"
        style={{ fontSize: isMdDown ? 14 : 16, padding: 15, width: isMdDown && '100%' }}
      >
        <AccessTimeIcon sx={{ width: 20, height: 20, marginRight: 1 }} /> Time left: &nbsp;
        <RfxTimer timerState={timerState} closeTime={closeTime} />
      </Label>
    </RootStyle>
  );
}
