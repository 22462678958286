/* eslint-disable camelcase */
import { Card, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import UserListToolbar from '../../components/UserListToolbar';
import DetailsTable from './DetailsTable';
import { getBuyerRequirementProducts, getUnitOfMeasure } from '../../../../apis/BuyerApi';
import { token } from '../../../../utils/helper';
import Page from '../../../../components/Page';
import ReportProductToolbar from './ReportProductToolbar';
import { columns } from './data';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
  color: theme.palette.primary.main
}));

const DetailsContainer = () => {
  const nid = localStorage.getItem('buyerReportPid');
  const [query, setQuery] = useState('');
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);

  const getList = async () => {
    await getBuyerRequirementProducts(token, nid).then((r) => {
      setRows(r.result);
      isLoading(false);
    });
  };
  useEffect(() => {
    getList();
  }, []);

  const search = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.nid.toString().includes(query.toLowerCase()) ||
        x.field_product.toLowerCase().includes(query.toLowerCase()) ||
        x.field_site_location.toLowerCase().includes(query.toLowerCase()) ||
        x.field_product.toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  const csvRows = () => {
    const data = rows;
    let field_unit_of_measure_req;
    let field_month_wise_delivery_date;

    const finalRows = [];
    data.forEach(async (x, index) => {
      const srNo = index + 1;
      const field_immediate = x.field_immediate === '0' ? 'No' : 'Yes';
      const units = await getUnitOfMeasure(token);
      // const dates = await x.field_month_wise_delivery_date.map((a) => a.value);

      const tem = await units.result.filter(
        (b) => x.field_unit_of_measure_req[0].target_id === b.tid
      );

      if (tem.length > 0) field_unit_of_measure_req = tem[0].name;
      // if (dates.length > 0) field_month_wise_delivery_date = dates.toString();
      const temp = {
        ...x,
        srNo,
        field_unit_of_measure_req,
        field_immediate
      };
      finalRows.push(temp);
    });

    return finalRows;
  };

  const csvReport = {
    data: csvRows(),
    headers: columns.map((x) => ({ label: x.label, key: x.key })),
    filename: 'reqProductsReport.csv'
  };

  return (
    <Page title="Requirement products">
      {loading ? (
        <Loader>
          <CircularProgress />
        </Loader>
      ) : (
        <>
          {rows.length > 0 ? (
            <Card>
              <UserListToolbar
                filterName={query}
                onFilterName={search}
                csvReport={csvReport}
                report
              />

              <DetailsTable rows={!query ? rows : filtered()} />
            </Card>
          ) : (
            <Loader>No data found</Loader>
          )}
        </>
      )}
    </Page>
  );
};

export default DetailsContainer;
