import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Stack
} from '@mui/material';
import React from 'react';
import Scrollbar from '../../../../components/Scrollbar';

const SelectInput = (props) => {
  const { cat, name, dta, getFieldProps, error, helper } = props;
  // console.log(cat);

  return (
    <FormControl sx={{ width: '100%', margin: 0, padding: 0 }} error={error}>
      <InputLabel id="demo-simple-select-autowidth-label">{name}</InputLabel>
      <Select
        {...getFieldProps}
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={cat}
        label={name}
        error={error}
        fullWidth
      >
        {dta.length > 0 ? (
          dta.map((x, i) => (
            <MenuItem key={i} value={x.tid}>
              {x.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="">
            <Stack justifyContent="space-between" direction="row" sx={{ width: '100%' }}>
              <em>Loading...</em>
              <CircularProgress color="inherit" size={20} />
            </Stack>
          </MenuItem>
        )}
      </Select>
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  );
};

export default SelectInput;
