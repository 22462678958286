import {
  Button,
  Card,
  CardContent,
  DialogActions,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/styles';
import Cookies from 'js-cookie';
import { LoadingButton } from '@mui/lab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useContext, useEffect, useState } from 'react';
import SelectInput from '../../../components/SelectInput';
import CheckBoxInput from '../../_components/CheckBoxInput';
import { formContext } from '../../FormContainer';
import { HeadingText, Helper, LabelText } from '../../styles';

import FullDialog from '../../_components/FullDialog';
import NewReqContainer from '../reqNewProduct/NewReqContainer';
import ProductInput from '../../_components/ProductInput';
import { getUnitOfMeasure, getProductList, getCategory } from '../../../../../apis/BuyerApi';
import { token } from '../../../../../utils/helper';
import { aPo, terms, creditOp, pro } from '../data';
import Drop from '../Drop';

const FormRight = () => {
  const companyName = localStorage.getItem('companyName');
  const form = useContext(formContext);
  const productState = form.productFormik;
  const newReqState = form.newProFormik;
  const mainState = form.mainFormik;
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    productState;

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Stack direction="column" spacing={3}>
        <CheckBoxInput formik={productState} />
        <div>
          <SelectInput
            cat={values.deliveryTerms}
            getFieldProps={getFieldProps('deliveryTerms')}
            name="Proposed delivery terms (if any)"
            dta={pro}
            error={Boolean(touched.deliveryTerms && errors.deliveryTerms)}
            helper={touched.deliveryTerms && errors.deliveryTerms}
          />
        </div>

        {values.deliveryTerms === 'other' && (
          <TextField
            fullWidth
            type="text"
            label="if other (in days)"
            {...getFieldProps('OtherDeliveryTerms')}
          />
        )}
        <TextField
          fullWidth
          type="text"
          label="Target price"
          {...getFieldProps('targetPrice')}
          error={Boolean(touched.targetPrice && errors.targetPrice)}
          helperText={touched.targetPrice && errors.targetPrice}
        />
        <div style={{ border: '1px solid lightgrey', padding: 10, borderRadius: 10 }}>
          <LabelText>Upload product specifications</LabelText>

          <Drop setFieldValue={setFieldValue} val={values.productFile} />

          <Helper style={{ marginTop: 4 }}>{errors.productFile}</Helper>
        </div>
      </Stack>
    </Grid>
  );
};

export default FormRight;
