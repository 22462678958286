import { Button, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

const HowItWorks = () => {
  const matches = useMediaQuery('(min-width:620px)');
  const navigate = useNavigate();
  return (
    <div
      style={{
        background:
          'linear-gradient(195deg, rgba(186, 128, 54, 0.6), rgba(25, 25, 25, 0.8)) , url(static/desktop.jpg)  transparent',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '300px',
        width: '100%',
        borderRadius: '15px',
        padding: '32px'
      }}
    >
      <Typography sx={{ fontSize: matches ? 40 : 30, color: 'white' }}>Nirmaan Procura</Typography>
      <Typography sx={{ fontSize: matches ? 40 : 30, color: 'white' }}>
        Reverse Auction System
      </Typography>
      <Typography sx={{ fontSize: matches ? 20 : 15, color: '#eceef1' }}>
        To know about how our system works
      </Typography>
      <Button sx={{ mt: 6 }} onClick={() => navigate('/howItWorks')}>
        Click here <ArrowForwardIosIcon style={{ fontSize: 15 }} />
      </Button>
    </div>
  );
};

export default HowItWorks;
