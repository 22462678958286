export const headCells = [
  { id: 'Srno', label: 'SrNo.', align: 'left' },
  { id: 'productId', label: 'Product id', align: 'left' },
  { id: 'productName', label: 'Product name', align: 'left' },
  { id: 'location', label: 'Location', align: 'left' },
  { id: 'targetPrice', label: 'Target price', align: 'left' },
  { id: 'months', label: 'No. of months', align: 'left' },
  { id: 'payment', label: 'Mode of payments', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'right' }
];

export const rows = [
  {
    Srno: 1,
    productId: 'PROD121',
    productName: 'bars',
    location: 'silvassa',
    months: 5,
    targetPrice: 1000,
    payment: 'cash, credit card'
  }
];

export const m = [
  {
    tid: 0,
    name: 'Select'
  },
  {
    tid: 1,
    name: 1
  },
  {
    tid: 2,
    name: 2
  },
  {
    tid: 3,
    name: 3
  },
  {
    tid: 4,
    name: 4
  },
  {
    tid: 5,
    name: 5
  },
  {
    tid: 6,
    name: 6
  },
  {
    tid: 7,
    name: 7
  },
  {
    tid: 8,
    name: 8
  },
  {
    tid: 9,
    name: 9
  },
  {
    tid: 10,
    name: 10
  },
  {
    tid: 11,
    name: 11
  },
  {
    tid: 12,
    name: 12
  }
];

export const pro = [
  {
    tid: 'ex-stock',
    name: 'ex-stock'
  },
  {
    tid: 'Within 15 days',
    name: 'within 15 days'
  },
  {
    tid: 'Within 01 month',
    name: 'within 01 month'
  },
  {
    tid: 'Within 02 months',
    name: 'within 02 months'
  },
  {
    tid: 'Within 03 months',
    name: 'within 03 months'
  }
  /* {
    tid: 'other',
    name: 'other'
  } */
];

export const terms = [
  {
    tid: 'payment on delivery',
    name: 'payment on delivery'
  },
  {
    tid: 'advance payments',
    name: 'advance payments'
  },

  {
    tid: 'credit',
    name: 'credit'
  }
];

export const aPo = [
  {
    tid: '10%',
    name: '10%'
  },
  {
    tid: '20%',
    name: '20%'
  },
  {
    tid: '30%',
    name: '30%'
  }
];

export const creditOp = [
  {
    tid: '7 days',
    name: '7 days'
  },
  {
    tid: '15 days',
    name: '15 days'
  },
  {
    tid: '30 days',
    name: '30 days'
  },
  {
    tid: '45 days',
    name: '45 days'
  },
  {
    tid: '60 days',
    name: '60 days'
  }
];
