export const columns = [
  { label: 'SrNo.', key: 'srNo' },
  {
    label: 'Unit cost',
    key: 'bid_unit_cost'
  },
  { label: 'Total cost', key: 'bid_total_cost' },

  {
    label: 'Bid amount',
    key: 'bid_amt'
  },
  {
    label: 'Rank',
    key: 'bid_rank'
  },
  {
    label: 'Time',
    key: 'bid_time'
  }
];
