import { Box, Card, Paper, Stack } from '@mui/material';
import React from 'react';
import Label from '../../../../components/Label';

const MobileProductCard = ({ row, index }) => {
  console.log('a');
  return (
    <Paper sx={{ borderBottom: '1px solid lightgrey', m: 1, p: 1 }} square>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box>{index + 1}</Box>
          <Stack>
            <Box>ply wood</Box>
            <Box style={{ fontSize: 12, padding: 12, color: 'grey' }}>WOOD & ACC >> MR PLYWOOD</Box>
            <Box style={{ fontSize: 12, padding: 12, color: 'grey' }}>Material Only</Box>
          </Stack>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Label variant="ghost" color="default" style={{ fontSize: 12, padding: 12 }}>
            ₹2950
          </Label>
        </Stack>
      </Stack>

      <Label variant="ghost" color="warning" style={{ fontSize: 12, padding: 12, marginLeft: 16 }}>
        RQ/2024/021
      </Label>
    </Paper>
  );
};

export default MobileProductCard;
