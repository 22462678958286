import React from 'react';
import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Delivery, AmtText } from './styles';

const MyBidRow = (props) => (
  <TableRow>
    <TableCell>
      <Typography variant="body2">{props.item}</Typography>
      <Typography variant="caption" sx={{ opacity: 0.6 }}>
        {props.description}
      </Typography>
    </TableCell>
    <TableCell align="right" variant="body2">
      {props.quantity}
    </TableCell>
    <TableCell align="right">
      <Delivery>{props.date}</Delivery>
    </TableCell>
    <TableCell align="right" variant="body2">
      <Typography variant="body2">{props.amt}</Typography>
      <AmtText variant="caption">{props.amtText}</AmtText>
    </TableCell>
  </TableRow>
);

export default MyBidRow;
