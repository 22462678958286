import { Grid, Box } from '@mui/material';
import React from 'react';
import { MyBid, BidHistory, InThisBid } from './productsBidd';
import TabBar from './productsBidd/TabBar';

const ProductsBid = () => {
  const hello = <h1>Products</h1>;
  return (
    <Grid container>
      <Grid
        container
        align="center"
        md={12}
        xs={12}
        lg={12}
        sx={{
          borderBottom: 'solid 1px  #e5e8eb',
          minHeight: 40,
          paddingX: 1,
          paddingY: 1,
          width: 100,
          backgroundColor: 'primary.lighter',
          borderRadius: '12px 12px 0 0'
        }}
      >
        <TabBar />
      </Grid>
      <Grid
        item
        md={3}
        sm={12}
        xs={12}
        sx={{
          borderRight: 'solid 1px #e5e8eb',
          borderLeft: 'solid 1px #e5e8eb',
          minHeight: '75vh',
          paddingX: 1,
          paddingY: 1
        }}
      >
        <InThisBid />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        sx={{ borderRight: 'solid 1px #e5e8eb  ', minHeight: '75vh', paddingX: 1, paddingY: 1 }}
      >
        <MyBid />
      </Grid>
      <Grid
        item
        md={3}
        sm={12}
        sx={{ minHeight: '70vh', borderRight: 'solid 1px #e5e8eb', paddingX: 1, paddingY: 1 }}
      >
        <BidHistory />
      </Grid>
    </Grid>
  );
};

export default ProductsBid;
