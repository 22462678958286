export const vendorData = [
  {
    step: 1,
    title: 'Login page',
    description:
      'First To Visit The www.nirmaanprocura.com and click to Login If you are already Registered on Nirmaanprocura portal. Enter registered Mobile Number to get OTP (One Time Password) Fill then click to Login Button',
    image: 'vendor/login.png'
  },
  {
    step: 2,
    title: 'Dashboard',
    description:
      'After Successful Login go to Dashboard to see all the Information and Activities Perform By You.',
    image: 'vendor/dashboard.png'
  },
  {
    step: 3,
    title: 'My auction',
    description:
      'My auction page to see all the Upcoming activities Pending for acceptance after accept the invitation go to Upcoming rfq and waiting to open the reverse auction when auction is started goes to Active rfq and auction time closed then go to participated ',
    image: 'vendor/myauction.png'
  },
  {
    step: 4,
    title: 'Pending for acceptance',
    description:
      'All the Upcoming Reverse Auction Invitation comes to pending for acceptance to click on action  view to respond Accept or Reject Invitation',
    image: 'vendor/pending.png'
  },
  {
    step: 5,
    title: 'Upcoming auction',
    description:
      'If You are Accept all the Inviation before the Reverse Auction start goes to Upcoming auction',
    image: 'vendor/upcomingrfx.png'
  },
  {
    step: 6,
    title: 'Select company',
    description:
      'Live Reverse Auction page before the bid started you have to select company you are willing to participated with ',
    image: 'vendor/selectcompany.png'
  },
  {
    step: 7,
    title: 'Active auction',
    description:
      'Live Reverse Auction page where all vendors are bid on particular product according to minimum bid change or to see product details',
    image: 'vendor/activerfx.png'
  },
  {
    step: 8,
    title: 'Participated',
    description:
      'If the Reverse Auction Time is Completed then all the Bid Related Information goes to Participated tab',
    image: 'vendor/participatedrfx.png'
  },
  {
    step: 9,
    title: 'Reports',
    description:
      'After Successfull Completed Reverse Auction go to see reports',
    image: 'vendor/reportsvendor.png'
  }
];

export const buyerData = [
  {
    step: 1,
    title: 'Login page',
    description:
      'Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!',
    image: 'buyer/login.png'
  },
  {
    step: 2,
    title: 'Dashboard',
    description:
      'Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!',
    image: 'buyer/dashboard.png'
  },
  {
    step: 3,
    title: 'My requirement',
    description:
      'Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!',
    image: 'buyer/reqlist.png'
  },
  {
    step: 4,
    title: 'Select category and company',
    description:
      'Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!',
    image: 'buyer/newreq1.png'
  },
  {
    step: 5,
    title: 'Fill product requirement',
    description:
      'Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!',
    image: 'buyer/newreq2.png'
  },
  {
    step: 6,
    title: 'Submit or add another product',
    description:
      'Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!',
    image: 'buyer/newreq3.png'
  },
  {
    step: 7,
    title: 'Reports',
    description:
      'Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!',
    image: 'buyer/reportbuyer.png'
  }
];
