/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useLocation, useParams } from 'react-router-dom';

import { UserListToolbar } from '../../../../components/_dashboard/user';

import FullDialog from './FullDialog';

// import ItemTable from './ItemTable';
import Label from '../../../../components/Label';
import {
  getProductDetails,
  getRfxDetails,
  getTempProductDetails
} from '../../../../apis/VendorApi';
import { token } from '../../../../utils/helper';
import SmallSnackbar from '../../../../components/SnackBar';
import ProductsTable from './ProductsTable';
import ProductDetails from './ProductDetails';
import Loader from '../../../../components/Loader';
import ReportProductToolbar from './ReportProductToolbar';
import { columns } from './data';

const styles = (theme) => ({
  acceptance: {
    backgroundColor: 'blue',
    // Match [xs, md) and [md + 1, ∞)
    //       [xs, md) and [lg, ∞)
    //       [0px, 900px) and [1200px, ∞)
    [theme.breakpoints.down('md')]: {
      align: 'center'
    }
  }
});

const ProductContainer = (props) => {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState();
  const [res, setRes] = useState();
  const [loading, isLoading] = useState(true);
  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const { state } = useLocation();

  const rid = localStorage.getItem('reportRid');

  // console.log(rows, 'from view product');
  const search = (event) => {
    setQuery(event.target.value);
  };

  /* const fetchProducts = async (token, tid) => {
    const d = await getProductDetails(token, tid);
    return d;
  }; */

  /*   const getRestProduct = (data) => {
    const temp = [];

    data.map((x) =>
      fetchProducts(token, x.field_product_master_)
        .then((r) => {
          const b = { ...x, ...r.result };
          temp.push(b);
          setRows([...temp]);
          isLoading(false);
          // console.log(r);
        })
        .catch((e) => {
          isLoading(false);
          console.log(e.message, 'at fetching tid');
        })
    );
  }; */

  const fetchMasterProducts = async (token, tid) => {
    const d = await getProductDetails(token, tid);
    return d;
  };

  const fetchTempProducts = async (token, tid) => {
    const d = await getTempProductDetails(token, tid);
    return d;
  };

  const getRestProduct = (data) => {
    const temp = [];

    data.map((x) =>
      x.field_product_temp_
        ? fetchTempProducts(token, x.field_product_temp_)
          .then((r) => {
            const b = { ...x, ...r.data.result };
            temp.push(b);
            setRows([...temp].reverse());
            isLoading(false);
            // console.log(r, 'from temp product');
            // console.log(r.result);
          })
          .catch((e) => {
            isLoading(false);
            console.log(e.message, 'at fetching tid');
          })
        : fetchMasterProducts(token, x.field_product_master_)
          .then((r) => {
            const b = { ...x, ...r.result };
            temp.push(b);
            setRows([...temp].reverse());
            isLoading(false);
            // console.log(r.result);
          })
          .catch((e) => {
            isLoading(false);
            console.log(e.message, 'at fetching tid');
          })
    );
  };

  const getRfx = async (id) => {
    await getRfxDetails(token, id)
      .then((r) => {
        setRes(...r.data.result);
        if (r.data.result[0].product.length === 0) {
          isLoading(false);
        } else {
          getRestProduct(r.data.result[0].product);
        }

        // console.log(r.data.result[0], 'rfx by nid');
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getRfx(rid);
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.field_product_name.toLowerCase().includes(query.toLowerCase())
      // x.field_lot.name.toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  const csvRows = () => {
    const data = rows;
    // console.log(data, 'from repor');
    const finalRows = [];
    data.forEach((x, index) => {
      const srNo = index + 1;
      const category = Object.values(x.category_items);
      const field_category = category.join('>>');
      const field_lot = x.field_lot.name;
      const temp = { ...x, srNo, field_category, field_lot };
      finalRows.push(temp);
    });
    return finalRows;
  };

  const csvReport = {
    data: csvRows(),
    headers: columns.map((x) => ({ label: x.label, key: x.key })),
    filename: 'productReport.csv'
  };

  return (
    <>
      {loading ? (
        <Loader height="75vh" />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12}>
              <Stack direction="row" spacing={1}>
                <Label sx={{ height: 'auto', padding: 1 }} color="primary">
                  <Stack>
                    <Typography sx={{ fontSize: 11, color: 'gray' }}>Rfq name</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 800 }}>
                      {res.title || '-'}
                    </Typography>
                  </Stack>
                </Label>
                <Label sx={{ height: 'auto', padding: 1 }} color="info">
                  <Stack>
                    <Typography sx={{ fontSize: 11, color: 'gray' }}>Unique id</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 800 }}>
                      {res.rfx_id || '-'}
                    </Typography>
                  </Stack>
                </Label>
              </Stack>
            </Grid>

            <Grid item sm={12}>
              <Card>
                <ReportProductToolbar
                  numSelected={0}
                  filterName={query}
                  onFilterName={search}
                  csvReport={csvReport}
                />
                <ProductsTable
                  rows={!query ? rows : filtered()}
                  setOpen={setOpen}
                  setItem={setItem}
                />
              </Card>
            </Grid>
          </Grid>
          <FullDialog open={open} setOpen={setOpen} headName="Product details">
            <ProductDetails data={item} />
          </FullDialog>
        </>
      )}
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </>
  );
};

export default ProductContainer;
