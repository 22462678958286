/* eslint-disable camelcase */
import { Card, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { BiRupee } from 'react-icons/bi';
import Label from '../../../../../components/Label';
import { fCurrency } from '../../../../../utils/formatNumber';

const Heading = styled('span')(({ theme }) => ({
  fontSize: 10,
  color: 'grey'
}));

const Item = styled('span')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.primary.main,
  fontWeight: 600
}));

const DetailsColumn = ({ row, bidingRules, rfxData }) => {
  const a = 1;
  const {
    name,
    field_place_of_supply,
    field_payment_term_buyer_product,
    field_minimum_desired_quantity,
    field_start_price,
    field_minimum_change_value,
    field_minimum_bid_change
  } = row;
  return (
    <Stack direction="row" spacing={1}>
      <Stack spacing={1} sx={{ width: '100%', height: '100%' }}>
        <Label
          color="primary"
          sx={{ height: 'auto', padding: '5px', backgroundColor: 'transparent' }}
        >
          <Stack justifyContent="center" alignItems="center">
            <Typography sx={{ fontSize: 14, fontWeight: 800 }} color="primary">
              {field_minimum_desired_quantity || '-'}
            </Typography>
            <Typography sx={{ fontSize: 10, color: 'gray' }}>Desired Quantity</Typography>
          </Stack>
        </Label>
        <Label
          color="primary"
          sx={{ height: 'auto', padding: '5px', backgroundColor: 'transparent' }}
        >
          {field_start_price && (
            <Stack justifyContent="center" alignItems="center">
              <Typography sx={{ fontSize: 14, fontWeight: 800 }} color="primary">
                <BiRupee style={{ position: 'relative', top: 2 }} />
                {fCurrency(field_start_price) || '-'}
              </Typography>
              <Typography sx={{ fontSize: 10, paddingLeft: '5px', color: 'gray' }}>
                Start price
              </Typography>
            </Stack>
          )}
        </Label>
      </Stack>
      <Stack spacing={1} sx={{ width: '100%', height: '100%' }}>
        {rfxData.type !== 'rfq' && (
          <Label
            color="primary"
            sx={{ height: 'auto', padding: '5px', backgroundColor: 'transparent' }}
          >
            <Stack justifyContent="center" alignItems="center">
              <Typography sx={{ fontSize: 14, fontWeight: 800 }} color="primary">
                {field_minimum_change_value ? (
                  <>
                    {field_minimum_bid_change === 'Amount' && (
                      <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                    )}
                    {fCurrency(field_minimum_change_value) || '-'}
                    {field_minimum_bid_change === 'Percentage' && '%'}
                  </>
                ) : (
                  <>
                    {bidingRules && (
                      <>
                        {bidingRules.field_minimum_bid_change === 'Amount' && (
                          <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                        )}
                        {bidingRules.field_change_value || '-'}
                        {bidingRules.field_minimum_bid_change === 'Percentage' && '%'}
                      </>
                    )}
                  </>
                )}
              </Typography>
              <Typography sx={{ fontSize: 10, color: 'gray' }}>Bid change</Typography>
            </Stack>
          </Label>
        )}
        {rfxData.type !== 'rfq' && (
          <Label
            color="primary"
            sx={{ height: 'auto', padding: '5px', backgroundColor: 'transparent' }}
          >
            <Stack justifyContent="center" alignItems="center">
              <Typography sx={{ fontSize: 14, fontWeight: 800 }} color="primary">
                <BiRupee style={{ position: 'relative', top: 2, left: 2 }} />
                {fCurrency(field_minimum_desired_quantity * field_start_price) || '-'}
              </Typography>
              <Typography sx={{ fontSize: 10, color: 'gray' }}>Total cost</Typography>
            </Stack>
          </Label>
        )}
      </Stack>
    </Stack>
  );
};

export default DetailsColumn;
