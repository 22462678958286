import { Card, CardContent, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { token } from '../../../utils/helper';
import { getVendorDetails } from '../../../apis/VendorApi';
import Company from './Company';
import ProfileFields from './ProfileFields';
import ProfileInfo from './ProfileInfo';
import { CompanyCardSkeleton, ProfileInfoSkeleton } from './components/skeletons';
import Page from '../../../components/Page';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh'
}));

const ProfileContainer = () => {
  const [data, setData] = useState();

  const getDetails = async () => {
    const d = await getVendorDetails(token);
    setData(d.result);
    console.log(d.result, 'vendor profile data');
  };

  useEffect(() => {
    getDetails();
  }, []);
  const a = 1;
  return (
    <Page title="Profile">
      <Card>
        <CardContent>
          {/* <ProfileFields data={data} /> */}
          {data ? <ProfileInfo data={data} /> : <ProfileInfoSkeleton />}
          <br />
          <br />
          <Company data={data} />
        </CardContent>
      </Card>

      {/*  <Loader>
          <CircularProgress color="primary" />
        </Loader> */}
    </Page>
  );
};

export default ProfileContainer;
