import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { MobileDateRangePicker } from '@mui/lab';
import { compareAsc } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// material
import { Box, Card, CardContent, CardHeader, TextField } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

export default function RfxPieChart({ pendingRows, upcomingRows, activeRows, participatedRows }) {
  // console.log(data);
  /*   const accept = data.filter((x) => x.field_verification_type === 'Accept');
  const completed = data.filter((x) => x.field_verification_type === 'Completed');
  const pending = data.filter((x) => x.field_verification_type === 'Pending for verification'); */

  const [chartData, setChartData] = useState({
    options: {
      labels: ['Pending', 'Upcoming', 'Active', 'Participated']
    },
    series: [pendingRows.length, upcomingRows.length, activeRows.length, participatedRows.length]
  });

  useEffect(() => {
    setChartData({
      options: {
        labels: ['Pending', 'Upcoming', 'Active', 'Participated']
      },
      series: [pendingRows.length, upcomingRows.length, activeRows.length, participatedRows.length]
    });
  }, []);

  return (
    <Card>
      {/*  <CardHeader title="Requirement Chart" /> */}
      <CardContent>
        <Chart options={chartData.options} series={chartData.series} type="pie" height={350} />
      </CardContent>
    </Card>
  );
}
