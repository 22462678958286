import { Grid, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { token } from '../../../utils/helper';
import { getBankDetails } from '../../../apis/VendorApi';

const Item = styled('div')(({ theme, right, bottom }) => ({
  /* backgroundColor: theme.palette.warning.lighter, */
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRight: !right && 'dashed 1px grey',
  borderBottom: !bottom && 'dashed 1px grey',
  height: 80,
  padding: '.5rem'
}));

const ItemHeading = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 800,
  wordWrap: 'break-word',
  textAlign: 'center'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));

const CompanyDetails = ({ data }) => {
  const a = 1;
  return (
    <div>
      <Grid container sx={{ maxHeight: 400, overflow: 'auto', minWidth: 400 }}>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item>
              <ItemHeading>{(data && data.field_company_name) || '-'}</ItemHeading>
              <ItemDesc>Company name</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{(data && data.field_sector) || '-'}</ItemHeading>
              <ItemDesc>Sector</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{(data && data.field_office_landline_number) || '-'}</ItemHeading>
              <ItemDesc>Office landline number</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item right>
              <ItemHeading>{(data && data.field_owner_name) || '-'}</ItemHeading>
              <ItemDesc>Owner name</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{(data && data.field_gst_number) || '-'}</ItemHeading>
              <ItemDesc>GST number</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{(data && data.field_highlights) || '-'}</ItemHeading>
              <ItemDesc>Highlights</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Item bottom right>
            <ItemHeading>{(data && data.field_location) || '-'}</ItemHeading>
            <ItemDesc>Location</ItemDesc>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyDetails;
