import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Stack,
  TableRow,
  Tooltip,
  useTheme
} from '@mui/material';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { enqueueSnackbar } from 'notistack';
import { BiPaperclip } from 'react-icons/bi';
import { Backup } from '@material-ui/icons';
import { createStyles, makeStyles } from '@mui/styles';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import LinesEllipsis from 'react-lines-ellipsis';
import EditableAutoCell from './EditableAutoCell';
import EditableTextCell from './EditableTextCell';
import EditableMedCell from './EditableMedCell';
import { aPo, creditOp, terms } from '../../../../../../../utils/helper';
import FullDialog from '../../../../../../../components/_dashboard/app/FullDialog';

const ls = require('local-storage');

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 150,
      maxWidth: 250
    },
    drip: {
      minHeight: '130px',
      maxHeight: '130px',
      color: 'grey',
      backgroundColor: '#FFF7CD',

      fontSize: 14
    }
  })
);

const AddRow = ({
  index,
  key,
  saltCom,
  isMdDown,
  checkPermission,
  columnPermissions,
  handleRowsChange,
  productItems,
  setProductItems,
  setAddItem,
  addItem,
  initialVal,
  tableStyle,
  units,
  handleAddMedicines,
  addNewMedicine,
  productAttachments,
  handleChangeAttachment,
  type
}) => {
  const [medType, setMedType] = useState('brand');
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const theme = useTheme();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [inputRefsArray] = useState(() => Array.from({ length: 7 }, () => createRef()));

  const widthArr = [
    {
      name: 'med',
      ratio: 2.7,
      percentage: 24,
      mdPercentage: 30
    },
    {
      name: 'uom',
      ratio: 0.63,
      percentage: 6,
      mdPercentage: 7
    },
    {
      name: 'desiredQty',
      ratio: 0.63,
      percentage: 8,
      mdPercentage: 7
    },
    {
      name: 'startPrice',
      ratio: 0.72,
      percentage: 8,
      mdPercentage: 8
    },
    {
      name: 'payTerms',
      ratio: 0.81,
      percentage: 12,
      mdPercentage: 9
    },
    {
      name: 'confPo',
      ratio: 0.63,
      percentage: 12,
      mdPercentage: 6
    },
    {
      name: 'credit',
      ratio: 0.99,
      percentage: 8,
      mdPercentage: 11
    },
    {
      name: 'desc',
      ratio: 0.99,
      percentage: type === 'rfx' ? 18 : 26,
      mdPercentage: 11
    },
    {
      name: 'attachment',
      ratio: 0.99,
      percentage: 8,
      mdPercentage: 11
    }
    // {
    //   name: 'disc',
    //   ratio: 0.99,
    //   percentage: 6,
    //   mdPercentage: 11
    // },
    // {
    //   name: 'base',
    //   ratio: 0.81,
    //   percentage: 7,
    //   mdPercentage: 10
    // },

    // {
    //   name: 'gst',
    //   ratio: 1.08,
    //   percentage: 7,
    //   mdPercentage: 7
    // }
  ];

  // console.log(doctorProfileDetails)

  const handleKeyPress = (nextField) => {
    console.log(nextField, 'next');
    if (typeof nextField !== 'object') {
      const nextInput = document.querySelector(`input[id=${nextField}]`);
      nextInput.disabled = false;
      nextInput.focus();
      nextInput.select();
      setCurrentIndex((prevIndex) => {
        // calculate the next input index, next input after the final input will be again the first input. you can change the logic here as per your needs
        const nextIndex = prevIndex < 7 - 1 ? prevIndex + 1 : 0;
        return nextIndex;
      });
    }
  };

  // const calculateTotal = () => {
  //   const ptr = addItem.ptr ? parseFloat(addItem.ptr) : 0.0;
  //   const custQty = addItem.qty ? parseInt(addItem.qty, 10) : 0.0;
  //   const schAmt = addItem.schAmt ? parseFloat(addItem.schAmt) : 0.0;
  //   const discPer = addItem.disc ? parseFloat(addItem.disc) : 0.0;
  //   const gstPer = addItem.gst ? parseFloat(addItem.gst) : 0.0;

  //   const disc = discPer / 100;
  //   const gst = gstPer / 100;

  //   let total = 0;

  //   const grossTotal = ptr * custQty;
  //   let base = ptr;
  //   total = grossTotal;

  //   if (schAmt) {
  //     total -= schAmt;
  //     base -= schAmt / custQty;
  //     console.log(base, ptr, schAmt, 'schAmt');
  //   }

  //   console.log(total, 'total');

  //   if (addItem.disc) {
  //     const discAmt = total * disc;
  //     total -= discAmt;
  //     base -= base * disc;
  //     console.log(total, disc, ptr, 'discAmt');
  //   }
  //   let gstAmt = 0.0;

  //   if (addItem.gst) {
  //     gstAmt = total * gst;
  //     total += gstAmt;
  //   }

  //   handleRowsChange(index, 'total', total ? total.toFixed(2) : 0, [
  //     { key: 'total', value: total ? total.toFixed(2) : 0 },
  //     { key: 'base', value: base.toFixed(2) || 0.0 },
  //     { key: 'gstAmt', value: gstAmt.toFixed(2) || 0.0 }
  //   ]);

  //   return total;
  // };

  const validateData = () => {
    const mrp = addItem.mrp ? parseFloat(addItem.mrp) : 0.0;
    // const qty = addItem.qty;
    const ptr = addItem.ptr ? parseFloat(addItem.ptr) : 0.0;

    const total = 0;
    console.log(addItem, 'validateData');
    if (ptr > mrp) {
      console.log(ptr, mrp, 'validateData');
      const msg = 'PTR should be less than MRP.';

      enqueueSnackbar(msg, {
        variant: 'warning',
        anchorOrigin: { horizontal: 'right', vertical: 'top' }
      });
    }

    // if (
    //   addItem.expiry == "" ||
    //   addItem.batch == "" ||
    //   addItem.mrp == "" ||
    //   addItem.ptr == "" ||
    //   addItem.qty == ""
    // ) {
    //   setErrors({
    //     expiry: []
    //   });
    // }

    // handleRowsChange(index, "total", total ? total.toFixed(2) : 0, [
    //   { key: "total", value: total ? total.toFixed(2) : 0 },
    //   { key: "discAmt", value: discAmt || 0.0 },
    // ]);

    return total;
  };

  useEffect(() => {
    if (addItem.label === '') {
      //
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
      // calculateTotal();
    }
  }, [addItem]);

  useEffect(() => {
    console.log('here');
    validateData();
    // calculateTotal();
  }, [addItem.ptr, addItem.mrp, addItem.qty, addItem.schAmt, addItem.disc, addItem.gst]);

  useEffect(() => {
    // focus the firs iput initially
    if (inputRefsArray[0].current) {
      inputRefsArray[0].current.focus();
    }

    // add the event listener for keyup keyboard event
    window.addEventListener('keyup', handleKeyPress, false);

    // remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, []);

  //  console.log(items, 'addItemww');
  // useEffect(() => {
  //   if (items.key != '') updateMedRow(items);
  //   // setItems(addItem);
  // }, [items]);

  const handleOnChange = (key, value, multipleVals) => {
    console.log(key, value, multipleVals, 'tempDat');
    let tempData = addItem;
    if (multipleVals) {
      multipleVals.forEach((val) => {
        tempData[val.key] = val.value;
      });
    } else {
      tempData = { ...addItem, [key]: value };
    }
    // setItems(tempData);
    // updateMedRow(tempData);
    handleRowsChange(index, key, value, multipleVals);
  };

  const calculateWidth = (columnName) => {
    const arr = widthArr.filter((x) => x.name === columnName);
    if (arr.length > 0) {
      const perc = isMdDown ? arr[0].mdPercentage : arr[0].percentage;
      // console.log(calc, colCount, 'calculated width')
      const notShown = columnPermissions.filter((x) => x.val === false && x.name !== 'typeSwitch');
      let ttl = 0;
      let dividedPerc = 0;

      if (notShown.length > 0) {
        notShown.forEach((y) => {
          const item = widthArr.filter((z) => z.name === y.name);
          if (item.length > 0) {
            // console.log(item[0].percentage, 'width ttl')
            ttl += item[0].percentage;
          }
        });
        dividedPerc = ttl / (widthArr.length - notShown.length);
      } else {
        dividedPerc = 0;
      }

      dividedPerc = `${dividedPerc + perc}%`;
      // console.log(ttl, dividedPerc, notShown, widthArr, 'width ttl')
      return dividedPerc;
    }
  };

  // calculateWidth('med')

  const getIndex = (columnName, defaultIndex) => {
    let res = false;
    if (columnPermissions) {
      const arr = columnPermissions.filter((x) => x.val === true);
      // console.log(arr, 'getIndex')
      const tempArr = arr
        .filter((a) => a.name !== 'typeSwitch')
        .map((x, i) => ({ ...x, id: i }))
        .filter((y) => y.name === columnName);
      let index = null;
      if (tempArr.length > 0) {
        index = tempArr[0].id + 1;
      } else {
        index = defaultIndex;
      }
      // console.log(index + 1)
      res = index;
    } else {
      res = false;
    }
    return res;
  };
  console.log(addItem.payTerms !== 'credit', 'checkkkkkk');
  // getIndex('qty')
  const handleAddBtn = () => {
    if (
      addItem.productName === '' ||
      addItem.uom === '' ||
      addItem.desiredQty === '' ||
      // addItem.startPrice === '' ||
      addItem.payTerms === ''
      // addItem.attachment.length === 0
    ) {
      enqueueSnackbar('Please Fill required fields.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' }
      });
    } else {
      const newData = productItems;
      console.log(newData, addItem, 'addBtnPressed');

      if (addItem.rowIndex !== undefined) {
        newData[addItem.rowIndex] = addItem;
        enqueueSnackbar('Item updated successfully', {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'top' }
        });
      } else {
        newData.push(addItem);
        // console.log(productItems.length, 'lenghthh');
        // handleChangeAttachment(addItem.attachment, productItems.length - 1);
        enqueueSnackbar('Item added successfully', {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'top' }
        });
      }
      setProductItems([...newData]);

      setAddItem(initialVal);
    }

    // handleAddMedicines(addItem, purchaseItems.length);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fileReject = () => {
    // setSnack({
    //   open: true,
    //   color: 'error',
    //   message: `Only pdf and image file is allowed`
    // });
  };

  return (
    <>
      <td style={{ fontSize: 11, color: theme.palette.secondary.main }} />
      {/* <EditableMedCell
        // width={isMdDown ? 260 : 300}
        width={calculateWidth('med')}
        dataList={[]}
        medName
        data={addItem}
        index={index}
        setMedType={setMedType}
        medType={medType}
        // handleOnChange={handleOnChange}
        // placeholder='Type salt name...'
        handleKeyPress={handleKeyPress}
        id={`field${index}-0`}
        fieldIndex={0}
        saltCom={saltCom}
        isMdDown={isMdDown}
        setIsDisabled={setIsDisabled}
        checkPermission={checkPermission}
      /> */}

      <EditableMedCell
        // width={isMdDown ? 260 : 300}
        width={calculateWidth('med')}
        dataList={[]}
        medName
        data={addItem}
        index={index}
        // handleOnChange={handleOnChange}
        // placeholder='Type salt name...'
        handleKeyPress={handleKeyPress}
        id={`med${index}-0`}
        fieldIndex={0}
        isMdDown={isMdDown}
        setIsDisabled={setIsDisabled}
        productItems={productItems}
        tableStyle={tableStyle}
        handleAddMedicines={handleAddMedicines}
        addNewMedicine={addNewMedicine}
        // editProduct={editProduct}
      />

      <EditableAutoCell
        width={calculateWidth('uom')}
        tableStyle={tableStyle}
        dataList={units.map((x) => ({ id: x.tid, title: x.name }))}
        data={addItem}
        index={index}
        handleOnChange={handleOnChange}
        name="uom"
        freeSolo={false}
        multiple={false}
        val={{ title: addItem.uom }}
        handleKeyPress={handleKeyPress}
        fieldIndex={1}
        isDisabled={isDisabled}
      />
      <EditableTextCell
        width={calculateWidth('desiredQty')}
        handleOnChange={handleOnChange}
        name="desiredQty"
        val={addItem.desiredQty}
        type="number"
        data={addItem}
        handleKeyPress={handleKeyPress}
        fieldIndex={2}
        index={index}
        isDisabled={isDisabled}
      />
      {type === 'rfx' && (
        <EditableTextCell
          width={calculateWidth('startPrice')}
          handleOnChange={handleOnChange}
          name="startPrice"
          val={addItem.startPrice}
          type="number"
          data={addItem}
          handleKeyPress={handleKeyPress}
          fieldIndex={3}
          index={index}
          isDisabled={isDisabled}
          startAdorn="₹"
        />
      )}

      <EditableAutoCell
        width={calculateWidth('payTerms')}
        tableStyle={tableStyle}
        dataList={terms.map((x) => ({ id: x.tid, title: x.name }))}
        data={addItem}
        index={index}
        handleOnChange={handleOnChange}
        name="payTerms"
        freeSolo={false}
        multiple={false}
        val={{ title: addItem.payTerms }}
        handleKeyPress={handleKeyPress}
        fieldIndex={4}
        isDisabled={isDisabled}
      />

      {/* <EditableAutoCell
        width={calculateWidth('confPo')}
        tableStyle={tableStyle}
        dataList={aPo.map((x) => ({ id: x.tid, title: x.name }))}
        data={addItem}
        index={index}
        handleOnChange={handleOnChange}
        name="confPo"
        freeSolo={false}
        multiple={false}
        val={{ title: addItem.confPo }}
        handleKeyPress={handleKeyPress}
        fieldIndex={5}
        isDisabled={isDisabled || addItem.payTerms !== 'advance payments'}
      /> */}

      <EditableAutoCell
        width={calculateWidth('credit')}
        tableStyle={tableStyle}
        dataList={creditOp.map((x) => ({ id: x.tid, title: x.name }))}
        data={addItem}
        index={index}
        handleOnChange={handleOnChange}
        name="credit"
        freeSolo={false}
        multiple={false}
        val={{ title: addItem.credit }}
        handleKeyPress={handleKeyPress}
        fieldIndex={6}
        isDisabled={isDisabled || addItem.payTerms !== 'credit'}
      />

      <EditableTextCell
        width={calculateWidth('desc')}
        handleOnChange={handleOnChange}
        name="desc"
        val={addItem.desc}
        // type="number"
        data={addItem}
        handleKeyPress={handleKeyPress}
        fieldIndex={7}
        index={index}
        isDisabled={isDisabled}
        // startAdorn="₹"
      />

      <td
        style={{
          padding: 0,
          borderBottom: '1px solid lightgrey',
          width: calculateWidth('attachment'),
          cursor: 'pointer'
        }}
        // onClick={() => setOpen(true)}
      >
        <Stack flexDirection="row" alignItems="center">
          <div>
            <IconButton
              variant="contained"
              size="small"
              color="primary"
              onClick={() => setOpen(true)}
              disabled={isDisabled}
            >
              <BiPaperclip />
            </IconButton>

            <FullDialog
              open={open}
              setOpen={setOpen}
              headName="Add Attachment"
              // subHead="Field marked as * are required"
              maxWidth="sm"
              minWidth="sm"
              onClose={handleClose}
            >
              <Box p={2}>
                <DropzoneAreaBase
                  fileObjects={addItem.attachment}
                  showAlerts={false}
                  showPreviews
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  acceptedFiles={['application/pdf', 'image/jpeg', 'image/png', 'image/bmp']}
                  // getFileAddedMessage={fileAdded}
                  // getFileRemovedMessage={fileRemove}
                  getDropRejectMessage={fileReject}
                  Icon={Backup}
                  dropzoneText="Drag and drop file"
                  onChange={(files) => console.log('Files:', files)}
                  onAdd={(newFileObjs) => {
                    // handleChange('attachment', newFileObjs);
                    handleOnChange('attachment', newFileObjs);
                    // setFileObjects(newFileObjs);
                    setOpen(false);
                  }}
                  onDelete={(newFileObjs) => {
                    // handleChange('attachment', newFileObjs);
                    handleOnChange('attachment', newFileObjs);
                    // setFileObjects(newFileObjs);
                  }}
                  filesLimit={1}
                  dropzoneClass={classes.drip}
                />

                <FormHelperText>
                  Max 2mb file size. Only pdf png jpeg jpg is allowed.
                </FormHelperText>
              </Box>
            </FullDialog>
          </div>

          {addItem.attachment.length > 0 && (
            <Box
              onClick={() => setOpen(true)}
              sx={{
                fontSize: 11,
                color: 'grey'
              }}
            >
              <LinesEllipsis
                text={addItem.attachment[0].file.path || 'N/A'}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="words"
              />
              {/* {addItem.attachment[0].file.path} */}
            </Box>
          )}
        </Stack>
      </td>

      {/* {checkPermission('mrp') && (
        <EditableTextCell
          width={calculateWidth('mrp')}
          handleOnChange={handleOnChange}
          name="mrp"
          val={addItem.mrp}
          data={addItem}
          handleKeyPress={handleKeyPress}
          fieldIndex={getIndex('mrp', 3)}
          index={index}
          isDisabled={isDisabled}
          type="number"
        />
      )}

      {checkPermission('ptr') && (
        <EditableTextCell
          width={calculateWidth('ptr')}
          handleOnChange={handleOnChange}
          name="ptr"
          val={addItem.ptr}
          data={addItem}
          handleKeyPress={handleKeyPress}
          fieldIndex={getIndex('ptr', 4)}
          index={index}
          isDisabled={isDisabled}
          type="number"
        />
      )}

      {checkPermission('qty') && (
        <EditableTextCell
          width={calculateWidth('qty')}
          handleOnChange={handleOnChange}
          name="qty"
          val={addItem.qty}
          data={addItem}
          handleKeyPress={handleKeyPress}
          fieldIndex={getIndex('qty', 5)}
          index={index}
          isDisabled={isDisabled}
          type="number"
        />
      )}
      {checkPermission('free') && (
        <EditableTextCell
          width={calculateWidth('free')}
          handleOnChange={handleOnChange}
          name="free"
          val={addItem.free}
          data={addItem}
          handleKeyPress={handleKeyPress}
          fieldIndex={getIndex('free', 6)}
          index={index}
          isDisabled={isDisabled}
          type="number"
        />
      )}
      {checkPermission('schAmt') && (
        <EditableTextCell
          width={calculateWidth('schAmt')}
          handleOnChange={handleOnChange}
          name="schAmt"
          val={addItem.schAmt}
          data={addItem}
          handleKeyPress={handleKeyPress}
          fieldIndex={getIndex('schAmt', 7)}
          index={index}
          isDisabled={isDisabled}
          type="number"
        />
      )}

      {checkPermission('disc') && (
        <EditableTextCell
          width={calculateWidth('disc')}
          handleOnChange={handleOnChange}
          name="disc"
          val={addItem.disc}
          data={addItem}
          handleKeyPress={handleKeyPress}
          fieldIndex={getIndex('disc', 8)}
          index={index}
          isDisabled={isDisabled}
          type="number"
        />
      )}

      {checkPermission('base') && (
        <EditableTextCell
          width={calculateWidth('base')}
          handleOnChange={handleOnChange}
          name="base"
          val={addItem.base}
          data={addItem}
          handleKeyPress={handleKeyPress}
          fieldIndex={getIndex('base', 9)}
          index={index}
          isDisabled={isDisabled}
        />
      )}
      {checkPermission('gst') && (
        <EditableTextCell
          width={calculateWidth('gst')}
          handleOnChange={handleOnChange}
          name="gst"
          val={addItem.gst}
          data={addItem}
          handleKeyPress={handleKeyPress}
          fieldIndex={getIndex('gst', 10)}
          index={index}
          isDisabled={isDisabled}
        />
      )} */}

      {/* <td
        style={{
          padding: 0,
          paddingRight: 10,
          // width: 20,
          backgroundColor: theme.palette.error[100],
          textAlign: 'right',
          fontSize: 12,
          minWidth: 85
        }}
      >
        {addItem.total}
      </td> */}

      <td style={{ padding: 0, width: 30 }}>
        <Stack flexDirection="row" alignItems="center" justifyContent="center">
          <Tooltip title="remove">
            <IconButton onClick={() => setAddItem(initialVal)}>
              <FiTrash2 style={{ fontSize: 14, color: theme.palette.primary[300] }} />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: 'white',
              height: 41,
              // borderTopRightRadius: 0,
              // borderBottomRightRadius: 0,
              borderRadius: 0,
              width: 56
              // backgroundColor: theme.palette.success[500],
            }}
            size="small"
            onClick={() => handleAddBtn()}
          >
            {addItem.rowIndex !== undefined ? 'Edit' : '+ Add'}
          </Button>
        </Stack>
      </td>
    </>
  );
};

export default AddRow;

// const mapStateToProps = (state) => ({
//   doctorProfileDetails: state.get('profileReducer').doctorProfileDetails,
// });

// export default connect(
//   mapStateToProps,
//   {

//   }
// )(EditableRow);
