import { Link as RouterLink, Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Card, Stack, Link, Container, Typography, CardContent, IconButton } from '@mui/material';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import AuthSocial from '../../components/authentication/AuthSocial';
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  borderRight: '1px solid lightgrey'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 0)
}));

// ----------------------------------------------------------------------

export default function LoginForm() {
  window.onbeforeunload = null;
  const navigate = useNavigate();
  return (
    <RootStyle title="Login">
      {/* <AuthLayout>a</AuthLayout> */}

      <MHidden width="mdDown">
        <SectionStyle>
          {/* <Typography variant="h4" sx={{ px: 5, mt: 10, mb: 1, alignSelf: 'center' }}>
            Hi, Welcome Back
          </Typography> */}
          {/* <img src="/static/illustrations/illustration_login.png" alt="login" /> */}

          <Logo Width={400} sx={{ alignSelf: 'center' }} />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Card>
            <CardContent>
              <Stack sx={{ mb: 5 }} spacing={5} direction="row" alignItems="center">
                <Stack alignItems="center">
                  <IconButton
                    onClick={() => navigate('/home')}
                    style={{ backgroundColor: '#e4e8eb', color: '#ba8036' }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography sx={{ fontSize: 12, color: '#ba8036' }}>Back</Typography>
                </Stack>

                <Stack>
                  <Typography variant="h4" gutterBottom>
                    Login to Nirmaan Procura
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    Enter the requested details below.
                  </Typography>
                </Stack>
              </Stack>
              <Outlet />
              <div style={{ marginTop: 15 }}>
                Don’t have an account? &nbsp;
                <Link variant="subtitle2" component={RouterLink} to="/contact">
                  click here to register
                </Link>
              </div>
            </CardContent>
          </Card>

          {/* <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
