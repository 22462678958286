import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ActionMenu = ({ setItem, setOpen, row }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewBid = () => {
    // console.log(row.p_nid);
    localStorage.setItem('reportPid', row.p_nid);
    setAnchorEl(null);
  };

  const handleViewProduct = (row) => {
    setItem(row);
    setOpen(true);
    setAnchorEl(null);
  };

  const menuOpen = Boolean(anchorEl);
  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu id="basic-menu" anchorEl={anchorEl} open={menuOpen} onClose={handleClose}>
        <MenuItem
          key={0}
          sx={{ textDecoration: 'none', color: 'gray' }}
          onClick={() => handleViewProduct(row)}
        >
          View product details
        </MenuItem>
        <MenuItem key={1} sx={{ color: 'grey' }} onClick={() => handleViewBid()}>
          <Link
            style={{ textDecoration: 'none', color: 'gray' }}
            to="/reports/products/bidhistory"
            state={{ row }}
          >
            View bid history
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ActionMenu;
