// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getDatabase, ref, onValue, set } from 'firebase/database';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyAxrvpvkZD8AGeoCi9dqCBVCRnIBwdd8iQ',
//   authDomain: 'nirmaan-club.firebaseapp.com',
//   projectId: 'nirmaan-club',
//   storageBucket: 'nirmaan-club.appspot.com',
//   messagingSenderId: '631313777916',
//   appId: '1:631313777916:web:0a860f02883b46a6018379',
//   measurementId: 'G-DDTQVLHTZE'
// };

const firebaseConfig = {
  apiKey: "AIzaSyCTGncvxdjkKvM7EAP-BSUpvOBrgT80aXU",
  authDomain: "nirmaan-procura.firebaseapp.com",
  projectId: "nirmaan-procura",
  storageBucket: "nirmaan-procura.appspot.com",
  messagingSenderId: "629153962062",
  appId: "1:629153962062:web:0aa95160e9832ecc7b4b1f",
  measurementId: "G-RE86HSSCD7"
};

// Initialize Firebase
const rdb = initializeApp(firebaseConfig);

export const db = getDatabase(rdb);
// const analytics = getAnalytics(app);
