import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { MobileDateRangePicker } from '@mui/lab';
import { compareAsc } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// material
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
  TextField
} from '@mui/material';

// ----------------------------------------------------------------------

export default function PieChartSkeleton({ data }) {
  //  console.log(data);

  return (
    <Card>
      {/*  <CardHeader title="Requirement Chart" /> */}
      <CardContent>
        <Grid container>
          <Grid xs={8} item>
            <Skeleton variant="circular" width={250} height={250} />
          </Grid>
          <Grid xs={4} item>
            <Stack spacing={2}>
              <Skeleton width="40%" />
              <Skeleton width="70%" />
              <Skeleton />
              <Skeleton width="50%" />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
