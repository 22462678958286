import { Button, Divider, Grid, Paper, Skeleton, Stack } from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';

import React from 'react';

const ProfileInfoSkeleton = () => {
  const a = 1;
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item sm={12} xs={12}>
          <h2 style={{ padding: '.5rem', color: 'grey' }}>Profile</h2>
          <Divider />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Skeleton variant="text" sx={{ width: '10%' }} />
        </Grid>
        <Grid item sm={6} xs={12} md={4}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div>
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <Stack sx={{ width: '100%' }}>
                <Skeleton variant="text" sx={{ width: '90%' }} />
                <Skeleton variant="text" sx={{ width: '50%' }} />
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={4}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div>
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <Stack sx={{ width: '100%' }}>
                <Skeleton variant="text" sx={{ width: '90%' }} />
                <Skeleton variant="text" sx={{ width: '50%' }} />
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={4}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div>
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <Stack sx={{ width: '100%' }}>
                <Skeleton variant="text" sx={{ width: '90%' }} />
                <Skeleton variant="text" sx={{ width: '50%' }} />
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={4}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div>
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <Stack sx={{ width: '100%' }}>
                <Skeleton variant="text" sx={{ width: '90%' }} />
                <Skeleton variant="text" sx={{ width: '50%' }} />
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={4}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div>
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <Stack sx={{ width: '100%' }}>
                <Skeleton variant="text" sx={{ width: '90%' }} />
                <Skeleton variant="text" sx={{ width: '50%' }} />
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        {/*   <Grid item sm={6} xs={12} md={4}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div>
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <Stack sx={{ width: '100%' }}>
                <Skeleton variant="text" sx={{ width: '90%' }} />
                <Skeleton variant="text" sx={{ width: '50%' }} />
              </Stack>
            </Stack>
          </Paper>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default ProfileInfoSkeleton;
