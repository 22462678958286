/* eslint-disable camelcase */
import { Stack, styled, Badge, Chip, Typography, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { BiRupee } from 'react-icons/bi';
import { getDatabase, ref, onValue, set, query, equalTo, child, get } from 'firebase/database';
import { useIdleTimer } from 'react-idle-timer';
import { format, formatDistanceToNow } from 'date-fns';
import Label from '../../../../../components/Label';
import { currentBid, lowestBid } from '../../../../../apis/VendorApi';
import { token, decodedJwt } from '../../../../../utils/helper';
import { fCurrency } from '../../../../../utils/formatNumber';
import Blinker from '../../_components/Blinker';
import { db } from '../../../../../firebase';

const Heading = styled('span')(({ theme }) => ({
  fontSize: 13,
  color: 'grey'
}));

const Item = styled('span')(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginLeft: '5px'
  // margin: '1.5px 5px'
}));

const LowestBid = ({ pNid }) => {
  const [lowest, setLowest] = React.useState();
  const [invisible, setInvisible] = React.useState(true);
  const rid = localStorage.getItem('rid');

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const fetchAllBids = () => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `bid`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const arr = [];
          snapshot.forEach((x) => {
            if (rid === x.val().rid) {
              arr.push(x.val());
            }
          });
          localStorage.setItem('previousBid', JSON.stringify(arr));
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const checkLowestUpdate = (latest) => {
    const previousBid = localStorage.getItem('previousBid') || [];
    const arr = [];
    if (previousBid.length > 0) {
      const previous = JSON.parse(previousBid);
      previous.forEach((x) => {
        if (pNid === x.bid_pid && rid === x.rid) {
          arr.push(x);
        }
      });

      const low = arr.length > 0 ? arr[arr.length - 1].bid_amt : 0;
      if (low !== latest) {
        // console.log('previous', low, 'latest', latest, 'pid', pNid);
        setInvisible(false);
        fetchAllBids();
        sleep(5000).then(() => {
          setInvisible(true);
        });
      }
    } else {
      setInvisible(false);
      fetchAllBids();
      sleep(5000).then(() => {
        setInvisible(true);
      });
    }
  };

  /* 

  const fetchLowestBid = async () => {
    const d = {
      rid,
      pNid
    };

    await lowestBid(token, d)
      .then((r) => {
        // console.log(r.data.result);
        checkLowestUpdate(lowest, r.data.result);
        setLowest(r.data.result);
      })
      .catch((e) => console.log(e.message));
  };

  React.useEffect(() => {
    fetchLowestBid();
  }, []);

  const handleChange = () => {
    fetchLowestBid();
  };

  const getCurremnt = useIdleTimer({
    // timeout: 1000 * 60 * 15,
    onAction: handleChange,
    debounce: 1000
  }); */

  useEffect(() => {
    const starCountRef = ref(db, `bid`);
    onValue(starCountRef, (snapshot) => {
      const arr = [];
      snapshot.forEach((x) => {
        if (pNid === x.val().bid_pid && rid === x.val().rid) {
          arr.push(x.val());
        }
      });
      const low = arr.length > 0 ? arr[arr.length - 1].bid_amt : 0;

      checkLowestUpdate(low);
      setLowest(low);
    });
  }, [db, pNid]);

  return (
    <Blinker invisible={invisible}>
      <Label color="info" sx={{ height: 'auto', backgroundColor: 'transparent' }}>
        <Stack>
          <Typography sx={{ fontSize: 14, fontWeight: 800 }}>
            <BiRupee style={{ position: 'relative', top: 2 }} />
            {fCurrency(lowest) || '-'}
          </Typography>
          <Typography sx={{ fontSize: 10, paddingLeft: '5px' }}>Lowest bid</Typography>
        </Stack>
      </Label>
    </Blinker>
  );
};

export default LowestBid;
