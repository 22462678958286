import Cookies from 'js-cookie';

const Logout = () => {
  Cookies.remove('jwt-token');
  Cookies.remove('log_response');
  localStorage.removeItem('reqId');
  localStorage.removeItem('nid');
  localStorage.removeItem('rid');
  localStorage.removeItem('reportPid');
  localStorage.removeItem('reportRid');
  localStorage.removeItem('previousBid');
};

export default Logout;
