import * as Yup from 'yup';

export const rfqProductSchema = Yup.object().shape({
  productName: Yup.string().nullable().required('product name is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  pincode: Yup.number().typeError('you must specify a number'),
  startPrice: Yup.number()
    .typeError('you must specify a number')
    .required('Start price is required'),
  desiredQty: Yup.number()
    .typeError('you must specify a number')
    .required('Desired Quantity is required'),
  // location: Yup.string().required('location is required'),
  unitOfMeasure: Yup.array()
    .min(1, 'Select atleast one unit of measure')
    .required('Unit of measure is required'),
  // targetPrice: Yup.number()
  //   .typeError('you must specify a number')
  //   .required('target price is required'),
  category: Yup.string().required('Category is required'),
  // subCategory: Yup.string().required('Category is required'),
  payTerms: Yup.string().required('payment terms is required'),
  confPo: Yup.string().when('payTerms', {
    is: 'advance payments',
    then: Yup.string().required('confirmation of PO is required')
  }),
  onCredit: Yup.string().when('payTerms', {
    is: 'credit',
    then: Yup.string().required('No. of days on credit is required')
  })
  /*  minQty: Yup.number().typeError('you must specify a number').required('quantity is required'), */
  // deliveryTerms: Yup.string().required('delivery terms is required'),
  // productFile: Yup.array().length(1, 'Product specification is required'),
  // immediate: Yup.boolean(),
  // totalQty: Yup.number().when('immediate', {
  //   is: true,
  //   then: Yup.number().typeError('you must specify a number').required('Total quantity is required')
  // }),
  // services: Yup.object({
  //   service: Yup.boolean(),
  //   serviceWithMaterial: Yup.boolean(),
  //   materialOnly: Yup.boolean()
  // }).test('myCustomTest', null, (obj) => {
  //   if (obj.service || obj.serviceWithMaterial || obj.materialOnly) {
  //     return true; // everything is fine
  //   }

  //   return new Yup.ValidationError('Please check at least one checkbox', null, 'services');
  // })
  /* deliveryChart: Yup.array().when('immediate', {
      is: false,
      then: Yup.array().length('noOfMonths', 'timeline is required')
    }) */

  /*  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
        emailId: Yup.string()
          .email('Email must be a valid email address')
          .required('Email is required'),
    
        turnover: Yup.number('only number is allowed').required('turnover is required'),
        address: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Address is required') */
});
