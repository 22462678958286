export const columns = [
  { label: 'SrNo.', key: 'srNo' },
  {
    label: 'Product name',
    key: 'field_product'
  },
  { label: 'Description', key: 'field_product_description' },

  {
    label: 'Location',
    key: 'field_site_location'
  },
  {
    label: 'Unit of measure ',
    key: 'field_unit_of_measure_req'
  },
  {
    label: 'Target price',
    key: 'field_target_price_buyer'
  },
  {
    label: 'Minimum quantity',
    key: 'field_field_assured_minimum_quan'
  },
  {
    label: 'Advance on confirmation of PO',
    key: 'field_advance_on_confirmation_of'
  },
  {
    label: 'Immediate',
    key: 'field_immediate'
  },
  {
    label: 'Total quantity',
    key: 'field_field_total_quantity'
  },
  {
    label: 'No. of months',
    key: 'number_of_months'
  },
  {
    label: 'Months wise delivery date',
    key: 'field_month_wise_delivery_date'
  },
  {
    label: 'Mode of payment',
    key: 'field_mode_of_payment'
  },
  {
    label: 'Payment terms',
    key: 'field_payment_term_buyer_product'
  },
  {
    label: 'Proposed delivery terms',
    key: 'field_proposed_delivery_terms_bu'
  }
];

export const headCells = [
  { id: 'Srno', label: 'SrNo.', align: 'left' },
  /*  { id: 'nid', label: 'Product Id', align: 'left' }, */
  { id: 'productName', label: 'Product name', align: 'left' },
  { id: 'field_site_location', label: 'Site location', align: 'left' },
  { id: 'field_field_assured_minimum_quan', label: 'Total quantity', align: 'left' },
  { id: 'field_target_price_buyer', label: 'Target price', align: 'left' },
  { id: 'field_mode_of_payment', label: 'Payment mode', align: 'left' }
];
