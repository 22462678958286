import { Card } from '@mui/material';
import React, { useState, useEffect } from 'react';
import UserListToolbar from './UserListToolbar';

import PreviewTable from './PreviewTable';

const PreviewContainer = ({ formik, rows, handleDelete }) => {
  const [query, setQuery] = useState('');
  console.log(rows);
  const search = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter((x) => x.productName.toLowerCase().includes(query));
    return r;
  };

  return (
    <Card sx={{ margin: 2 }}>
      <UserListToolbar numSelected={0} filterName={query} onFilterName={search} />
      <PreviewTable rows={!query ? rows : filtered()} handleDelete={handleDelete} />
    </Card>
  );
};

export default PreviewContainer;
