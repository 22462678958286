import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, TextField, useMediaQuery, useTheme } from '@mui/material';
import { FileDownloadTwoTone } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { getDatabase, ref, onValue, set, query, equalTo, child, get } from 'firebase/database';
import { enqueueSnackbar } from 'notistack';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import TaskBar from '../../../../components/_dashboard/app/TaskBar';
import QuotationList from './QuotationList';
import { getBuyerRfqProductDetails, getRfxBidList } from '../../../../apis/BuyerApi';
import { db } from '../../../../firebase';

const ViewQuotation = () => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const { rfDet, type } = location.state;
  const [rows, setRows] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [newBids, setNewBids] = useState([]);

  const [query, setQuery] = useState('');

  const getList = async () => {
    await getRfxBidList({
      nid: rfDet.nid
    }).then((res) => {
      if (res.status) {
        setBidList(res.result.reverse());
      }
    });
  };

  const getProductList = async () => {
    await getBuyerRfqProductDetails({
      type: 'rfx',
      nid: rfDet.nid
    }).then((res) => {
      if (res.status) {
        setRows(res.result || []);
      }
    });
  };

  useEffect(() => {
    const starCountRef = ref(db, `bid`);
    onValue(starCountRef, (snapshot) => {
      const arr = [];
      snapshot.forEach((x) => {
        if (rfDet.nid === x.val().rid) {
          arr.push(x.val());
        }
      });
      const newItem = arr.length > 0 && arr[arr.length - 1];
      setNewBids([...arr]);
      if (newBids.length !== arr.length) {
        enqueueSnackbar('Updated', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
        getList();
      }
    });
  }, [db]);

  useEffect(() => {
    getList();
    getProductList();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.title.toLowerCase().includes(query.toLowerCase()) ||
        x.field_product_name_rfq.toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  const getCsvProps = () => {
    if (bidList && bidList.length > 0) {
      const column = Object.keys(bidList[0])
        // .filter((y) => y !== 'bid_uid' || y !== 'pid_nid')
        .map((x) => ({ key: x, label: x }));
      // column.push({ key: 'vendor', label: 'vendor' });
      // column.push({ key: 'productName', label: 'productName' });

      const data = bidList;
      const finalRows = [];
      data.forEach((x, index) => {
        const srNo = index + 1;
        const created = moment(parseInt(x.bid_time, 10) * 1000).format('DD-MM-YYYY HH:mm');

        const temp = { ...x, srNo, bid_time: created };
        finalRows.push(temp);
      });

      const csvReport = {
        data: finalRows,
        headers: [...column],
        filename: `${rfDet.name}-bidlist.csv`
      };
      // console.log(csvReport);
      return csvReport;
    }
  };

  return (
    <div>
      <TaskBar
        leftItems={() => (
          <>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> RFX Name: </span> {rfDet.name}
            </Box>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> RFX ID: </span> {rfDet.field_rfx_id}
            </Box>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search"
              sx={{ mt: '0px !important' }}
              fullWidth={isMdDown}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </>
        )}
        rightItems={() => (
          <>
            {bidList.length > 0 && (
              <CSVLink {...getCsvProps()}>
                <Button variant="outlined" size="small">
                  <FileDownloadTwoTone style={{ fontSize: 20, marginRight: 5 }} />
                  CSV
                </Button>
              </CSVLink>
            )}
          </>
        )}
      />
      {/* {rows.length > 0 && (
        <Paper elevation={3} sx={{ width: 300, m: 1 }}>
          <Box sx={{ fontSize: 14 }}>{rows[0].user_full_name}</Box>
          <Box sx={{ fontSize: 14 }}>{rows[0].}</Box>

        </Paper>
      )} */}

      <QuotationList rows={!query ? rows : filtered()} bidList={bidList} rfDet={rfDet} />
    </div>
  );
};

export default ViewQuotation;
