/* eslint-disable camelcase */
import * as React from 'react';
import { Breadcrumbs, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHeading from '../../../../components/table/TableHeading';
import { Heading, ItemContainer } from '../../../../components/table/styles';
import { getComparator, stableSort } from '../../../../components/table/utils';
// import BasicMenu from './BasicMenu';
import { headCells } from './components/data';
import FullDialog from '../components/FullDialog';
import ViewProductInfo from './ViewProductInfo';

export default function ProductTable({ rows }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();
  /*  const handleAction = (x) => {
    setOpen(true);
    setData(x);
  }; */

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDetailAction = (row) => {
    setOpen(true);
    setData(row);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filtered = stableSort(rows, getComparator(order, orderBy));

  const NotFound = filtered.length === 0;

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer sx={{ minWidth: 650, overflow: 'auto', maxHeight: 420 }}>
        <Table>
          <TableHeading
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows && rows.length}
            headCells={headCells}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {filtered
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const {
                  product_id,
                  field_product_id,
                  name,
                  field_unit_of_measure,
                  field_place_of_supply,
                  description,
                  field_category
                } = row;
                const Srno = index + 1;

                const category = Object.values(field_category);

                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell>{Srno}</TableCell>
                    <TableCell>{field_product_id}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      <Breadcrumbs separator="-" maxItems={1} sx={{ fontSize: 13 }}>
                        {category.map((y, i) => (
                          <span key={i}>{y}</span>
                        ))}
                      </Breadcrumbs>
                    </TableCell>
                    <TableCell>{field_place_of_supply}</TableCell>
                    <TableCell align="right">
                      <Button onClick={() => handleDetailAction(row)}>View more</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {NotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  Not found
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <FullDialog open={open} setOpen={setOpen} headName="products">
        <ViewProductInfo data={data} />
      </FullDialog>
    </Box>
  );
}

/* const category = Object.values(field_category);
                const Srno = index + 1;
                const address = field_supply_location.map((x, i) => (
                  <span key={i}>
                    {x.value}
                    {i !== field_supply_location.length - 1 && ', '}
                  </span>
                )); */
/* [
  {
    id: 90,
    value: 'pipe',
    uni: 'PI'
  },
  {
    id: 90,
    value: 'pipe',
    uni: 'PI'
  },
  {
    90: 'pipe',
    90: 'pipe',
  }
]
] */
