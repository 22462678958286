/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
  TextField,
  IconButton,
  Typography,
  Stack,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  getDatabase,
  ref,
  onValue,
  set,
  push,
  get,
  child,
  query,
  equalTo
} from 'firebase/database';
import { styled } from '@mui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { BiRupee } from 'react-icons/bi';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useEffect, useState } from 'react';
import FullDialog from '../../_components/FullDialog';
import BidInfo from './BidInfo';
import { bidInfoData } from './bidData';

import { bidSubmit, currentBid, lowestBid } from '../../../../../apis/VendorApi';
import { token, decodedJwt } from '../../../../../utils/helper';
import SmallSnackbar from '../../../../../components/SnackBar';
import Page from '../../../../../components/Page';
import { fCurrency } from '../../../../../utils/formatNumber';
import CurrentBid from './CurrentBid';
import { db } from '../../../../../firebase';
// validation changes
const BidButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: 11,
  height: 40,
  width: 150,
  position: 'relative',
  right: -14,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
}));

const BidInput = (props) => {
  const [open, setOpen] = useState(false);
  const [nextBid, setNextBid] = useState();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [snack, setSnack] = useState({
    open: false,
    color: '',
    message: ''
  });
  const [bidChange, setBidChange] = useState({
    value: '',
    type: ''
  });
  const { data, pNid, bidingRules, companyName, rows, fetchAllBids } = props;
  // console.log(data.field_start_price, 'start')
  const rid = localStorage.getItem('rid');

  /*  const userBidRef = ref(db, `bid/${rid}/${data.p_nid}/${decodedJwt.uid}`);
  onValue(userBidRef, (snapshot) => {
    const data = snapshot.val();
    console.log(data);
  }); */
  // console.log(decodedJwt);
  const checkBidChange = () => {
    if (data.field_minimum_change_value) {
      setBidChange({
        value: data.field_minimum_change_value,
        type: data.field_minimum_bid_change
      });
    } else if (bidingRules) {
      setBidChange({
        value: bidingRules.field_change_value,
        type: bidingRules.field_minimum_bid_change
      });
    }
  };

  useEffect(() => {
    checkBidChange();
  }, [pNid, rows]);

  /*  useEffect(() => {
    const starCountRef = ref(db, `bid`);
    onValue(starCountRef, (snapshot) => {
      const arr = [];
      snapshot.forEach((x) => {
        if (pNid === x.val().bid_pid && rid === x.val().rid) {
          arr.push(x.val());
        }
      });
      const low = arr.length > 0 ? arr[arr.length - 1].bid_amt : 0;

      let bid;
      if (low) {
        bid = low;
      } else {
        bid = 0;
      }
      //  console.log(low, 'lowest bid');

      if (bidChange.type === 'Amount') {
        const calcAmt = bid - bidChange.value;
        console.log(calcAmt, 'calculated');
        setNextBid(calcAmt);
      }
      if (bidChange.type === 'Percentage') {
        const perc = (bid * bidChange.value) / 100;
        const calcAmt = bid - perc;
        console.log(calcAmt, 'calculated');
        setNextBid(calcAmt);
      }
    });
  }, [db, pNid, rows]); */

  const amtSchema = Yup.object().shape({
    bidAmt: Yup.number('only numbers are allowed')
      .required('Please enter amount')
      /*  .test('len', 'amount should be less', (val) => val && val.toString().length <= 7) */
      .test(
        'start_price',
        'amount should be less than start price',
        (val) => val < data.field_start_price
      )
      .test(
        'bid_change',
        `amount should be as per bid change i.e ${bidChange.type === 'Amount' ? '₹' : ''}
        ${bidChange.value || '-'}
        ${bidChange.type === 'Percentage' ? '%' : ''}`,
        async (val) => {
          const d = {
            rid,
            pNid
          };
          let ans;
          let bid;
          await lowestBid(token, d)
            .then((r) => {
              if (r.data.result) {
                bid = r.data.result;
              } else {
                bid = 0;
              }

              console.log(r.data.result);
            })
            .catch((e) => /* console.log(e.message) */ (bid = 0));

          if (bidChange.type === 'Amount') {
            if (bid === 0) bid = data.field_start_price;
            // console.log(bid);
            const calcAmt = bid - bidChange.value;
            console.log(calcAmt);
            // setNextBid(calcAmt);
            ans = val <= calcAmt;
            return ans;
          }
          if (bidChange.type === 'Percentage') {
            if (bid === 0) bid = data.field_start_price;
            const perc = (bid * bidChange.value) / 100;
            const calcAmt = bid - perc;
            console.log(calcAmt);
            // setNextBid(calcAmt);
            ans = val <= calcAmt;
            return ans;
          }

          // return ans;
        }
      )
      .test('more than zero', 'amount should be greater than zero', (val) => val > 0)
  });

  const formik = useFormik({
    initialValues: {
      bidAmt: ''
    },
    validationSchema: amtSchema,
    onSubmit: async () => {
      const totalCost = data.field_minimum_desired_quantity * values.bidAmt;
      const d = {
        rid,
        bid_pid: data.p_nid,
        bid_amt: values.bidAmt,
        // bid_unit_cost: data.field_unit_cost,
        bid_total_cost: totalCost,
        company_id: companyName.tid
      };

      const bidRef = ref(db, 'bid');
      const newBidRef = push(bidRef);

      set(newBidRef, {
        ...d,
        uid: decodedJwt.uid,
        bid_time: Date.now()
      });

      console.log(d);
      await bidSubmit(token, d)
        .then((r) => {
          console.log(r.data, 'bid submitted');
          if (r.data.status === true) {
            fetchAllBids();
            setSnack({
              open: true,
              color: 'info',
              message: 'Bid submitted'
            });
          }
        })
        .catch((e) => {
          setSnack({
            open: true,
            color: 'error',
            message: 'Something went wrong try again later'
          });
          console.log(e.message);
        });
      resetForm();
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    const starCountRef = ref(db, `bid`);
    onValue(starCountRef, (snapshot) => {
      const arr = [];
      snapshot.forEach((x) => {
        if (pNid === x.val().bid_pid && rid === x.val().rid) {
          arr.push(x.val());
        }
      });
      const low = arr.length > 0 ? arr[arr.length - 1].bid_amt : 0;

      let ans;
      let bid = low;
      let val = values.bidAmt;
      console.log(bidChange, 'bidChange');
      if (bidChange.type === 'Amount') {
        if (bid === 0) bid = data.field_start_price;
        // console.log(bid);
        const calcAmt = bid - bidChange.value;
        console.log(calcAmt, 'calcAmt');
        setNextBid(calcAmt);
        ans = val <= calcAmt;
        return ans;
      }
      if (bidChange.type === 'Percentage') {
        if (bid === 0) bid = data.field_start_price;
        const perc = (bid * bidChange.value) / 100;
        const calcAmt = bid - perc;
        console.log(calcAmt);
        setNextBid(calcAmt);
        ans = val <= calcAmt;
        return ans;
      }

      // setNextBid(low);
    });
  }, [db, pNid, bidChange]);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <Page>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={10}>
              <Stack spacing={2}>
                <CurrentBid pNid={pNid} />
                <TextField
                  type="text"
                  label="Amount"
                  size="small"
                  value={values.bidAmt}
                  {...getFieldProps('bidAmt')}
                  error={Boolean(touched.bidAmt && errors.bidAmt)}
                  helperText={touched.bidAmt && errors.bidAmt}
                  InputProps={{
                    shrink: 'true',
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    endAdornment: (
                      <BidButton
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Submit bid
                      </BidButton>
                    ),
                    maxLength: 10
                  }}
                />
                {nextBid ? (
                  <Stack spacing={1} direction="row" alignItems="center">
                    <InfoOutlinedIcon sx={{ width: 15, height: 15 }} />
                    <Typography sx={{ fontSize: 12 }}>
                      your next bid should be or less than{' '}
                      <BiRupee style={{ position: 'relative', top: 2 }} />
                      {fCurrency(nextBid) || ''}
                    </Typography>
                  </Stack>
                ) : (
                  ''
                )}
              </Stack>
            </Grid>
            <Grid
              item
              sm={1}
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {isMdDown ? (
                <>
                  <Button variant="outlined" onClick={handleClick}>
                    {' '}
                    <InfoOutlinedIcon sx={{ mr: 1 }} />
                    View Bid History
                  </Button>
                </>
              ) : (
                <IconButton onClick={handleClick}>
                  <InfoOutlinedIcon sx={{ width: 25, height: 25 }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <FullDialog open={open} setOpen={setOpen} headName="Bid history" active>
        <BidInfo rows={bidInfoData} pNid={data.p_nid} />
      </FullDialog>
      <SmallSnackbar snack={snack} setSnack={setSnack} />
    </Page>
  );
};

export default BidInput;
