import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { formContext } from '../../FormContainer';

const SubmitForm = ({ prod, setSnack, setChartError, setFlag }) => {
  const navigate = useNavigate();
  const form = useContext(formContext);
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    form.productFormik;

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleMainSubmit = async () => {
    if (prod.length === 0) {
      if (checkTimelineValidation() === true) {
        setFlag(1);
        await handleSubmit();
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (values.productName !== '') {
        setFlag(1);
        await handleSubmit();
      } else {
        localStorage.removeItem('reqId');
        localStorage.removeItem('nid');
        localStorage.removeItem('companyName');
        setSnack({
          open: true,
          color: 'success',
          message: `Requirement generated successfully`
        });
        sleep(1000).then(() => {
          navigate('/requirementList');
        });
      }
    }
  };

  const checkTimelineValidation = () => {
    let decision;
    if (values.immediate === false) {
      if (values.noOfMonths === values.deliveryChart.length) {
        setChartError('');
        decision = true;
      } else {
        setChartError('Complete requirement timeline is required');
        decision = false;
      }
    } else {
      decision = true;
    }
    return decision;
  };

  const handleProductSubmit = async () => {
    if (checkTimelineValidation() === true) await handleSubmit();
  };
  return (
    <Grid item xs={12} sm={12} align="right">
      <LoadingButton
        onClick={handleProductSubmit}
        type="submit"
        sx={{ mr: 1 }}
        variant="outlined"
        loading={isSubmitting ? true : undefined}
        disabled={isSubmitting}
      >
        Save & add new product
      </LoadingButton>
      <LoadingButton
        variant="contained"
        onClick={() => handleMainSubmit()}
        disabled={isSubmitting}
        loading={isSubmitting ? true : undefined}
      >
        Submit
      </LoadingButton>
    </Grid>
  );
};

export default SubmitForm;
