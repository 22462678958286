// material
import { Box, Grid, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReqChartSkeleton from '../components/_dashboard/app/skeleton/ReqChartSkeleton';
import PieChartSkeleton from '../components/_dashboard/app/skeleton/PieChartSkeleton';
import { getBuyerDetails, getBuyerRequirementList } from '../apis/BuyerApi';
// components
import Page from '../components/Page';
import {
  AppTasks,
  Verified,
  Pending,
  Completed,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  TotalReq,
  UserProfile,
  Activities,
  ReqChart,
  ReqPieChart
} from '../components/_dashboard/app';
import { userData, getRole, token } from '../utils/helper';

// ----------------------------------------------------------------------

// new chart

export default function BuyerDashboardApp() {
  const { fullName } = userData;
  const [data, setData] = useState();
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);

  const getDetails = async () => {
    const d = await getBuyerDetails(token);
    if (d.status === true) {
      setData({
        name: d.result.owner_name,
        email: d.result.owner_email,
        mobile: d.result.owner_mobile_number,
        address: d.result.Vendor_address,
        designation: d.result.designation
      });
    }
  };

  const getList = async () => {
    await getBuyerRequirementList(token).then((r) => {
      // setRows(r.result);
      // console.log(r.result);
      isLoading(false);
    });
  };
  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    getDetails();
  }, []);
  // window.location.reload();
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            Welcome, <span style={{ color: '#b98036' }}>{fullName}</span>
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <TotalReq data={rows} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Verified data={rows} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Completed data={rows} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Pending data={rows} />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <UserProfile data={data} />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Activities />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            {loading ? <ReqChartSkeleton /> : <ReqChart data={rows} />}

            {/* <DataTable /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            {/* <AppCurrentSubject /> */}
            {loading ? <PieChartSkeleton /> : <ReqPieChart data={rows} />}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {/* <AppNewsUpdate /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <AppOrderTimeline /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <AppTrafficBySite /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {/* <AppTasks /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
