import { Box, Card, Collapse, Paper, Stack } from '@mui/material';
import React from 'react';
import moment from 'moment';
import Label from '../../../../components/Label';

import InvitationStatus from './InvitationStatus';

const MobileInvitationListCard = ({ row, index, selectedIndex, setSelectedIndex }) => {
  // const []
  console.log('a');
  return (
    <Paper
      sx={{ borderBottom: '1px solid lightgrey', m: 1, p: 1 }}
      square
      onClick={() => setSelectedIndex(selectedIndex === index ? null : index)}
    >
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box>{index + 1}</Box>
          <Stack>
            <Box>{row.vendor_name}</Box>
            <Box style={{ fontSize: 12, color: 'grey' }}>
              {' '}
              {moment(parseInt(row.created, 10) * 1000).format('DD-MM-YYYY hh:mm A')}
            </Box>
          </Stack>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Label variant="ghost" color="warning" style={{ fontSize: 12, padding: 12 }}>
            {row.invitation_id}
          </Label>
        </Stack>
      </Stack>
      <Collapse in={selectedIndex === index} timeout="auto" unmountOnExit>
        <Stack sx={{ ml: 2, mt: 0.8 }} gap={1}>
          {/* <Box>
            <Label variant="ghost" color="warning" style={{ fontSize: 12, padding: 12 }}>
              {row.invitation_id}
            </Label>
          </Box> */}
          <Box mt={2}>
            <InvitationStatus row={row} />
          </Box>
        </Stack>
      </Collapse>
    </Paper>
  );
};

export default MobileInvitationListCard;
