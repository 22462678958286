import { Card, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/styles';
import UserListToolbar from './UserListToolbar';
import { rows } from './data';
import NotiTable from './NotiTable';
import { ReadAllNotification } from '../../apis/CommonApi';
import { getRole, token } from '../../utils/helper';
import Page from '../../components/Page';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
  color: theme.palette.primary.main
}));

const NotiContainer = () => {
  const [query, setQuery] = useState('');
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);

  const search = (event) => {
    setQuery(event.target.value);
  };

  const getList = async () => {
    await ReadAllNotification(token, getRole()).then((r) => {
      setRows(r.data.result.reverse());
      // console.log(r.data.result);
      isLoading(false);
    });
  };
  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter((x) => x.message.toLowerCase().includes(query.toLowerCase()));
    return r;
  };

  return (
    <Page title="Notifications">
      {loading ? (
        <Loader>
          <CircularProgress />
        </Loader>
      ) : (
        <Card>
          <UserListToolbar numSelected={0} filterName={query} onFilterName={search} list />
          <NotiTable rows={!query ? rows : filtered()} />
        </Card>
      )}
    </Page>
  );
};

export default NotiContainer;
