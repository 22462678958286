import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import { FileDownloadTwoTone } from '@mui/icons-material';
import { AddCircleTwoTone } from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ProductList from './ProductList';
import TaskBar from '../../../../../components/_dashboard/app/TaskBar';
import { getBuyerRfqProductDetails } from '../../../../../apis/BuyerApi';

const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { rfDet, type } = location.state;
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [rows, setRows] = useState([]);
  const [query, setQuery] = useState('');

  const getProductList = async () => {
    await getBuyerRfqProductDetails({
      type,
      nid: rfDet.nid
    }).then((res) => {
      if (res.status) {
        setRows(res.result || []);
      }
    });
  };

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.title.toLowerCase().includes(query.toLowerCase()) ||
        x.field_product_name_rfq.toString().toLowerCase().includes(query.toLowerCase()) ||
        x.field_services.toString().toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  const getCsvProps = () => {
    if (rows && rows.length > 0) {
      const column = Object.keys(rows[0]).map((x) => ({ key: x, label: x }));
      // column.push({ key: 'startDate', label: 'startDate' });
      // column.push({ key: 'endDate', label: 'endDate' });

      const data = rows;
      const finalRows = [];
      data.forEach((x, index) => {
        const srNo = index + 1;
        // const startDate = moment(
        //   parseInt(x.schedule[x.schedule.length - 1].close_date, 10) * 1000
        // ).format('DD-MM-YYYY HH:mm');
        // const endDate = moment(
        //   parseInt(x.schedule[x.schedule.length - 1].close_date, 10) * 1000
        // ).format('DD-MM-YYYY HH:mm');
        const uom = x.field_unit_of_measure.map((y) => y.default_unit_of_measure).join(' ');
        const cat = x.product_cat.map((y) => y.name).join(' ');
        // const created = moment(parseInt(x.created, 10) * 1000).format('DD-MM-YYYY HH:mm');
        const temp = { ...x, srNo, field_unit_of_measure: uom, product_cat: cat };
        finalRows.push(temp);
      });

      const csvReport = {
        data: finalRows,
        headers: [...column],
        filename: `${type === 'rfq' ? rfDet.title : rfDet.name}-products.csv`
      };
      // console.log(csvReport);
      return csvReport;
    }
  };

  return (
    <div>
      <TaskBar
        leftItems={() => (
          <>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> {type.toUpperCase()} Name: </span>{' '}
              {type === 'rfq' ? rfDet.title : rfDet.name}
            </Box>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> {type.toUpperCase()} ID: </span>{' '}
              {type === 'rfq' ? rfDet.field_rfq_id : rfDet.field_rfx_id}
            </Box>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search"
              sx={{ mt: '0px !important' }}
              fullWidth={isMdDown}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </>
        )}
        rightItems={() => (
          <>
            {rows.length > 0 && (
              <CSVLink {...getCsvProps()}>
                <Button variant="outlined" size="small">
                  <FileDownloadTwoTone style={{ fontSize: 20, marginRight: 5 }} />
                  CSV
                </Button>
              </CSVLink>
            )}

            <Button
              size="small"
              variant="contained"
              onClick={() =>
                navigate(`/${type}/products/add`, {
                  state: {
                    rfDet,
                    type
                  }
                })
              }
            >
              <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
              Add Product
            </Button>
          </>
        )}
      />
      <ProductList rows={!query ? rows : filtered()} rfDet={rfDet} type={type} />
    </div>
  );
};

export default Products;
