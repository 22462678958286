import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { FaRegFilePdf, FaRegImage } from 'react-icons/fa';
import { TbFileTypeCsv } from 'react-icons/tb';
import React, { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Backup, Close, CloudUpload } from '@material-ui/icons';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';
import Label from '../../../../../../components/Label';
import { rfqProductSchema } from '../../../../../../utils/formikSchema';
import { aPo, creditOp, decodedJwt, serv, terms } from '../../../../../../utils/helper';
import {
  addProductByCsv,
  getCategory,
  getUnitOfMeasure,
  submitBuyerNodeProduct
} from '../../../../../../apis/BuyerApi';
import CsvUpload from './CsvUpload';
import EditableProductTable from './EditableProductTable';

const ls = require('local-storage');

const useStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 150,
      maxWidth: 170
    },
    drip: {
      minHeight: '130px',
      maxHeight: '130px',
      color: 'grey',
      backgroundColor: '#FFF7CD',

      fontSize: 14
    }
  })
);
export const ProductContext = createContext();

const initialVal = {
  medid: '',
  productName: '',
  uom: '',
  desiredQty: '',
  startPrice: '',
  payTerms: '',
  confPo: '',
  credit: '',
  attachment: [],
  desc: ''
  // camount: '',
  // sper: '',
  // msettel: '',
  // s_profit: '',
  // rtot: '',
  // cartItem: false
};

// const validationSchema = Yup.object().shape({
//   qty: Yup.string()
//     .required('Quantity is required')
//     .test('myCustomTest', null, (val) => {
//       console.log(val, 'qtyyyyyyy');
//       if (val >= 1) {
//         return true; // everything is fine
//       }

//       return new Yup.ValidationError('Quantity should be more than 0', null, 'qty');
//     }),
//   expiry: Yup.string().required('Expiry Date is required'),
//   batch: Yup.string().required('Batch No. is required'),
//   mrp: Yup.string().required('MRP is required'),
//   ptr: Yup.string()
//     .required('PTR is required')
//     .test('ptrTest', null, (val) => {
//       console.log(val, 'ptrrrrrr');
//       if (val >= 1) {
//         return true; // everything is fine
//       }

//       return new Yup.ValidationError('PTR should be more than 0', null, 'ptr');
//     })
//     .test('ptrTest2', null, (val, testContext) => {
//       console.log(testContext, 'ptrrrrrr');
//       if (testContext.parent.mrp >= val) {
//         return true; // everything is fine
//       }

//       return new Yup.ValidationError('PTR should be less than MRP', null, 'ptr');
//     })

//   // lastName: Yup.string().required("Last name is required"),
//   // mobileNumber: Yup.number().required("Mobile number is required"),
//   // drivingLicense: Yup.string().required("Driving license is required"),
//   // // .matches(
//   // //   // /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
//   // //   /^[A-Z]{2}-\d{13}$/,
//   // //   "Invalid driving license format"
//   // // ),
//   // service_pincode: Yup.number().required("Servicable pincode is required"),
//   // address: Yup.string().required("Address is required"),
//   // status: Yup.string(),
// });

export const useTableStyles = makeStyles((theme) => ({
  table: {
    borderSpacing: 0
  },
  th: { padding: '8px 10px', textAlign: 'left', fontSize: 12 },
  td: {
    padding: '2px 10px',
    borderBottom: '1px solid lightgrey',
    fontSize: 12
  },
  tr: { borderBottom: '1px solid lightgrey' },
  listbox: {
    width: 'auto',
    fontSize: 12,
    margin: 0,
    marginTop: 8,
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    // borderBottomLeftRadius: 6,
    // borderBottomRightRadius: 6,
    // elevation: 6,
    zIndex: 1000,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: 'white',
    overflow: 'auto',
    maxHeight: 200,
    // border: "1px solid rgba(0,0,0,.25)",
    '& .Mui-focused': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      cursor: 'pointer'
    },
    '& .Mui-focusVisible': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      cursor: 'pointer'
    },
    '& li[data-focus="true"]': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      cursor: 'pointer'
    },
    '& li:active': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white'
    }
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.secondary[100], // Use Material-UI theme for consistency
      cursor: 'pointer' // Optional: Change cursor to pointer on hover
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const stateList = [
  { id: 1, name: 'Gujarat' },
  { id: 2, name: 'Maharashtra' },
  { id: 3, name: 'Rajasthan' },
  { id: 4, name: 'Delhi' }
];

const cityList = [
  { id: 1, name: 'Vapi' },
  { id: 2, name: 'Mumbai' },
  { id: 3, name: 'Surat' },
  { id: 4, name: 'Ahmedabad' }
];

const AddPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const tableStyle = useTableStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { rfDet, type, editData } = location.state;
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [value, setValue] = React.useState('1');
  const [productItems, setProductItems] = React.useState([]);
  const [productAttachments, setProductAttachments] = useState([]);
  const [addItem, setAddItem] = useState(initialVal);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [csvFile, setCsvFile] = useState();

  const currentAppFromStorage = (appId) => {
    const performa = ls('performa');
    return performa ? performa.find((item) => item.rfq_id === appId) : null;
  };

  const appFromStorage = () => {
    const performa = ls('performa');
    return performa;
  };

  const updateCurrentAppInStorage = (currentData) => {
    const lsData = appFromStorage() || [];
    const restData = lsData.filter((item) => item.rfq_d !== currentData.rfq_id);
    const finalArr = [...restData, currentData];
    // const finalArr = [currentData];
    console.log(finalArr, 'finalArr');
    ls('performa', finalArr);
    return finalArr;
  };

  const deleteOrderFromStorage = (orderNo) => {
    const lsData = appFromStorage() || [];
    const restData = lsData.filter((item) => item.rfq_id !== orderNo);
    console.log(restData);
    // const finalArr = [...restData, currentData]
    const finalArr = [...restData];
    ls('performa', finalArr);

    return finalArr;
  };

  useEffect(() => {
    const currentApp = currentAppFromStorage(rfDet.nid);
    console.log(currentApp, 'currentApp2');
    if (currentApp) {
      if (currentApp.productItems.length === 0) {
        // setProductItems([...currentApp.productItems, initialVal]);
        setProductItems([...currentApp.productItems]);
      } else {
        setProductItems([...currentApp.productItems]);
      }
    } else {
      const newPerformaData = [];
      // const newPerformaData = [initialVal];

      updateCurrentAppInStorage({
        rfq_id: rfDet.nid,
        productItems: newPerformaData
      });
      setProductItems([...newPerformaData]);
    }
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  console.log(editData, 'editData');
  const initialVals = {
    productName: editData ? editData.field_product_name_rfq : '',
    category: '',
    subCategory: '',
    bidChange: '',
    changeValue: '',
    services: {
      service: false,
      serviceWithMaterial: false,
      materialOnly: false
    },
    unitOfMeasure: [],
    desiredQty: '',
    startPrice: '',
    payTerms: '',
    confPo: '',
    onCredit: '',
    state: '',
    city: '',
    pincode: '',
    productFile: [],
    desc: '',
    serviceType: '',
    address: ''
  };

  const fetchCategories = async () => {
    await getCategory().then((res) => {
      if (res.result) setCategories(res.result.map((x) => ({ tid: x.pid, name: x.name })));
    });
  };

  const fetchUnits = async () => {
    await getUnitOfMeasure().then((res) => {
      if (res.result) setUnits(res.result);
    });
  };
  useEffect(() => {
    fetchCategories();
    fetchUnits();
    if (editData) {
      if (editData.product_cat.length > 0) {
        setFieldValue('category', editData.product_cat[0].tid);
      }

      if (editData.field_state) {
        setFieldValue('state', editData.field_state);
      }
      if (editData.field_city) {
        setFieldValue('city', editData.field_city);
      }
      if (editData.field_pin_code) {
        setFieldValue('pincode', editData.field_pin_code);
      }
      if (editData.field_services) {
        const servArr = editData.field_services.split(',');
        // console.log(servArr, 'servArr');
        if (servArr.includes('Service')) setFieldValue('services.service', true);
        if (servArr.includes('Service With Material'))
          setFieldValue('services.serviceWithMaterial', true);
        if (servArr.includes('Material Only')) setFieldValue('services.materialOnly', true);
      }

      if (editData.field_unit_of_measure) {
        setFieldValue(
          'unitOfMeasure',
          editData.field_unit_of_measure.map((x) => x.default_unit_of_measure)
        );
      }

      if (editData.field_minimum_desired_quantity) {
        setFieldValue('desiredQty', editData.field_minimum_desired_quantity);
      }

      if (editData.field_start_price) {
        setFieldValue('startPrice', editData.field_start_price);
      }
      if (editData.field_payment_term_buyer_product) {
        setFieldValue('payTerms', editData.field_payment_term_buyer_product);
      }
      if (editData.description) {
        setFieldValue('desc', editData.description);
      }
    }
  }, []);

  const fileReject = () => {
    // setSnack({
    //   open: true,
    //   color: 'error',
    //   message: `Only pdf and image file is allowed`
    // });
  };
  const hamdleManualProducts = async () => {
    if (productItems.length > 0) {
      productItems.forEach((row, i) => {
        // const keys = Object.keys(values.services);
        // const filteredServices = keys.filter((key) => values.services[key]);
        // const newServ = filteredServices.map((y) => serv.find((x) => x.key === y).value);
        const uom = units.find((y) => y.name === row.uom);
        if (row.productName !== '') {
          const params = {
            type,
            nid: rfDet.nid,
            field_services: values.serviceType,
            unitOfMeasure: [uom.tid],
            product_cat: parseInt(values.category, 10),
            name: row.productName,
            // field_minimum_bid_change: 'Amount',
            field_minimum_desired_quantity: row.desiredQty,
            // change_value: 10,
            field_start_price: type === 'rfx' ? row.startPrice : '',
            field_payment_term_buyer_product: row.payTerms,
            field_number_of_days: row.credit,
            field_state: values.state,
            field_city: values.city,
            field_pin_code: values.pincode,
            field_description_field: row.desc || '',
            productFile: row.attachment
          };
          submitBuyerNodeProduct(params)
            .then((res) => {
              if (res.status) {
                // enqueueSnackbar('Product Added Successfully!', {
                //   variant: 'success'
                // });
                // navigate(-1);
                if (i === productItems.length - 1) {
                  enqueueSnackbar('Product Added Successfully!', {
                    variant: 'success'
                  });
                  // setProductItems([initialVal]);
                  setProductItems([]);

                  setProductAttachments([]);
                  setSubmitLoading(false);
                  navigate(-1);
                }
              } else {
                setSubmitLoading(false);
              }
            })
            .catch((e) => {
              setSubmitLoading(false);
            });
        }
      });
    } else {
      setSubmitLoading(false);
      enqueueSnackbar('Atleast add one product!', {
        variant: 'error'
      });
    }
  };

  const handleCsvUpload = async () => {
    if (csvFile) {
      const formdata = new FormData();
      formdata.append('role', 'buyer');
      formdata.append('type', type);
      formdata.append('uid', decodedJwt.uid);
      formdata.append('nid', rfDet.nid);
      formdata.append('field_services', values.serviceType);
      formdata.append('product_cat', parseInt(values.category, 10));
      formdata.append('field_state', values.state);
      formdata.append('field_city', values.city);
      formdata.append('field_pin_code', values.pincode);
      formdata.append('product_csv_file', csvFile, 'product-Sheet1.csv');

      console.log(formdata, 'formdata');
      await addProductByCsv(formdata)
        .then((res) => {
          setSubmitLoading(false);
          if (res.data.status === true) {
            setCsvFile();
            enqueueSnackbar('Bulk Product Uploaded Successfully!', {
              variant: 'success'
            });
            navigate(-1);
          }
        })
        .catch((e) => {
          setSubmitLoading(false);
          console.log(e);
        });
    } else {
      setSubmitLoading(false);
      enqueueSnackbar('Please upload csv file!', {
        variant: 'error'
      });
    }
  };

  const productFormik = useFormik({
    initialValues: initialVals,
    validationSchema: Yup.object().shape({
      state: Yup.string().required('State is required'),
      city: Yup.string().required('City is required'),
      pincode: Yup.number().typeError('you must specify a number'),
      category: Yup.string().required('Category is required')
    }),
    onSubmit: async () => {
      setSubmitLoading(true);
      if (value === '1') {
        hamdleManualProducts();
      } else if (value === '2') {
        handleCsvUpload();
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    isValid
  } = productFormik;
  console.log(errors, values);

  const addRowFormik = useFormik({
    initialVal,
    onSubmit: () => {
      console.log('here');
      console.log('here');
    },
    validationSchema: rfqProductSchema
  });

  const handleEdit = (row, index) => {
    setAddItem({
      ...row,
      rowIndex: index
    });
  };
  // console.log(values, errors, 'values');
  // const { service, serviceWithMaterial, materialOnly } = values.services;

  return (
    <Box mx={2}>
      {/* <h3>Add Product</h3> */}
      <Grid container spacing={2} sx={{ borderBottom: '1px solid lightgrey', mb: 1, p: 0.8 }}>
        <Grid item xs={12} sm={2} sx={{ flexDirection: 'row', display: 'flex', gap: 2 }}>
          <Box>
            <Box sx={{ fontSize: 11, color: 'gray' }}>{type.toUpperCase()} Id</Box>
            <Box sx={{ fontSize: 14, fontWeight: '600', color: theme.palette.primary.main }}>
              {type === 'rfq' ? rfDet.field_rfq_id : rfDet.field_rfx_id}
            </Box>
          </Box>

          <Box>
            <Box sx={{ fontSize: 11, color: 'gray' }}> {type.toUpperCase()} name</Box>
            <Box sx={{ fontSize: 14, fontWeight: '600', color: theme.palette.primary.main }}>
              {type === 'rfq' ? rfDet.title : rfDet.name}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl
            fullWidth
            size="small"
            error={touched.category && errors.category}
            helperText={touched.category && errors.category}
            required
            // sx={{ width: 250 }}
          >
            <InputLabel>Select Category</InputLabel>
            <Select
              value={values.category}
              label="Select Category"
              fullWidth
              {...getFieldProps('category')}
              defaultValue={values.category}
            >
              {categories.map((x) => (
                <MenuItem value={x.tid}>{x.name}</MenuItem>
              ))}

              {/* <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
            {touched.category && <FormHelperText>{errors.category}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} sx={{ flexDirection: 'row', display: 'flex', gap: 1 }}>
          <FormControl
            fullWidth
            size="small"
            error={touched.state && errors.state}
            helperText={touched.state && errors.state}
            required
          >
            <InputLabel>Select Delivery State</InputLabel>
            <Select
              value={values.state}
              label="Select Delivery State"
              fullWidth
              {...getFieldProps('state')}
            >
              {stateList.map((x) => (
                <MenuItem value={x.name}>{x.name}</MenuItem>
              ))}
              {/*               
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
            {touched.state && <FormHelperText>{errors.state}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth size="small" required error={touched.city && errors.city}>
            <InputLabel>Select Delivery City</InputLabel>
            <Select
              value={values.city}
              label="Select Delivery City"
              fullWidth
              {...getFieldProps('city')}
            >
              {cityList.map((x) => (
                <MenuItem value={x.name}>{x.name}</MenuItem>
              ))}
            </Select>
            {touched.city && <FormHelperText>{errors.city}</FormHelperText>}
          </FormControl>
          <TextField
            label="Pincode"
            // multiline
            // rows={2}
            size="small"
            type="number"
            error={Boolean(touched.pincode && errors.pincode)}
            helperText={touched.pincode && errors.pincode}
            inputProps={{ maxLength: 6 }}
            {...getFieldProps('pincode')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl
            fullWidth
            size="small"
            error={touched.serviceType && errors.serviceType}
            helperText={touched.serviceType && errors.serviceType}
            required
          >
            <InputLabel>Select Service Type</InputLabel>
            <Select
              value={values.serviceType}
              label="Select Service Type"
              fullWidth
              {...getFieldProps('serviceType')}
            >
              {/* {serviceTypeList.map((x) => (
                <MenuItem value={x.name}>{x.name}</MenuItem>
              ))} */}

              <MenuItem value="Service">Service</MenuItem>
              <MenuItem value="Material Only">Material Only</MenuItem>
              <MenuItem value="Service With Material">Service With Material</MenuItem>
            </Select>
            {touched.serviceType && <FormHelperText>{errors.serviceType}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>

      {/* <Box sx={{ color: theme.palette.error.main, fontSize: '.8rem' }}>
            Field marked with * is mandatory
          </Box> */}
      <Tabs
        value={value}
        onChange={handleChangeTab}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="1" label="Enter Manually" />
        <Tab
          value="2"
          label="CSV Upload"
          // icon={<TbFileTypeCsv />}
          // iconPosition="start"
        />
        {/* <Tab value="three" label="Item Three" /> */}
      </Tabs>

      {value === '1' && (
        <EditableProductTable
          setProductItems={setProductItems}
          productItems={productItems}
          tableStyle={tableStyle}
          rfDet={rfDet}
          initialVal={initialVal}
          units={units}
          setProductAttachments={setProductAttachments}
          productAttachments={productAttachments}
          commonValues={values}
          type={type}
          addRowFormik={addRowFormik}
          setAddItem={setAddItem}
          addItem={addItem}
          handleEdit={handleEdit}
          submitLoading={submitLoading}
          setSubmitLoading={setSubmitLoading}
          handleSubmitProducts={handleSubmit}
        />
      )}
      {value === '2' && (
        <CsvUpload
          submitLoading={submitLoading}
          setCsvFile={setCsvFile}
          csvFile={csvFile}
          handleSubmitProducts={handleSubmit}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Stack gap={3}>
            {/* <FormControl
                fullWidth
                size="small"
                error={touched.subCategory && errors.subCategory}
                helperText={touched.subCategory && errors.subCategory}
                required
              >
                <InputLabel>Select Sub Category</InputLabel>
                <Select
                  value={values.subCategory}
                  label="Select Sub Category"
                  fullWidth
                  {...getFieldProps('subCategory')}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl> */}
            {/* <FormControl size="small">
                <FormLabel>Minimum Bid Change</FormLabel>
                <RadioGroup defaultValue="na" row {...getFieldProps('bidChange')}>
                  <FormControlLabel
                    value="na"
                    control={<Radio size="small" />}
                    label={<Typography sx={{ fontSize: '0.9rem' }}>N/A</Typography>}
                  />
                  <FormControlLabel
                    value="amount"
                    control={<Radio size="small" />}
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Amount</Typography>}
                  />
                  <FormControlLabel
                    value="percentage"
                    control={<Radio size="small" />}
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Percentage</Typography>}
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                label="Change Value"
                // multiline
                // rows={2}
                size="small"
                error={Boolean(touched.changeValue && errors.changeValue)}
                helperText={touched.changeValue && errors.changeValue}
                inputProps={{ maxLength: 255 }}
                {...getFieldProps('changeValue')}
                fullWidth
              /> */}
            {/* 
            <DropzoneAreaBase
              fileObjects={values.productFile}
              showAlerts={false}
              showPreviews
              previewChipProps={{ classes: { root: classes.previewChip } }}
              showPreviewsInDropzone={false}
              useChipsForPreview
              acceptedFiles={['application/pdf', 'image/jpeg', 'image/png', 'image/bmp']}
              // getFileAddedMessage={fileAdded}
              // getFileRemovedMessage={fileRemove}
              getDropRejectMessage={fileReject}
              Icon={Backup}
              dropzoneText="Drag and drop file"
              onChange={(files) => console.log('Files:', files)}
              onAdd={(newFileObjs) => {
                setFieldValue('productFile', newFileObjs);
              }}
              onDelete={(newFileObjs) => {
                setFieldValue('productFile', newFileObjs);
              }}
              filesLimit={1}
              dropzoneClass={classes.drip}
            />

            <Box>
              <h6>Current file</h6>
              {editData && editData.field_product_catalog && (
                <Box>
                  {editData.field_product_catalog.split('/').pop().split('.').pop() === 'pdf' ? (
                    'pdf'
                  ) : (
                    <img src={editData.field_product_catalog} alt="img" height={100} width={100} />
                  )}
                </Box>
              )}
            </Box> */}
          </Stack>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <Stack gap={3}>
            <FormControl
              variant="standard"
              error={touched.services && errors.services}
              helperText={touched.services && errors.services}
              component="fieldset"
              sx={{ border: '1px solid lightgrey', p: 1, borderRadius: 1 }}
              size="small"
            >
              <FormGroup aria-label="position" column>
                <FormLabel component="legend">Services</FormLabel>
                <Stack direction="row" flexWrap="wrap">
                  <FormControlLabel
                    value={values.services.service}
                    control={
                      <Checkbox
                        {...getFieldProps('services.service')}
                        checked={service}
                        size="small"
                      />
                    }
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Service</Typography>}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={values.services.serviceWithMaterial}
                    control={
                      <Checkbox
                        {...getFieldProps('services.serviceWithMaterial')}
                        checked={serviceWithMaterial}
                        size="small"
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '0.9rem' }}>Service With Material</Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={values.services.materialOnly}
                    control={
                      <Checkbox
                        {...getFieldProps('services.materialOnly')}
                        checked={materialOnly}
                        size="small"
                      />
                    }
                    label={<Typography sx={{ fontSize: '0.9rem' }}>Material Only</Typography>}
                    labelPlacement="end"
                  />
                </Stack>
              </FormGroup>
              <FormHelperText> {touched.services && errors.services}</FormHelperText>
            </FormControl>
            <TextField
              label="Product Name"
              required
              // multiline
              // rows={2}
              size="small"
              error={Boolean(touched.productName && errors.productName)}
              helperText={touched.productName && errors.productName}
              inputProps={{ maxLength: 255 }}
              {...getFieldProps('productName')}
              fullWidth
            />

            <FormControl
              size="small"
              error={touched.unitOfMeasure && errors.unitOfMeasure}
              required
            >
              <InputLabel>Unit Of Measure</InputLabel>
              <Select
                multiple
                value={values.unitOfMeasure}
                onChange={(e) => {
                  setFieldValue(
                    'unitOfMeasure',
                    typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                  );
                }}
                input={<OutlinedInput label="Unit Of Measure" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {units.map((x) => (
                  <MenuItem
                    key={x.tid}
                    value={x.name}
                    // style={getStyles(name, personName, theme)}
                  >
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
              {touched.unitOfMeasure && <FormHelperText>{errors.unitOfMeasure}</FormHelperText>}
            </FormControl>
            <TextField
              label="Minimum Desired Quantity"
              required
              // multiline
              // rows={2}
              type="number"
              size="small"
              error={Boolean(touched.desiredQty && errors.desiredQty)}
              helperText={touched.desiredQty && errors.desiredQty}
              // inputProps={{ maxLength: 255 }}
              {...getFieldProps('desiredQty')}
              fullWidth
            />
            <TextField
              label="Start Price"
              required
              // multiline
              // rows={2}
              type="number"
              size="small"
              error={Boolean(touched.startPrice && errors.startPrice)}
              helperText={touched.startPrice && errors.startPrice}
              // inputProps={{ maxLength: 255 }}
              {...getFieldProps('startPrice')}
              fullWidth
            />

            <FormControl fullWidth size="small" error={touched.payTerms && errors.payTerms}>
              <InputLabel>Payment Terms</InputLabel>
              <Select
                value={values.payTerms}
                label="Payment Terms"
                fullWidth
                {...getFieldProps('payTerms')}
              >
                {terms.map((x) => (
                  <MenuItem value={x.tid}>{x.name}</MenuItem>
                ))}
              </Select>
              {touched.payTerms && <FormHelperText>{errors.payTerms}</FormHelperText>}
            </FormControl>
            {values.payTerms === 'credit' && (
              <FormControl fullWidth size="small">
                <InputLabel>No. Of Days</InputLabel>
                <Select
                  value={values.onCredit}
                  label="No. Of Days"
                  fullWidth
                  {...getFieldProps('onCredit')}
                >
                  {creditOp.map((x) => (
                    <MenuItem value={x.tid}>{x.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {values.payTerms === 'advance payments' && (
              <FormControl fullWidth size="small">
                <InputLabel>Advance on confirmation of po</InputLabel>
                <Select
                  value={values.confPo}
                  label="Advance on confirmation of po"
                  fullWidth
                  {...getFieldProps('confPo')}
                >
                  {aPo.map((x) => (
                    <MenuItem value={x.tid}>{x.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <TextField
              label="Description"
              // required
              multiline
              rows={2}
              // type="number"
              size="small"
              error={Boolean(touched.desc && errors.desc)}
              helperText={touched.desc && errors.desc}
              inputProps={{ maxLength: 255 }}
              {...getFieldProps('desc')}
              fullWidth
            />
            <Stack alignItems="flex-end">
              <Box>
                <Button variant="contained" onClick={() => handleSubmit()}>
                  Submit
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        */}
      </Grid>
    </Box>
  );
};

export default AddPage;
