import { Grid, Box } from '@mui/material';
import React from 'react';
import DetailsCard from './DetailsCard';

const ProjectDetContainer = ({ data }) => {
  const item = [
    {
      name: 'Project name',
      prop: data.field_project_name || '-'
    },
    {
      name: 'Statutary compliance',
      prop: data.field_statutory_compliance || '-'
    },
    {
      name: 'Contact person name',
      prop: data.field_admin_contact_person_name || '-'
    },
    {
      name: 'Admin name',
      prop: data.field_admin_contact_person_name || '-'
    },
    {
      name: 'Email id',
      prop: data.field_admin_email_address || '-'
    },
    {
      name: 'Mobile number',
      prop: data.field_admin_contact_number || '-'
    },
    {
      name: 'Alt mobile number',
      prop: data.field_admin_alternate_contact_nu || '-'
    },
    {
      name: 'Project category',
      prop: data.field_project_category || '-'
    },
    {
      name: 'Total buildings',
      prop: data.field_total_no_of_buildings || '-'
    },
    {
      name: 'Est. cost',
      prop: data.field_estimated_cost_of_project || '-'
    },
    {
      name: 'Starting year',
      prop: data.field_year_of_start_of_project || '-'
    },
    {
      name: 'Year of completion',
      prop: data.field_projected_years_of_complet || '-'
    },
    {
      name: 'Unit cost',
      prop: data.field_unit_cost || '-'
    },
    {
      name: 'Per sqft cost',
      prop: data.field_per_sqft_cost || '-'
    },
    {
      name: 'Target customer',
      prop: data.field_target_customer || '-'
    },
    {
      name: 'Location',
      prop: data.field_site_location || '-'
    }
  ];
  return (
    <Box sx={{ padding: 2, maxHeight: 500, overflow: 'auto' }}>
      {data && (
        <Grid container spacing={2}>
          {item.map((x, i) => (
            <Grid item sm={4} key={i}>
              <DetailsCard item={x} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default ProjectDetContainer;
