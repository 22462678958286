/* eslint-disable camelcase */
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { deleteRequirement } from '../../../apis/BuyerApi';
import { token } from '../../../utils/helper';

export default function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { menu, nid, requirement_id, getList, setSnack } = props;

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRoute = (a) => {
    if (a) {
      navigate(`/dashboard/${a}`, { replace: true });
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOp = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = async (remove) => {
    if (remove === true) {
      const r = await deleteRequirement(token, nid);
      if (r.status === true) {
        getList();
        setSnack({
          open: true,
          color: 'success',
          message: 'Requirement deleted successfully'
        });
      }
      console.log(r);
      /* localStorage.setItem('reqId', requirement_id);
      localStorage.setItem('nid', nid); */
    }
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menu.map((x, index) => (
          <MenuItem key={index} onClick={handleOp}>
            {nid ? (
              <Link
                style={{ textDecoration: 'none', color: 'grey' }}
                to={x.goTo}
                state={{ nid }}
                onClick={() => handleMenuClick(x.remove)}
              >
                {x.text}
              </Link>
            ) : (
              <Link style={{ textDecoration: 'none', color: 'grey' }} to={x.goTo}>
                {x.text}
              </Link>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
/* () => handleRoute(x.goTo) */
