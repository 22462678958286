import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { MobileDateRangePicker } from '@mui/lab';
import { compareAsc } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// material
import { Box, Card, CardContent, CardHeader, TextField } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

export default function ReqChart({ data }) {
  // console.log(data);
  const accept = data.filter((x) => x.field_verification_type === 'Accept');
  const completed = data.filter((x) => x.field_verification_type === 'Completed');
  const pending = data.filter((x) => x.field_verification_type === 'Pending for verification');
  const [value, setValue] = useState([null, null]);
  const [chartData, setChartData] = useState([
    {
      name: 'Requirements',
      data: [data.length, accept.length, completed.length, pending.length]
    }
  ]);
  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: {
        show: false
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => seriesName
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '20%', borderRadius: 2 }
    },
    xaxis: {
      categories: ['Total', 'Verified', 'Completed', 'Pending']
    },
    stroke: {
      curve: 'straight'
    },
    markers: {
      size: 8
    }
  });
  const CHART_DATA = [
    {
      data: [data.length, accept.length, completed.length, pending.length]
    }
  ];

  const checkFilter = (rows) => {
    const newRows = [];
    if (rows)
      rows.forEach((x) => {
        const openTime = new Date(x.schedule[0].open_date * 1000);
        const start = compareAsc(openTime, value[0]);
        const end = compareAsc(openTime, value[1]);
        if ((start === 0 || start === 1) && (end === 0 || end === -1)) {
          // console.log(x.title);
          newRows.push(x);
        }
      });

    return newRows;
  };

  const handleFilter = () => {
    setChartData([
      {
        name: 'Requirements',
        data: [data.length, accept.length, completed.length, pending.length]
      }
    ]);
  };

  // console.log(activeRows);

  useEffect(() => {
    setChartData([
      {
        name: 'Requirements',
        data: [data.length, accept.length, completed.length, pending.length]
      }
    ]);
  }, []);

  useEffect(() => {
    if (value[0]) {
      handleFilter();
    }
  }, [value]);

  return (
    <Card>
      {/*    <CardHeader title="Requirement Chart" /> */}
      <CardContent>
        <Chart options={chartOptions} series={chartData} type="line" height={300} />
      </CardContent>
    </Card>
  );
}
