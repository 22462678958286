import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { styled } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { Field, FieldArray, Form } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { formContext } from '../FormContainer';
import DateInput from './DateInput';

const MonthName = styled(TextField)({
  fontSize: 18,
  padding: 0
});

const DeliveryTable = ({ onDelete }) => {
  const form = useContext(formContext);
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    form.productFormik;

  const items = values.deliveryChart;

  return (
    <>
      <TableContainer sticky sx={{ maxHeight: 200 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableCell>SrNo</TableCell>
            <TableCell align="center">Date of Delivery</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((x, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>

                  <TableCell align="center">{x.date}</TableCell>
                  <TableCell align="center">{x.qty}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => onDelete(i)}>
                      <ClearIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center" sx={{ color: 'red' }}>
                  Add timeline
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DeliveryTable;
