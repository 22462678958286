/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate, useLocation } from 'react-router-dom';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Backdrop, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import Logout from '../utils/Logout';
import { checkAuth, getOtp } from '../apis/LoginApi';
import { getRole, token, userData } from '../utils/helper';
import OtpInput from './OtpInput';

const InfoBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.primary,
  height: '100%'
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

export default function IdleContainer(props) {
  const sessionTimeoutRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const [timer, setTimer] = useState(20);
  const [clicked, setClicked] = useState(false);
  const [click, setClick] = useState(false);
  const [otp, setOtp] = useState(20);
  const [err, setErr] = useState({
    error: false,
    message: ''
  });
  window.onbeforeunload = null;

  const location = useLocation();

  useEffect(() => {
    if (clicked === true) {
      const t = timer >= 0 ? setInterval(() => setTimer(timer - 1), 1000) : initials();
      return () => clearInterval(t);
    }
  }, [clicked, timer]);

  const initials = () => {
    setTimer(20);
    setClicked(false);
  };

  const logOut = () => {
    setOpen(false);
    console.log('loggedOut');
    clearTimeout(sessionTimeoutRef.current);
    Logout();
    // navigate('/login');
    window.location.href = '/login/number';
  };

  const handleOnIdle = () => {
    if (
      location.pathname !== '/login/number' &&
      location.pathname !== '/login/otp' &&
      location.pathname !== '/register' &&
      location.pathname !== '/home' &&
      location.pathname !== '/howItWorks' &&
      location.pathname !== '/404' &&
      location.pathname !== '/contact'
    ) {
      setOpen(true);
      console.log('user is idle');
      // console.log('last active', getLastActiveTime());
      setClicked(true);
      sessionTimeoutRef.current = setTimeout(logOut, 1000 * 20);
    }
  };

  /*  const handleOnActive = (event) => {
    setOpen(false);
    // console.log('user is active', event);
    //  console.log('time remaining', getRemainingTime());
    clearTimeout(sessionTimeoutRef.current);
  }; */

  const handleOnAction = () => {
    setTimer(20);
    setOpen(false);
    setClicked(false);
    clearTimeout(sessionTimeoutRef.current);

    console.log('user did something');
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle
  });

  const loginCheck = async () => {
    if (
      location.pathname !== '/login/number' &&
      location.pathname !== '/login/otp' &&
      location.pathname !== '/home' &&
      location.pathname !== '/howItWorks' &&
      location.pathname !== '/404' &&
      location.pathname !== '/contact'
    ) {
      await checkAuth(token, getRole())
        .then((r) => {
          // console.log(r.data, 'erorr in auth');
          if (!r.data.status) {
            setAuthOpen(true);
          } else {
            setErr({
              error: false,
              message: ''
            });
          }
        })
        .catch((e) => {
          if (
            location.pathname !== '/home' &&
            location.pathname !== '/howItWorks' &&
            location.pathname !== '/contact' &&
            location.pathname !== '/404'
          ) {
            setErr({
              error: true,
              message: e.message
            });
          }
        });
    }
  };

  const loginAgain = async () => {
    setTimer(20);
    const num = userData.mobile;
    const ot = await getOtp(num);
    // const c = await checkOtp(num, o.otp.toString());
    setOtp(ot.otp.toString());
    setClick(true);
    //  setAuthOpen(false);
  };

  const authLogout = async () => {
    setAuthOpen(false);
    logOut();
  };

  const authCheck = useIdleTimer({
    // timeout: 1000 * 60 * 15,
    onAction: loginCheck,
    debounce: 2000
  });

  const AuthInfo = () => {
    const a = 1;
    return (
      <InfoBox>
        <Stack spacing={1}>
          <h4>Another device logged in </h4>
          {click ? (
            <OtpInput otp={otp} setAuthOpen={setAuthOpen} />
          ) : (
            <>
              <Button fullWidth onClick={loginAgain} variant="contained">
                Use here
              </Button>
              <Button fullWidth onClick={authLogout} variant="outlined">
                Log out
              </Button>
            </>
          )}
        </Stack>
      </InfoBox>
    );
  };

  const Info = () => {
    const a = 1;
    return (
      <InfoBox>
        <h4>You will be logged out in {timer}s</h4>
        <Button fullWidth onClick={handleOnAction}>
          Keep me logged in
        </Button>
      </InfoBox>
    );
  };

  return (
    <>
      {props.children}
      <Dialog maxWidth="md" open={open} TransitionComponent={Transition} sx={{ minHeight: 400 }}>
        <Info />
      </Dialog>
      <Dialog
        maxWidth="md"
        open={authOpen}
        TransitionComponent={Transition}
        sx={{ minHeight: 400 }}
      >
        <AuthInfo />
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={err.error}>
        <Stack spacing={2} alignItems="center">
          <CloudOffIcon sx={{ width: 60, height: 60 }} />
          <Typography>{err.message}</Typography>
        </Stack>
      </Backdrop>
    </>
  );
}
