import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import Label from '../../../components/Label';

export const Containerr = styled(Paper)(({ theme }) => ({
  margin: '1rem 0rem',
  padding: '1rem'
}));

export const HeadingText = styled(Label)(({ theme }) => ({
  padding: '1rem',
  fontSize: '1rem'
}));

export const Input = styled('input')({
  display: 'none'
});

export const LabelText = styled('div')({
  color: 'grey',
  fontSize: 14,
  marginBottom: '.5rem'
});

export const Helper = styled('div')({
  color: 'red',
  fontSize: 12,
  marginLeft: 14
});
