import { AddCircleTwoTone, ScheduleTwoTone } from '@material-ui/icons';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { enqueueSnackbar } from 'notistack';
import { DateTimePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import FullDialog from '../../../../components/_dashboard/app/FullDialog';
import Label from '../../../../components/Label';
import { addEditSchedule } from '../../../../apis/BuyerApi';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const initalState = {
  openDate: null,
  closeDate: null,
  desc: ''
};

const Schedule = ({ row, getList }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initalState);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  // console.log(row.schedule, row.nid, 'rowswwwww');
  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const handleSubmit = async () => {
    if (formData.openDate !== null && formData.closeDate !== null) {
      const params = {
        open_date: formData.openDate.format('MM/DD/YYYY HH:mm'),
        close_date: formData.closeDate.format('MM/DD/YYYY HH:mm'),
        round_name: 'a',
        round_description: 'a',
        reminder: '1'
      };

      if (row.schedule.length > 0) {
        params.sid = row.schedule[row.schedule.length - 1].id;
      } else {
        params.nid = row.nid;
      }

      await addEditSchedule(params).then((res) => {
        if (res.status) {
          enqueueSnackbar('Schedule Created Successfully!', {
            variant: 'success'
          });
          getList();
          handleClose();
        }
      });
    } else {
      enqueueSnackbar('Please fill all fields!', {
        variant: 'error'
      });
    }

    // console.log(formData);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(initalState);
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          // handleMenuClose();
          setOpen(true);
        }}
      >
        <ListItemIcon sx={{ mr: 0 }}>
          <ScheduleTwoTone style={{ color: theme.palette.primary.main, fontSize: 20 }} />
        </ListItemIcon>
        Schedule
      </MenuItem>
      {/* <Button variant="contained" onClick={() => setOpen(true)}>
        <AddCircleTwoTone style={{ fontSize: 20, marginRight: 5 }} />
        Schedule
      </Button> */}
      <FullDialog
        open={open}
        setOpen={setOpen}
        headName="Schedule"
        subHead="Field marked as * are required"
        maxWidth="sm"
        minWidth="sm"
        onClose={handleClose}
      >
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              {row.schedule.length > 0 ? (
                <>
                  <Label variant="ghost" color="success" style={{ fontSize: 12, padding: 12 }}>
                    {moment(
                      parseInt(row.schedule[row.schedule.length - 1].open_date, 10) * 1000
                    ).format('DD-MM-YYYY hh:mm A')}
                  </Label>{' '}
                  -{' '}
                  <Label variant="ghost" color="error" style={{ fontSize: 12, padding: 12 }}>
                    {moment(
                      parseInt(row.schedule[row.schedule.length - 1].close_date, 10) * 1000
                    ).format('DD-MM-YYYY hh:mm A')}
                  </Label>
                </>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack
                  flexDirection={isMdDown ? 'column' : 'row'}
                  alignItems="center"
                  gap={2}
                  justifyContent="center"
                >
                  <DateTimePicker
                    fullWidth
                    label="Open Date"
                    value={formData.openDate}
                    onChange={(newValue) => handleChange('openDate', newValue)}
                    format="DD-MM-YYYY hh:mm A"
                    disablePast
                    required
                    // ampmInClock
                  />
                  -
                  <DateTimePicker
                    fullWidth
                    label="Close Date"
                    value={formData.closeDate}
                    onChange={(newValue) => handleChange('closeDate', newValue)}
                    format="DD-MM-YYYY hh:mm A"
                    disablePast
                    required
                    // ampmInClock
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack flexDirection="row" justifyContent="flex-end">
                <Button variant="contained" onClick={() => handleSubmit()}>
                  {row.schedule.length > 0 ? 'Update' : 'Submit'}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </FullDialog>
    </>
  );
};

export default Schedule;
