import { Grid, Link, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const Item = styled('div')(({ theme, right, bottom }) => ({
  /* backgroundColor: theme.palette.warning.lighter, */
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRight: !right && 'dashed 1px grey',
  borderBottom: !bottom && 'dashed 1px grey',
  height: 80,
  padding: '.5rem'
}));

const ItemHeading = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 800,
  wordWrap: 'break-word',
  textAlign: 'center'
}));

const ItemDesc = styled('div')(({ theme, right }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));

const ViewCompanyInfo = ({ data }) => {
  // console.log(data);
  const a = 1;
  return (
    <div>
      <Grid container sx={{ maxHeight: 400, minWidth: 500, overflow: 'auto' }}>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item>
              <ItemHeading>{data.company_name || '-'}</ItemHeading>
              <ItemDesc>Name</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_country || '-'}</ItemHeading>
              <ItemDesc>Country</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_state || '-'}</ItemHeading>
              <ItemDesc>State</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_city || '-'}</ItemHeading>
              <ItemDesc>City</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_pin_code || '-'}</ItemHeading>
              <ItemDesc>PIN code</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_company_address || '-'}</ItemHeading>
              <ItemDesc>Address</ItemDesc>
            </Item>
            <Item>
              <ItemHeading>{data.field_company_turnover || '-'}</ItemHeading>
              <ItemDesc>Turnover</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Stack>
            <Item right>
              <ItemHeading>{data.field_type_of_organization || '-'}</ItemHeading>
              <ItemDesc>Organization type</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{data.field_type_of_business || '-'}</ItemHeading>
              <ItemDesc>Business type</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{data.field_pan_number_vendor || '-'}</ItemHeading>
              <ItemDesc>PAN card number</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{data.field_gst_number_vendor || '-'}</ItemHeading>
              <ItemDesc>GST number</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{data.field_email_id || '-'} </ItemHeading>
              <ItemDesc>Email Id</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{data.field_mobile_number_owner_vendor || '-'}</ItemHeading>
              <ItemDesc>Phone number</ItemDesc>
            </Item>
            <Item right>
              <ItemHeading>{data.field_company_established_in_yea || '-'}</ItemHeading>
              <ItemDesc>Company established in year</ItemDesc>
            </Item>
          </Stack>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Item bottom right>
            {data.field_certificates ? (
              <Link href={data.field_certificates} target="_blank">
                View
              </Link>
            ) : (
              <ItemHeading>{data.field_certificates || '-'}</ItemHeading>
            )}

            <ItemDesc>Certificate</ItemDesc>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewCompanyInfo;
