/* eslint-disable camelcase */
import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHeading from '../../components/table/TableHeading';
// import { Heading, ItemContainer } from '../../../components/table/styles';
import { getComparator, stableSort } from '../../components/table/utils';
import { headCells, rows } from './data';
// import BasicMenu from '../components/BasicMenu';

const Loader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60vh',
  color: theme.palette.primary.main
}));

export default function NotiTable({ rows }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filtered = stableSort(rows, getComparator(order, orderBy));

  const NotFound = filtered.length === 0;

  return (
    <Box sx={{ width: '100%' }}>
      {rows.length > 0 ? (
        <>
          <TableContainer sx={{ minWidth: 650 }}>
            <Table>
              <TableHeading
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows && rows.length}
                headCells={headCells}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {filtered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const { message, timestamp } = row;
                    const Srno = index + 1;
                    const monthName = [
                      'Jan',
                      'Feb',
                      'Mar',
                      'Apr',
                      'May',
                      'Jun',
                      'Jul',
                      'Aug',
                      'Sep',
                      'Oct',
                      'Nov',
                      'Dec'
                    ];

                    const formatAMPM = (date) => {
                      let hours = date.getHours();
                      let minutes = date.getMinutes();
                      const ampm = hours >= 12 ? 'pm' : 'am';
                      hours %= 12;
                      hours = hours || 12;
                      minutes = minutes.toString().padStart(2, '0');
                      const strTime = `${hours}:${minutes} ${ampm}`;
                      return strTime;
                    };
                    const time = new Date(timestamp * 1000);
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell>{Srno}</TableCell>
                        <TableCell>{message}</TableCell>
                        <TableCell>{`${monthName[time.getMonth()]} ${time.getDate()} ${formatAMPM(
                          time
                        )}`}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {NotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      Not found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{' '}
        </>
      ) : (
        <Loader>no data found</Loader>
      )}
    </Box>
  );
}
