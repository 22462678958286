import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import { FileDownloadTwoTone } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import TaskBar from '../../../../components/_dashboard/app/TaskBar';
import QuotationList from './QuotationList';
import { getRfqBidList } from '../../../../apis/BuyerApi';

const ViewQuotation = () => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const { rfDet, type } = location.state;
  const [rows, setRows] = useState([]);
  const [query, setQuery] = useState('');
  const getList = async () => {
    await getRfqBidList({
      nid: rfDet.nid
    }).then((res) => {
      if (res.status) {
        setRows(res.result);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.pid.toLowerCase().includes(query.toLowerCase()) ||
        x.user_full_name.toString().toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  const getCsvProps = () => {
    if (rows && rows.length > 0) {
      const column = Object.keys(rows[0]).map((x) => ({ key: x, label: x }));

      const data = rows;
      const finalRows = [];
      data.forEach((x, index) => {
        const srNo = index + 1;
        const created = moment(parseInt(x.q_time, 10) * 1000).format('DD-MM-YYYY HH:mm');
        const temp = { ...x, srNo, q_time: created };
        finalRows.push(temp);
      });

      const csvReport = {
        data: finalRows,
        headers: [...column],
        filename: `${rfDet.title}-bidlist.csv`
      };
      // console.log(csvReport);
      return csvReport;
    }
  };

  return (
    <div>
      <TaskBar
        leftItems={() => (
          <>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> RFQ Name: </span> {rfDet.title}
            </Box>
            <Box sx={{ fontSize: 14 }}>
              <span style={{ fontWeight: 700 }}> RFQ ID: </span> {rfDet.field_rfq_id}
            </Box>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search"
              sx={{ mt: '0px !important' }}
              fullWidth={isMdDown}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </>
        )}
        rightItems={() => (
          <>
            {rows.length > 0 && (
              <CSVLink {...getCsvProps()}>
                <Button variant="outlined" size="small">
                  <FileDownloadTwoTone style={{ fontSize: 20, marginRight: 5 }} />
                  CSV
                </Button>
              </CSVLink>
            )}
            {/* <Button variant="outlined" size="small">
              <FileDownloadTwoTone style={{ fontSize: 20, marginRight: 5 }} />
              Excel
            </Button>
            <Button variant="outlined" size="small">
              <FileDownloadTwoTone style={{ fontSize: 20, marginRight: 5 }} />
              PDF
            </Button> */}
          </>
        )}
      />
      <QuotationList rows={!query ? rows : filtered()} />
    </div>
  );
};

export default ViewQuotation;
