import React from 'react';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const InThisBid = () => {
  const hello = <h1>in this bid</h1>;
  return (
    <div>
      <Typography color="primary" variant="h6" sx={{ opacity: 0.7 }}>
        In This Bid
      </Typography>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Items</TableCell>
              <TableCell align="right">Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell>
                <Typography variant="body2" sx={{ opacity: 0.9 }}>
                  Some Items
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.6 }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell align="right" variant="body2">
                100
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <Typography variant="body2" sx={{ opacity: 0.9 }}>
                  Some Items
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.6 }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell align="right" variant="body2">
                100
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <Typography variant="body2" sx={{ opacity: 0.9 }}>
                  Some Items
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.6 }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell align="right" variant="body2">
                100
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InThisBid;
