import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { FileDownloadTwoTone } from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import NewRfq from './newRfq/NewRfq';
import TaskBar from '../../../components/_dashboard/app/TaskBar';
import RfqList from './RfqList';
import { getBuyerRfqList } from '../../../apis/BuyerApi';

const tableHead = [
  { id: 'srNo', label: 'SrNo.', align: 'left' },
  { id: 'rfq_id', label: 'RFQ ID', align: 'left' },
  { id: 'rfq_name', label: 'RFQ Name', align: 'left' },
  { id: 'field_description', label: 'Description', align: 'center' },
  { id: 'schedule', label: 'Schedule', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  // { id: 'field_start_time_minutes_', label: 'Open time', align: 'center' },
  // { id: 'field_close_time_minutes_', label: 'Close time', align: 'center' },
  { id: 'actions', label: 'Actions', align: 'right' }
];

const Rfq = () => {
  const [rows, setRows] = useState([]);
  const [query, setQuery] = useState('');
  const getList = async () => {
    await getBuyerRfqList().then((res) => {
      if (res.status) {
        const arr = [...res.result].reverse();
        setRows(arr);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    filtered();
  }, [query]);

  const filtered = () => {
    const r = rows.filter(
      (x) =>
        x.field_rfq_id.toLowerCase().includes(query.toLowerCase()) ||
        x.title.toString().toLowerCase().includes(query.toLowerCase())
    );
    return r;
  };

  // const csvRows = () => {
  //   const data = rows;
  //   const finalRows = [];
  //   data.forEach((x, index) => {
  //     const srNo = index + 1;
  //     const startDate = moment(parseInt(x.schedule[x.schedule.length - 1].close_date, 10) * 1000).format(
  //       'DD-MM-YYYY HH:mm'
  //     );
  //     const endDate = moment(parseInt(x.schedule[x.schedule.length - 1].close_date, 10) * 1000).format(
  //       'DD-MM-YYYY HH:mm'
  //     );
  //     const temp = { ...x, srNo, startDate, endDate };
  //     finalRows.push(temp);
  //   });
  //   return finalRows;
  // };

  const getCsvProps = () => {
    if (rows && rows.length > 0) {
      const column = Object.keys(rows[0])
        .filter((y) => y !== 'schedule')
        .map((x) => ({ key: x, label: x }));
      column.push({ key: 'startDate', label: 'startDate' });
      column.push({ key: 'endDate', label: 'endDate' });

      const data = rows;
      const finalRows = [];
      data.forEach((x, index) => {
        const srNo = index + 1;
        let startDate = '';
        let endDate = '';
        if (x.schedule.length > 0) {
          startDate = moment(
            parseInt(x.schedule[x.schedule.length - 1].close_date, 10) * 1000
          ).format('DD-MM-YYYY HH:mm');
          endDate = moment(
            parseInt(x.schedule[x.schedule.length - 1].close_date, 10) * 1000
          ).format('DD-MM-YYYY HH:mm');
        }
        const created = moment(parseInt(x.created, 10) * 1000).format('DD-MM-YYYY HH:mm');
        const temp = { ...x, srNo, startDate, endDate, created };
        finalRows.push(temp);
      });

      const csvReport = {
        data: finalRows,
        headers: [...column],
        filename: 'rfqlist.csv'
      };
      // console.log(csvReport);
      return csvReport;
    }
  };

  return (
    <div>
      <TaskBar
        leftItems={() => (
          <>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search"
              sx={{ mt: '0px !important' }}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              fullWidth
            />
          </>
        )}
        rightItems={() => (
          <>
            {rows.length > 0 && (
              <CSVLink {...getCsvProps()}>
                <Button variant="outlined" size="small">
                  <FileDownloadTwoTone style={{ fontSize: 20, marginRight: 5 }} />
                  CSV
                </Button>
              </CSVLink>
            )}

            <NewRfq getList={getList} />
          </>
        )}
      />
      <RfqList rows={!query ? rows : filtered()} getList={getList} tableHead={tableHead} />
    </div>
  );
};

export default Rfq;
