export const headCells = [
  { id: 'Srno', label: 'SrNo.', align: 'left', maxWidth: 50 },
  { id: 'product_id', label: 'Product id', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'category', label: 'Category', align: 'left' },
  { id: 'field_place_of_supply', label: 'Location', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'right' }
];

export const rows = [
  {
    srNo: 1,
    name: 'item 1',
    category: 'Office item',
    address: '113, 114, Balaji platinum, main road, Athal, DDDNH',
    maxQty: 100,
    measure: 'kg',
    description: 'very good product for Office'
  },
  {
    srNo: 2,
    name: 'item 2',
    category: 'Office item',
    address: '113, 114, Balaji platinum, main road, Athal, DDDNH',
    maxQty: 100,
    measure: 'kg',
    description: 'very good product for Office'
  },
  {
    srNo: 3,
    name: 'item 3',
    category: 'Office item',
    address: '113, 114, Balaji platinum, main road, Athal, DDDNH',
    maxQty: 100,
    measure: 'kg',
    description: 'very good product for Office'
  },
  {
    srNo: 4,
    name: 'item 4',
    category: 'Office item',
    address: '113, 114, Balaji platinum, main road, Athal, DDDNH',
    maxQty: 100,
    measure: 'kg',
    description: 'very good product for Office'
  },
  {
    srNo: 5,
    name: 'item 5',
    category: 'Office item',
    address: '113, 114, Balaji platinum, main road, Athal, DDDNH',
    maxQty: 100,
    measure: 'kg',
    description: 'very good product for Office'
  },
  {
    srNo: 6,
    name: 'item 6',
    category: 'Office item',
    address: '113, 114, Balaji platinum, main road, Athal, DDDNH',
    maxQty: 100,
    measure: 'kg',
    description: 'very good product for Office'
  }
];
