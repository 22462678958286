import { Button, Card, CardActions, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { BtnContainer, CompanyBody, CompanyHeader, Item, ItemText, useStyles } from './styles';

const CompanyCard = ({ data, setOpen, setDetails }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleAction = () => {
    setOpen(true);
    setDetails(data);
  };

  return (
    <>
      {data && (
        <Card>
          <CompanyHeader>
            <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary">
              {data.company_name}
            </Typography>
            <Typography color="text.secondary" sx={{ fontSize: 12 }}>
              {data.field_type_of_organization}
            </Typography>
          </CompanyHeader>
          <CompanyBody>
            <Item>
              <BusinessIcon className={classes.icon} />
              <ItemText> {data.field_type_of_business}</ItemText>
            </Item>
            <Item>
              <EmailIcon className={classes.icon} />
              <ItemText> {data.field_email_id || 'N/A'}</ItemText>
            </Item>
            <Item>
              <PhoneIcon className={classes.icon} />
              <ItemText>{data.field_mobile_number_owner_vendor || 'N/A'}</ItemText>
            </Item>
            <Item>
              <LocationOnIcon className={classes.icon} />
              <ItemText>
                <Tooltip title={data.field_residence_address || 'N/A'} placement="bottom">
                  <div>
                    <LinesEllipsis
                      text={data.field_residence_address || 'N/A'}
                      maxLine="1"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </div>
                </Tooltip>
                {/* {data.field_residence_address} */}
              </ItemText>
            </Item>
          </CompanyBody>

          <BtnContainer>
            <Button
              size="small"
              variant="outlined"
              onClick={() =>
                navigate('/profile/products', { state: { products: data.company_product } })
              }
            >
              View products
            </Button>
            <Button size="small" variant="contained" onClick={handleAction}>
              View more
            </Button>
          </BtnContainer>
        </Card>
      )}
    </>
  );
};

export default CompanyCard;
