import { Box, Card, Link, Paper, Stack } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { AttachmentTwoTone, EventTwoTone } from '@material-ui/icons';
import Label from '../../../components/Label';
import RfqMenu from './RfqMenu';
import { rfqStatus } from '../../../utils/helper';

const MobileRfqCard = ({ row, index }) => {
  console.log('a');
  return (
    <Paper sx={{ borderBottom: '1px solid lightgrey', m: 1, p: 1 }} square>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box>{index + 1}</Box>
          <Box>
            <Box sx={{ fontWeight: '600' }}>{row.title}</Box>
            <Box sx={{ fontSize: 12, color: 'grey' }}>{row.field_rfq_id}</Box>
          </Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Label variant="ghost" color="default" style={{ fontSize: 12, padding: 12 }}>
            {rfqStatus.find((x) => x.id === parseInt(row.status, 10)).label}
          </Label>
          <RfqMenu row={row} />
        </Stack>
      </Stack>

      <Stack sx={{ ml: 1.5, mt: 0.8 }} gap={0.8}>
        {row.schedule.length > 0 ? (
          <>
            <Box>
              <Label
                variant="ghost"
                color="success"
                style={{ fontSize: 12, padding: 12, backgroundColor: 'transparent' }}
              >
                <EventTwoTone style={{ fontSize: 16, marginRight: 8 }} />
                {moment(
                  parseInt(row.schedule[row.schedule.length - 1].open_date, 10) * 1000
                ).format('DD-MM-YYYY hh:mm A')}
              </Label>
            </Box>

            <Box>
              <Label
                variant="ghost"
                color="error"
                style={{ fontSize: 12, padding: 12, backgroundColor: 'transparent' }}
              >
                <EventTwoTone style={{ fontSize: 16, marginRight: 8 }} />
                {moment(
                  parseInt(row.schedule[row.schedule.length - 1].close_date, 10) * 1000
                ).format('DD-MM-YYYY hh:mm A')}
              </Label>
            </Box>
          </>
        ) : (
          ''
        )}

        {row.field_rfq_attachment && (
          <Box>
            <AttachmentTwoTone style={{ fontSize: 16, marginRight: 8, marginLeft: 12 }} />
            <Link target="_blank" href={row.field_rfq_attachment} sx={{ fontSize: 13 }}>
              Attachment
            </Link>
          </Box>
        )}
      </Stack>
    </Paper>
  );
};

export default MobileRfqCard;
