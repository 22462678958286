import { Divider, Grid } from '@mui/material';
import React, { useState } from 'react';
import FullDialog from './components/FullDialog';
import ViewCompanyInfo from './ViewCompanyInfo';
import CompanyCard from './CompanyCard';
import { CompanyCardSkeleton } from './components/skeletons';
import NoDataFound from '../../../components/NoDataFound';

const Company = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <h2 style={{ padding: '.5rem', color: 'grey' }}>Company</h2>
          <Divider />
        </Grid>
        {data ? (
          <>
            {data.company_details.length === 0 ? (
              <NoDataFound width="100%" height="100%" />
            ) : (
              <>
                {data.company_details.map((x, i) => (
                  <Grid item sm={6} xs={12} md={4} key={i}>
                    <CompanyCard setOpen={setOpen} setDetails={setDetails} data={x} />
                  </Grid>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            <Grid item sm={6} md={4} xs={12}>
              <CompanyCardSkeleton />
            </Grid>
            <Grid item sm={6} md={4} xs={12}>
              <CompanyCardSkeleton />
            </Grid>
            <Grid item sm={6} md={4} xs={12}>
              <CompanyCardSkeleton />
            </Grid>
          </>
        )}
      </Grid>
      <FullDialog open={open} setOpen={setOpen} headName="Company Information">
        <ViewCompanyInfo data={details} />
      </FullDialog>
    </>
  );
};

export default Company;
