/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import { useAutocomplete, Grid, IconButton, Paper, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input } from '@mui/base';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block'
  },
  input: {
    // width: 200,
    fontSize: 12,
    paddingLeft: 5,
    border: 'none',
    resize: 'none',
    outline: 'none'
  },
  listbox: {
    width: 'auto',
    fontSize: 12,
    margin: 0,

    // borderBottomLeftRadius: 6,
    // borderBottomRightRadius: 6,
    // elevation: 6,
    zIndex: 1000,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    // border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#13098e',
      color: 'white',
      cursor: 'pointer'
    },
    '& li:active': {
      backgroundColor: '#13098e',
      color: 'white'
    }
  },
  saltText: {
    fontSize: '10px !important',
    color: 'grey',
    paddingLeft: 8
  }
}));

export default function EditableProductCell({
  width,
  dataList,
  first,
  medName,
  data,
  index,
  setMedType,
  medType,
  handleKeyPress,
  fieldIndex,
  saltCom,
  isMdDown,
  setIsDisabled,
  inputVal,
  setInputVal,
  setSwitchCell,
  productItems,
  tableStyle,
  handleAddMedicines,
  addNewMedicine,
  editProduct
}) {
  const classes = useStyles();

  const [selectedMed, setSelectedMed] = useState([]);

  const [searchResult, setSearchResult] = useState([]);

  const handleInputChange = (val, medSalt) => {
    console.log(val, 'from input change');
    if (val !== data.medname) {
      setInputVal(val || '');
      if (val) fetchMedResults(val || '');
    }
  };

  const goToNextField = (id) => {
    setSwitchCell(false);
    handleKeyPress(id);
  };

  // useEffect(() => {
  //   fetchMedResults("a");
  // }, []);

  const handleAdd = (val, e) => {
    const checkDuplicate = productItems.find((cData) => cData.medname === val.medname);
    if (checkDuplicate === undefined) {
      // console.log(val, 'from handle add')
      handleInputChange(typeof val === 'object' ? val.medname : val);
      // console.log(val, 'hanldleadd0');
      setSelectedMed(val);
      // setInputVal(typeof val == 'object' ? val.medname : val);

      if (typeof val === 'object') {
        goToNextField(`med${index}-${fieldIndex + 3}`);
        handleAddMedicines({ ...val, medstripqty: 1, qty: 1 }, index);
        editProduct(val, 1, 1);
      } else {
        console.log(val, 'hanldleadd4');

        goToNextField(`med${index}-${fieldIndex + 1}`);
      }
    }
  };

  const fetchMedResults = async (searchTxt) => {
    // const sellerRes = localStorage.getItem("sellerRes");
    // const parsedInfo = sellerRes ? JSON.parse(sellerRes).sellerDetail : {};
    const filterArr = [];
    // await searchMedicalProducts({
    //   // seller_id: parsedInfo.id,
    //   medicineName: searchTxt
    // }).then((res) => {
    //   if (res.data.success == true) {
    //     const arr = res.data.data;
    //     filterArr = arr.map((x) => ({
    //       medid: x.product_id,
    //       medname: x.name,
    //       mrp: x.p_mrp,
    //       rtot: x.discounted_amount,
    //       dis: x.discount_per,
    //       camount: x.actual_cost
    //     }));
    //   }
    // });

    setSearchResult(filterArr);
  };

  const getDefaultValue = () => {
    // console.log(data, 'from default value')
    setInputVal(data.medname || '');

    // } else {
    //   setInputVal('');

    // }
    return data.medname;
  };

  useEffect(() => {
    if (data) getDefaultValue();
  }, [data, medType]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    inputValue
  } = useAutocomplete({
    id: `med${index}-${fieldIndex}`,
    options: searchResult,
    getOptionLabel: (option) => option.medname || '',
    freeSolo: data.cartItem === true,
    openOnFocus: true,
    onInputChange: (e) => handleInputChange(e ? e.target.value : data.medname),
    onChange: (e, val) => {
      console.log(e.medid, val, 'onchnageeee');
      if (val) {
        if (val.medid === '') {
          console.log('newMed2');
        } else {
          handleAdd({ ...val, cartItem: true }, e);
          // handleAdd(val, e);
        }
      }
    },
    inputValue: inputVal,
    multiple: medType === 'generic',
    value: selectedMed,

    disabled: false
  });

  const onFieldFocus = () => {
    setSwitchCell(true);

    if (data.type === 'generic') {
      const newArr = data.medname.split(' + ').map((item) => ({
        salt_name: item
      }));

      setSelectedMed(newArr);
      setInputVal('');
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Box {...getRootProps()} onMouseDown={(e) => e.preventDefault()}>
        <Input
          className={classes.input}
          {...getInputProps()}
          style={{
            width: '100%',
            height: 25,
            border: 'none'
          }}
          onMouseDown={(e) => e.preventDefault()}
          onBlur={(e) => {
            getInputProps().onBlur();
            console.log(data, e.medid, selectedMed, 'iamhere3');
            setInputVal(data.medname || '');
          }}
        />
      </Box>

      {groupedOptions.length > 0 ? (
        <Paper elevation={6} className={tableStyle.listbox} component="ul" {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })} style={{ padding: '2px 10px' }}>
              {option.medname}
            </li>
          ))}
        </Paper>
      ) : null}
    </div>
  );
}
