import * as Yup from 'yup';

export const reqSchema = Yup.object().shape({
  category: Yup.string().required('category is required'),
  description: Yup.string().required('highlights is required'),
  company: Yup.string().required('company is required')
});

export const productSchema = Yup.object().shape({
  productName: Yup.string().nullable().required('product name is required'),

  location: Yup.string().required('location is required'),
  // unitOfMeasure: Yup.string().required('Unit of measure is required'),
  targetPrice: Yup.number()
    .typeError('you must specify a number')
    .required('target price is required'),
  payTerms: Yup.string().required('payment terms is required'),
  confPo: Yup.string().when('payTerms', {
    is: 'advance payments',
    then: Yup.string().required('confirmation of PO is required')
  }),
  onCredit: Yup.string().when('payTerms', {
    is: 'credit',
    then: Yup.string().required('No. of days on credit is required')
  }),
  /*  minQty: Yup.number().typeError('you must specify a number').required('quantity is required'), */
  deliveryTerms: Yup.string().required('delivery terms is required'),
  productFile: Yup.array().length(1, 'Product specification is required'),
  immediate: Yup.boolean(),
  totalQty: Yup.number().when('immediate', {
    is: true,
    then: Yup.number().typeError('you must specify a number').required('Total quantity is required')
  }),
  paymentMode: Yup.object({
    cash: Yup.boolean(),
    card: Yup.boolean(),
    netBanking: Yup.boolean(),
    cheque: Yup.boolean()
  }).test('myCustomTest', null, (obj) => {
    if (obj.cash || obj.card || obj.netBanking || obj.cheque) {
      return true; // everything is fine
    }

    return new Yup.ValidationError('Please check at least one checkbox', null, 'paymentMode');
  })
  /* deliveryChart: Yup.array().when('immediate', {
    is: false,
    then: Yup.array().length('noOfMonths', 'timeline is required')
  }) */

  /*  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
      emailId: Yup.string()
        .email('Email must be a valid email address')
        .required('Email is required'),
  
      turnover: Yup.number('only number is allowed').required('turnover is required'),
      address: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Address is required') */
});

export const newProductSchema = Yup.object().shape({
  category: Yup.string().required('category is required'),
  description: Yup.string().required('description is required'),
  name: Yup.string().required('product name is required'),
  unitOfMeasure: Yup.string().required('unit of measure is required'),
  productFile: Yup.array().length(1, 'Product specification is required')
});
