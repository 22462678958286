/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  Button,
  useScrollTrigger,
  CssBaseline,
  Stack,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdbIcon from '@mui/icons-material/Adb';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import { token, userData } from '../../../utils/helper';

// const pages = ['How it works', 'Contact us'];
const pages = [
  {
    name: 'How it works',
    goTo: '/howItWorks'
  },
  {
    name: 'Contact us',
    goTo: '/contact'
  }
];

const auth = [
  {
    name: 'Login',
    goTo: '/login'
  },
  {
    name: 'Register',
    goTo: '/contact'
  }
];
/* const settings = ['Profile',  'Dashboard', 'Logout']; */
const settings = [
  {
    name: 'Profile',
    goTo: '/profile'
  },
  {
    name: 'Dashboard',
    goTo: '/dashboard'
  },
  {
    name: 'Logout',
    goTo: '/login'
  }
];

const NavTab = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElAuth, setAnchorElAuth] = React.useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const matches = useMediaQuery('(min-width:600px)');

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenAuthMenu = (event) => {
    setAnchorElAuth(event.currentTarget);
  };

  const handleCloseNavMenu = (goTo) => {
    setAnchorElNav(null);
    navigate(goTo);
  };

  const handleCloseUserMenu = (goTo) => {
    setAnchorElUser(null);
    navigate(goTo);
  };
  const handleCloseAuthMenu = (goTo) => {
    setAnchorElAuth(null);
    navigate(goTo);
  };

  function stringAvatar(name) {
    return {
      sx: {},
      children: `${name.split(' ')[0][0]}`.toUpperCase()
    };
  }

  /*   sx={{
    background: 'linear-gradient( RGBA(0,0,0, 0.8), RGBA(255, 255, 255, 0.1))',
    boxShadow: 'none',
    borderShadow: 'RGBA(255, 255, 255, 0.1)'
  }} */
  //  console.log(pathname);

  return (
    <AppBar position="fixed" sx={{ px: matches ? '40px' : '' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{ height: '50px', minHeight: '50px' }}>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img src="static/svglogo.svg" alt="logo" height="35px" width="auto" />
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/home"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            NIRMAAN PROCURA
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={() => handleCloseNavMenu(page.goTo)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/home"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            NIRMAAN PROCURA
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <Box
                key={index}
                onClick={() => handleCloseNavMenu(page.goTo)}
                sx={{
                  margin: '0px 8px',
                  height: '50px',
                  display: 'flex',
                  px: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderBottom: pathname === page.goTo ? '3px solid white' : '',
                  '&:hover': {
                    borderBottom: '3px solid white'
                  }
                }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{page.name}</Typography>
              </Box>
            ))}
          </Box>
          {token ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="avatar"
                    src="/static/images/avatar/2.jpg"
                    {...stringAvatar(userData.fullName)}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
              >
                {settings.map((setting, index) => (
                  <MenuItem key={index} onClick={() => handleCloseUserMenu(setting.goTo)}>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <>
              <Stack direction="row" spacing={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button sx={{ color: 'white' }} onClick={() => navigate('/login')}>
                  Login
                </Button>
                <Button
                  variant="outlined"
                  sx={{ color: 'white', borderColor: 'white' }}
                  onClick={() => navigate('/contact')}
                >
                  Register
                </Button>
              </Stack>
              <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenAuthMenu} sx={{ p: 0 }} color="inherit">
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElAuth}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElAuth)}
                  onClose={() => setAnchorElAuth(null)}
                >
                  {auth.map((a, index) => (
                    <MenuItem key={index} onClick={() => handleCloseAuthMenu(a.goTo)}>
                      <Typography textAlign="center">{a.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavTab;
